import {TreeConfig} from '@spout/global-any/models';
import {uuid} from '../uuid';

export function createInitialConfig(
  id?: string | null,
  name?: string
): Partial<TreeConfig> {
  return {
    id: id ? id : uuid(),
    isDefault: false,
    parentId: '',
    name: name ? name : '',
    parentName: '',
    description: ''
  };
}
