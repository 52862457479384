export interface Role {
  author: boolean;
  owner: boolean;
  collaborator: boolean;
  readOnly: boolean;
  anonymous: boolean;
  reviewer: boolean;
}

export enum PROJECT_ROLE {
  Author = 'author',
  Owner = 'owner',
  Collaborator = 'collaborator',
  ReadOnly = 'readOnly',
  Anonymous = 'anonymous',
  Reviewer = 'reviewer'
}

export const PROJECT_ROLE_NAME: {[key: string]: string} = {
  [PROJECT_ROLE.Author]: 'Author',
  [PROJECT_ROLE.Owner]: 'Owner',
  [PROJECT_ROLE.Collaborator]: 'Collaborator',
  [PROJECT_ROLE.ReadOnly]: 'Read Only',
  [PROJECT_ROLE.Anonymous]: 'Anonymous',
  [PROJECT_ROLE.Reviewer]: 'Reviewer'
};

export function getProjectRoleName(role: PROJECT_ROLE): string {
  return PROJECT_ROLE_NAME[role];
}

export function getProjectRoleTitle(role: Role): string {
  return Object.entries(role).reduce((a: string, i: [string, boolean]) => {
    if (i[1]) {
      return getProjectRoleName(<PROJECT_ROLE>i[0]);
    }

    return a;
  }, getProjectRoleName(PROJECT_ROLE.Reviewer));
}
