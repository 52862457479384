import {Systeminformation} from 'systeminformation';

type Platform =
  | 'aix'
  | 'android'
  | 'darwin'
  | 'freebsd'
  | 'linux'
  | 'openbsd'
  | 'sunos'
  | 'win32'
  | 'cygwin'
  | 'netbsd';

// https://github.com/sebhildebrandt/systeminformation#2-system-hw
export interface SptSystemInformation {
  // audio: Systeminformation.AudioData | null;
  computerPlatform: Platform | null;
  electron: string | null;
  electronAppBuild: string | null;
  isMac: boolean | null;
  isPc: boolean | null;
  // cpu: Systeminformation.CpuData;
  system: Systeminformation.SystemData;
  // bios: Systeminformation.BiosData;
  // baseboard: Systeminformation.BaseboardData;
  // chassis: Systeminformation.ChassisData;
  // cpuFlags: string;
  // cpuCache: Systeminformation.CpuCacheData;
  // cpuCurrentSpeed: Systeminformation.CpuCurrentSpeedData;
  // cpuTemperature: Systeminformation.CpuTemperatureData;
  // mem: Systeminformation.MemData;
  // memLayout: Systeminformation.MemLayoutData[];
  // battery: Systeminformation.BatteryData;
  // graphics: Systeminformation.GraphicsData;
  // osInfo: Systeminformation.OsData;
  // uuid: Systeminformation.UuidData;
}

export interface SptSystemInformationRequest {
  // audio: boolean;
  // cpu: boolean;
  electron: boolean;
  electronAppBuild: boolean;
  system: boolean;
  // bios: boolean;
  // baseboard: boolean;
  // chassis: boolean;
  // cpuFlags: boolean;
  // cpuCache: boolean;
  // cpuCurrentSpeed: boolean;
  // cpuTemperature: boolean;
  // mem: boolean;
  // memLayout: boolean;
  // battery: boolean;
  // graphics: boolean;
  // osInfo: boolean;
  // uuid: boolean;
}

export const systemInformationRequest: SptSystemInformationRequest = {
  // audio: true,
  // cpu: true,
  electron: true,
  electronAppBuild: true,
  system: true
  // bios: true,
  // baseboard: true,
  // chassis: true,
  // cpuFlags: true,
  // cpuCache: true,
  // cpuCurrentSpeed: true,
  // cpuTemperature: true,
  // mem: true,
  // memLayout: true,
  // battery: true,
  // graphics: false,
  // osInfo: true,
  // uuid: false
};

export const systemParamsToGet = [
  // 'audio',
  // 'cpu',
  'electron',
  'electronAppBuild',
  'system'
  // 'bios',
  // 'baseboard',
  // 'chassis',
  // 'cpuFlags',
  // 'cpuCache',
  // 'cpuCurrentSpeed',
  // 'cpuTemperature',
  // 'mem',
  // 'memLayout',
  // 'battery',
  // 'graphics',
  // 'osInfo',
  // 'uuid'
];

export const initialDesktopInformation: SptSystemInformation = {
  // audio: null,
  computerPlatform: null,
  electron: null,
  electronAppBuild: null,
  isMac: false,
  isPc: false,
  // cpu: {
  //   manufacturer: '',
  //   brand: '',
  //   vendor: '',
  //   family: '',
  //   flags: '',
  //   model: '',
  //   stepping: '',
  //   revision: '',
  //   voltage: '',
  //   speed: 0,
  //   speedMin: 0,
  //   speedMax: 0,
  //   governor: '',
  //   cores: 0,
  //   physicalCores: 0,
  //   processors: 0,
  //   socket: '',
  //   virtualization: false,
  //   cache: {
  //     l1d: 0,
  //     l1i: 0,
  //     l2: 0,
  //     l3: 0
  //   }
  // },
  system: {
    manufacturer: '',
    model: '',
    version: '',
    serial: '',
    uuid: '',
    sku: '',
    virtual: false
  }
  // bios: {
  //   vendor: '',
  //   version: '',
  //   releaseDate: '',
  //   revision: ''
  // },
  // baseboard: {
  //   manufacturer: '',
  //   model: '',
  //   version: '',
  //   serial: '',
  //   assetTag: ''
  // },
  // chassis: {
  //   manufacturer: '',
  //   model: '',
  //   type: '',
  //   version: '',
  //   serial: '',
  //   assetTag: '',
  //   sku: ''
  // },
  // cpuFlags: '',
  // cpuCache: {
  //   l1d: 0,
  //   l1i: 0,
  //   l2: 0,
  //   l3: 0
  // },
  // cpuCurrentSpeed: {
  //   min: 0,
  //   max: 0,
  //   avg: 0,
  //   cores: []
  // },
  // cpuTemperature: {
  //   main: 0,
  //   cores: [],
  //   max: 0
  // },
  // mem: {
  //   total: 0,
  //   free: 0,
  //   used: 0,
  //   active: 0,
  //   available: 0,
  //   buffcache: 0,
  //   buffers: 0,
  //   cached: 0,
  //   slab: 0,
  //   swaptotal: 0,
  //   swapused: 0,
  //   swapfree: 0
  // },
  // memLayout: [],
  // battery: {
  //   hasBattery: false,
  //   cycleCount: 0,
  //   isCharging: false,
  //   voltage: 0,
  //   designedCapacity: 0,
  //   maxCapacity: 0,
  //   currentCapacity: 0,
  //   capacityUnit: '',
  //   percent: 0,
  //   timeRemaining: 0,
  //   acConnected: false,
  //   type: '',
  //   model: '',
  //   manufacturer: '',
  //   serial: ''
  // },
  // graphics: {
  //   controllers: [],
  //   displays: []
  // },
  // osInfo: {
  //   fqdn: '',
  //   platform: '',
  //   distro: '',
  //   release: '',
  //   codename: '',
  //   kernel: '',
  //   arch: '',
  //   hostname: '',
  //   codepage: '',
  //   logofile: '',
  //   serial: '',
  //   build: '',
  //   servicepack: '',
  //   uefi: false
  // },
  // uuid: {
  //   os: '',
  //   hardware: ''
  // }
};
