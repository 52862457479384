import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {
  AccountState,
  AccountStateConnect,
  ConnectionRegistryState,
  ConnectionService,
  WEBSOCKET_REGISTRY_FEATURE_KEY,
  websocketRegistryAdapter
} from '@spout/global-web/models';
import {pipe} from 'rxjs';
import {distinctUntilKeyChanged, filter} from 'rxjs/operators';
import {selectAccountState} from '../+account/account.selectors';

// Lookup the 'ConnectionRegistry' feature state managed by NgRx
const selectConnectionRegistryState =
  createFeatureSelector<ConnectionRegistryState>(
    WEBSOCKET_REGISTRY_FEATURE_KEY
  );

const {selectEntities} = websocketRegistryAdapter.getSelectors();

export const selectAllDisconnected = createSelector(
  selectConnectionRegistryState,
  (state: ConnectionRegistryState) => {
    return !state.allConnected;
  }
);

export const selectAllDisconnectedFn = () => {
  return createSelector(
    selectConnectionRegistryState,
    (state: ConnectionRegistryState) => {
      return !state.allConnected;
    }
  );
};

export const selectWebSockets = createSelector(
  selectConnectionRegistryState,
  selectEntities
);

/**
 * @deprecated replace with getWebSocketIdConnected;
 */
export const selectWebSocketIdConnected = createSelector(
  selectWebSockets,
  (entities: Dictionary<ConnectionService>, id: string) => {
    if (entities && id && entities[id] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return entities[id].connected;
    } else {
      return false;
    }
  }
);

export const getWebSocketIdConnected = (id: string) =>
  createSelector(
    selectWebSockets,
    (entities: Dictionary<ConnectionService>) => {
      if (entities && id && entities[id] !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return entities[id].connected;
      } else {
        return false;
      }
    }
  );

export const selectDoConnect = createSelector(
  selectConnectionRegistryState,
  (state: ConnectionRegistryState) => state.doConnect
);

export const selectDoDisconnect = createSelector(
  selectConnectionRegistryState,
  (state: ConnectionRegistryState) => state.doDisconnect
);

export interface GetAuthAccountConnect {
  user: AccountState;
  doConnect: boolean;
}

export const selectAccountStateConnect = createSelector(
  selectAccountState,
  selectDoConnect,
  (account: AccountState, doConnect: boolean): AccountStateConnect => {
    // console.log(account);

    return {
      user: account,
      doConnect
    };
  }
);

/**
 * Returns SelectWebSocketStatus
 */
export const selectAuthAccountConnect$ = pipe(
  select(selectAccountStateConnect),
  filter((d: AccountStateConnect): boolean => {
    // console.log(d);

    return (
      d &&
      d.user &&
      d.user.uid !== null &&
      d.user.uid !== undefined &&
      d.user.uid.length > 0
    );
  }),
  // filter((d) => hasValueIn(d, 'user.auth.uid')),
  distinctUntilKeyChanged('doConnect')
  // tap((d: GetAuthAccountConnect) => {
  //   console.log(d);
  // }),
);

/**
 * Returns SelectWebSocketStatus
 */
export const connectToFirestore$ = pipe(
  select(selectAccountStateConnect),
  filter((d: AccountStateConnect): boolean => d.user.isRetrievedFromFirestore),
  distinctUntilKeyChanged('doConnect')
);
