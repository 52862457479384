import {createAction, props} from '@ngrx/store';
import {AppVersions} from '@spout/global-any/models';

export const loadStudios = createAction('[Studio] Load Studios');
export const hasUiUpdate = createAction(
  '[Studio] Has UI Update',
  props<{hasUiUpdate: boolean}>()
);
export const hasElectronUpdate = createAction(
  '[Studio] Has App Update',
  props<{hasElectronUpdate: boolean}>()
);
export const loadInstalledElectron = createAction(
  '[Studio] Load Installed Electron',
  props<{installed: AppVersions}>()
);

export const StudioActions = {
  loadStudios,
  hasUiUpdate,
  hasElectronUpdate
};
