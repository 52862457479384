import {Musician} from '@spout/global-any/models';

export function isCreatedByLoggedInMusician(
  uid: string | null,
  config: {createdBy: Musician} | null
): boolean {
  if (uid && config && config.createdBy && config.createdBy.uid) {
    return uid === config.createdBy.uid;
  }
  return false;
}
