import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {HelpContentComponent} from './help-content.component';
import {HelpContentHeaderComponent} from './help-content-header/help-content-header.component';
import {HelpContentBodyComponent} from './help-content-body/help-content-body.component';
import {HelpTooltipHeaderComponent} from './help-tooltip-header/help-tooltip-header.component';

@NgModule({
  declarations: [
    HelpContentComponent,
    HelpContentHeaderComponent,
    HelpContentBodyComponent,
    HelpTooltipHeaderComponent
  ],
  exports: [
    HelpContentComponent,
    HelpContentHeaderComponent,
    HelpContentBodyComponent,
    HelpTooltipHeaderComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    PerfectScrollbarModule
  ]
})
export class HelpContentModule {}
