/* APPS */
import {createGAEvent} from '../analytics';
import {
  logGAEvent,
  logGAUserEvent,
  gaPrefixEventName
} from './google-analytics.actions';

export enum GA_STUDIO_APP_EVENT_NAME {
  STUDIO_APP_ACTIVE = 'studio_app_active',
  STUDIO_APP_INACTIVE = 'studio_app_inactive',
  STUDIO_APP_LOGOUT = 'studio_app_logout',

  ACCOUNT_DELETE_MARKETING = 'account_delete_marketing',

  CONTROLS_ADD_MIX = 'controls_add_mix',
  CONTROLS_SHOW_LATENCY_CALIBRATOR = 'controls_show_latency_calibrator',
  CONTROLS_SHOW_LATENCY_CALIBRATOR_BTN = 'controls_show_latency_calibrator_btn',
  CONTROLS_SHOW_EDIT_ENTITIES = 'controls_show_edit_entities',
  CONTROLS_SHOW_RECORD = 'controls_show_record',
  CONTROLS_EDIT_PROJECT = 'controls_edit_project',
  CONTROLS_EDIT_SONG = 'controls_edit_song',
  CONTROLS_EDIT_TRACK = 'controls_edit_mix',
  CONTROLS_EDIT_MIX = 'controls_edit_mix',
  CONTROLS_SELECT_MIX = 'controls_select_mix',
  CONTROLS_SELECT_MICROPHONE = 'controls_select_microphone',
  CONTROLS_SET_GAIN = 'controls_set_gain',

  CONTROLS_DYNAMICS_COMPRESSOR_THRESHOLD = 'controls_dynamics_compressor_threshold',
  CONTROLS_DYNAMICS_COMPRESSOR_KNEE = 'controls_dynamics_compressor_knee',
  CONTROLS_DYNAMICS_COMPRESSOR_RATIO = 'controls_dynamics_compressor_ratio',
  CONTROLS_DYNAMICS_COMPRESSOR_REDUCTION = 'controls_dynamics_compressor_reduction',
  CONTROLS_DYNAMICS_COMPRESSOR_ATTACK = 'controls_dynamics_compressor_attack',
  CONTROLS_DYNAMICS_COMPRESSOR_RELEASE = 'controls_dynamics_compressor_release',

  CONTROLS_RENDER_MIX = 'controls_render_mix',
  CONTROLS_MASTER_VOLUME = 'controls_master_volume',
  CONTROLS_OPEN = 'controls_open',
  CONTROLS_CLOSE = 'controls_close',

  DROP_DOWN_ADD_PROJECT = 'drop_down_add_project',
  DROP_DOWN_ADD_SONG = 'drop_down_add_song',
  DROP_DOWN_ADD_MIX = 'drop_down_add_mix',

  HEADER_VOLUME = 'header_volume',
  HEADER_UPDATE_SUBSCRIPTION = 'header_update_subscription',
  HEADER_CLICK_LOGO = 'header_click_logo',
  HEADER_CLICK_MIXER_CONSOLE = 'header_click_mixer_console',

  LATENCY_ADJUSTOR = 'latency_adjustor',
  LATENCY_WIZARD_START = 'latency_wizard_start',
  LATENCY_WIZARD_CANCEL = 'latency_wizard_cancel',
  LATENCY_WIZARD_SAVE_LATENCY = 'latency_wizard_save_latency',

  LOGIN_LAUNCH_BROWSER = 'login_launch_browser',
  LOGIN_LOGGED_IN = 'login_logged_in',

  INVITE_MUSICIAN = 'invite_musician',

  IMPORT_FILES_OPEN_DIALOG = 'import_files_open_dialog',
  IMPORT_FILES_DRAG_DROP = 'import_files_drag_drop',

  DIALOG_ABOUT = 'dialog_about',

  ONBOARDING_GET_WORKSPACE_DIRECTORY = 'onboarding_get_workspace_directory',
  ONBOARDING_SAVE_BETA_PROGRAM = 'onboarding_save_beta_program',
  ONBOARDING_CREATE_WORKSPACE_DIRECTORY = 'onboarding_create_workspace_directory',

  PAGE_ACCOUNT = 'page_account',
  PAGE_MIXER = 'page_mixer',
  PAGE_SETTINGS = 'page_settings',
  PAGE_EXTERNAL_PRIVACY_POLICY = 'page_external_privacy_policy',
  PAGE_EXTERNAL_TOC = 'page_external_toc',
  PAGE_EXTERNAL_CONTACT = 'page_external_contact',
  PAGE_EXTERNAL_COMPANY = 'page_external_company',

  PLAYER_FULL_REWIND = 'player_full_rewind',
  PLAYER_FAST_REWIND = 'player_fast_rewind',
  PLAYER_PLAY = 'player_play',
  PLAYER_STOP = 'player_stop',
  PLAYER_RECORD_START = 'player_record_start',
  PLAYER_RECORD_STOP = 'player_record_stop',
  PLAYER_FULL_FORWARD = 'player_full_forward',
  PLAYER_FAST_FORWARD = 'player_fast_forward',

  SETTINGS_CHOOSE_DIRECTORY = 'settings_choose_directory',
  SETTINGS_OPEN_DIRECTORY = 'settings_open_directory',
  SETTINGS_CLEAR_MICROPHONE_CACHE = 'settings_clear_microphone_cache',

  SUBSCRIBE_TO_PLAN = 'subscribe_to_plan',
  UPDATE_SUBSCRIPTION = 'update_subscription',
  CANCEL_SUBSCRIPTION = 'cancel_subscription',

  ADD_PROMO_CODE = 'add_promo_code',
  DELETE_PROMO_CODE = 'delete_promo_code',

  NOTIFICATION_ACTION_UPDATE_UI = 'notification_action_update_ui',

  OPEN_RIGHT_DRAWER_CHAT = 'open_right_drawer_chat',
  OPEN_RIGHT_DRAWER_INVITE = 'open_right_drawer_invite',
  OPEN_RIGHT_DRAWER_HELP = 'open_right_drawer_help',
  OPEN_RIGHT_DRAWER_NOTIFICATIONS = 'open_right_drawer_notifications',

  RIGHT_DRAWER_CHAT_SELECT_MIX = 'right_drawer_chat_select_mix',
  RIGHT_DRAWER_CHAT_SELECT_TIME = 'right_drawer_chat_select_time',
  RIGHT_DRAWER_CHAT_SHOW_ARCHIVED = 'right_drawer_chat_show_archived',

  LEFT_DRAWER_ADD_AUDIO_TRACK = 'left_drawer_add_audio_track',
  LEFT_DRAWER_ADD_SONG = 'left_drawer_add_song',
  LEFT_DRAWER_ADD_MIX = 'left_drawer_add_mix',
  LEFT_DRAWER_ADD_PROJECT = 'left_drawer_add_project',
  LEFT_DRAWER_SELECT_PROJECT_SONG = 'left_drawer_select_project_song',
  LEFT_DRAWER_NAVIGATE_MIXER_CONSOLE = 'left_drawer_navigate_mixer_console',
  LEFT_DRAWER_TOGGLE = 'left_drawer_toggle',

  CHAT_DELETE = 'chat_delete',
  CHAT_ADD_CONVERSATION = 'chat_add_conversation',
  CHAT_ADD_MESSAGE = 'chat_add_message',

  HELP_ITEM = 'help_item',

  SAVE_DISPLAY_NAME = 'save_display_name',
  SAVE_GENRE = 'save_genre',
  SAVE_INSTRUMENTS = 'save_instruments',

  /* TRACK CONTROLS */
  TRACK_CONTROL_ARM = 'track_control_arm',
  TRACK_CONTROL_CLEAR = 'track_control_clear',
  TRACK_CONTROL_DELETE = 'track_control_delete',
  TRACK_CONTROL_EDIT_NAME = 'track_control_edit_name',
  TRACK_CONTROL_INFO = 'track_control_info',
  TRACK_CONTROL_MUTE = 'track_control_mute',
  TRACK_CONTROL_SET_REFERENCE = 'track_control_set_reference',
  TRACK_CONTROL_SET_COLOR = 'track_control_set_color',
  TRACK_CONTROL_SET_OFFSET = 'track_control_set_offset',
  TRACK_CONTROL_SET_VOLUME = 'track_control_set_volume',
  TRACK_CONTROL_SHARE = 'track_control_share',
  TRACK_CONTROL_SET_LATENCY = 'track_control_latency'
}

export enum GA_STUDIO_ACTION {
  /* APP */
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export const GA_PLAYER = {
  PLAYER_FULL_REWIND: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_FULL_REWIND)
    }),
  PLAYER_FAST_REWIND: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_FAST_REWIND)
    }),
  PLAYER_PLAY: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_PLAY)
    }),
  PLAYER_STOP: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_STOP)
    }),
  PLAYER_RECORD_START: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_RECORD_START)
    }),
  PLAYER_RECORD_STOP: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_RECORD_STOP)
    }),
  PLAYER_FULL_FORWARD: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_FULL_FORWARD)
    }),
  PLAYER_FAST_FORWARD: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PLAYER_FAST_FORWARD)
    })
};

export const GA_IMPORT_FILES = {
  IMPORT_FILES_OPEN_DIALOG: (d: {[key: string]: number}) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.IMPORT_FILES_OPEN_DIALOG, d)
    }),
  IMPORT_FILES_DRAG_DROP: (d: {[key: string]: number}) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.IMPORT_FILES_DRAG_DROP, d)
    })
};

export const GA_LATENCY_WIZARD = {
  LATENCY_WIZARD_START: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LATENCY_WIZARD_START)
    }),
  LATENCY_WIZARD_CANCEL: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LATENCY_WIZARD_CANCEL)
    }),
  LATENCY_WIZARD_SAVE_LATENCY: (latency: {
    detected: number | null;
    adjusted: number;
  }) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.LATENCY_WIZARD_SAVE_LATENCY,
        {...latency}
      )
    })
};

export const GA_LATENCY_ADJUSTOR = {
  LATENCY_ADJUSTOR: (latency: {detected: number | null; adjusted: number}) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LATENCY_ADJUSTOR, {
        ...latency
      })
    })
};

export const GA_CONTROLS_ACTION = {
  SHOW_LATENCY_CALIBRATOR: () =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_SHOW_LATENCY_CALIBRATOR
      )
    }),
  CONTROLS_ADD_MIX: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_ADD_MIX)
    }),

  CONTROLS_SHOW_EDIT_ENTITIES: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_SHOW_EDIT_ENTITIES)
    }),
  CONTROLS_SHOW_RECORD: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_SHOW_RECORD)
    }),
  CONTROLS_SHOW_LATENCY_CALIBRATOR_BTN: () =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_SHOW_LATENCY_CALIBRATOR_BTN
      )
    }),
  CONTROLS_RENDER_MIX: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_RENDER_MIX)
    }),
  CONTROLS_MASTER_VOLUME: (volume: number) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_MASTER_VOLUME, {
        volume
      })
    }),
  CONTROLS_OPEN: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_OPEN)
    }),
  CONTROLS_CLOSE: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_CLOSE)
    }),
  CONTROLS_EDIT_PROJECT: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_EDIT_PROJECT)
    }),
  CONTROLS_EDIT_SONG: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_EDIT_SONG)
    }),
  CONTROLS_EDIT_TRACK: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_EDIT_TRACK)
    }),
  CONTROLS_EDIT_MIX: () =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_EDIT_MIX)
    }),
  CONTROLS_SELECT_MICROPHONE: (microphone_name: string) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_SELECT_MICROPHONE,
        {microphone_name}
      )
    }),
  CONTROLS_SET_GAIN: (gain_value: number) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_SET_GAIN, {
        gain_value
      })
    }),
  CONTROLS_DYNAMICS_COMPRESSOR_THRESHOLD: (threshold_value: number) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_DYNAMICS_COMPRESSOR_THRESHOLD,
        {threshold_value}
      )
    }),
  CONTROLS_DYNAMICS_COMPRESSOR_KNEE: (knee_value: number) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_DYNAMICS_COMPRESSOR_KNEE,
        {knee_value}
      )
    }),
  CONTROLS_DYNAMICS_COMPRESSOR_RATIO: (ratio_value: number) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_DYNAMICS_COMPRESSOR_RATIO,
        {ratio_value}
      )
    }),
  CONTROLS_DYNAMICS_COMPRESSOR_REDUCTION: (reduction_value: number) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_DYNAMICS_COMPRESSOR_REDUCTION,
        {reduction_value}
      )
    }),
  CONTROLS_DYNAMICS_COMPRESSOR_ATTACK: (attack_value: number) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_DYNAMICS_COMPRESSOR_ATTACK,
        {attack_value}
      )
    }),
  CONTROLS_DYNAMICS_COMPRESSOR_RELEASE: (release_value: number) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.CONTROLS_DYNAMICS_COMPRESSOR_RELEASE,
        {release_value}
      )
    }),
  CONTROLS_SELECT_MIX: (mix_name: string) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CONTROLS_SELECT_MIX, {
        mix_name
      })
    })
};

/* TRACK CONTROLS */
export interface GATrackControlParam {
  track_id: string;
  track_name: string;
  track_creator_email: string;
  track_creator_uid: string;

  [key: string]: unknown;
}

export const GA_TRACK_CONTROL_ACTION = {
  ARM: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_ARM, {...p})
    }),
  CLEAR: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_CLEAR, {...p})
    }),
  DELETE: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_DELETE, {
        ...p
      })
    }),
  EDIT_NAME: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_EDIT_NAME, {
        ...p
      })
    }),
  INFO: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_INFO, {...p})
    }),
  SET_REFERENCE: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_SET_REFERENCE,
        {...p}
      )
    }),
  SET_COLOR: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_SET_COLOR, {
        ...p
      })
    }),
  SET_OFFSET: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_SET_OFFSET, {
        ...p
      })
    }),
  SET_VOLUME: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_SET_VOLUME, {
        ...p
      })
    }),
  MUTE: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_MUTE, {
        ...p
      })
    }),
  SHARE: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_SHARE, {...p})
    }),
  SET_LATENCY: (p: GATrackControlParam) =>
    logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.TRACK_CONTROL_SET_LATENCY, {
        ...p
      })
    })
};

export const ga_studioAppActive = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.STUDIO_APP_ACTIVE)
  });

export const ga_studioAppInActive = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.STUDIO_APP_INACTIVE)
  });

export const ga_HeaderVolume = (volume: number) =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.HEADER_VOLUME, {volume})
  });

export const ga_HeaderClickLogo = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.HEADER_CLICK_LOGO)
  });

export const ga_HeaderClickMixerConsole = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.HEADER_CLICK_MIXER_CONSOLE)
  });

export const ga_HeaderUpdateSubscription = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.HEADER_UPDATE_SUBSCRIPTION)
  });

export const ga_LoginLaunchBrowser = () =>
  logGAEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LOGIN_LAUNCH_BROWSER)
  });

export const ga_LoginLoggedIn = () =>
  logGAEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LOGIN_LOGGED_IN)
  });

export const ga_PageAccount = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PAGE_ACCOUNT)
  });

export const ga_PageMixer = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PAGE_MIXER)
  });

export const ga_PageSettings = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PAGE_SETTINGS)
  });

export const ga_Logout = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.STUDIO_APP_LOGOUT)
  });

export const ga_PageExternalPrivacyPolicy = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PAGE_EXTERNAL_PRIVACY_POLICY)
  });

export const ga_PageExternalTOC = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PAGE_EXTERNAL_TOC)
  });

export const ga_PageExternalContact = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PAGE_EXTERNAL_CONTACT)
  });

export const ga_PageExternalCompany = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.PAGE_EXTERNAL_COMPANY)
  });

export const ga_DialogAbout = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.DIALOG_ABOUT)
  });

export const ga_SettingsChooseDirectory = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.SETTINGS_CHOOSE_DIRECTORY)
  });

export const ga_SettingsOpenDirectory = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.SETTINGS_OPEN_DIRECTORY)
  });

export const ga_SettingsClearMicrophoneCache = () =>
  logGAUserEvent({
    event: createGAEvent(
      GA_STUDIO_APP_EVENT_NAME.SETTINGS_CLEAR_MICROPHONE_CACHE
    )
  });

export const ga_SubscribeToPlan = (name: string = 'account') =>
  logGAUserEvent({
    event: createGAEvent(name + GA_STUDIO_APP_EVENT_NAME.SUBSCRIBE_TO_PLAN)
  });

export const ga_UpdateSubscription = (name: string = 'account') =>
  logGAUserEvent({
    event: createGAEvent(name + GA_STUDIO_APP_EVENT_NAME.UPDATE_SUBSCRIPTION)
  });

export const ga_CancelSubscription = (name: string = 'account') =>
  logGAUserEvent({
    event: createGAEvent(name + GA_STUDIO_APP_EVENT_NAME.CANCEL_SUBSCRIPTION)
  });

export const ga_DeleteAccountMarketing = (name: string = 'account') =>
  logGAUserEvent({
    event: createGAEvent(
      name + GA_STUDIO_APP_EVENT_NAME.ACCOUNT_DELETE_MARKETING
    )
  });

export const ga_AddPromoCode = (
  promo_code: string,
  gaName: string = 'account'
) =>
  logGAUserEvent({
    event: createGAEvent(
      gaName + '_' + GA_STUDIO_APP_EVENT_NAME.ADD_PROMO_CODE,
      {
        promo_code
      }
    )
  });

export const ga_DeletePromoCode = (
  promo_code: string,
  gaName: string = 'account'
) =>
  logGAUserEvent({
    event: createGAEvent(
      gaName + '_' + GA_STUDIO_APP_EVENT_NAME.DELETE_PROMO_CODE,
      {
        promo_code
      }
    )
  });

export const ga_SaveDisplayName = (
  display_name: string,
  gaName: string = 'account'
) =>
  logGAUserEvent({
    event: createGAEvent(
      gaName + '_' + GA_STUDIO_APP_EVENT_NAME.SAVE_DISPLAY_NAME,
      {
        display_name
      }
    )
  });

export const ga_SaveGenre = (gaName: string = 'account') =>
  logGAUserEvent({
    event: createGAEvent(gaName + '_' + GA_STUDIO_APP_EVENT_NAME.SAVE_GENRE)
  });

export const ga_SaveInstruments = (gaName: string = 'account') =>
  logGAUserEvent({
    event: createGAEvent(
      gaName + '_' + GA_STUDIO_APP_EVENT_NAME.SAVE_INSTRUMENTS
    )
  });

export const ga_GetWorkspaceDirectory = () =>
  logGAUserEvent({
    event: createGAEvent(
      GA_STUDIO_APP_EVENT_NAME.ONBOARDING_GET_WORKSPACE_DIRECTORY
    )
  });

export const ga_CreateWorkspaceDirectory = () =>
  logGAUserEvent({
    event: createGAEvent(
      GA_STUDIO_APP_EVENT_NAME.ONBOARDING_CREATE_WORKSPACE_DIRECTORY
    )
  });

export const ga_SaveBetaProgram = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.ONBOARDING_SAVE_BETA_PROGRAM)
  });

export const ga_OpenRightDrawerChat = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.OPEN_RIGHT_DRAWER_CHAT)
  });

export const ga_OpenRightDrawerInvite = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.OPEN_RIGHT_DRAWER_INVITE)
  });

export const ga_OpenRightDrawerHelp = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.OPEN_RIGHT_DRAWER_HELP)
  });

export const ga_OpenRightDrawerNotifications = () =>
  logGAUserEvent({
    event: createGAEvent(
      GA_STUDIO_APP_EVENT_NAME.OPEN_RIGHT_DRAWER_NOTIFICATIONS
    )
  });

export const ga_InviteMusician = (d: {[key: string]: string}) =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.INVITE_MUSICIAN, {...d})
  });

export const GA_RIGHT_DRAWER_CHAT = {
  RIGHT_DRAWER_CHAT_SELECT_MIX: (d: {[key: string]: string}) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.RIGHT_DRAWER_CHAT_SELECT_MIX,
        {...d}
      )
    }),
  RIGHT_DRAWER_CHAT_SELECT_TIME: (d: {[key: string]: string}) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.RIGHT_DRAWER_CHAT_SELECT_TIME,
        {...d}
      )
    }),
  RIGHT_DRAWER_CHAT_SHOW_ARCHIVED: (d: {[key: string]: string | boolean}) =>
    logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.RIGHT_DRAWER_CHAT_SHOW_ARCHIVED,
        {...d}
      )
    })
};

export const GA_CHAT = {
  CHAT_DELETE: (prefix: string, d: {[key: string]: string | boolean}) => {
    const eventName = prefix.length
      ? prefix + '_' + GA_STUDIO_APP_EVENT_NAME.CHAT_DELETE
      : GA_STUDIO_APP_EVENT_NAME.CHAT_DELETE;

    return logGAUserEvent({
      event: createGAEvent(eventName, {...d})
    });
  },
  CHAT_ADD_CONVERSATION: () => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.CHAT_ADD_CONVERSATION)
    });
  },
  CHAT_ADD_MESSAGE: (eventName: string) => {
    return logGAUserEvent({
      event: createGAEvent(
        gaPrefixEventName(eventName, GA_STUDIO_APP_EVENT_NAME.CHAT_ADD_MESSAGE)
      )
    });
  }
};

export const ga_showHelpItem = (help_item_key: string) =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.HELP_ITEM, {
      help_item_key
    })
  });

export const ga_NotificationactionUpdateUi = () =>
  logGAUserEvent({
    event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.NOTIFICATION_ACTION_UPDATE_UI)
  });

export const GA_LEFT_DRAWER = {
  LEFT_DRAWER_ADD_AUDIO_TRACK: () => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LEFT_DRAWER_ADD_AUDIO_TRACK)
    });
  },
  LEFT_DRAWER_ADD_SONG: () => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LEFT_DRAWER_ADD_SONG)
    });
  },
  LEFT_DRAWER_ADD_MIX: () => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LEFT_DRAWER_ADD_MIX)
    });
  },
  LEFT_DRAWER_ADD_PROJECT: () => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LEFT_DRAWER_ADD_PROJECT)
    });
  },
  LEFT_DRAWER_SELECT_PROJECT_SONG: () => {
    return logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.LEFT_DRAWER_SELECT_PROJECT_SONG
      )
    });
  },
  LEFT_DRAWER_NAVIGATE_MIXER_CONSOLE: () => {
    return logGAUserEvent({
      event: createGAEvent(
        GA_STUDIO_APP_EVENT_NAME.LEFT_DRAWER_NAVIGATE_MIXER_CONSOLE
      )
    });
  },
  LEFT_DRAWER_TOGGLE: () => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.LEFT_DRAWER_TOGGLE)
    });
  }
};

export const GA_DROP_DOWN = {
  DROP_DOWN_ADD_PROJECT: (d: {[key: string]: string | boolean}) => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.DROP_DOWN_ADD_PROJECT, {
        ...d
      })
    });
  },
  DROP_DOWN_ADD_SONG: (d: {[key: string]: string | boolean}) => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.DROP_DOWN_ADD_SONG, {...d})
    });
  },
  DROP_DOWN_ADD_MIX: (d: {[key: string]: string | boolean}) => {
    return logGAUserEvent({
      event: createGAEvent(GA_STUDIO_APP_EVENT_NAME.DROP_DOWN_ADD_MIX, {...d})
    });
  }
};
