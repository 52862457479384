import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {CustomFirestoreService} from '../firebase/custom-firestore.service';
import {getMusicianAccountPathByUID} from '@spout/global-web/fns';
import {AccountState} from '@spout/global-web/models';

import {concatMap, map, switchMap} from 'rxjs/operators';
import {Observable, EMPTY, of} from 'rxjs';
import {getUserFromFirestoreEffect, upsertSptUser} from './spt-user.actions';

import * as SptUserActions from './spt-user.actions';
import {SptUser} from './spt-user.model';
import {getSptUserByUID} from './spt-user.selectors';

@Injectable()
export class SptUserEffects {
  loadSptUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SptUserActions.loadSptUsers),
      /** An EMPTY observable only emits completion. Replace with your own observable API request */
      concatMap(() => EMPTY as Observable<{type: string}>)
    );
  });

  getUserFromFirestoreEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUserFromFirestoreEffect),
      switchMap(action =>
        this._store.pipe(
          select(getSptUserByUID(action.uid)),
          switchMap((user: SptUser | undefined | null) => {
            if (user !== undefined) {
              return EMPTY;
            }

            return this._sptFirestore
              .docData$<AccountState>(getMusicianAccountPathByUID(action.uid))
              .pipe(
                switchMap((_user: AccountState) => {
                  console.log(_user);
                  if (
                    _user &&
                    _user.uid !== undefined &&
                    _user.uid !== null &&
                    _user.stageName !== undefined &&
                    _user.stageName !== null
                  ) {
                    return of(
                      upsertSptUser({
                        sptUser: {
                          uid: _user.uid,
                          stageName: _user.stageName
                        }
                      })
                    );
                  }
                  return EMPTY;
                })
              );
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private _store: Store,
    private _sptFirestore: CustomFirestoreService
  ) {}
}
