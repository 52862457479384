import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {
  ACCOUNT_ROLE,
  FEATURE_ID,
  FeaturePermission
} from '@spout/global-web/models';
import * as PermissionActions from './permission.actions';
import {addPermissionAccountRole} from './permission.actions';
import {updateUserPermissions} from './permission.fns';
import {SptPermissionState} from './permission.model';

export const permissionsFeatureKey = 'permissions';

export const adapter: EntityAdapter<FeaturePermission> =
  createEntityAdapter<FeaturePermission>();

export const permissionsInitialState: SptPermissionState =
  adapter.getInitialState({
    // additional entity state properties
    accountRole: ACCOUNT_ROLE.PERSONAL,
    // projectRole: ACCOUNT_ROLE.REVIEWER,
    // permissionRole: ACCOUNT_ROLE.PERSONAL,
    promoCode: null,
    permissions: {
      [FEATURE_ID.ADD_ANNOTATIONS]: false,
      [FEATURE_ID.ADD_COLLABORATORS]: false,
      [FEATURE_ID.CHAT]: false,
      [FEATURE_ID.CLEAR_TRACKS]: false,
      [FEATURE_ID.CREATE_MIXES]: false,
      [FEATURE_ID.CREATE_PROJECTS]: false,
      [FEATURE_ID.CREATE_SONGS]: false,
      [FEATURE_ID.CREATE_TRACKS]: false,
      [FEATURE_ID.COPY_TRACKS]: false,
      [FEATURE_ID.DELETE_TRACKS]: false,
      [FEATURE_ID.EXPORT_SONG]: false,
      [FEATURE_ID.IMPORT_AUDIO_FILES]: false,
      [FEATURE_ID.RECORD]: false,
      // [FEATURE_ID.RENDER_MIX]: false,
      [FEATURE_ID.SHARE_TRACKS]: false,
      [FEATURE_ID.TRACK_CONTROLS]: false
    }
  });

export const reducer = createReducer(
  permissionsInitialState,
  on(PermissionActions.addPermission, (state, action) =>
    adapter.addOne(action.permission, state)
  ),
  on(PermissionActions.upsertPermission, (state, action) =>
    adapter.upsertOne(action.permission, state)
  ),
  on(PermissionActions.addPermissions, (state, action) =>
    adapter.addMany(action.permissions, state)
  ),
  on(PermissionActions.upsertPermissions, (state, action) =>
    updateUserPermissions(adapter.upsertMany(action.permissions, state))
  ),
  on(PermissionActions.updatePermission, (state, action) =>
    adapter.updateOne(action.permission, state)
  ),
  on(PermissionActions.updatePermissions, (state, action) =>
    updateUserPermissions(adapter.updateMany(action.permissions, state))
  ),
  on(PermissionActions.deletePermission, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(PermissionActions.deletePermissions, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(PermissionActions.loadPermissions, (state, action) =>
    adapter.setAll(action.permissions, adapter.removeAll(state))
  ),
  on(PermissionActions.clearPermissions, state => adapter.removeAll(state)),

  on(addPermissionAccountRole, (state, action) => {
    return updateUserPermissions({
      ...state,
      accountRole: action.role
    });
  })
);
