import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {ProcessQueue} from '@spout/global-any/utils';
import {logGAEvent, logGAUserEvent} from './actions';
import {AccountState} from '@spout/global-web/models';
import {distinctUntilKeyChanged, tap} from 'rxjs/operators';
import {selectAccountState} from '../+account/account.selectors';
import {CustomFirestoreService} from '../firebase/custom-firestore.service';
import {GA_CONSOLE_LOG_EVENTS, GAEventItem} from './analytics';

@Injectable({providedIn: 'root'})
export class GoogleAnalyticsEffects {
  private _userEventQueue: ProcessQueue<GAEventItem> =
    new ProcessQueue<GAEventItem>({delayProcessing: true});
  private _eventQueue: ProcessQueue<GAEventItem> =
    new ProcessQueue<GAEventItem>();

  logGAUserEvent$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(logGAUserEvent),
        tap(action => {
          this._userEventQueue.next(action.event);
        })
      ),
    {dispatch: false}
  );

  logGAEvent$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(logGAEvent),
        tap(action => {
          this._eventQueue.next(action.event);
        })
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private _firestore: CustomFirestoreService,
    private _store: Store,
    @Inject(GA_CONSOLE_LOG_EVENTS) private logEvents: boolean
  ) {
    this._store
      .pipe(select(selectAccountState), distinctUntilKeyChanged('uid'))
      .subscribe((a: AccountState) => {
        if (a && a.uid) {
          const userFunc = (e: GAEventItem) => {
            return {
              ...e,
              eventParams: {
                ...e.eventParams,
                email: a.email,
                uid: a.uid,
                stage_name: a.stageName
              }
            };
          };

          this._userEventQueue.addFunction(userFunc);
          this._userEventQueue.start();
        }
      });

    this._userEventQueue.subscribe((e: GAEventItem) => {
      this.consoleLogEvent(e);
      this._firestore.logEvent(e);
    });

    this._eventQueue.subscribe((e: GAEventItem) => {
      this.consoleLogEvent(e);
      this._firestore.logEvent(e);
    });
  }

  consoleLogEvent(e: GAEventItem) {
    if (!this.logEvents) {
      return;
    }

    const eventName = `${e.eventName}:`;
    let params = ``;

    if (e.eventParams) {
      Object.keys(e.eventParams).forEach((p: string) => {
        if (e && e.eventParams && e.eventParams[p]) {
          params = `${params}
                  * ${p}: ${<string>e.eventParams[p]}
                 `;
        }
      });
    }

    const log = `
       ${eventName}
       ${params}
      `;

    console.log(log);
  }
}
