<div class="mat-h3">
  Your trial has ended. A subscription is required to access Spout Studio.
  Choose from a variety of plans that fit your needs.
</div>
<div class="cta">
  <button mat-flat-button color="accent" (click)="updateSubscription()">
    Subscribe To A Plan
  </button>

  <button mat-flat-button color="accent" (click)="logout()">Logout</button>
  <!--  <ng-container *ngrxLet="hasSubscribedAt$; let hasSubscribedAt">-->
  <!--    <button-->
  <!--      *ngIf="hasSubscribedAt"-->
  <!--      mat-flat-button-->
  <!--      color="accent"-->
  <!--      (click)="updateSubscription()">-->
  <!--      Subscribe To A Plan-->
  <!--    </button>-->

  <!--    <button-->
  <!--      *ngIf="!hasSubscribedAt"-->
  <!--      mat-flat-button-->
  <!--      color="accent"-->
  <!--      (click)="updateSubscription()">-->
  <!--      Subscribe for a {{ trialDays$ | ngrxPush }} day trial-->
  <!--    </button>-->

  <!--    <button mat-flat-button color="accent" (click)="logout()">Logout</button>-->
  <!--  </ng-container>-->
</div>
