/* MARKETING */

import {createGAEvent} from '../analytics';
import {logGAEvent, logGAUserEvent} from './google-analytics.actions';

// node_modules/firebase/compat/index.d.ts:5384
export enum GA_MARKETING_EVENT_NAME {
  PAGE_HOME = 'page_home',
  PAGE_SUBSCRIBE = 'page_subscribe',
  PAGE_ACCOUNT = 'page_account',
  PAGE_CONTACT = 'page_contact',
  MARKETING_APP_ACTIVE = 'marketing_app_active',
  MARKETING_APP_INACTIVE = 'marketing_app_inactive',
  DOWNLOAD_PC = 'download_pc',
  DOWNLOAD_MAC_INTEL = 'download_mac_intel',
  DOWNLOAD_MAC_M1 = 'download_mac_m1'
}

export enum GA_MARKETING_ACTIONS {
  WIZARD_WIDE = 'payment_wizard_wide',
  WIZARD_SMALL = 'payment_wizard_small',
  WIZARD_LEARN_MORE_EARLY_ADOPTER = 'payment_wizard_learn_more_early_adopter',
  WIZARD_SELECT_EARLY_ADOPTER = 'payment_wizard_select_early_adopter',
  WIZARD_PRICING = 'payment_wizard_pricing',
  WIZARD_CREATE_ACCOUNT = 'payment_wizard_create_account',
  WIZARD_SELECT_PLAN = 'payment_wizard_select_plan',
  WIZARD_CHECK_OUT = 'wizard_check_out',
  WIZARD_DOWNLOAD = 'payment_wizard_download'
}

export const marketingAppActive = () =>
  logGAUserEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.MARKETING_APP_ACTIVE)
  });

export const marketingAppInActive = () =>
  logGAUserEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.MARKETING_APP_INACTIVE)
  });

export const marketingHome = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_HOME)
  });

export const marketingSubscribe = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE)
  });

export const marketingWizardWide = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE, {
      action: GA_MARKETING_ACTIONS.WIZARD_WIDE
    })
  });
export const marketingWizardEarlyAdopter = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE, {
      action: GA_MARKETING_ACTIONS.WIZARD_LEARN_MORE_EARLY_ADOPTER
    })
  });

export const marketingWizardPricing = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE, {
      action: GA_MARKETING_ACTIONS.WIZARD_PRICING
    })
  });

export const marketingWizardCreateAccount = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE, {
      action: GA_MARKETING_ACTIONS.WIZARD_CREATE_ACCOUNT
    })
  });

export const marketingWizardSelectPlan = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE, {
      action: GA_MARKETING_ACTIONS.WIZARD_SELECT_PLAN
    })
  });

export const marketingWizardDownlaod = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE, {
      action: GA_MARKETING_ACTIONS.WIZARD_DOWNLOAD
    })
  });

export const marketingWizardCheckout = (params: {[key: string]: string}) =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.PAGE_SUBSCRIBE, {
      action: GA_MARKETING_ACTIONS.WIZARD_CHECK_OUT,
      params
    })
  });

export const marketingDownloadPC = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.DOWNLOAD_PC)
  });

export const marketingDownloadMacIntel = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.DOWNLOAD_MAC_INTEL)
  });

export const marketingDownloadMacM1 = () =>
  logGAEvent({
    event: createGAEvent(GA_MARKETING_EVENT_NAME.DOWNLOAD_MAC_M1)
  });
