import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';
import {
  PRODUCT_FEATURE_LIST,
  ProductWithPrices,
  selectEarlyAdopterPlanWithPrices,
  selectProductsWiPrices
} from '../../../+product';

@Component({
  selector: 'spt-stripe-products',
  templateUrl: './stripe-subscribe-view.component.html',
  styleUrls: ['./stripe-subscribe-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StripeSubscribeViewComponent implements OnInit {
  productWithPrices$: Observable<ProductWithPrices[]>;
  featureListEnum = PRODUCT_FEATURE_LIST;

  selectEarlyAdopterPlanWithPrices$: Observable<ProductWithPrices | null>;

  constructor(private _store: Store) {
    this.selectEarlyAdopterPlanWithPrices$ = this._store.pipe(
      select(selectEarlyAdopterPlanWithPrices),
      filter(
        (products: ProductWithPrices | null): boolean => products !== null
      ),
      take<ProductWithPrices | null>(1)
    );

    this.productWithPrices$ = this._store.pipe(
      select(selectProductsWiPrices)
      // tap((p: ProductWithPrices[]) => {
      //   console.log(p);
      // })
    );
  }

  ngOnInit(): void {}
}
