import {Injectable, NgZone} from '@angular/core';
import {AccountState} from '@spout/global-web/models';
import {CustomFirestoreService} from '../../firebase';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {appstoreInAppPurchaseFeatureKey} from './appstore-in-app-purchase.reducer';
import {AbstractConnectionService} from '../../firebase/abstract-connection.service';
import {Store} from '@ngrx/store';
import {AppstoreInAppPurchase} from './appstore-in-app-purchase.model';
import {
  deleteAppstoreInAppPurchases,
  updateAppstoreInAppPurchases,
  upsertAppstoreInAppPurchases
} from './appstore-in-app-purchase.actions';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {FirestoreCollectionQuery} from '../../firebase/firestore-query';
// import {firestoreAppstoreInAppPurchaseCollection} from '../../firebase/database-paths';

@Injectable({
  providedIn: 'root'
})
export class AppstoreInAppPurchaseService extends AbstractConnectionService {
  private _queryService: FirestoreCollectionQuery<AppstoreInAppPurchase>;

  constructor(
    private _customFirestoreService: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    override _store: Store,
    private _zone: NgZone
  ) {
    super(appstoreInAppPurchaseFeatureKey, _connector, _store);

    this._queryService = new FirestoreCollectionQuery<AppstoreInAppPurchase>(
      {
        queryMember: true,
        upsertManyAction: (appstoreInAppPurchases: AppstoreInAppPurchase[]) =>
          upsertAppstoreInAppPurchases({appstoreInAppPurchases}),
        updateManyAction: (appstoreInAppPurchases: AppstoreInAppPurchase[]) =>
          updateAppstoreInAppPurchases({
            appstoreInAppPurchases: aggregateUpdates(appstoreInAppPurchases)
          }),
        deleteManyAction: (ids: string[]) => deleteAppstoreInAppPurchases({ids})
      },
      _zone,
      _store,
      _customFirestoreService
    );
  }

  // deleteDoc$(appstoreInAppPurchase: Project | undefined, uid: string) {
  //   if (appstoreInAppPurchase) {
  //     const path: string = appstoreInAppPurchase.isPrivate
  //       ? firestoreUserAppstoreInAppPurchaseDoc(uid)
  //       : firestoreAppstoreInAppPurchaseDoc();
  //     return this._customFirestoreService.deleteDoc$(path);
  //   }
  //   return of(true);
  // }
  //
  // deleteDocs$(
  //   appstoreInAppPurchase: Project | undefined,
  //   ids: string[],
  //   uid: string
  // ) {
  //   if (appstoreInAppPurchase) {
  //     const isPrivate = appstoreInAppPurchase.isPrivate;
  //     const path: string = isPrivate
  //       ? firestoreUserAppstoreInAppPurchaseCollection(uid)
  //       : firestoreAppstoreInAppPurchaseCollection();
  //     return this._customFirestoreService.deleteDocs$(path, ids);
  //   }
  //   return of(true);
  // }

  onConnect(user: AccountState) {
    this._connector.keyIsConnected(appstoreInAppPurchaseFeatureKey);
    // implement query
    // this._queryService.onConnect(
    //   firestoreAppstoreInAppPurchaseCollection(),
    //   <string>user.uid
    // );
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query
    this._queryService.onDisconnect();

    // Unsubscribe to query before calling this
    this._connector.keyIsDisconnected(appstoreInAppPurchaseFeatureKey);
  }

  // updatePartialFirestore(
  //   changes: Partial<AppstoreInAppPurchase>,
  //   appstoreInAppPurchase: AppstoreInAppPurchase,
  //   uid: string | null
  // ): Observable<AppstoreInAppPurchase> {
  //   if (uid) {
  //     return this._customFirestoreService
  //       .update<AppstoreInAppPurchase>(
  //         firestoreAppstoreInAppPurchasePathByProject(
  //           appstoreInAppPurchase,
  //           uid
  //         ),
  //         changes
  //       )
  //       .pipe(map((r: Exists<AppstoreInAppPurchase>) => r.data));
  //   }
  //
  //   return of(appstoreInAppPurchase);
  // }
}
