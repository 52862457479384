import {hasValue, ternary} from '@uiux/fn';
import {from, Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

export function getAudioDeviceList(): Observable<MediaDeviceInfo[]> {
  return from(navigator.mediaDevices.enumerateDevices()).pipe(
    map((devices: MediaDeviceInfo[]) => {
      return devices.filter((d: MediaDeviceInfo) => d.kind === 'audioinput');
    })
  );
}

/**
 * This Observable completes
 */
export function getDefaultAudioDevice(): Observable<MediaDeviceInfo | null> {
  return getAudioDeviceList().pipe(
    filter((devices: MediaDeviceInfo[]) => hasValue(devices)),
    map((devices: MediaDeviceInfo[]) =>
      devices
        .filter((device: MediaDeviceInfo) => device.deviceId === 'default')
        .reduce((acc: MediaDeviceInfo | null, device: MediaDeviceInfo) => {
          return device;
        }, null)
    )
  );
}

export function getAudioDeviceById(
  deviceId: string = 'default'
): Observable<MediaDeviceInfo | null> {
  deviceId = ternary(deviceId, 'default');
  return getAudioDeviceList().pipe(
    filter((devices: MediaDeviceInfo[]) => hasValue(devices)),
    map((devices: MediaDeviceInfo[]) =>
      devices
        .filter((device: MediaDeviceInfo) => device.deviceId === deviceId)
        .reduce((acc: MediaDeviceInfo | null, device: MediaDeviceInfo) => {
          return device;
        }, null)
    )
    // tap((device: MediaDeviceInfo) => { console.log('getAudioDeviceById -> device >>>>', device);  })
  );
}
