import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {currentIdSet} from '@spout/global-web/fns';
import {EMPTY, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {selectUidHash$} from '../+account/account.selectors';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';
import {createProjectAction} from '../actions/create-entites.actions';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {
  setDeviceStoreCurrentIdsFromTrackAndMixEntity,
  upsertDeviceStorage
} from './device-storage.actions';
import {AudioFileSaveService} from './services/audio-file-save.service';
import {DeviceStorageService} from './services/device-storage.service';

@Injectable()
export class DeviceCurrentIdsEffects {
  // SAVE CURRENT IDS
  setCurrentIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        setDeviceStoreCurrentIdsFromTrackAndMixEntity,
        createProjectAction,
        createDefaultConfigsToFirestore
      ),
      switchMap(action => {
        return this.store.pipe(
          selectUidHash$,
          switchMap(account => {
            if (action.track && action.mix) {
              const storage = {
                currentIds: currentIdSet(action.track, action.mix)
              };

              // console.log('SAVE CURRENT IDS', storage);

              // TODO ERROR NOTIFICATION
              this.device
                .saveDeviceStorage({
                  account,
                  storage
                })
                .subscribe(() => {
                  /* noop */
                });

              return of(upsertDeviceStorage({storage}));
            }

            return EMPTY;
          })
        );
      })
    );
  });

  constructor(
    private dss: DynamicStoreService,
    private device: DeviceStorageService,
    private actions$: Actions,
    private fileService: AudioFileSaveService,
    private store: Store
  ) {}
}
