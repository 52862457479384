import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {SptSystemInformation} from '@spout/global-any/models';
import {
  DEVICE_DETECTION_FEATURE_KEY,
  DeviceDetectionState
} from '@spout/global-web/models';
import {hasValuePipe} from '@uiux/rxjs';
import {pipe} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

// Lookup the 'Device' feature state managed by NgRx
export const deviceState = createFeatureSelector<DeviceDetectionState>(
  DEVICE_DETECTION_FEATURE_KEY
);

// export const deviceGetDevice = createSelector(
//   deviceState,
//   (state: DeviceDetectionState): DeviceDection => state.device
// );

// export const deviceGetCordova = createSelector(deviceState, (state: DeviceDetectionState) => state.cordova);

// export const deviceIsCordova = createSelector(deviceState, (state: DeviceDetectionState) => state.isCordova);

// export const getIsCordova$ = pipe(select(deviceIsCordova), hasValuePipe<boolean, boolean>(), distinctUntilChanged());
//
// export const deviceNotIsCordova$ = pipe(
//   select(deviceIsCordova),
//   hasValuePipe<boolean, boolean>(),
//   distinctUntilChanged(),
//   map((_isCordova: boolean) => !_isCordova)
// );

// export const deviceNotCordova = createSelector(deviceState, (state: DeviceDetectionState) => !state.isCordova);

export const selectDeviceIsPortrait = createSelector(
  deviceState,
  (state: DeviceDetectionState) => state.isPortrait
);

// export const deviceIsCordovaPortrait = createSelector(
//   deviceIsCordova,
//   deviceIsPortrait,
//   (_isCordova: boolean, _isPortrait: boolean) => _isCordova && _isPortrait
// );

export const selectDeviceIsLandscape = createSelector(
  deviceState,
  (state: DeviceDetectionState) => state.isLandscape
);

export const deviceIsElectron = createSelector(
  deviceState,
  (state: DeviceDetectionState) => state.isElectron
);

export const isBrowser = createSelector(
  deviceState,
  (state: DeviceDetectionState) => state.isBrowser
);

export const deviceIsBrowserOrElectron = createSelector(
  deviceState,
  (state: DeviceDetectionState) => {
    return state.isBrowser || state.isElectron;
  }
);

// export const selectElectronVersion = createSelector(deviceState, (state: DeviceDetectionState) => state.electronVersion);

export const isBrowserOrElectron$ = pipe(
  select(deviceIsBrowserOrElectron),
  hasValuePipe<boolean, boolean>(),
  distinctUntilChanged()
);

// export const isCordovaOrElectron = createSelector(
//   deviceState,
//   (state: DeviceDetectionState) => state.isCordova || state.isElectron
// );

// export const isCordovaOrElectron$ = pipe(
//   select(isCordovaOrElectron),
//   hasValuePipe<boolean, boolean>(),
//   distinctUntilChanged()
// );

export const selectDeviceIsSmallScreen = createSelector(
  deviceState,
  (state: DeviceDetectionState) => state.isSmall
);

// export const isIphoneNotch = createSelector(deviceState, (state: DeviceDetectionState) => state.isIphoneNotch);

// export const isIphoneNotchSmall = createSelector(
//   isIphoneNotch,
//   selectDeviceIsSmallScreen,
//   (notch: boolean, smallScreen: boolean) => notch && smallScreen
// );

// export const isIphoneNotchLarge = createSelector(
//   isIphoneNotch,
//   selectDeviceIsSmallScreen,
//   (notch: boolean, smallScreen: boolean) => notch && !smallScreen
// );

// export const isIphoneNotchPortrait = createSelector(
//   isIphoneNotch,
//   deviceIsPortrait,
//   (notch: boolean, portrait: boolean) => notch && portrait
// );

// export const isIphoneNotchLandscape = createSelector(
//   isIphoneNotch,
//   deviceIsLandscape,
//   (notch: boolean, landscape: boolean) => notch && landscape
// );

// export const isSmallToolbar = createSelector(
//   isIphoneNotch,
//   selectDeviceIsSmallScreen,
//   (notch, smallScreen) => !notch && smallScreen
// );

// export const isLargeToolbar = createSelector(
//   isIphoneNotch,
//   selectDeviceIsSmallScreen,
//   (notch, smallScreen) => !notch && !smallScreen
// );

// export const fixedTopGap = createSelector(selectDeviceIsSmallScreen, isIphoneNotch, deviceIsPortrait, deviceIsElectron, calcTopTap);

export const selectComputerPlatform = createSelector(
  deviceState,
  state => state.desktopInformation.computerPlatform
);

export const selectIsDesktop = createSelector(deviceState, state => {
  return state.desktopInformation.isMac || state.desktopInformation.isPc;
});

// SYSTEM INFORMATION SYSTEM INFORMATION SYSTEM INFORMATION
// SYSTEM INFORMATION SYSTEM INFORMATION SYSTEM INFORMATION
// SYSTEM INFORMATION SYSTEM INFORMATION SYSTEM INFORMATION
// SYSTEM INFORMATION SYSTEM INFORMATION SYSTEM INFORMATION
export const getDesktopInformation = createSelector(
  deviceState,
  (state: DeviceDetectionState): SptSystemInformation => {
    return state.desktopInformation;
  }
);

export const isMobile = createSelector(
  deviceState,
  (state: DeviceDetectionState) => {
    return state.useragent?.os?.name?.includes('iOS');
  }
);

export const deviceQuery = {
  // getDevice: deviceGetDevice,
  // getCordova: deviceGetCordova,
  // notCordova: deviceNotCordova,
  // isCordova: deviceIsCordova,
  isPortrait: selectDeviceIsPortrait,
  // isCordovaPortrait: deviceIsCordovaPortrait,
  isLandscape: selectDeviceIsLandscape,
  isDesktop: deviceIsElectron,
  isBrowser,
  isBrowserOrElectron: deviceIsBrowserOrElectron,
  // isCordovaOrElectron,
  getDeviceState: deviceState,
  // isIphoneNotch,
  // isIphoneNotchSmall,
  // isIphoneNotchLarge,
  isSmallScreen: selectDeviceIsSmallScreen
  // isIphoneNotchPortrait,
  // isIphoneNotchLandscape,
  // isSmallToolbar,
  // isLargeToolbar,
  // fixedTopGap
};
