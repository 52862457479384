import {ProductWizardStore} from '../../+subscriptions';
import {ItemizedCost} from '../payment-wizard.service.model';

export function itemizedCheckoutList(
  state: ProductWizardStore
): ItemizedCost[] {
  const items: ItemizedCost[] = [];

  // console.log('selectedProductPrice', state.selectedProduct);

  /**
   * Selected plan
   * TODO Selected plan doubles with storage
   */
  if (state.selectedProductPrice) {
    items.push({
      isTrial: state.currentSubscription?.status === 'trialing',
      itemName:
        state.selectedProduct?.name !== undefined
          ? state.selectedProduct.name
          : '',
      itemDescription:
        state.selectedProduct?.description !== undefined
          ? state.selectedProduct.description
          : '',
      itemPrice: state.selectedProductPrice.unit_amount / 100,
      isTotal: false,
      isIncludedStorage: false,

      // currentSubscriptionStorageProduct.price.product.metadata.baseStorageGB
      baseStorage: <string>state.selectedProduct?.metadata?.baseStorageGB
    });

    items.push({
      isTrial: state.currentSubscription?.status === 'trialing',
      itemName: `${state.selectedProduct?.metadata?.baseStorageGB} GB Base Storage`,
      itemDescription: `Storage included with this plan.`,
      itemPrice: 0,
      isIncludedStorage: true,
      isTotal: false,
      baseStorage: <string>state.selectedProduct?.metadata?.baseStorageGB
    });
  }

  /**
   * Storage
   */
  let storagePrice = 0;

  if (
    state.monthlyStoragePrice &&
    state.yearlyStoragePrice &&
    state.baseMonthlyStoragePrice &&
    state.baseYearlyStoragePrice
  ) {
    if (state.additionalStoragePerGB > 0) {
      if (state.interval === 'month') {
        storagePrice =
          (state.monthlyStoragePrice.unit_amount / 100) *
          state.additionalStoragePerGB;
      } else {
        // year
        storagePrice =
          (state.yearlyStoragePrice.unit_amount / 100) *
          state.additionalStoragePerGB;
      }
    } else {
      if (state.interval === 'month') {
        storagePrice = state.baseMonthlyStoragePrice.unit_amount;
      } else {
        // year
        storagePrice = state.baseYearlyStoragePrice.unit_amount;
      }
    }
  }

  items.push({
    isTrial: false,
    itemName: `${state.additionalStoragePerGB || 0} GB Additional Storage`,
    itemDescription:
      state.storageProduct?.description !== undefined
        ? state.storageProduct.description
        : '',
    itemPrice: storagePrice,
    isTotal: false,
    isIncludedStorage: false,
    baseStorage: ''
  });

  return items;
}
