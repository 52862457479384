import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {HelpContentModule} from '../common/help-content/help-content.module';
import {HelpCompressorModule} from '../content/help-compressor/help-compressor.module';
import {SptComponentRefService} from '../spt-component-ref.service';
import componentRefDict from './content-dictionary';
import {HelpGettingStartedCollaborationModule} from './help-getting-started-collaboration/help-getting-started-collaboration.module';
import {HelpGettingStartedCreatingModule} from './help-getting-started-creating/help-getting-started-creating.module';
import {HelpGettingStartedReviewingModule} from './help-getting-started-reviewing/help-getting-started-reviewing.module';
import {HelpLatencyVsOffsetModule} from './help-latency-vs-offset/help-latency-vs-offset.module';
import {HelpManualLatencyCorrectionModule} from './help-manual-latency-correction/help-manual-latency-correction.module';
import {HelpRecordLatencyModule} from './help-record-latency/help-record-latency.module';
import {HelpSampleRateModule} from './help-sample-rate/help-sample-rate.module';
import {COMPONENT_REF_DICT, HelpTocComponent} from './help-toc.component';

@NgModule({
  declarations: [HelpTocComponent],
  exports: [HelpTocComponent],
  providers: [
    SptComponentRefService,
    {provide: COMPONENT_REF_DICT, useValue: componentRefDict}
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    HelpCompressorModule,
    HelpRecordLatencyModule,
    HelpGettingStartedCollaborationModule,
    HelpGettingStartedCreatingModule,
    HelpGettingStartedReviewingModule,
    HelpLatencyVsOffsetModule,
    HelpSampleRateModule,
    HelpManualLatencyCorrectionModule,
    HelpContentModule,
    PerfectScrollbarModule
  ]
})
export class HelpTocModule {}
