import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  ProjectEntity,
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet
} from '@spout/global-any/models';
import {
  assignTrackMixAudioFileMetaMethod,
  createInitialAudioFileMetaDataEntity,
  createInitialMixEntityWithSong,
  createInitialProjectEntity,
  createInitialSongEntity,
  createInitialTrackEntity,
  createInitialTrackMixConfig,
  setAudioFileMetaDataEntityProps,
  setMixEntityProps,
  setProjectEntityProps,
  setSongEntityProps,
  setTrackEntityProps,
  setTrackMixProps
} from '@spout/global-web/fns';
import {AccountState} from '@spout/global-web/models';
import {assignMethod, hasValue, objectMethodAssign} from '@uiux/fn';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';

@Injectable({
  providedIn: 'root'
})
export class CreateDefaultEntitiesService {
  constructor(private store: Store, private zone: NgZone) {}

  createDefaultEntities(account: AccountState): void {
    const that = this;
    if (account.email && !hasValue(account.defaultProjectId)) {
      let projectEntity: ProjectEntity = createInitialProjectEntity(account);

      projectEntity = setProjectEntityProps(projectEntity, {
        name: `${account.stageName}'s Default Project`,
        description: `${account.stageName}'s Project`,
        isDefault: true
      });

      let songEntity = createInitialSongEntity(account, projectEntity);

      songEntity = setSongEntityProps(songEntity, {
        name: `${account.stageName}'s Default Song`,
        description: `${account.stageName}'s Song`,
        isDefault: true
      });

      let trackEntity: TrackEntity = createInitialTrackEntity(
        account,
        songEntity
      );

      trackEntity = setTrackEntityProps(trackEntity, {
        name: `Reference`,
        description: `for ${account.stageName}'s Song`,
        isDefault: true,
        shared: true
      });

      let audioFileEntity = createInitialAudioFileMetaDataEntity(
        account,
        trackEntity
      );

      audioFileEntity = setAudioFileMetaDataEntityProps(audioFileEntity, {
        name: 'Audio'
      });

      let mixEntity = createInitialMixEntityWithSong(account, songEntity);

      mixEntity = setMixEntityProps(mixEntity, {
        name: `${account.stageName}'s Default Mix`,
        description: `for ${account.stageName}'s Mix`,
        isDefault: true
      });

      const trackMix = objectMethodAssign<TrackMix>(
        createInitialTrackMixConfig(
          account,
          trackEntity,
          mixEntity.id,
          audioFileEntity
        )
      ).pipe(
        assignMethod<TrackMix>(setTrackMixProps, {isReference: true}),
        assignMethod<TrackMix, TrackMixAudioSnippet>(
          assignTrackMixAudioFileMetaMethod(audioFileEntity),
          {
            isDefault: true
          }
        )
      );

      // trackMix = setTrackMixProps(trackMix, { isMaster: true });
      // trackMix = setTrackMixAudioFileSnippetPropsByAudioFileMetaDataEntity(trackMix, audioFileEntity, { isDefault: true });

      // Saved to Firestore
      that.zone.run(() => {
        that.store.dispatch(
          createDefaultConfigsToFirestore({
            project: projectEntity,
            song: songEntity,
            mix: mixEntity,
            track: trackEntity,
            file: audioFileEntity,
            trackMix
          })
        );
      });

      // this.store.dispatch(
      //   accountSaveFirebase({
      //                         payload: {
      //                           defaultProjectId: projectEntity.id,
      //                           defaultSongId: songEntity.id,
      //                           defaultMixId: mixEntity.id,
      //                           defaultTrackId: trackEntity.id,
      //                         },
      //                       })
      // );

      // this.store.dispatch(
      //   setDeviceStoreCurrentIdsFromTrackAndMixEntity({
      //                                                   track: trackEntity,
      //                                                   mix: mixEntity,
      //                                                 })
      // );
    }
  }
}
