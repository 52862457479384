import {ACCOUNT_ROLE} from '@spout/global-web/models';
import {isPlainObject, omit} from 'lodash';
import {Coupon} from '../+invoices';
import {Subscription, SubscriptionItem} from './subscription.model';

export function calculateUnitPriceFromDiscount(
  c: Coupon | null,
  i: SubscriptionItem
): number {
  let unitAmount = 0;

  if (c) {
    if (c.amount_off) {
      unitAmount = i.price.unit_amount - c.amount_off;
    }

    if (c.percent_off) {
      unitAmount =
        i.price.unit_amount - (c.percent_off / 100) * i.price.unit_amount;
    }

    return unitAmount;
  }

  return i.price.unit_amount;
}

function applyFunctionToObjectRecursively<T extends any>(
  o: T | T[],
  f: (arg: T) => T
): T | T[] {
  if (Array.isArray(o)) {
    return <T[]>o.map((_o: T) => applyFunctionToObjectRecursively(_o, f));
  } else if (isPlainObject(o)) {
    const _o: any = f(o);
    return Object.keys(_o).reduce((a: any, k: string) => {
      a[k] = applyFunctionToObjectRecursively(a[k], f);
      return a;
    }, _o);
  } else {
    return o;
  }
}

function omitFirestoreFeatures(subscription: Subscription): Subscription {
  return <Subscription>omit(subscription, ['firestore', '_key']);
}

export function removeFirestoreFeatures(
  subscription: Subscription[]
): Subscription[] {
  return <Subscription[]>(
    applyFunctionToObjectRecursively<Subscription>(
      subscription,
      omitFirestoreFeatures
    )
  );
}

export function getSubscriptionPlan(
  s: SubscriptionItem[]
): SubscriptionItem | null {
  return s.reduce((a: SubscriptionItem | null, i: SubscriptionItem) => {
    if (!a && i.price.product.metadata.productType === 'plan') {
      return i;
    }
    return a;
  }, null);
}

export function getStoragePlan(s: SubscriptionItem[]): SubscriptionItem | null {
  return s.reduce((a: SubscriptionItem | null, i: SubscriptionItem) => {
    if (!a && i.price.product.metadata.featureList === 'storage') {
      return i;
    }
    return a;
  }, null);
}

export function getRoleFromSubscriptionItems(
  s: SubscriptionItem[]
): ACCOUNT_ROLE {
  const plan = getSubscriptionPlan(s);
  const role: string | undefined = <string | undefined>(
    plan?.price.product.metadata.accountRole
  );
  return role !== undefined && role !== null
    ? parseInt(role, 10)
    : ACCOUNT_ROLE.PERSONAL;
}

export function getRoleFromSubscription(s: Subscription[]): ACCOUNT_ROLE {
  return s.reduce((r: ACCOUNT_ROLE | null, _s: Subscription) => {
    return getRoleFromSubscriptionItems(_s.items);
  }, ACCOUNT_ROLE.PERSONAL);
}
