import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Observer} from 'rxjs';
import {CustomFirestoreService} from '../firebase';
import {logout} from './auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthLogoutWatchService {
  constructor(
    private sptFirebase: CustomFirestoreService,
    private store: Store,
    private zone: NgZone
  ) {}

  logoutFirebase$() {
    return new Observable((observer: Observer<any>) => {
      this.sptFirebase.auth
        .signOut()
        .then(() => {
          observer.next(true);
        })
        .catch((error: any) => {
          observer.error(error);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  logoutOnBrowserClose() {
    const that = this;
    window.onbeforeunload = () => {
      // This does not do anything, just feels right to
      // put it here.
      //
      that.zone.run(() => {
        that.store.dispatch(logout());
      });

      this.sptFirebase.auth.signOut().then(() => {
        /* noop */
      });
    };
  }
}
