import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  AUDIO_WORKLET_MAP,
  DYN_STORE,
  TrackEntityAndAudioFileMetaDataEntity
} from '@spout/global-web/models';
import {isDefinedPipe} from '@uiux/rxjs';
import {
  BehaviorSubject,
  from,
  Observable,
  of,
  ReplaySubject,
  switchMap
} from 'rxjs';
import {concatAll, filter, map, mergeMap, take, tap} from 'rxjs/operators';
import {AudioFileLoadService} from '../../+device-storage/services/audio-file-load.service';
import {AudioFileSaveService} from '../../+device-storage/services/audio-file-save.service';
import {DeviceStorageService} from '../../+device-storage/services/device-storage.service';
import {DynamicStoreService} from '../../services/dynamic-store.service';
import {FirebaseStorageService} from '../../services/firebase-storage.service';
import {getPlayerInstanceKeyByEntities} from '../helpers/audio.helpers';
import {SharedBufferWorkletOptions} from '../spt-recorder.audioworklet';
import {SptTransportService} from '../spt-transport.service';
import {SptAudioPlayerWorklet} from '../spt-audio-player.worklet';
import {SongMetricsService} from './song-metrics.service';
import {SptMapSubject} from './spt-map.subject';
import {SptVolumeTranslateService} from './spt-volume-translate.service';

@Injectable({
  providedIn: 'root'
})
export class SptPlayerCache {
  private workletProcessorLoaded: BehaviorSubject<boolean>;
  private cache: SptMapSubject<SptAudioPlayerWorklet>;

  constructor(
    private volumeService: SptVolumeTranslateService,
    private dss: DynamicStoreService,
    private device: DeviceStorageService,
    private store: Store,
    private audioFileLoadService: AudioFileLoadService,
    private audioFileSaveService: AudioFileSaveService,
    private metrics: SongMetricsService,
    private sptTransportService: SptTransportService, // @Inject(SPT_TRANSPORT_TOKEN) private sptTransportService: SptTransportService
    private _storage: FirebaseStorageService
  ) {
    const that = this;
    this.cache = new SptMapSubject();
    this.workletProcessorLoaded = new BehaviorSubject<boolean>(false);

    this.sptTransportService.audioContext$
      .pipe(isDefinedPipe<AudioContext, AudioContext>())
      .subscribe((audioContext: AudioContext) => {
        audioContext.audioWorklet
          .addModule(AUDIO_WORKLET_MAP.SHARED_BUFFER_PLAYER_WORKLET_PROCESSOR)
          .then(() => {
            this.workletProcessorLoaded.next(true);
          });
      });
  }

  init() {}

  getPlayer(
    a: TrackEntityAndAudioFileMetaDataEntity
  ): SptAudioPlayerWorklet | undefined | null {
    const playerKey: string | null = getPlayerInstanceKeyByEntities(a);
    if (playerKey) {
      return this.cache.get(playerKey);
    }
    return null;
  }

  hasPlayer(a: TrackEntityAndAudioFileMetaDataEntity): boolean {
    const playerKey: string | null = getPlayerInstanceKeyByEntities(a);
    // console.log(playerKey);
    return playerKey !== null && this.cache.has(playerKey);
  }

  getSptPlayer$(
    a: TrackEntityAndAudioFileMetaDataEntity,
    options?: SharedBufferWorkletOptions
  ): Observable<SptAudioPlayerWorklet | undefined> {
    const that = this;

    // console.log('isLoaded => ', a);
    // console.log('isLoaded => ', this.workletProcessorLoaded);

    return this.workletProcessorLoaded.pipe(
      // tap((isLoaded: boolean) => {
      //   console.log('isLoaded => ', isLoaded);
      // }),
      // filter((isLoaded: boolean) => isLoaded),
      // take(1),
      switchMap((isLoaded: boolean) => {
        // console.log('isLoaded => ', isLoaded);

        return this.sptTransportService.audioContext$.pipe(
          map((audioContext: AudioContext) => {
            const playerKey: string | null = getPlayerInstanceKeyByEntities(a);
            const audioContextId: number = (<any>audioContext)['id'];

            // console.log(playerKey);

            // https://developer.mozilla.org/en-US/docs/Web/API/AudioWorkletNodeOptions
            let _options: SharedBufferWorkletOptions = {
              isActiveInPlaylist:
                options && options.isActiveInPlaylist !== undefined
                  ? options.isActiveInPlaylist
                  : true,
              parameterData: {
                mute: 0 // true == 1, false === 0
              }
            };

            if (options && !options.numberOfInputs) {
              _options = Object.assign(_options, <AudioWorkletNodeOptions>{
                numberOfOutputs: 1,
                outputChannelCount: [2]
              });
            } else if (options) {
              _options = Object.assign(_options, options);
            }

            if (playerKey && that.cache.has(playerKey)) {
              const player: SptAudioPlayerWorklet | undefined =
                that.cache.get(playerKey);

              if (player && !player.audioContextIdMatches(audioContextId)) {
                player.destroy();
                that.cache.delete(playerKey);
              }
            }

            if (playerKey && !that.cache.has(playerKey)) {
              that.addPlayer(
                new SptAudioPlayerWorklet(
                  audioContext,
                  playerKey,
                  a,
                  this.dss,
                  this.store,
                  this.sptTransportService,
                  this.audioFileLoadService,
                  this.audioFileSaveService,
                  this.volumeService,
                  this.metrics,
                  this._storage,
                  true, // isActiveInPlaylist
                  this.device,
                  _options
                )
              );
            }

            return playerKey ? that.cache.get(playerKey) : undefined;
          })
        );
      })
    );
  }

  muteAll() {
    if (this.cache.size) {
      from(Array.from(this.cache.keys()))
        .pipe(
          map((key: string) => {
            const pi = this.cache.get(key);
            if (pi) {
              pi.mute = true;
            }
          })
        )
        .subscribe(() => {
          /* noop */
        });
    }
  }

  muteAll$(): Observable<boolean> {
    return from(<SptAudioPlayerWorklet[]>[...this.cache.values()]).pipe(
      map((p: SptAudioPlayerWorklet) => p.mute$(true)),
      concatAll()
    );
  }

  disconnectAll$(): Observable<boolean> {
    if (this.cache.size < 1) {
      return of(true);
    }

    return from(<SptAudioPlayerWorklet[]>[...this.cache.values()]).pipe(
      map((p: SptAudioPlayerWorklet) => p.disconnectOutput$()),
      concatAll()
    );
  }

  unmuteActiveInPlaylist$() {
    if (this.cache.size) {
      return from(<SptAudioPlayerWorklet[]>[...this.cache.values()]).pipe(
        map((pi: SptAudioPlayerWorklet) => {
          if (pi && pi.isActiveInPlaylist) {
            return pi.unmute$();
          }

          return of(true);
        }),
        concatAll()
      );
    }

    return of(true);
  }

  getSptPlayersByTrackId(trackId: string): SptAudioPlayerWorklet[] {
    if (this.cache.size) {
      return Array.from(this.cache.values()).filter(
        (pi: SptAudioPlayerWorklet) => pi.trackId === trackId
      );
    }

    return [];
  }

  /**
   * Return only first track found
   * @param trackId
   */
  getSptHeadPlayerByTrackId(
    trackId: string
  ): Observable<SptAudioPlayerWorklet | null> {
    return this.cache.pipe(
      map((cache: Map<string, SptAudioPlayerWorklet>) => {
        return Array.from(cache.values()).reduce(
          (p: SptAudioPlayerWorklet | null, i: SptAudioPlayerWorklet) => {
            if (!p && i.trackId === trackId) {
              return i;
            }
            return p;
          },
          null
        );
      })
    );
  }

  deleteSptPlayerByTrackId(trackId: string): void {
    this.getSptPlayersByTrackId(trackId).forEach(
      (pi: SptAudioPlayerWorklet) => {
        pi.disconnect();
        this.cache.delete(pi.id);
      }
    );
  }

  deleteSptPlayersbyTrackIDs(trackIds: string[]): void {
    const that = this;
    trackIds.forEach((id: string) => {
      that.deleteSptPlayerByTrackId(id);
    });
  }

  clearSptPlayersByTrackId(trackId: string): void {
    this.getSptPlayersByTrackId(trackId).forEach(
      (pi: SptAudioPlayerWorklet) => {
        // console.log(pi);
        if (pi) {
          // pi.disconnect();
          pi.clear();
        }
      }
    );

    // this.dss.emit(DYN_STORE.TRACK_CLEARED, trackId);
  }

  clear() {
    this.cache.clear();
  }

  addPlayer(value: SptAudioPlayerWorklet) {
    this.cache.set(value.id, value);
  }
}
