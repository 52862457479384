import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'spt-checkout-wait-dialog',
  templateUrl: './checkout-wait-dialog.component.html',
  styleUrls: ['./checkout-wait-dialog.component.scss']
})
export class CheckoutWaitDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
