import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export interface GenresMusician {
  id: string;
}

export type GenresMusicianState = EntityState<GenresMusician>;

export const genresMusicianEntityAdapter: EntityAdapter<GenresMusician> =
  createEntityAdapter<GenresMusician>();

export const intialGenreMusicianState: GenresMusicianState =
  genresMusicianEntityAdapter.getInitialState({
    // additional entity state properties
  });
