import {Injectable, NgZone} from '@angular/core';
import {AccountState} from '@spout/global-web/models';
import {CustomFirestoreService} from '../../firebase';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {firestoreProductCollection} from '../database-paths';
import {convertProductValuesStringToNumber} from './product.fns';
import {productFeatureKey} from './product.reducer';
import {AbstractConnectionService} from '../../firebase/abstract-connection.service';
import {Store} from '@ngrx/store';
import {
  deleteProducts,
  loadProducts,
  updateProducts,
  upsertProducts
} from './product.actions';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {FirestoreCollectionQuery} from '../../firebase/firestore-query';
import {Product} from './product.model';
import {where} from '@firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends AbstractConnectionService {
  private _queryService: FirestoreCollectionQuery<Product>;

  constructor(
    private _customFirestoreService: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    override _store: Store,
    private _zone: NgZone
  ) {
    super(productFeatureKey, _connector, _store);

    this._queryService = new FirestoreCollectionQuery<Product>(
      {
        queryConstrains: [where('active', '==', true)],
        queryMember: false,
        upsertManyAction: (products: Product[]) =>
          loadProducts({
            products: convertProductValuesStringToNumber(products)
          }),
        updateManyAction: (products: Product[]) =>
          updateProducts({
            products: aggregateUpdates(
              convertProductValuesStringToNumber(products)
            )
          }),
        deleteManyAction: (ids: string[]) => deleteProducts({ids}),
        mapFirestoreID: true,
        disconnectFirestoreOnLogout: false
      },
      _zone,
      _store,
      _customFirestoreService
    );

    this._queryService.onConnect(firestoreProductCollection());
  }

  onConnect(user: AccountState) {
    this._connector.keyIsConnected(productFeatureKey);

    // implement query
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query
    // this._queryService.onDisconnect();
    // Unsubscribe to query before calling this
    // this._connector.keyIsDisconnected(productFeatureKey);
  }
}
