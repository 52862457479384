import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {PromoCodeService} from './promo-code.service';
import {PartialPromoCodeState} from './promo-code.reducer';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<PartialPromoCodeState>,
    private _promoCodeService: PromoCodeService
  ) {}
}
