import {Inject, Injectable} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {ComponentStore} from '@ngrx/component-store';
import {select, Store} from '@ngrx/store';
import {
  anyBooleanValueTrue,
  distinctUntilJsonChanged,
  firestoreCheckoutSessions
} from '@spout/global-web/fns';
import {
  AccountState,
  ENVIRONMENT,
  IEnvironmentState
} from '@spout/global-web/models';
import {Stripe} from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import {
  addDoc,
  DocumentData,
  DocumentSnapshot,
  onSnapshot
} from '@firebase/firestore';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import {
  getPriceByID,
  getPriceIntervalByIDAndCommonProduct,
  Price,
  PriceInterval
} from '../+prices';
import {
  getProductByID,
  PriceByIntervalDict,
  Product,
  StorageAndPriceByIntervalDict
} from '../+product';
import {ProductWizardStore} from '../+subscriptions';
import {selectAccountState} from '../../+account/account.selectors';
import {marketingWizardCheckout} from '../../+google-analytics/actions';
import {
  CustomFirestoreService,
  removeTimestampCTorFromDocumentSnapshot
} from '../../firebase';
import {
  selectStorageByIntervalIDDict,
  selectSubscriptionPriceIDsAndStorageQuantity,
  TrialDaysUIDCheckout,
  trialDaysUIDCheckout
} from '../stripe.selectors';
import {checkoutDisabled} from './fns/checkout-disabled';
import {createCheckoutSession} from './fns/create-checkout-session';
import {createUpdatePayload} from './fns/create-update-payload';
import {itemizedCheckoutList} from './fns/itemized-checkout-list';
import {
  getCurrentSubscriptionStorageQuantity,
  isPerMonth,
  selectCurrentSubscription,
  selectEarlyAdopterAgreementSigned,
  selectedStoragePrice,
  selectStorageQtyAndInterval,
  signEarlyAdopterAgreement,
  totalCost
} from './fns/page-subscribe.fns';
import {selectTrialEnds} from './fns/select-trial-ends';
import {updateProductPrice} from './fns/update-product-price';
import {updateStoragePrice} from './fns/update-storage-price';
import {updateStorageQuantity} from './fns/update-storage-quantity';
import {CheckoutWaitDialogComponent} from './modals/checkout-wait-dialog/checkout-wait-dialog.component';
import {StripeErrorModalComponent} from './modals/stripe-error-modal/stripe-error-modal.component';
import {UpdateSubscriptionErrorComponent} from './modals/update-subscription-error/update-subscription-error.component';
import {UpdateSubscriptionSuccessComponent} from './modals/update-subscription-success/update-subscription-success.component';

import {
  APIStripeSubscriptionPayload,
  APIStripeUpdateSubscrptionItem,
  ItemizedCost,
  StorageAndInterval
} from './payment-wizard.service.model';

@Injectable()
export class StripePaymentSelectionService extends ComponentStore<ProductWizardStore> {
  // readonly state$: Observable<ProductWizardStore> = this.select(
  //   (state: ProductWizardStore) => ({...state})
  // );

  readonly getCurrentSubscriptionStorageQuantity$: Observable<number | null> =
    this.select(getCurrentSubscriptionStorageQuantity);

  readonly selectCurrentSubscription$ = this.select(selectCurrentSubscription);

  readonly cancelAtPeriodEnd$ = this.select((state: ProductWizardStore) => {
    return state.currentSubscription?.cancel_at_period_end;
  });

  readonly selectStorageQtyAndInterval$: Observable<StorageAndInterval> =
    this.select(selectStorageQtyAndInterval).pipe(distinctUntilJsonChanged());

  readonly selectAdditionalStoragePerGB: Observable<number> = this.select(
    (state: ProductWizardStore) => state.additionalStoragePerGB
  ).pipe(distinctUntilChanged());

  readonly selectIsInTrial$: Observable<boolean> = this.select(
    (state: ProductWizardStore) =>
      state?.currentSubscription?.status === 'trialing'
  ).pipe(distinctUntilChanged());

  readonly selectTrialEnds$: Observable<{
    trialEnds: number;
    isInTrial: boolean;
  }> = this.select(selectTrialEnds);

  getEarlyAdopterSelected$: Observable<boolean> = this.select(state => {
    return state.selectedProduct?.metadata?.featureList === 'earlyAdopter';
  });

  signEarlyAdopterAgreement = this.updater(signEarlyAdopterAgreement);

  readonly itemizedCheckoutList$: Observable<ItemizedCost[]> =
    this.select(itemizedCheckoutList);

  baseStoragePrice = 300;

  readonly totalCost$: Observable<number> = this.itemizedCheckoutList$.pipe(
    map((items: ItemizedCost[]) => {
      return totalCost(items);
    })
  );

  readonly isPerMonth$: Observable<boolean> = this.select(isPerMonth).pipe(
    distinctUntilChanged()
  );

  readonly selectedPlanPrice$: Observable<string | null> = this.select(
    (state: ProductWizardStore) =>
      state.selectedProductPrice ? state.selectedProductPrice?.id : null
  ).pipe(distinctUntilChanged());

  readonly selectedStoragePrice$: Observable<string | null> = this.select(
    selectedStoragePrice
  ).pipe(distinctUntilChanged());

  readonly selectEarlyAdopterAgreementSigned$: Observable<boolean> =
    this.select(selectEarlyAdopterAgreementSigned).pipe(distinctUntilChanged());

  showCheckoutProgress$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  checkoutDisabled$ = combineLatest([
    this.select(checkoutDisabled),
    this.showCheckoutProgress$
  ]).pipe(map(anyBooleanValueTrue));

  private _updateStorageQty = this.updater(updateStorageQuantity);

  private _updateProductPrice = this.updater(updateProductPrice);

  private _updateStoragePrice = this.updater(updateStoragePrice);

  private _updateSubscriptionItems = this.updater(
    (state: ProductWizardStore, s: Partial<ProductWizardStore>) => {
      return {
        ...state,
        ...s
      };
    }
  );

  constructor(
    private _store: Store,
    private _firestore: CustomFirestoreService,
    private _dialog: MatDialog,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {
    super({
      trialDays: 0,

      currentSubscription: null,
      currentSubscriptionProduct: null,
      currentSubscriptionStorageProduct: null,
      currentSubscriptionProductPrice: null,
      currentSubscriptionStoragePrice: null,

      selectedProduct: null,
      selectedProductPrice: null,

      storageProduct: null,
      baseMonthlyStoragePrice: null,
      baseYearlyStoragePrice: null,
      monthlyStoragePrice: null,
      yearlyStoragePrice: null,

      additionalStoragePerGB: 0,

      earlyAdopterAgreementSigned: false,

      interval: 'month'
    });
  }

  updateStorageQty(quantity: number) {
    this._store
      .pipe(select(selectStorageByIntervalIDDict), take(1))
      .subscribe((storage: StorageAndPriceByIntervalDict) => {
        this._updateStorageQty({
          quantity,
          storage
        });
      });
  }

  init(callback:any) {
    this._store
      .pipe(
        select(selectSubscriptionPriceIDsAndStorageQuantity),
        // filter((s: ProductWizardStore) => s.currentSubscription !== null),
        distinctUntilJsonChanged()
      )
      .subscribe((s: ProductWizardStore) => {
        // console.log(s);
        this._updateSubscriptionItems(s);
        if(callback)callback();
      });
  }

  updatePrice(priceID: string) {
    combineLatest([
      // Get price
      this._store.pipe(select(getPriceByID(priceID))),

      // get PriceByIntervalDict
      this._store.pipe(select(getPriceIntervalByIDAndCommonProduct(priceID)))
    ])
      .pipe(
        take(1),
        switchMap(
          ([price, priceByInterval]: [
            Price | undefined,
            PriceByIntervalDict
          ]) => {
            if (price) {
              return this._store.pipe(
                // Get Product by price id
                select(getProductByID((<Price>priceByInterval.month)?.product)),

                // get Storage prices
                map((product: Product | undefined) => {
                  return {
                    price: priceByInterval,
                    product,
                    interval: price?.interval ? price.interval : 'month'
                  };
                })
              );
            }

            return of({
              price: {
                month: null,
                year: null
              },
              product: null,
              interval: <PriceInterval>'month'
            });
          }
        )
      )
      .subscribe(
        ({
          price,
          product,
          interval
        }: {
          price: PriceByIntervalDict;
          product: Product | null | undefined;
          interval: PriceInterval;
        }) => {
          if (product && price && interval) {
            this._updateProductPrice({product, price, interval});
          }
        }
      );
  }

  onCheckout(hasPromo:boolean) {
    this.state$
      .pipe(
        withLatestFrom(this._store.pipe(select(trialDaysUIDCheckout))),
        take(1)
      )
      .subscribe(async ([c, a]: [ProductWizardStore, TrialDaysUIDCheckout]) => {
        if (c.selectedProductPrice) {
          // this.showCheckoutProgress$.next(true);

          const waitForCheckoutToLoad: MatDialogRef<any> = this._dialog.open(
            CheckoutWaitDialogComponent,
            {
              backdropClass: 'studio-dialog-backdrop',
              width: '600px',
              disableClose: true
            }
          );

          const session: {
            session: any;
            gaEventParams: {[key: string]: any};
          } | null = createCheckoutSession([c, a],hasPromo,this.environment);
          console.log("onCheckout");
          console.log(c);
          console.log(a);

          if (a.uid && session && session.session) {
            this._store.dispatch(
              marketingWizardCheckout(session.gaEventParams)
            );
            let firestoreCheckoutSessions_collectionRef =
              this._firestore.collectionRef(
                firestoreCheckoutSessions(<string>a.uid)
              );
            console.log(firestoreCheckoutSessions_collectionRef);
            console.log(session.session);

            const docRef = await addDoc(
              firestoreCheckoutSessions_collectionRef,
              session.session
            );

            // Wait for the CheckoutSession to get attached by the extension
            onSnapshot(docRef, async (snap: DocumentSnapshot<DocumentData>) => {
              const {error, sessionId} = <any>(
                removeTimestampCTorFromDocumentSnapshot(snap)
              );
              if (error) {
                waitForCheckoutToLoad.close();

                // this.showCheckoutProgress$.next(false);
                // Show an error to your customer and then inspect your function logs.
                const ref = this._dialog.open(StripeErrorModalComponent, {
                  width: '600px',
                  backdropClass: 'studio-dialog-backdrop',
                  data: error.message
                });
              }
              if (sessionId) {
                // We have a session, let's redirect to Checkout
                // Init Stripe
                const stripe: Stripe | null = await loadStripe(
                  this.environment.stripePublishableKey
                );

                if (stripe) {

                  stripe.redirectToCheckout({sessionId});
                }
              }
            });
          }
        }
      });
  }

  onUpdate() {
    this.state$
      .pipe(
        withLatestFrom(this._store.pipe(select(selectAccountState))),
        take(1)
      )
      .subscribe(async ([c, account]: [ProductWizardStore, AccountState]) => {
        this.showCheckoutProgress$.next(true);
        if (c.currentSubscription) {
          const subscritpionPayload: APIStripeSubscriptionPayload =
            createUpdatePayload([c, account]);

          // console.log(JSON.stringify(subscritpionPayload, null, 2));
          // return;

          subscritpionPayload.items = subscritpionPayload.items.map(
            (i: APIStripeUpdateSubscrptionItem) => {
              delete i.itemNameForDev;
              return i;
            }
          );

          if (subscritpionPayload.items.length) {
            // console.log(c);
            // console.log(JSON.stringify(subscritpionPayload, null, 2));
            const updateSubscription =
              this._firestore.httpsCallable('updateSubscription');

            updateSubscription(subscritpionPayload)
              .then(() => {
                this.showCheckoutProgress$.next(false);
                this._dialog.open(UpdateSubscriptionSuccessComponent, {
                  backdropClass: 'studio-dialog-backdrop',
                  width: '600px'
                });
              })
              .catch((error: any) => {
                this.showCheckoutProgress$.next(false);
                this._dialog.open(UpdateSubscriptionErrorComponent, {
                  backdropClass: 'studio-dialog-backdrop',
                  width: '600px',
                  data: error
                });
              });
          }
        }
      });
  }
}
