import {Price, Product} from './+product';

export interface StripeProductWithPrices {
  product: Product;
  prices: Price[];
}

export interface PlanPriceOption {
  price: Price;
  isCurrentSubscriptionPrice: boolean;
}

export interface StripeProductWithPricesCurrentSubscription {
  product: Product;
  prices: PlanPriceOption[];
  isCurrentSubscriptionPlan: boolean;
}

export const defaultStripePaymentsOptions: {
  customersCollection: string;
  productsCollection: string;
} = {
  customersCollection: 'users',
  productsCollection: 'products'
};
