import {ENDPOINTS} from './base.endpoints';
import {EndpointModels} from './endpoint.models';

// const SERVER = 'https://spout-studio.herokuapp.com';
const SERVER = 'https://spout-proxy-server-2jmqw.ondigitalocean.app';

export const endpointsMain: EndpointModels = {
  SERVER,
  UPLOAD_BACKBLAZE: `${SERVER}/${ENDPOINTS.UPLOAD_BACKBLAZE}`,
  DELETE_BACKBLAZE: `${SERVER}/${ENDPOINTS.DELETE_BACKBLAZE}`,
  DOWNLOAD_BACKBLAZE: `${SERVER}/${ENDPOINTS.DOWNLOAD_BACKBLAZE}`
};
