import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import {Subscription} from '@spout/global-web/data-access';

@Component({
  selector: 'spt-subscription-end',
  templateUrl: './subscription-end.component.html',
  styleUrls: ['./subscription-end.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionEndComponent {
  seconds = 0;

  @Input()
  set subscription(subscription: Subscription | undefined | null) {
    if (subscription?.current_period_end?.seconds) {
      this.seconds = subscription?.current_period_end?.seconds;
      this._cd.detectChanges();
    }
  }

  constructor(private _cd: ChangeDetectorRef) {}
}
