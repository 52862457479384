import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ProjectEntity} from '@spout/global-any/models';

import {BehaviorSubject} from 'rxjs';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {ProjectFactoryService} from './project-factory.service';
import {selectAllProjects} from './project-storage.selectors';
import {ProjectNode} from './project.node';

@Injectable({
  providedIn: 'root'
})
export class ProjectNodeCache {
  private mapCache: BehaviorSubject<Map<string, ProjectNode>> =
    new BehaviorSubject(new Map<string, ProjectNode>());

  constructor(
    private dss: DynamicStoreService,
    private projectFactoryService: ProjectFactoryService,
    private store: Store
  ) {
    this.store
      .pipe(select(selectAllProjects))
      .subscribe((result: ProjectEntity[]) => {
        // console.log(result);

        const cache: Map<string, ProjectNode> = this.mapCache.value;

        // Add / Update projects
        result.forEach((project: ProjectEntity) => {
          if (!cache.has(project.id)) {
            cache.set(
              project.id,
              projectFactoryService.createProjectNode(project)
            );
          } else {
            const projectNode = cache.get(project.id);
            if (projectNode) {
              projectNode.updateConfig(project);
            }
          }
        });

        // Remove Projects
        // cache.forEach((project: ProjectNode) => {
        //   const found = result.find((rProject: ProjectEntity) => rProject.id === project.id);
        //
        //   if (!found) {
        //     project.onDisconnect();
        //     cache.delete(project.id);
        //   }
        // });

        this.mapCache.next(cache);
      });
  }

  init() {
    /* noop */
  }
}
