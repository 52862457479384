import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {SptRecorderService} from '@spout/global-web/data-access';

@Component({
  selector: 'spt-help-manual-latency-correction-content',
  templateUrl: './help-manual-latency-correction-content.component.html',
  styleUrls: ['./help-manual-latency-correction-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpManualLatencyCorrectionContentComponent {
  constructor(private recorder: SptRecorderService) {}
  refresh(): void {
    this.recorder.refreshAudioDeviceList().subscribe(() => {});
  }
}
