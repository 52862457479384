import {filterFileListByApprovedExtension} from '@spout/global-any/models';
import {getFileName, uuid} from '@spout/global-web/fns';
import {FileWithId} from './import-files.models';

export function createFilesWithId(files: File[]): FileWithId[] {
  return filterFileListByApprovedExtension(files).map((file: File) => {
    return {
      file,
      id: uuid(),
      // name: getFileName(file.name).split(' ').join('_'),
      name: getFileName(file.name)
    };
  });
}
