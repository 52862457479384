import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {Store} from '@ngrx/store';
import {StorageMap} from '@ngx-pwa/local-storage';
import {clearIndexedDBCached$} from '@spout/global-web/fns';

import {getIn} from '@uiux/fn';
import {ReplaySubject} from 'rxjs';
import {User} from '@firebase/auth';
import {logout} from '../../+account/auth.actions';

@Component({
  selector: 'spt-no-email-warning',
  templateUrl: './no-email-warning.component.html',
  styleUrls: ['./no-email-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoEmailWarningComponent implements OnInit {
  providerId$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<NoEmailWarningComponent>,
    private store: Store,
    private storage: StorageMap,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {}

  ngOnInit(): void {
    this.providerId$.next(
      getIn(this.data, 'providerData[0].providerId', 'unknown')
    );
    this.cd.detectChanges();
  }

  ok() {
    localStorage.clear();
    this.storage.clear();
    this.store.dispatch(logout());

    clearIndexedDBCached$().subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
