import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpCompressorContentComponent} from './help-compressor-content/help-compressor-content.component';
import {HelpCompressorComponent} from './help-compressor.component';

@NgModule({
  declarations: [HelpCompressorComponent, HelpCompressorContentComponent],
  exports: [HelpCompressorComponent, HelpCompressorContentComponent],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpCompressorModule {}
