import {Injectable, NgZone} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {
  AppVersions,
  concatElectronBuildVersion,
  concatUiBuildVersion,
  firestoreElectronVersionIsGreaterThanLocal,
  firestoreUIVersionIsGreaterThanLocal,
  StudioVersion
} from '@spout/global-any/models';
import {studioVersion} from '@spout/global-web/fns';
import {StaticNotificationIds, StudioInstalled} from '@spout/global-web/models';
import {
  DocumentSnapshot,
  FirestoreError,
  onSnapshot
} from '@firebase/firestore';
import {combineLatest, EMPTY, ReplaySubject} from 'rxjs';
import {filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {deviceIsElectron} from '../+device-detection/device-detection.selectors';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {
  createElectronUpdateNotification,
  createUiUpdateNotification
} from '../+notifications/helpers/notification.creators';
import {
  deleteNotification,
  upsertNotification
} from '../+notifications/notifications.actions';
import {
  removeTimestampCTorFromDocumentSnapshot,
  CustomFirestoreService
} from '../firebase';
import {loadInstalledElectron, StudioActions} from './studio.actions';
import {selectInstalledStudioVersion} from './studio.selectors';

@Injectable({providedIn: 'root'})
export class StudioEffects {
  loadInstalled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      withLatestFrom(this.store.pipe(select(deviceIsElectron))),
      tap(() => {
        this.onRootEffectsInit();
      }),
      switchMap(([_, isElectron]) => {
        // console.log(isElectron);
        if (!isElectron) {
          return EMPTY;
        }

        return this.deviceStorage.getElectronBuild().pipe(
          map((installed: AppVersions) => {
            return loadInstalledElectron({
              installed
            });
          })
        );
      })
    );
  });

  firebaseStudioVersion$: ReplaySubject<StudioVersion>;

  constructor(
    private actions$: Actions,
    private store: Store,
    private deviceStorage: DeviceStorageService,
    private sptFirestore: CustomFirestoreService,
    private zone: NgZone
  ) {
    this.firebaseStudioVersion$ = new ReplaySubject<StudioVersion>(1);

    // TEST NOTIFICATIONS
    // const notifications = [];
    // for (let i = 0; i < 20; i++) {
    //
    //   notifications.push(createGenericNotification({
    //     header: `Notification ${i}`,
    //     message: 'Test Notification',
    //     level: NotificationLevel.High,
    //     type: NotificationTypes.General
    //   }));
    //
    // }
    //
    // this.store.dispatch(upsertNotifications({
    //   notifications,
    // }));
  }

  private onRootEffectsInit() {
    const that = this;
    const _ref = this.sptFirestore.docRef(studioVersion());

    onSnapshot(
      _ref,
      (doc: DocumentSnapshot) => {
        this.firebaseStudioVersion$.next(
          removeTimestampCTorFromDocumentSnapshot(doc)
        );
      },
      (error: FirestoreError) => {
        // console.error(error);
      }
    );

    combineLatest([
      this.firebaseStudioVersion$,
      this.store.pipe(
        select(selectInstalledStudioVersion),
        filter((s: StudioInstalled) => s !== null && s !== undefined)
      )
    ]).subscribe(
      ([firestoreVersion, installedVersion]: [
        StudioVersion,
        StudioInstalled
      ]) => {
        console.log('latest firestore ui version', firestoreVersion.ui);
        console.log('installed ui version', installedVersion.ui);
        console.log(
          'latest firestore electron version',
          firestoreVersion.electron
        );
        console.log(
          'installed electron version',
          installedVersion.electronAppBuild
        );

        that.zone.run(() => {
          // ELECTRON VERSION
          // ELECTRON VERSION
          if (
            installedVersion &&
            firestoreElectronVersionIsGreaterThanLocal(
              firestoreVersion.electron,
              installedVersion.electron
            )
          ) {
            that.store.dispatch(
              StudioActions.hasElectronUpdate({
                hasElectronUpdate: true
              })
            );

            that.store.dispatch(
              upsertNotification({
                notification: createElectronUpdateNotification(
                  concatElectronBuildVersion(firestoreVersion.electron)
                )
              })
            );
          } else {
            that.store.dispatch(
              StudioActions.hasElectronUpdate({
                hasElectronUpdate: false
              })
            );

            that.store.dispatch(
              deleteNotification({id: StaticNotificationIds.UpdateElectron})
            );
          }

          // UI VERSION
          // UI VERSION
          if (
            installedVersion &&
            firestoreUIVersionIsGreaterThanLocal(
              firestoreVersion.ui,
              installedVersion.ui
            )
          ) {
            if (window.navigator.userAgent.includes('Electron')) {
              that.store.dispatch(
                upsertNotification({
                  notification: createUiUpdateNotification(
                    concatUiBuildVersion(firestoreVersion.ui)
                  )
                })
              );
            }
            that.store.dispatch(StudioActions.hasUiUpdate({hasUiUpdate: true}));
          } else {
            that.store.dispatch(
              StudioActions.hasUiUpdate({hasUiUpdate: false})
            );
            that.store.dispatch(
              deleteNotification({id: StaticNotificationIds.UpdateUI})
            );
          }
        });
      },
      error => {
        console.error(error);
      }
    );
  }
}
