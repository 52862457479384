import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {AppIdleDialogComponent} from './app-idle-dialog.component';

@NgModule({
  declarations: [AppIdleDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [AppIdleDialogComponent]
})
export class AppIdleDialogModule {}
