import {ProductWizardStore} from '../../+subscriptions';

export function checkoutDisabled(state: ProductWizardStore): boolean {
  if (state.selectedProductPrice !== null) {
    return state.selectedProduct?.metadata.featureList === 'earlyAdopter'
      ? !state.earlyAdopterAgreementSigned
      : false;
  }

  if (state.baseMonthlyStoragePrice !== null) {
    return (
      state.currentSubscriptionStorageProduct?.quantity ===
      state.additionalStoragePerGB
    );
  }

  return true;
}
