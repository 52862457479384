import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {ENVIRONMENT, IEnvironmentState} from '@spout/global-web/models';
import {WINDOW} from '@spout/global-web/utils';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {
  IsTrialWithDaysRemaining,
  selectHasActiveSubscription,
  selectHasActiveSubscription$,
  selectSubscriptionsIsRetrievedFromFirestore$,
  selectTrialDays
} from '../../../+subscriptions';
import {selectSubscribedAtFromAccount} from '../../../../+account/account.selectors';
import {logout} from '../../../../+account/auth.actions';
import {deviceIsElectron} from '../../../../+device-detection/device-detection.selectors';
import {ga_Logout} from '../../../../+google-analytics/actions';
import {selectIsTrialAndDaysRemaining} from '../../../stripe.selectors';

@Component({
  selector: 'spt-stripe-subscribe-dialog',
  templateUrl: './stripe-subscribe-dialog.component.html',
  styleUrls: ['./stripe-subscribe-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'spt-stripe-subscribe-dialog'
  }
})
export class StripeSubscribeDialogComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();

  trialDays$: Observable<number>;
  hasSubscribedAt$: Observable<boolean>;

  constructor(
    private _router: Router,
    private _store: Store,
    private _dialog: MatDialogRef<StripeSubscribeDialogComponent>,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState,
    @Inject(WINDOW) private window: Window
  ) {
    this.trialDays$ = this._store.pipe(select(selectTrialDays));
    this.hasSubscribedAt$ = this._store.pipe(
      select(selectSubscribedAtFromAccount),
      map((subscribedAt: number | null) => subscribedAt !== null)
    );
  }

  ngOnInit() {
    combineLatest([
      this._store.pipe(select(selectIsTrialAndDaysRemaining)),
      this._store.pipe(selectSubscriptionsIsRetrievedFromFirestore$),
      this._store.pipe(select(selectHasActiveSubscription))
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        ([trial, isRetrievedFromFirestore, hasActiveSubscription]: [
          IsTrialWithDaysRemaining,
          boolean,
          boolean
        ]) => {
          if (isRetrievedFromFirestore) {
            if (trial.isInTrial || hasActiveSubscription) {
              this._dialog.close();
            }
          }
        }
      );
  }

  updateSubscription() {
    this._store
      .pipe(select(deviceIsElectron), take(1))
      .subscribe((isElectron: boolean) => {
        if (isElectron) {
          if (this.environment.production) {
            this.window.open('https://spoutstudio.io/subscribe?s=2');
          } else {
            this.window.open('https://dev.spoutstudio.io/subscribe?s=2');
          }
        } else {
          this._router.navigate(['subscribe'], {
            queryParams: {
              s: 2
            }
          });
        }

        // this.router.navigate(['subscribe'], {
        //   queryParams: {
        //     s: 2
        //   }
        // });
      });
  }

  logout() {
    this._store.dispatch(ga_Logout());
    this._store.dispatch(logout());
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
