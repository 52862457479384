// This is a copy of lib
// https://www.w3.org/TR/mediacapture-streams/#media-track-constraints
// https://github.com/microsoft/TypeScript/blob/master/lib/lib.dom.d.ts#L770

export interface SptMediaTrackConstraintSet {
  // GoogleMediaTrackConstraints
  autoGainControl?: boolean;
  autoGainControl2?: boolean;
  echoCancellation?: boolean;
  noiseSuppression?: boolean;
  highpassFilter?: boolean;
  echoCancellation2?: boolean;
  noiseSuppression2?: boolean;
  DAEchoCancellation?: boolean;
  noiseReduction?: boolean;
  audioMirroring?: boolean;

  arrayGeometry?: boolean;
  beamforming?: boolean;
  typingNoiseDetection?: boolean;

  // Extra
  aspectRatio?: number;
  channelCount?: number;
  deviceId?: string;
  facingMode?: string;
  frameRate?: number;
  groupId?: string;
  height?: number;
  // latency?: number;
  resizeMode?: string;
  sampleRate?: number;
  sampleSize?: number;

  width?: number;

  microphoneGain: number;
  recordInputCompressorThreshold: number;
  recordInputCompressorKnee: number;
  recordInputCompressorRatio: number;
  recordInputCompressorAttack: number;
  recordInputCompressorRelease: number;
  exportMergeCompressorThreshold: number;
  exportMergeCompressorKnee: number;
  exportMergeCompressorRatio: number;
  exportMergeCompressorAttack: number;
  exportMergeCompressorRelease: number;
}

export interface GoogleMediaTrackConstraints {
  googAutoGainControl: boolean;
  googAutoGainControl2: boolean;
  googEchoCancellation: boolean;
  googEchoCancellation2: boolean;
  googNoiseSuppression: boolean;
  googNoiseSuppression2: boolean;
  googNoiseReduction: boolean;
  googDAEchoCancellation: boolean;
  googAudioMirroring: boolean;

  // NOTE: CAUSES MONO RECORDING
  // highpassFilter: 'googHighpassFilter',
  // googHighpassFilter: boolean;

  // NOTE: NOT SUPPORTED
  // typingNoiseDetection: 'googTypingNoiseDetection',
  // beamforming: 'googBeamforming',
  // arrayGeometry: 'googArrayGeometry',
}

export interface SptMediaTrackConstraints {
  autoGainControl: boolean;
  autoGainControl2: boolean;
  echoCancellation: boolean;
  noiseSuppression: boolean;
  highpassFilter: boolean;
  echoCancellation2: boolean;
  noiseSuppression2: boolean;
  DAEchoCancellation: boolean;
  noiseReduction: boolean;
  audioMirroring: boolean;
}

export const initialSptMediaTrackConstraints: SptMediaTrackConstraints = {
  autoGainControl: false,
  autoGainControl2: false,
  echoCancellation: false,
  noiseSuppression: false,
  highpassFilter: false,
  echoCancellation2: false,
  noiseSuppression2: false,
  DAEchoCancellation: false,
  noiseReduction: false,
  audioMirroring: false
};

export const initialGoogleMediaTrackConstraints: GoogleMediaTrackConstraints = {
  googAutoGainControl: false,
  googAutoGainControl2: false,
  googEchoCancellation: false,
  googEchoCancellation2: false,
  googNoiseSuppression: false,
  googNoiseSuppression2: false,
  googNoiseReduction: false,
  googDAEchoCancellation: false,
  googAudioMirroring: false

  // NOTE: CAUSES MONO RECORDING
  // googHighpassFilter: 'highpassFilter',

  // NOTE: NOT SUPPORTED
  // googTypingNoiseDetection: 'typingNoiseDetection',
  // googBeamforming: 'beamforming',
  // googArrayGeometry: 'arrayGeometry',
};
