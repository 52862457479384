// TODO create mixer subroutes:
export const MIXER_ROUTES = {
  CONSOLE: 'console',
  ADD_PROJECT: 'add-project',
  EDIT_PROJECT: 'edit-project',
  ADD_SONG: 'add-song',
  EDIT_SONG: 'edit-song',
  ADD_TRACK: 'add-track',
  EDIT_TRACK: 'edit-track',
  ADD_MIX: 'add-mix',
  EDIT_MIX: 'edit-mix',
  COPY_MIX: 'copy-mix',
  LATENCY_TEST: 'latency-test',
  SELECT_PROJECT_SONG: 'select-project-song',
  FORMS: 'forms',
  SELECT_OPTIONS: 'select-options'
};

export const APP_ROUTES = {
  ACCOUNT: 'account',
  ACCOUNT_OVERVIEW: 'account/overview',
  // ONBOARDING: 'onboarding',
  // ONBOARDING_CORDOVA: 'onboarding/onboarding-cordova',
  // ONBAORDING_DESKTOP: 'onboarding/onboarding-desktop',
  // ONBOARDING_BROWSER: 'onboarding/onboarding-browser',
  LOGIN_ELECTRON: 'login-electron',
  LOGIN_FIREBASE: 'login-browser',
  LOGIN_SUCCESS: 'login-success',
  LOGOUT: 'logout',

  MIXER: 'mixer',
  MIXER_PAGES: 'mixer-pages',
  MIXER_CONSOLE: `mixer/mixer-pages/${MIXER_ROUTES.CONSOLE}`,
  MIXER_SELECT_PROJECT_SONG: `${MIXER_ROUTES.SELECT_PROJECT_SONG}`,
  MIXER_ADD_SONG: `${MIXER_ROUTES.ADD_SONG}`,
  MIXER_ADD_PROJECT: `${MIXER_ROUTES.ADD_PROJECT}`,
  MIXER_EDIT_PROJECT: `${MIXER_ROUTES.EDIT_PROJECT}`,
  MIXER_EDIT_SONG: `${MIXER_ROUTES.EDIT_SONG}`,
  MIXER_ADD_TRACK: `${MIXER_ROUTES.ADD_TRACK}`,
  MIXER_EDIT_TRACK: `${MIXER_ROUTES.EDIT_TRACK}`,
  MIXER_ADD_MIX: `${MIXER_ROUTES.ADD_MIX}`,
  MIXER_COPY_MIX: `${MIXER_ROUTES.COPY_MIX}`,
  MIXER_EDIT_MIX: `${MIXER_ROUTES.EDIT_MIX}`,
  MIXER_LATENCY_TEST: `${MIXER_ROUTES.LATENCY_TEST}`,
  MIXER_SELECT_OPTIONS: `${MIXER_ROUTES.SELECT_OPTIONS}`,

  // MIXER_SELECT_PROJECT_SONG: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.SELECT_PROJECT_SONG}`,
  // MIXER_ADD_SONG: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.ADD_SONG}`,
  // MIXER_ADD_PROJECT: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.NEW_PROJECT}`,
  // MIXER_EDIT_PROJECT: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.EDIT_PROJECT}`,
  // MIXER_EDIT_SONG: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.EDIT_SONG}`,
  // MIXER_ADD_TRACK: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.ADD_TRACK}`,
  // MIXER_EDIT_TRACK: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.EDIT_TRACK}`,
  // MIXER_WIZARD: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.WIZARD}`,
  // MIXER_MIX: `${MIXER_ROUTES.FORMS}/${MIXER_ROUTES.MIX}`,

  // MIXER_ALBUMS: 'mixer/albums',
  // MIXER_PROJECTS: 'mixer/projects',
  // MIXER_TRACKS: 'mixer/tracks',
  // MIXER_COLLABORATE: 'mixer/collaborate',
  // MIXER_SUPPORT: 'mixer/support',
  // MIXER_CHAT: 'mixer/chat',

  SETTINGS: 'settings',
  SETTINGS_HOME: 'settings/settings-home',
  SUBSCRIBE: 'subscribe',

  THEME: 'theme'
};
