import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'spt-cancel-subscription-error',
  templateUrl: './cancel-subscription-error.component.html',
  styleUrls: ['./cancel-subscription-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelSubscriptionErrorComponent {
  constructor(
    public dialogRef: MatDialogRef<CancelSubscriptionErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
