import {Action, createReducer, on} from '@ngrx/store';
import {
  DEVICE_DETECTION_FEATURE_KEY,
  deviceDetectionInitialState,
  DeviceDetectionState
} from '@spout/global-web/models';
import {
  deviceIsLandscape,
  deviceIsPortrait,
  deviceIsSmall,
  deviceLoadDesktopInformation,
  deviceLoaded,
  loadElectronVersion
} from './device.actions';

/**
 * Interface for the 'Device' data used in
 *  - DeviceState, and
 *  - deviceReducer
 *
 *  Note: replace if already defined in another module
 */

export interface DevicePartialState {
  readonly [DEVICE_DETECTION_FEATURE_KEY]: DeviceDetectionState;
}

const reducer = createReducer(
  deviceDetectionInitialState,
  on(deviceLoaded, (state: DeviceDetectionState, action) => {
    return <DeviceDetectionState>{
      ...state,
      ...action.payload
    };
  }),
  on(deviceIsPortrait, (state: DeviceDetectionState, action) => {
    return <DeviceDetectionState>{
      ...state,
      isPortrait: true,
      isLandscape: false
    };
  }),
  on(deviceIsLandscape, (state: DeviceDetectionState, action) => {
    return <DeviceDetectionState>{
      ...state,
      isPortrait: false,
      isLandscape: true
    };
  }),
  on(deviceIsSmall, (state: DeviceDetectionState, action) => {
    return <DeviceDetectionState>{
      ...state,
      isSmall: action.isSmall
    };
  }),

  on(loadElectronVersion, (state: DeviceDetectionState, action) => {
    return {
      ...state,
      electronVersion: action.electronVersion
    };
  }),

  on(deviceLoadDesktopInformation, (state: DeviceDetectionState, action) => {
    const _state: DeviceDetectionState = {
      ...state,
      // electronVersion: action.electronVersion,
      useragent: action.useragent
    };

    if (action.desktopInformation) {
      _state.desktopInformation = {
        ...action.desktopInformation
      };
    }

    return _state;
  })
);

export function deviceDetectionReducer(
  state: DeviceDetectionState | undefined,
  action: Action
) {
  return reducer(state, action);
}
