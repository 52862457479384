import {Injectable, NgZone} from '@angular/core';
import {
  IAPError,
  IAPProduct,
  InAppPurchase2
} from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import {Platform} from '@angular/cdk/platform';
import {select, Store} from '@ngrx/store';
import {selectUid} from '../+account/account.selectors';
import {
  appStoreCheckoutInProgress,
  appStorePurchaseError,
  upsertAppstoreInAppPurchase,
  upsertAppStoreProducts
} from './+appstore';
import {AbstractConnectionService} from '../firebase/abstract-connection.service';
import {CustomFirestoreService} from '../firebase';
import {FirestoreWebSocketConnectorService} from '../firebase/firestore-web-socket-connector.service';
import {
  ANDROID_PRODUCT_ID_MENTOR_SUBSCRIPTION,
  ANDROID_PRODUCT_ID_STUDENT_SUBSCRIPTION,
  ANDROID_PRODUCT_ID_TEACHER_SUBSCRIPTION,
  createStudentSubscription,
  FoveaInAppPurchase,
  getActiveInAppPurchase,
  IN_APP_PLATFORM,
  IOS_PRODUCT_ID_MENTOR_SUBSCRIPTION,
  IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION,
  IOS_PRODUCT_ID_TEACHER_SUBSCRIPTION
} from './in-app.model';
import {Observable, Observer, ReplaySubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {AccountState} from '@spout/global-web/models';
import firebase from 'firebase/compat';
import HttpsCallableResult = firebase.functions.HttpsCallableResult;

const IN_APP_PURCHASE_CONNECTOR_KEY = 'IN_APP_PURCHASE_CONNECTOR_KEY';

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService extends AbstractConnectionService {
  loggedIn$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private _customFirestoreService: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    private _platform: Platform,
    private _ngrxStore: Store,
    private store: InAppPurchase2,
    private _zone: NgZone
  ) {
    super(IN_APP_PURCHASE_CONNECTOR_KEY, _connector, _ngrxStore);
  }

  init() {
    const that = this;
    // console.log('PLATFORM', this._platform);

    this._ngrxStore
      .pipe(select(selectUid), distinctUntilChanged())
      .subscribe((uid: string | null) => {
        if (uid) {
          if (that._platform.IOS) {
            that._inAppPurchase(
              [
                IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION,
                IOS_PRODUCT_ID_MENTOR_SUBSCRIPTION,
                IOS_PRODUCT_ID_TEACHER_SUBSCRIPTION
              ],
              uid
            );
          } else if (that._platform.ANDROID) {
            that._inAppPurchase(
              [
                ANDROID_PRODUCT_ID_STUDENT_SUBSCRIPTION,
                ANDROID_PRODUCT_ID_MENTOR_SUBSCRIPTION,
                ANDROID_PRODUCT_ID_TEACHER_SUBSCRIPTION
              ],
              uid
            );
          } else {
            const verifyInAppPurchaseFn =
              this._customFirestoreService.httpsCallable('verifyInAppPurchase');

            verifyInAppPurchaseFn(uid).then(
              (response: HttpsCallableResult) => {
                const purchase: FoveaInAppPurchase | undefined =
                  getActiveInAppPurchase(response);

                if (purchase) {
                  const [device, subscriptionID] =
                    purchase.productId.split(':');

                  console.log(device);
                  console.log(subscriptionID);
                  console.log(purchase);

                  that._zone.run(() => {
                    that._ngrxStore.dispatch(
                      upsertAppstoreInAppPurchase({
                        appstoreInAppPurchase: createStudentSubscription(
                          subscriptionID,
                          this._platform.IOS
                            ? IN_APP_PLATFORM.IOS
                            : IN_APP_PLATFORM.ANDROID,
                          'Month'
                        )
                      })
                    );
                  });
                }
              },
              (e: any) => {
                console.log(e);
              }
            );

            // this._validate.validateSubscription(uid).subscribe((r: any) => {
            //   console.log(r);
            // });
          }
        } else {
          console.log('NOT LOGGED ON');
        }
      });
  }

  onConnect(user: AccountState) {
    this._connector.keyIsConnected(IN_APP_PURCHASE_CONNECTOR_KEY);
    if (this.loggedIn$) {
      this.loggedIn$.next(true);
    }
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query before calling this
    this._connector.keyIsDisconnected(IN_APP_PURCHASE_CONNECTOR_KEY);
    this.loggedIn$.next(false);
  }

  private _inAppPurchase(PRODUCT_IDS: string[], uid: string): void {
    const that = this;
    this.store.verbosity = this.store.DEBUG;
    this.store.validator =
      'https://validator.fovea.cc/v1/validate?appName=us.quizkit.create&apiKey=e8cd9a24-c930-400e-afff-91fef05d3787';

    this.store.applicationUsername = uid;

    this._registerProducts(PRODUCT_IDS);

    const registeredHandler = (product: IAPProduct) => {
      console.log('REGISTERED' + JSON.stringify(product));
    };

    const ownedHandler = (p: IAPProduct) => {
      console.log('OWNED' + JSON.stringify(p));

      that._zone.run(() => {
        that._ngrxStore.dispatch(
          upsertAppstoreInAppPurchase({
            appstoreInAppPurchase: createStudentSubscription(
              p.id,
              this._platform.IOS
                ? IN_APP_PLATFORM.IOS
                : IN_APP_PLATFORM.ANDROID,
              p.billingPeriodUnit,
              p.title,
              p.price
            )
          })
        );
      });
    };

    const updatedHandler = (product: IAPProduct) => {
      // console.log('Updated' + JSON.stringify(storageProduct));

      const _p = JSON.parse(JSON.stringify(product));

      if (_p && _p.price && _p.price.length > 0) {
        // _p = mapNameTitleForAndroid(_p);

        // console.log(_p);

        that._zone.run(() => {
          that._ngrxStore.dispatch(
            upsertAppStoreProducts({
              products: [_p]
            })
          );
        });
      }
    };

    const cancelledHandler = (product: IAPProduct) => {
      console.error('PURCHASE_CANCELLED');
      that._zone.run(() => {
        that._ngrxStore.dispatch(
          appStoreCheckoutInProgress({
            checkoutInProgress: false
          })
        );
      });
    };

    // const approvedHandler = (storageProduct: IAPProduct): any => {
    //   console.log('APPROVED' + JSON.stringify(storageProduct));
    //   return storageProduct.verify();
    // };

    // const verifiedHandler = (p: IAPProduct) => {
    //   return p.finish();
    // };

    const errorHandler = (err: IAPError) => {
      console.error('STORE_ERROR' + JSON.stringify(err));
      that._zone.run(() => {
        that._ngrxStore.dispatch(
          appStoreCheckoutInProgress({
            checkoutInProgress: false
          })
        );

        if (err && err.message && err.message.length) {
          that._ngrxStore.dispatch(
            appStorePurchaseError({
              purchaseError: err.message
            })
          );
        }
      });
    };

    const readyHandler = () => {
      // console.log('Store is ready');
      // console.log('Products: ' + JSON.stringify(that.store.products));
      // console.log(
      //   'IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION: ' + JSON.stringify(that.store.get(IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION))
      // );

      console.log('STORE_READY', that.store.products);

      if (that.store.products && that.store.products.length) {
        let products = JSON.parse(JSON.stringify(that.store.products));
        products =
          products && products.length
            ? products.filter(
                (p: IAPProduct) => p && p.price && p.price.length > 0
              )
            : [];

        if (products && products.length > 0) {
          // products = products.map(mapNameTitleForAndroid);

          console.log(products);

          that._zone.run(() => {
            that._ngrxStore.dispatch(
              upsertAppStoreProducts({
                products
              })
            );
          });
        }
      }
    };

    PRODUCT_IDS.forEach((PRODUCT_ID: string) => {
      this.loggedIn$
        .pipe(distinctUntilChanged())
        .subscribe((isLoggedIn: boolean) => {
          if (
            isLoggedIn &&
            that.store &&
            that.store.when(PRODUCT_ID) &&
            that.store.when(PRODUCT_ID).registered
          ) {
            // Register event handers
            // Register event handlers for the specific storageProduct
            that.store.when(PRODUCT_ID).registered(registeredHandler);

            // Specific query for one ID
            that.store.when(PRODUCT_ID).owned(ownedHandler);

            // Updated
            that.store.when(PRODUCT_ID).updated(updatedHandler);

            // User closed the native purchase dialog
            that.store.when(PRODUCT_ID).cancelled(cancelledHandler);

            // General query to all products
            that.store
              .when(PRODUCT_ID)
              .approved((p: IAPProduct) => {
                return p.verify();
              })
              .verified((p: IAPProduct) => {
                return p.finish();
              });

            // Track all store errors
            that.store.error(errorHandler);

            // Run some code only when the store is ready to be used
            that.store.ready(readyHandler);

            // Refresh the status of in-app products
            that.store.refresh();

            /**
             * NOTE; FOR TESTING ONLY
             */
            // that._zone.run(() => {
            //   that._ngrxStore.dispatch(
            //     upsertAppstoreInAppPurchase({
            //       appstoreInAppPurchase: createStudentSubscription(
            //         'Student',
            //         IN_APP_PLATFORM.IOS,
            //         '$4.99',
            //         'Monthly'
            //       )
            //     })
            //   );
            // });
          } else {
            // unregister callbacks
            that.store.off(registeredHandler);
            that.store.off(ownedHandler);
            that.store.off(updatedHandler);
            that.store.off(cancelledHandler);
            // that.store.off(approvedHandler);
            // that.store.off(verifiedHandler);
            that.store.off(errorHandler);
            that.store.off(readyHandler);
          }
        });
    });
  }

  private _registerProducts(PRODUCT_IDS: string[]) {
    const that = this;

    const products = PRODUCT_IDS.map((productId: string) => ({
      id: productId,
      type: that.store.PAID_SUBSCRIPTION
    }));

    this.store.register(products);
  }

  order(product: IAPProduct): Observable<any | null> {
    console.log('ORDERING PRODUCT', product.id);

    const that = this;
    return new Observable((observer: Observer<any | null>) => {
      this.store.order(product.id).then(
        (p: IAPProduct) => {
          that._zone.run(() => {
            that._ngrxStore.dispatch(
              appStoreCheckoutInProgress({
                checkoutInProgress: true
              })
            );
          });
          observer.next(null);
          observer.complete();
        },
        (e: any) => {
          that._zone.run(() => {
            that._ngrxStore.dispatch(
              appStoreCheckoutInProgress({
                checkoutInProgress: false
              })
            );

            that._ngrxStore.dispatch(
              appStorePurchaseError({
                purchaseError: e
              })
            );
          });
          observer.next(e);
        }
      );
    });
  }
}
