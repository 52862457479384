import {getIn} from '@uiux/fn';

export function includesBy(item: any, arr: any[], key: string): boolean {
  const compareValue = getIn(item, key);
  return arr.reduce((isIncluded: boolean, arrItem: any) => {
    const arrItemCompareValue = getIn(arrItem, key);
    if (!isIncluded) {
      isIncluded = compareValue === arrItemCompareValue;
    }
    return isIncluded;
  }, false);
}
