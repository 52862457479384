<div class="help-header mat-h2">{{ helpTile }}</div>
<div class="help-content">
  <perfect-scrollbar [scrollIndicators]="false">
    <ng-container *ngFor="let cmp of refs">
      <button
        *ngIf="cmp.id !== helpModel.TOC"
        mat-button
        (click)="loadComponent(cmp.id)">
        <span class="spt-button-content">
          <span>{{ cmp.title }}</span>
          <mat-icon>chevron_right</mat-icon>
        </span>
      </button>
      <mat-divider></mat-divider>
    </ng-container>
  </perfect-scrollbar>
</div>
