import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  NotificationModel,
  notificationsesFeatureKey,
  NotificationsState,
  NotificationTypes
} from '@spout/global-web/models';

// Lookup the 'Notifications' feature state managed by NgRx
const getNotificationsState = createFeatureSelector<NotificationsState>(
  notificationsesFeatureKey
);

export const selectAllNotifications = createSelector(
  getNotificationsState,
  (state: NotificationsState) => Object.values(state.entities)
);

export const selectNotificationCount = createSelector(
  getNotificationsState,
  (state: NotificationsState) => Object.values(state.entities).length
);

export const selectElectronUpdateNotification = createSelector(
  getNotificationsState,
  (state: NotificationsState) => {
    return (<NotificationModel[]>Object.values(state.entities)).reduce(
      (found: boolean, n: NotificationModel) => {
        if (!found) {
          return n.type === NotificationTypes.UpdateElectron;
        }

        return found;
      },
      false
    );
  }
);
