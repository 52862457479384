import {TRANSPORT_FEATURE_KEY} from '../studio-app-keys';

export interface TransportPartialState {
  readonly [TRANSPORT_FEATURE_KEY]: TransportState;
}

export const transportInitialState: TransportState = {
  isPlayingMix: false,
  isRecording: false,
  isSavingRecordedFileTrackId: null,
  playMixOnRecord: true,
  playMetronomeOnRecord: false
  // isPlayingMetronome: false,
  // applyMixSelectedToPlay: true,
};

export interface PlayOptions {
  isPlayingMix: boolean;
  isRecording: boolean;
}

export interface ProcessOptions {
  isSavingRecordedFileTrackId: string | null;
}

export interface RecordOptions {
  playMixOnRecord: boolean;
  playMetronomeOnRecord: boolean;
}

export type TransportState = PlayOptions & ProcessOptions & RecordOptions;
