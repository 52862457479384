import {FeaturePermission, getPermissionState} from '@spout/global-web/models';
import {SptPermissionState} from './permission.model';

export function updateUserPermissions(
  state: SptPermissionState
): SptPermissionState {
  return {
    ...state,
    permissions: getPermissionState(
      state.accountRole,
      <FeaturePermission[]>Object.values(state.entities),
      state.permissions
    )
  };
}
