import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {getAllChatsByTrackId} from './chat.selectors';
import {firestoreMessageByIdPath} from '@spout/global-web/fns';
import {
  AccountState,
  ChatEntity,
  ConversationMessage,
  createAuthorMusician
} from '@spout/global-web/models';
import {DocumentReference, WriteBatch} from '@firebase/firestore';
import {from} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {selectAccountState} from '../+account/account.selectors';
import {deleteTrackEffect} from '../+tracks/track.actions';
import {CustomFirestoreService} from '../firebase';
import {
  deleteConversation,
  deleteMessage,
  sendMessage,
  updateConversation
} from './chat.actions';
import {ChatService} from './chat.service';

@Injectable({
  providedIn: 'root'
})
export class ChatEffects {
  sendConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendMessage),
        switchMap(({message}) => {
          return this.store.pipe(
            select(selectAccountState),
            take(1),
            switchMap((account: AccountState) => {
              const _message: any = JSON.parse(JSON.stringify(message));

              _message.createdBy = createAuthorMusician(account);
              return this.sptFirestore.upsertDoc(
                firestoreMessageByIdPath(_message),
                _message
              );
            })
          );
        })
      ),
    {dispatch: false}
  );

  updateConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateConversation),
        switchMap(({conversation}) => {
          return this.sptFirestore.upsertDoc(
            firestoreMessageByIdPath(conversation),
            conversation
          );
        })
      ),
    {dispatch: false}
  );

  deleteConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteConversation),
        switchMap(({chats}) => {
          const batch: WriteBatch = this.sptFirestore.writeBatch();

          chats.forEach((_chat: ChatEntity) => {
            const doc: DocumentReference = this.sptFirestore
              // .collection(fileCollection(file))
              .docRef(firestoreMessageByIdPath(_chat));

            batch.delete(doc);
          });

          return from(batch.commit());
        })
      ),
    {dispatch: false}
  );

  deleteMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteMessage),
        switchMap(({message}: {message: ConversationMessage}) => {
          return (
            this.sptFirestore
              // .collection(fileCollection(file))
              .deleteDoc(firestoreMessageByIdPath(message))
          );
        })
      ),
    {dispatch: false}
  );

  deleteTrack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTrackEffect),
        switchMap(action => {
          return this.store.pipe(
            select(getAllChatsByTrackId, {
              trackId: action.trackModel.trackEntity.id
            }),
            take<ChatEntity[]>(1),
            switchMap((chats: ChatEntity[]) => {
              return this._chatService.deleteChats(chats);
            })
          );
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    public sptFirestore: CustomFirestoreService,
    public store: Store,
    private _chatService: ChatService
  ) {}
}
