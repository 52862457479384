import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

export enum ALERT_MESSAGE_TYPE {
  WARN = 'warn',
  SUCCESS = 'success'
}

export enum ALERT_MESSAGE_ID {
  PASSWORD_AUTH = 'password_auth',
  CREATE_PASSWORD = 'create_password',
  EMAIL_RESET_PASSWORD = 'email_reset_password',
  RESET_EMAIL_SENT = 'reset_email_sent'
}

export interface AlertMessage {
  id: ALERT_MESSAGE_ID;
  type: ALERT_MESSAGE_TYPE;
  message: string;
}

export interface AlertMessageInput {
  id: ALERT_MESSAGE_ID;
  message: string;
}

export interface AlertMessageState {
  [key: string]: AlertMessage;
}

@Injectable()
export class AlertMessageStoreService extends ComponentStore<AlertMessageState> {
  constructor() {
    super({});
  }

  readonly addSuccessMessage = this.updater(
    (state, messageInput: AlertMessageInput) => ({
      ...state,
      [messageInput.id]: {
        id: messageInput.id,
        message: messageInput.message,
        type: ALERT_MESSAGE_TYPE.SUCCESS
      }
    })
  );

  readonly addWarnMessage = this.updater(
    (state, messageInput: AlertMessageInput) => ({
      ...state,
      [messageInput.id]: {
        id: messageInput.id,
        message: messageInput.message,
        type: ALERT_MESSAGE_TYPE.WARN
      }
    })
  );

  readonly deleteMessage = this.updater(
    (state: AlertMessageState, key: string) => {
      delete state[key];
      return {
        ...state
      };
    }
  );

  readonly selectAllMessages$: Observable<AlertMessage[]> = this.select(state =>
    Object.values(state)
  );

  readonly hasMessages$: Observable<boolean> = this.selectAllMessages$.pipe(
    map((alertMessages: AlertMessage[]) => alertMessages.length > 0),
    distinctUntilChanged()
  );

  readonly warnMessages$: Observable<AlertMessage[]> =
    this.selectAllMessages$.pipe(
      map((alertMessages: AlertMessage[]) =>
        alertMessages.filter(
          (message: AlertMessage) => message.type === ALERT_MESSAGE_TYPE.WARN
        )
      ),
      debounceTime(100)
    );

  readonly successMessages$: Observable<AlertMessage[]> =
    this.selectAllMessages$.pipe(
      map((alertMessages: AlertMessage[]) =>
        alertMessages.filter(
          (message: AlertMessage) => message.type === ALERT_MESSAGE_TYPE.SUCCESS
        )
      ),
      debounceTime(100)
    );
}
