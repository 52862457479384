<p>
  If setting gain does not fix distortion, you may need to set your input
  levels, please open your DAW, microphone, controller software or system
  microphone settings and manually adjust your input levels as needed.
</p>
<p>
  Setting Gain to 0 ( zero ) does not affect output. Set to 0 to "turn off"
  gain.
</p>
<p>
  You may adjust gain by dragging the control or using your up or down arrow
  keys after clicking the control.
</p>
