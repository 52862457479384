import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {SavedLatency, SptSystemInformation} from '@spout/global-any/models';

export interface LatencyEntity extends SavedLatency {
  id: string; // unique uuid
  desktopInformation: SptSystemInformation;
}

export const latenciesFeatureKey = 'latencies';

export type LatencyState = EntityState<LatencyEntity>;

export const latencyAdapter: EntityAdapter<LatencyEntity> =
  createEntityAdapter<LatencyEntity>();

export const initialLatencyState: LatencyState = latencyAdapter.getInitialState(
  {
    // additional entity state properties
  }
);
