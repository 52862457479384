import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {logout} from '../../+account/auth.actions';
import * as SubscriptionActions from './subscription.actions';
import {Subscription} from './subscription.model';

export const subscriptionFeatureKey = 'stripe_subscription';

export interface SubscriptionState extends EntityState<Subscription> {
  // additional entities state properties
  isRetrievedFromFirestore: boolean;
  // overridden by firestore value
  trialDays: number;
  stripeCoupon: string | null;
  stripeCouponMessage: string | null;
}

export interface PartialSubscriptionState {
  readonly [subscriptionFeatureKey]: SubscriptionState;
}

export const subscriptionAdapter: EntityAdapter<Subscription> =
  createEntityAdapter<Subscription>();

export const initialSubscriptionState: SubscriptionState =
  subscriptionAdapter.getInitialState({
    // additional entity state properties
    isRetrievedFromFirestore: false,
    // overridden by firestore value
    trialDays: 0,
    stripeCoupon: null,
    stripeCouponMessage: null
  });

export const reducer = createReducer(
  initialSubscriptionState,
  on(SubscriptionActions.addSubscription, (state, action) =>
    subscriptionAdapter.addOne(action.subscription, state)
  ),
  on(SubscriptionActions.setSubscription, (state, action) =>
    subscriptionAdapter.setOne(action.subscription, state)
  ),
  on(SubscriptionActions.addSubscriptions, (state, action) =>
    subscriptionAdapter.addMany(action.subscriptions, state)
  ),
  on(SubscriptionActions.updateSubscription, (state, action) =>
    subscriptionAdapter.updateOne(action.subscription, state)
  ),
  on(SubscriptionActions.updateSubscriptions, (state, action) =>
    subscriptionAdapter.updateMany(action.subscriptions, state)
  ),
  on(SubscriptionActions.upsertSubscription, (state, action) =>
    subscriptionAdapter.upsertOne(action.subscription, state)
  ),
  on(SubscriptionActions.upsertSubscriptions, (state, action) =>
    subscriptionAdapter.upsertMany(action.subscriptions, state)
  ),
  on(SubscriptionActions.mapSubscription, (state, {entityMap}) => {
    return subscriptionAdapter.mapOne(entityMap, state);
  }),
  on(SubscriptionActions.mapSubscriptions, (state, {entityMap}) => {
    return subscriptionAdapter.map(entityMap, state);
  }),
  on(SubscriptionActions.deleteSubscription, (state, action) =>
    subscriptionAdapter.removeOne(action.id, state)
  ),
  on(SubscriptionActions.deleteSubscriptions, (state, action) =>
    subscriptionAdapter.removeMany(action.ids, state)
  ),
  on(SubscriptionActions.loadSubscriptions, (state, action) =>
    subscriptionAdapter.setAll(action.subscriptions, state)
  ),
  on(SubscriptionActions.setSubscriptions, (state, action) =>
    subscriptionAdapter.setMany(action.subscriptions, state)
  ),
  on(SubscriptionActions.clearSubscriptions, state =>
    subscriptionAdapter.removeAll(state)
  ),
  on(logout, state => ({
    ...initialSubscriptionState,
    ...subscriptionAdapter.removeAll(state)
  })),
  // on(SubscriptionActions.selectSubscriptionID, (state, action) => {
  //   return {
  //     ...state,
  //     selectedSubscriptionID: action.id
  //   };
  // }),
  on(SubscriptionActions.updateTrial, (state: SubscriptionState, action) => {
    return <SubscriptionState>{
      ...state,
      trialDays: action.trial.days,
      stripeCoupon: action.trial.stripeCoupon,
      stripeCouponMessage: action.trial.stripeCouponMessage
    };
  }),
  // on(setRemoteConfig, (state, action) => {
  //   return {
  //     ...state,
  //     trialDays: <number>action.remoteConfig.value
  //   };
  // }),
  on(
    SubscriptionActions.subscriptionRetrievedFromFirestore,
    (state: SubscriptionState, action) => {
      return <SubscriptionState>{
        ...state,
        isRetrievedFromFirestore: true
      };
    }
  )
);
