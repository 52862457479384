<ng-container *ngrxLet="{
  isWideScreen:deviceNotIsSmallScreen$,
    isLoggedIn: isLoggedIn$,
    hasSubscription: hasSubscription$,
    showReminder: showReminder$,
    currentRoute: currentRoute$,
    currentSubscriptions: currentSubscriptions$
  } as vm">
  
  <mat-toolbar>
    <div class="page-layout--wide">
      <button (click)="navigate('home')" mat-button tooltip="home"
        [options]="{placement: 'bottom', tooltipClass: 'g-tooltip'}" class="logo push-right-4">
        <mat-icon svgIcon="icon:spoutLogoWithText"></mat-icon>
      </button>
      <div class="dev-environment push-left-10 push-top-5 warn-bold" *ngIf="!environment.production">
        DEV
      </div>
      <div class="flex-spacer"></div>
      <ng-container *ngIf="vm.isWideScreen">
        <nav mat-tab-nav-bar color="accent">
          <a mat-tab-link (click)="navigate('subscribe')" [active]="activeLink === '/subscribe'">
            Subscribe
          </a>
          <a mat-tab-link (click)="navigate('contact')" [active]="activeLink === '/contact'">
            Contact
          </a>
          <ng-container *ngIf="vm.isLoggedIn == true">
            <a mat-tab-link (click)="navigate('downloads')" [active]="activeLink === '/downloads'">
              Downloads
            </a>
          </ng-container>

          <ng-container *ngIf="vm.isLoggedIn == false">
            <a mat-tab-link (click)="navigate('login')" [active]="
                  activeLink === '/signup-with-promo' || activeLink === '/login'
                ">
              Login
            </a>
          </ng-container>
          <!-- <ng-container *ngIf="vm.showReminder == true">
              <button
                id="sign-up"
                class="sign-up-button"
                mat-tab-link
                (click)="onClickPromo(vm.isLoggedIn)">
                Click here for<br />3 Months Free
              </button>
            </ng-container> -->
          <ng-container *ngIf="vm.isLoggedIn">
            <a mat-tab-link [active]="activeLink === '/account/overview'" [matMenuTriggerFor]="accountMenu">
              <div class="user-initial">
                <div class="user-initial--letter">
                  {{ userInitial$ | ngrxPush }}
                </div>
              </div>
              <span class="text">{{ stageName$ | ngrxPush }}</span>
            </a>
          </ng-container>
        </nav>

        <mat-menu #accountMenu="matMenu">
          <button mat-menu-item (click)="navigate('account/overview')">
            <mat-icon>lock</mat-icon>
            <span>Account</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </ng-container>
      <ng-container *ngIf="!vm.isWideScreen">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="navigate('home')">
            <mat-icon>home</mat-icon>
            <span>Home</span>
          </button>

          <ng-container *ngrxLet="isLoggedIn$; let isLoggedIn">
            <button mat-menu-item (click)="navigate('subscribe')">
              <mat-icon>subscriptions</mat-icon>
              <span>Subscribe</span>
            </button>

            <button mat-menu-item (click)="navigate('contact')">
              <mat-icon>email</mat-icon>
              <span>Contact</span>
            </button>


            <button mat-menu-item (click)="navigate('downloads')">
              <mat-icon>download</mat-icon>
              <span>Downloads</span>
            </button>


            <ng-container *ngIf="!isLoggedIn">
              <button mat-menu-item (click)="navigate('login')">
                <mat-icon>subscriptions</mat-icon>
                <span>Login</span>
              </button>
            </ng-container>

            <!-- <ng-container *ngIf="!isLoggedIn">
                <button
                  mat-menu-item
                  (click)="navigate('signup-with-promo')"
                  class="sign-up-button">
                  <mat-icon>subscriptions</mat-icon>
                  <span>3 Months Free</span>
                </button>
              </ng-container> -->

            <ng-container *ngIf="isLoggedIn">
              <button mat-menu-item *ngIf="isLoggedIn$ | ngrxPush" (click)="navigate('account/overview')">
                <mat-icon>lock</mat-icon>
                <span>Account</span>
              </button>
              <button mat-menu-item *ngIf="isLoggedIn$ | ngrxPush" (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
              </button>
            </ng-container>
          </ng-container>
        </mat-menu>
      </ng-container>
    </div>
  </mat-toolbar>
  <!-- <ng-container *ngrxLet="{showReminder: showReminder$} as vm"> -->
    <ng-container *ngIf="(vm.currentRoute ==='/home' ||  vm.currentRoute ==='/'||  vm.currentRoute ==='')  &&  vm.isWideScreen == false  && (vm.isLoggedIn === false || (vm.hasSubscription === false && vm.showReminder === false))">
    <div class="spout-homepage-promo-tier-inline">
    
      <h1>FREE FOR 90 DAYS</h1>
      Fully featured for 3 months (90 days) at no cost to you.
      <button (click)="onClickPromo(vm.isLoggedIn)">GET STARTED FOR FREE</button>
    </div>
  </ng-container>
  <div [classList]="
     ((vm.currentRoute ==='/home' ||  vm.currentRoute ==='/'||  vm.currentRoute ==='')  && (vm.isLoggedIn && vm.hasSubscription == true && vm.showReminder == false ))
        ? 'app-content offset-app-content'
        : (vm.currentRoute ==='/home' ||  vm.currentRoute ==='/'||  vm.currentRoute ==='')  &&  vm.isWideScreen == false  && (vm.isLoggedIn === false || (vm.hasSubscription === false && vm.showReminder === false)) 
        ? 'app-content  offset-app-content-for-promo':'app-content'">

    <router-outlet></router-outlet>

  </div>

  <div class="app-footer" *ngIf="vm.isWideScreen == true">
    © 2024 Spout Software Inc. All rights reserved. Proprietary Software.

    <spt-social design="footer"></spt-social>
  </div>
  <div class="app-footer" *ngIf="vm.isWideScreen == false">
    © 2024 Spout Software Inc.

    <spt-social design="footer"></spt-social>
  </div>
  <div class="reminder" *ngIf="(vm.currentRoute ==='/home' ||  vm.currentRoute ==='/'||  vm.currentRoute ==='') && (vm.isLoggedIn && vm.hasSubscription == true && vm.showReminder == false)">

    Your subscription has expired.
    <button (click)="onClickRenew(vm.isLoggedIn)" href="#"> Click here to renew.</button>
  </div>
  <ng-container *ngIf="(vm.currentRoute ==='/home' ||  vm.currentRoute ==='/'||  vm.currentRoute ==='') &&  vm.isWideScreen == true && (vm.isLoggedIn === false || (vm.hasSubscription === false && vm.showReminder === false))">
    <div class="spout-homepage-promo-tier">
    
      <h1>FREE FOR 90 DAYS</h1>
      Fully featured for 3 months (90 days) at no cost to you.
      <button (click)="onClickPromo(vm.isLoggedIn)">GET STARTED FOR FREE</button>
    </div>
  </ng-container>
</ng-container>
<!-- </ng-container> -->