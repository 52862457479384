import {createAction, props} from '@ngrx/store';
import {ChatEntity, HelpModel} from '@spout/global-web/models';

// General
export const toggleRightDrawer = createAction('[MixerRightDrawer] Toggle');

// export const openMixerRightDrawer = createAction('[MixerRightDrawer] Open MixerRightDrawer');

// export const closeMixerRightDrawer = createAction('[MixerRightDrawer] Close MixerRightDrawer');

// Invite

export const toggleInviteRightDrawer = createAction(
  '[MixerRightDrawer] Invite Musician'
);

// Chat

export const toggleChatTrackRightDrawer = createAction(
  '[MixerRightDrawer] Chat Track'
);

export const openChatTrackRightDrawer = createAction(
  '[MixerRightDrawer] Open Chat Track'
);

export const openConversationInTrackRightDrawer = createAction(
  '[MixerRightDrawer] Open Chat Track In Right Drawer',
  props<{conversation: ChatEntity}>()
);

export const openConversationInTrack = createAction(
  '[MixerRightDrawer] Open Chat Track In Track',
  props<{conversation: ChatEntity}>()
);

// Notification

export const openNotificationRightDrawer = createAction(
  '[MixerRightDrawer] Open Notification Right Drawer'
);
export const toggleNotificationRightDrawer = createAction(
  '[MixerRightDrawer] Notification Right Drawer'
);

// Help

export const toggleHelpRightDrawer = createAction('[MixerRightDrawer] Help');

export const openHelpRightDrawer = createAction(
  '[MixerRightDrawer] Open Help Right Drawer'
);

export const openHelpViewRightDrawer = createAction(
  '[MixerRightDrawer] Open Help View',
  props<{
    helpView: HelpModel;
  }>()
);
