import {Injectable} from '@angular/core';
import {SongEntity} from '@spout/global-any/models';
import {firestoreSongByIdPath} from '@spout/global-web/fns';
import {DocumentReference, WriteBatch} from '@firebase/firestore';
import {from, Observable, Observer} from 'rxjs';
import {Exists, CustomFirestoreService} from '../firebase';

@Injectable({
  providedIn: 'root'
})
export class SongService {
  constructor(private sptFirestore: CustomFirestoreService) {}

  createSong(song: Partial<SongEntity>): Observable<SongEntity> {
    return new Observable((observer: Observer<any>) => {
      this.sptFirestore
        .setDocIfNotExist<SongEntity>(firestoreSongByIdPath(song), song)
        .subscribe(
          (r: Exists<SongEntity>) => {
            observer.next(r.data);
          },
          error => {
            observer.error(error);
          }
        );
    });
  }

  updateSong(song: Partial<SongEntity>): Observable<SongEntity> {
    return new Observable((observer: Observer<any>) => {
      this.sptFirestore
        .update<SongEntity>(firestoreSongByIdPath(song), song)
        .subscribe(
          (r: Exists<SongEntity>) => {
            observer.next(r.data);
          },
          error => {
            observer.error(error);
          }
        );
    });
  }

  deleteSong(song: SongEntity): Observable<boolean> {
    return this.sptFirestore.deleteDoc$(firestoreSongByIdPath(song));
  }

  deleteSongs(songs: SongEntity[]) {
    const batch: WriteBatch = this.sptFirestore.writeBatch();

    songs.forEach((song: SongEntity) => {
      const doc: DocumentReference = this.sptFirestore
        // .collection(fileCollection(file))
        .docRef(firestoreSongByIdPath(song));

      batch.delete(doc);
    });

    return from(batch.commit());
  }
}
