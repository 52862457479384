import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpLatencyVsOffsetContentComponent} from './help-latency-vs-offset-content/help-latency-vs-offset-content.component';
import {HelpLatencyVsOffsetComponent} from './help-latency-vs-offset.component';

@NgModule({
  declarations: [
    HelpLatencyVsOffsetComponent,
    HelpLatencyVsOffsetContentComponent
  ],
  exports: [HelpLatencyVsOffsetComponent, HelpLatencyVsOffsetContentComponent],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpLatencyVsOffsetModule {}
