import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity, TrackMix} from '@spout/global-any/models';
import {
  aggregateDocChanges,
  firestoreTrackMixesPath
} from '@spout/global-web/fns';
import {
  AccountState,
  AggregateFirebaseSnapshotChangesEntities
} from '@spout/global-web/models';
import {DocumentChange, onSnapshot, QuerySnapshot} from '@firebase/firestore';
import {
  deleteTrackMixs,
  upsertTrackMixs
} from '../../+track-mix/track-mix.actions';
import {CustomFirestoreService} from '../../firebase';
import {QueryModel} from './query.model';

export class QueryTrackMixesService implements QueryModel<TrackMix> {
  // TRACK
  private trackMixesSub: (() => void) | undefined;

  constructor(
    private sptFirebase: CustomFirestoreService,
    private store: Store,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AccountState) {
    const that = this;

    // BEGIN TRACKS
    if (this.trackMixesSub) {
      this.trackMixesSub();
    }

    this.trackMixesSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreTrackMixesPath(this.config)),
      (snapshot: QuerySnapshot) => {
        // that.processTracks(snapshot, user.auth.uid);
        that.process.apply(that, [
          snapshot.docChanges() as DocumentChange<TrackMix>[]
        ]);
      },
      () => {
        /* noop */
      },
      () => {
        /* noop */
      }
    );
  }

  onDisconnect(user?: AccountState) {
    // TRACKS
    if (this.trackMixesSub) {
      this.trackMixesSub();
    }
  }

  process(snapshot: DocumentChange<TrackMix>[]) {
    const that = this;
    const aggregate: AggregateFirebaseSnapshotChangesEntities<TrackMix> =
      aggregateDocChanges<TrackMix>(
        snapshot
        // createdByUID
      );

    this.zone.run(() => {
      if (aggregate.added.length) {
        // aggregate.added.forEach((added) => {
        //   console.log('TRACKS ADDED', added.id);
        // });

        // aggregate.added = aggregate.added.map((t: TrackMix) => {
        //   Object.keys(t.audioSnippets).forEach((key: string) => {
        //     t.audioSnippets[key] = removeTimeStampCTorFromData(t.audioSnippets[key]);
        //   });
        //
        //   return t;
        // });

        that.store.dispatch(
          upsertTrackMixs({
            trackMixs: <TrackMix[]>aggregate.added
          })
        );
      }

      if (aggregate.modified.length) {
        // aggregate.modified.forEach((modified) => {
        //   console.log('TRACKS MODIFIED', modified.id);
        // });

        // aggregate.modified = aggregate.modified.map((t: TrackMix) => {
        //   Object.keys(t.audioSnippets).forEach((key: string) => {
        //     t = removeTimeStampCTorFromData(t);
        //     t.audioSnippets[key] = removeTimeStampCTorFromData(t.audioSnippets[key]);
        //   });
        //
        //   return t;
        // });

        that.store.dispatch(
          upsertTrackMixs({
            trackMixs: <TrackMix[]>aggregate.modified
          })
        );
      }

      if (aggregate.removed.length) {
        // aggregate.removed.forEach((removed) => {
        //   console.log('TRACKS REMOVED', removed);
        // });

        that.store.dispatch(
          deleteTrackMixs({
            ids: aggregate.removed
          })
        );
      }
    });
  }
}
