/**
 * BASE
 * Format 2020.06.0.3
 */
import {baseElectronVersion, releaseType, ui} from './spout-build';
import {
  AppVersions,
  BuildVersionElectron,
  BuildVersionUi
} from './spout-version.model';
import {concatBuildVersion} from './version.helper';

/**
 * MAC
 */
export const mac: BuildVersionElectron = {
  year: baseElectronVersion.year,
  month: baseElectronVersion.month,
  electronMajorVersion: baseElectronVersion.electronMajorVersion,
  electronVersion: baseElectronVersion.electronVersion,
  buildNumber: baseElectronVersion.buildNumber
};

/**
 * PC
 */
export const pc: BuildVersionUi = {
  year: baseElectronVersion.year,
  month: baseElectronVersion.month,
  angularVersion: baseElectronVersion.electronMajorVersion,
  buildNumber: baseElectronVersion.buildNumber
};

export const appVersions: AppVersions = {
  releaseType: releaseType,
  macString: `${mac.year}.${mac.month}.${mac.electronMajorVersion}.${mac.buildNumber}`,
  pcString: `${pc.year}.${pc.month}.${pc.angularVersion}.${pc.buildNumber}`,
  build: baseElectronVersion,
  ui: concatBuildVersion(ui),
  platform: {
    isM1Mac: true,
    isIntelMac: false,
    isPc: false
  }
};

// TODO add electron ipc to show version
