import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'spt-update-subscription-error',
  templateUrl: './update-subscription-error.component.html',
  styleUrls: ['./update-subscription-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateSubscriptionErrorComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateSubscriptionErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
