import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {getTrackColorCSS} from '@spout/global-web/fns';
import {BehaviorSubject} from 'rxjs';
import {WarnDeleteSelectedTrack} from '../track-item-dialog.model';

@Component({
  selector: 'spt-warn-delete-selected',
  templateUrl: './warn-delete-selected.component.html',
  styleUrls: ['./warn-delete-selected.component.scss']
})
export class WarnDeleteSelectedComponent {
  selectedColor = '';

  @Input()
  set data(data: WarnDeleteSelectedTrack) {
    if (data && data.track !== null) {
      this.selectedColor = getTrackColorCSS(data.track);
      this._cd.detectChanges();
    }
  }

  @Output()
  ok: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _cd: ChangeDetectorRef) {}
}
