import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  CurrentIds,
  Musician,
  ProjectEntity,
  ProjectState
} from '@spout/global-any/models';
import {
  createPassThroughSelector,
  getDefaultEntityConfig,
  isCreatedByLoggedInMusician
} from '@spout/global-web/fns';
import {
  AccountState,
  getProjectRoleTitle,
  getProjectRoleType,
  PROJECT_FEATURE_KEY
} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {selectAccountState, selectUid} from '../+account/account.selectors';
import {selectCurrentIDsFromStore} from '../+device-storage/device-storage.selectors';

// PROJECT PROJECT PROJECT PROJECT
// PROJECT PROJECT PROJECT PROJECT
// PROJECT PROJECT PROJECT PROJECT
// PROJECT PROJECT PROJECT PROJECT

export const selectProjectState =
  createFeatureSelector<ProjectState>(PROJECT_FEATURE_KEY);

export const selectProjectEntities = createSelector(
  selectProjectState,
  (state: ProjectState): Dictionary<ProjectEntity> => state.entities
);

export const selectAllProjects = createSelector(
  selectProjectEntities,
  (entities: Dictionary<ProjectEntity>): ProjectEntity[] => {
    if (Object.keys(entities).length) {
      return <ProjectEntity[]>Object.values(entities);
    }

    return <ProjectEntity[]>[];
  }
);

/**
 * @deprecated
 */
export const selectAllProjects_passThrough = createPassThroughSelector(
  selectProjectEntities,
  (entities: Dictionary<ProjectEntity>): ProjectEntity[] => {
    if (Object.keys(entities).length) {
      return <ProjectEntity[]>Object.values(entities);
    }

    return <ProjectEntity[]>[];
  }
);

export const selectDefaultProject = createSelector(
  selectAllProjects,
  selectAccountState,
  (entities: ProjectEntity[], account: AccountState): ProjectEntity | null => {
    return <ProjectEntity>(
      getDefaultEntityConfig(
        <{isDefault: boolean; createdByUID: string}[]>entities,
        account
      )
    );
  }
);

export const getProjectById = createSelector(
  selectProjectEntities,
  (
    entities: Dictionary<ProjectEntity>,
    props: {id: string}
  ): ProjectEntity | undefined => entities[props.id]
);

export const selectCurrentProjectEntity = createSelector(
  selectAllProjects,
  selectDefaultProject,
  selectCurrentIDsFromStore,
  (
    projects: ProjectEntity[],
    defaultProject: ProjectEntity | null,
    ids: CurrentIds | null
  ): ProjectEntity | undefined | null => {
    if (projects && projects.length) {
      if (ids && ids.currentProjectId) {
        let foundProject = projects.find(
          (project: ProjectEntity) => ids && project.id === ids.currentProjectId
        );

        if (foundProject) {
          return foundProject;
        } else if (defaultProject) {
          return defaultProject;
        }

        return projects[0];
      }
    }

    return null;
  }
);

export const selectCurrentProjectEntity_passThrough = createSelector(
  selectAllProjects,
  selectCurrentIDsFromStore,
  (
    projects: ProjectEntity[],
    ids: CurrentIds | null
  ): ProjectEntity | undefined => {
    return projects.find(
      (project: ProjectEntity) => ids && project.id === ids.currentProjectId
    );
  }
);

export const getProjectEntityById = (projectId: string | undefined) =>
  createSelector(selectAllProjects, (projects: ProjectEntity[]) =>
    projects.find(
      (project: ProjectEntity) => projectId && project.id === projectId
    )
  );

export const selectedCurrentProjectEntityIsCreatedByLoggedInMusician =
  createSelector(
    selectCurrentProjectEntity,
    selectUid,
    (
      trackEntity: ProjectEntity | undefined | null,
      uid: string | null
    ): boolean => {
      return trackEntity && uid
        ? isCreatedByLoggedInMusician(uid, trackEntity)
        : false;
    }
  );

export const notSelectedCurrentProjectEntityIsCreatedByLoggedInMusician =
  createSelector(
    selectCurrentProjectEntity,
    selectUid,
    (
      trackEntity: ProjectEntity | undefined | null,
      uid: string | null
    ): boolean => {
      return trackEntity && uid
        ? !isCreatedByLoggedInMusician(uid, trackEntity)
        : false;
    }
  );

// export const selectedIsProjectOwner = createSelector(
//   selectCurrentProjectEntity,
//   selectUid,
//   (project: ProjectEntity | undefined, uid: string | null) => {
//     if (project && uid) {
//       if (project.members[uid]) {
//         return project.members[uid].role.owner;
//       } else {
//         return false;
//       }
//     }
//
//     return false;
//   }
// );

// export const selectIsMixCreator = createSelector(
//   selectCurrentMixEntity,
//   selectUid,
//   (song: MixEntity | null, uid: string | null) => {
//     if (song && uid) {
//       if (song.members[uid]) {
//         return song.members[uid].role.owner;
//       } else {
//         return false;
//       }
//     }
//
//     return false;
//   }
// );

export const selectCurrentProjectEntityMemberList = createSelector(
  selectCurrentProjectEntity,
  (projectEntity: ProjectEntity | undefined | null) => {
    if (projectEntity && !!Object.keys(projectEntity.members).length) {
      return Object.values(projectEntity.members).map((musician: Musician) => {
        return {
          ...musician,
          roleTitle: getProjectRoleTitle(musician.role),
          roleType: getProjectRoleType(musician.role)
        };
      });
    }

    return [];
  }
);

export const selectCurrentProjectMembersNotOwners = createSelector(
  selectCurrentProjectEntityMemberList,
  (m: Musician[]) => {
    return m.filter((m: Musician) => !m.role.owner);
  }
);

export const selectCurrentProjectEntityOwnerList = createSelector(
  selectCurrentProjectEntity,
  (projectEntity: ProjectEntity | undefined | null) => {
    if (projectEntity && !!Object.keys(projectEntity.owners).length) {
      return Object.values(projectEntity.owners).map((musician: Musician) => {
        return {
          ...musician,
          roleTitle: getProjectRoleTitle(musician.role),
          roleType: getProjectRoleType(musician.role)
        };
      });
    }

    return [];
  }
);

export const selectCurrentProjectMembersAndOwners = createSelector(
  selectCurrentProjectEntityMemberList,
  selectCurrentProjectEntityOwnerList,
  (members: Musician[], owners: Musician[]) => {
    const entities: {[key: string]: Musician} = [...members, ...owners].reduce(
      (a: {[key: string]: Musician}, m: Musician) => {
        a[m.uid] = m;

        return a;
      },
      <{[key: string]: Musician}>{}
    );

    return Object.values(entities);
  }
);

export const selectAllProjectsIOwn = createSelector(
  selectAllProjects,
  selectUid,
  (projects: ProjectEntity[], uid: string | null) => {
    return projects.filter((project: ProjectEntity) =>
      uid ? isCreatedByLoggedInMusician(uid, project) : false
    );
  }
);

export const selectCurrentProjectIsDefault = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined | null) =>
    hasValue(project) && project ? project.isDefault : false
);

export const selectCurrentProjectId = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined | null): string | null =>
    project ? project.id : null
);

export const selectCurrentProjectNamePassThrough = createPassThroughSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined | null) => (project ? project.name : null)
);

export const projectIsCreatedByLoggedInMusician = createSelector(
  selectUid,
  selectCurrentProjectEntity,
  (uid: string | null, project: ProjectEntity | undefined | null) => {
    return project && uid ? isCreatedByLoggedInMusician(uid, project) : false;
  }
);

export const selectProjectNotCreatedByLoggedInMusician = createSelector(
  selectUid,
  selectCurrentProjectEntity,
  (uid: string | null, project: ProjectEntity | undefined | null) => {
    return project && uid ? !isCreatedByLoggedInMusician(uid, project) : false;
  }
);

export const getProjectIsCreatedByLoggedInMusician = (project: ProjectEntity) =>
  createSelector(selectUid, (uid: string) => {
    return project && uid ? isCreatedByLoggedInMusician(uid, project) : false;
  });

export const selectShareProjectTooltip = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined | null) => {
    if (project && project.isDefault) {
      return 'A personal project is not sharable. Create a new project to share.';
    } else {
      return 'Share project with collaborators';
    }
  }
);

export const selectShareProjectTooltipCSSClass = createSelector(
  selectCurrentProjectEntity,
  (project: ProjectEntity | undefined | null) => {
    if (project && project.isDefault) {
      return 'spt-tooltip-warn';
    } else {
      return '';
    }
  }
);
