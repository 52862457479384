import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {intialDeviceStorageState} from '@spout/global-any/models';
import {
  chatInitialState,
  deviceDetectionInitialState,
  genreInitialState,
  initialAccountState,
  initialDialogQueueState,
  initialEnvironmentState,
  initialInstrumentMusicianState,
  initialLatencyState,
  initialMetaDataState,
  initialMixerRightDrawerState,
  initialSongState,
  initialStudioState,
  initialTrackState,
  initialWebsocketRegistryState,
  instrumentInitialState,
  intialGenreMusicianState,
  notificationsInitialState,
  routerInitialState,
  transportInitialState
} from '@spout/global-web/models';
import {WINDOW_PROVIDERS} from '@spout/global-web/utils';
import {AccountEffects} from './+account/account.effects';
import {accountReducer} from './+account/account.reducer';
import {AccountService} from './+account/account.service';
import {AuthLogoutWatchService} from './+account/auth-logout-watch.service';
import {CreateDefaultEntitiesService} from './+account/create-default-entities.service';
import {ProfilesService} from './+account/profiles.service';
import {TrackCloudStorageService} from './+account/track-cloud-storage.service';
import {AudioFileMetaDataFirestoreService} from './+audio-file-meta-data/audio-file-meta-data-firestore.service';
import {AudioFileMetaDataEffects} from './+audio-file-meta-data/audio-file-meta-data.effects';
import {audioFileMetaDataReducer} from './+audio-file-meta-data/audio-file-meta-data.reducer';
import {ChatEffects} from './+chat/chat.effects';
import {chatReducer} from './+chat/chat.reducer';
import {DeviceDetectionEffects} from './+device-detection/device-detection.effects';
import {deviceDetectionReducer} from './+device-detection/device-detection.reducer';
import {DeviceDetectionService} from './+device-detection/device-detection.service';
import {DeviceAudioFileMetaDataEffects} from './+device-storage/device-audio-file-meta-data.effects';
import {DeviceCurrentIdsEffects} from './+device-storage/device-current-ids.effects';
import {DeviceStorageEffects} from './+device-storage/device-storage.effects';
import {deviceStorageReducer} from './+device-storage/device-storage.reducer';
import {DeviceWorkspaceEffects} from './+device-storage/device-workspace.effects';
import {AudioFileDeleteService} from './+device-storage/services/audio-file-delete.service';
import {AudioFileLoadService} from './+device-storage/services/audio-file-load.service';
import {AudioFileSaveService} from './+device-storage/services/audio-file-save.service';
import {DeviceEntityService} from './+device-storage/services/device-entity.service';
import {DeviceStorageService} from './+device-storage/services/device-storage.service';
import {DialogQueueEffects} from './+dialog-queue/dialog-queue.effects';
import {dialogQueueReducer} from './+dialog-queue/dialog-queue.reducer';
import {EnvironmentEffects} from './+environment/environment.effects';
import {environmentReducer} from './+environment/environment.reducer';
import {GenresMusicianEffects} from './+genres-musician/genres-musician.effects';
import {genreMusicianReducer} from './+genres-musician/genres-musician.reducer';
import {GenreEffects} from './+genres/genre.effects';
import {genreReducer} from './+genres/genres.reducer';
import {GoogleAnalyticsEffects} from './+google-analytics/google-analytics.effects';
import {
  appStoreInitialStates,
  appStoreReducers,
  appStoresEffects
} from './+in-app-purchase/store';
import {InstrumentMusicianEffects} from './+instruments-musician/instrument-musician.effects';
import {instrumentMusicianReducer} from './+instruments-musician/instrument-musician.reducer';
import {InstrumentEffects} from './+instruments/instruments.effects';
import {instrumentReducer} from './+instruments/instruments.reducer';
import {LatencyEffects} from './+latency/latency.effects';
import {latencyReducer} from './+latency/latency.reducer';
import {MixEffects} from './+mixes/mix.effects';
import {initialMixState, mixesReducer} from './+mixes/mixes.reducer';
import {MixesService} from './+mixes/mixes.service';
import {NotificationsEffects} from './+notifications/notifications.effects';
import {notificationsReducer} from './+notifications/notifications.reducer';
import {SptNotificationsService} from './+notifications/notifications.service';
import {permissionsInitialState} from './+permission/permission.reducer';
import {ProjectFactoryService} from './+project/project-factory.service';
import {ProjectNodeCache} from './+project/project-node-cache.service';
import {ProjectEffects} from './+project/project.effects';
import {initialProjectsState, projectReducer} from './+project/project.reducer';
import {ProjectService} from './+project/project.service';
import {MixerRightDrawerEffects} from './+right-drawer/mixer-right-drawer.effects';
import {mixerRightDrawerReducer} from './+right-drawer/mixer-right-drawer.reducer';
import {RouterEffects} from './+router/router.effects';
import {routerReducer} from './+router/router.reducer';
import {SongService} from './+songs/song.service';
import {SongsEffects} from './+songs/songs.effects';
import {songsReducer} from './+songs/songs.reducer';

/**
 * Stripe
 */
import {
  stripeInitialStates,
  stripeReducers,
  stripeEffects
} from './+stripe/store';
import {StudioEffects} from './+studio/studio.effects';
import {studioReducer} from './+studio/studio.reducer';
import {GlobalTableStateService} from './+table-state/global-table-state.service';
import {
  initialTrackMixStateState,
  trackMixReducer
} from './+track-mix/track-mix.reducer';
import {TrackMixesEffects} from './+track-mix/track-mixes.effects';
import {TrackMixesService} from './+track-mix/track-mixes.service';
import {CreateTrackService} from './+tracks/create-track.service';
import {FirestoreTracksService} from './+tracks/firestore-tracks.service';
import {TracksEffects} from './+tracks/track.effects';
import {tracksReducer} from './+tracks/tracks.reducer';
import {SptUserEffects} from './+users/spt-user.effects';
import * as fromSptUser from './+users/spt-user.reducer';
import {websocketRegistryReducer} from './+websocket-registry/websocket-registry.reducer';
import {SongMetricsService} from './audio/services/song-metrics.service';
import {SptMetronomeService} from './audio/services/spt-metronome.service';
import {SptPlayerCacheService} from './audio/services/spt-player-cache.service';
import {SptScrubberService} from './audio/services/spt-scrubber.service';
import {SptVolumeTranslateService} from './audio/services/spt-volume-translate.service';
import {SptTransportEffects} from './audio/spt-transport.effects';
import {sptTransportReducer} from './audio/spt-transport.reducer';
import {AggregateService} from './services/aggregate.service';
import {DeleteAccountService} from './services/delete-account/delete-account.service';
import {DynamicStoreService} from './services/dynamic-store.service';
import {PresenceService} from './services/presence.service';
import {NoEmailWarningModule} from './ui/no-email-warning/no-email-warning.module';
import {TrackItemDialogModule} from './ui/track-item-dialog/track-item-dialog.module';
import * as fromPermission from './+permission/permission.reducer';
import {PermissionEffects} from './+permission/permission.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TrackItemDialogModule,
    NoEmailWarningModule,
    MatDialogModule,

    StoreModule.forRoot(
      {
        account: accountReducer,
        audioFileMetaData: audioFileMetaDataReducer,
        chat: chatReducer,
        deviceDetection: deviceDetectionReducer,
        deviceStorage: deviceStorageReducer,
        dialogQueues: dialogQueueReducer,
        environment: environmentReducer,
        genres: genreReducer,
        genresMusicians: genreMusicianReducer,
        instruments: instrumentReducer,
        instrumentMusicians: instrumentMusicianReducer,
        latencies: latencyReducer,
        mix: mixesReducer,
        notifications: notificationsReducer,
        permissions: fromPermission.reducer,
        project: projectReducer,
        mixerRightDrawer: mixerRightDrawerReducer,
        router: routerReducer,
        song: songsReducer,
        sptUsers: fromSptUser.reducer,
        studio: studioReducer,
        transport: sptTransportReducer,
        track: tracksReducer,
        trackMixes: trackMixReducer,
        webSocketRegistry: websocketRegistryReducer,
        // Stripe
        ...stripeReducers,
        ...appStoreReducers
      },
      {
        initialState: {
          account: initialAccountState,
          audioFileMetaData: initialMetaDataState,
          chat: chatInitialState,
          deviceDetection: deviceDetectionInitialState,
          deviceStorage: intialDeviceStorageState,
          dialogQueues: initialDialogQueueState,
          environment: initialEnvironmentState,
          genres: genreInitialState,
          genresMusicians: intialGenreMusicianState,
          instruments: instrumentInitialState,
          instrumentMusicians: initialInstrumentMusicianState,
          latencies: initialLatencyState,
          mix: initialMixState,
          mixerRightDrawer: initialMixerRightDrawerState,
          notifications: notificationsInitialState,
          permissions: permissionsInitialState,
          project: initialProjectsState,
          song: initialSongState,
          router: routerInitialState,
          sptUsers: fromSptUser.initialState,
          studio: initialStudioState,
          transport: transportInitialState,
          track: initialTrackState,
          trackMixes: initialTrackMixStateState,
          webSocketRegistry: initialWebsocketRegistryState,
          // Stripe
          ...stripeInitialStates,
          ...appStoreInitialStates
        },
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true, // issue
          strictActionSerializability: true, // issue
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
        // metaReducers: !environment.production ? [ storeFreeze ] : []
      }
    ),

    EffectsModule.forRoot([
      AccountEffects,
      AudioFileMetaDataEffects,
      ChatEffects,
      DeviceAudioFileMetaDataEffects,
      DeviceCurrentIdsEffects,
      DeviceWorkspaceEffects,
      DeviceDetectionEffects,
      DeviceStorageEffects,
      DialogQueueEffects,
      EnvironmentEffects,
      GenreEffects,
      GenresMusicianEffects,
      GoogleAnalyticsEffects,
      InstrumentEffects,
      InstrumentMusicianEffects,
      LatencyEffects,
      MixerRightDrawerEffects,
      MixEffects,
      NotificationsEffects,
      ProjectEffects,
      RouterEffects,
      SongsEffects,
      SptTransportEffects,
      StudioEffects,
      TracksEffects,
      TrackMixesEffects,
      SptUserEffects,
      PermissionEffects,
      // stripe
      ...stripeEffects,
      ...appStoresEffects
    ]),

    StoreModule.forFeature(
      fromPermission.permissionsFeatureKey,
      fromPermission.reducer
    )
  ],
  providers: [
    WINDOW_PROVIDERS,
    AccountService,
    AggregateService,
    AuthLogoutWatchService,
    DeviceDetectionService,
    DeviceStorageService,
    AudioFileDeleteService,
    AudioFileLoadService,
    AudioFileSaveService,
    CreateTrackService,
    DeviceEntityService,
    CreateDefaultEntitiesService,
    GlobalTableStateService,
    DeleteAccountService,
    AudioFileMetaDataFirestoreService,
    PresenceService,
    SptNotificationsService,
    ProjectNodeCache,
    ProjectService,
    ProjectFactoryService,
    SongService,
    FirestoreTracksService,
    MixesService,
    DynamicStoreService,
    DeviceStorageService,
    ProfilesService,
    SptMetronomeService,
    SptScrubberService,
    SptPlayerCacheService,
    SptVolumeTranslateService,
    SongMetricsService,
    TrackMixesService,
    TrackCloudStorageService
  ]
})
export class DataAccessStudioStoreModule {
  constructor(private projectFacade: ProjectNodeCache) {
    this.projectFacade.init();
  }
}
