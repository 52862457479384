import {createAction, props} from '@ngrx/store';
import {GAEventItem} from '../analytics';

export const logGAUserEvent = createAction(
  '[GoogleAnalytics] Log User Event',
  props<{event: GAEventItem}>()
);

export const logGAEvent = createAction(
  '[GoogleAnalytics] Log Event',
  props<{event: GAEventItem}>()
);

export const gaPrefixEventName = (eventName: string, event: string) => {
  return eventName + '_' + event;
};
