import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {TreeConfig} from '@spout/global-any/models';
import {GENRE_FEATURE_KEY} from '../studio-app-keys';

export enum GenreActionTypes {
  GenreLoaded = '[Genre] Genre Loaded',
  SaveGenresToFirebase = '[Account] Save Genres to Firebase'
}

export const genreAdapter: EntityAdapter<TreeConfig> =
  createEntityAdapter<TreeConfig>();

export interface GenreState extends EntityState<TreeConfig> {
  rootNode: TreeConfig | null; // Genre Tree
  loaded?: boolean; // has the Genre list been loaded
  error?: any; // last none error (if any)
  // genresSelected?: TreeConfig[];
}

export interface GenrePartialState {
  readonly [GENRE_FEATURE_KEY]: GenreState;
}

export const genreInitialState: GenreState = genreAdapter.getInitialState({
  rootNode: null,
  loaded: false,
  error: null,
  genresSelected: []
});
