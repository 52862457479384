export function addUniqueItemToCollection<T>(
  items: T[] | undefined,
  item: T
): T[] {
  const _set = new Set(items || []);
  _set.add(item);
  return Array.from(_set);
}

export function removeItemFromCollection<T>(
  items: T[] | undefined,
  item: T
): T[] {
  const _set = new Set(items || []);
  _set.delete(item);
  return Array.from(_set);
}

export function anyBooleanValueTrue(d: boolean[]): boolean {
  return d.some((i: boolean) => i);
}

export function noBooleanValueTrue(d: boolean[]): boolean {
  return !anyBooleanValueTrue(d);
}
