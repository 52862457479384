import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {Musician, ProjectEntity} from '@spout/global-any/models';

export const initProjectsEffects = createAction('[Project] Init Effects');

export const loadProjects = createAction(
  '[Project] Load Projects',
  props<{projects: ProjectEntity[]}>()
);

export const addProject = createAction(
  '[Project] Add Project',
  props<{project: ProjectEntity}>()
);

export const createProject = createAction(
  '[Project] Create Project',
  props<{project: ProjectEntity}>()
);

export const upsertProject = createAction(
  '[Project] Upsert Project',
  props<{project: ProjectEntity}>()
);

export const addProjects = createAction(
  '[Project] Add Projects',
  props<{projects: ProjectEntity[]}>()
);

export const upsertProjects = createAction(
  '[Project] Upsert Projects',
  props<{projects: ProjectEntity[]}>()
);

export const updateProject = createAction(
  '[Project] Update Project',
  props<{project: Update<ProjectEntity>}>()
);

export const updateProjects = createAction(
  '[Project] Update Projects',
  props<{projects: Update<ProjectEntity>[]}>()
);

export const deleteProject = createAction(
  '[Project] Delete Project',
  props<{id: string}>()
);
export const deleteProjectEffect = createAction(
  '[Project] Delete Project Effect',
  props<{project: ProjectEntity}>()
);

export const deleteProjects = createAction(
  '[Project] Delete Projects',
  props<{ids: string[]}>()
);

export const clearProjects = createAction('[Project] Clear Projects');

export const selectProjectEffect = createAction(
  '[Project] Select Project Effect',
  props<{project: ProjectEntity}>()
);

export const selectProject = createAction(
  '[Project] Select Project',
  props<{project: ProjectEntity}>()
);

export const setDefaultProjectId = createAction(
  '[Project] Set Default Project',
  props<{project: ProjectEntity}>()
);

export const addMusicianToCurrentProject = createAction(
  '[Project] Add Musician to current project',
  props<{musician: Musician}>()
);

export const updateMusicianToCurrentProject = createAction(
  '[Project] Update Musician to current project',
  props<{musician: Musician}>()
);

export const removeMusicianFromCurrentProject = createAction(
  '[Project] Remove Musician to current project',
  props<{musician: Musician}>()
);

export const shareProject = createAction(
  '[Project] Share',
  props<{
    share: boolean;
    project: ProjectEntity;
  }>()
);

export const syncProject = createAction(
  '[Project] Sync',
  props<{
    sync: boolean;
    project: ProjectEntity;
  }>()
);

export const selectedProjectEntity = createAction(
  '[Project] Selected Entity',
  props<{
    projectEntity: ProjectEntity;
  }>()
);
