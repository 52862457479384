import {Injectable, NgZone} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  dispatchNgrxFirestoreAggregate,
  firestoreGenresPath
} from '@spout/global-web/fns';
import {
  AccountState,
  AccountStateConnect,
  GENRE_FEATURE_KEY
} from '@spout/global-web/models';
import {onSnapshot, QuerySnapshot} from '@firebase/firestore';
import {
  upsertWebsocketRegistry,
  websocketIsDisconnectedAction
} from '../+websocket-registry/websocket-registry.actions';
import {selectAuthAccountConnect$} from '../+websocket-registry/websocket-registry.selectors';
import {CustomFirestoreService} from '../firebase';
import {OnWebsocketMonitor} from '../websocket-monitor/websocket-monitor';
import {genreAggregate} from './genre.actions';

@Injectable()
export class GenreEffects implements OnWebsocketMonitor {
  private _databaseSub: (() => void) | undefined;

  constructor(
    private sptFirestore: CustomFirestoreService,
    private actions$: Actions,
    private store: Store<AccountStateConnect>,
    private zone: NgZone
  ) {
    const that = this;
    this.zone.run(() => {
      this.store.dispatch(
        upsertWebsocketRegistry({
          id: GENRE_FEATURE_KEY
        })
      );
    });

    this.store
      .pipe(selectAuthAccountConnect$)
      .subscribe((s: AccountStateConnect) => {
        if (s.doConnect) {
          this.onConnect.call(this, s.user);
        } else {
          this.onDisconnect.call(this, s.user);
        }
      });
  }

  onConnect(user: AccountState): void {
    const that = this;
    if (!this._databaseSub) {
      this.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: GENRE_FEATURE_KEY
          })
        );
      });

      this._databaseSub = onSnapshot(
        this.sptFirestore.collectionRef(firestoreGenresPath()),
        (snapshot: QuerySnapshot) => {
          dispatchNgrxFirestoreAggregate<AccountStateConnect>(
            this.store,
            snapshot,
            genreAggregate,
            this.zone
          );
        },
        (err: Error) => {
          console.error(err);
        },
        () => {
          /* noop */
        }
      );
    }
  }

  onDisconnect(user: AccountState): void {
    const that = this;
    if (this._databaseSub) {
      this._databaseSub();

      that.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: GENRE_FEATURE_KEY
          })
        );
      });
    }
  }
}
