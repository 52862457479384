import {gt} from 'semver';
import {baseElectronVersion} from './spout-build';
import {BuildVersionElectron, BuildVersionUi} from './spout-version.model';

export function concatBuildVersion(
  u: BuildVersionUi | BuildVersionElectron
): string {
  return `${u.year}.${u.month}.${u.buildNumber}`;
}

export function concatUiBuildVersion(u: BuildVersionUi): string {
  return `${u.year}.${u.month}.${u.angularVersion}.${u.buildNumber}`;
}

export function concatElectronBuildVersion(u: BuildVersionElectron): string {
  return `${u.year}.${u.month}.${u.electronMajorVersion}.${u.buildNumber}`;
}

export function getInstalledElectronVersion(): string {
  return baseElectronVersion.electronVersion;
}

export function getInstalledElectronAppBuildVersion(): string {
  return concatElectronAppBuildVersionFromString(baseElectronVersion);
}

export function concatElectronAppBuildVersionFromString(
  e: BuildVersionElectron
): string {
  return `${e.year}.${e.month}.${e.buildNumber}`;
}

export function firestoreUIVersionIsGreaterThanLocal(
  uploaded: BuildVersionUi | null,
  installed: BuildVersionUi | null
): boolean {
  if (
    uploaded === null ||
    uploaded === undefined ||
    installed === null ||
    installed === undefined
  ) {
    return false;
  }

  const uploadedVersion = `${uploaded.year}.${uploaded.month}.${uploaded.buildNumber}`;
  const installedVersion = `${installed.year}.${installed.month}.${installed.buildNumber}`;

  // console.log(uploadedVersion, installedVersion);
  // console.log(gt(uploadedVersion, installedVersion));

  return gt(uploadedVersion, installedVersion);
}

export function firestoreElectronVersionIsGreaterThanLocal(
  uploaded: BuildVersionElectron | null,
  installed: BuildVersionElectron | null
): boolean {
  if (
    uploaded === null ||
    uploaded === undefined ||
    installed === null ||
    installed === undefined
  ) {
    return false;
  }

  const uploadedVersion = `${uploaded.year}.${uploaded.month}.${uploaded.buildNumber}`;
  const installedVersion = `${installed.year}.${installed.month}.${installed.buildNumber}`;

  // console.log(uploadedVersion, installedVersion);
  // console.log(gt(uploadedVersion, installedVersion));

  return gt(uploadedVersion, installedVersion);
}
