import {PROJECT_ROLE, ProjectEntity} from '@spout/global-any/models';
import {hasValue} from '@uiux/fn';

export const permissionFeatureKey = 'permission';

export enum ACCOUNT_ROLE {
  USE_ACCOUNT_ROLE = -1, // For mapping project rol
  EARLY_ADOPTER = 0, // Early Adopter
  COLLABORATOR = 1, // Reviewer
  PERSONAL = 2, // Personal
  BAND = 3, // Band
  REVIEWER = 4
}

export const ROLE_RANK: {[key: string]: number} = {
  [ACCOUNT_ROLE.REVIEWER]: 0,
  [ACCOUNT_ROLE.COLLABORATOR]: 10,
  [ACCOUNT_ROLE.EARLY_ADOPTER]: 20,
  [ACCOUNT_ROLE.PERSONAL]: 20,
  [ACCOUNT_ROLE.BAND]: 30
};

export const ROLE_NAME: {[key: string]: string} = {
  [ACCOUNT_ROLE.EARLY_ADOPTER]: 'Early Adopter',
  [ACCOUNT_ROLE.COLLABORATOR]: 'Collaborator',
  [ACCOUNT_ROLE.PERSONAL]: 'Personal',
  [ACCOUNT_ROLE.BAND]: 'Band',
  [ACCOUNT_ROLE.REVIEWER]: 'Reviewer'
};

export function getRoleName(role: number) {
  return ROLE_NAME[role];
}

/**
 * Stubbed for now
 * @param role
 */
export function getPermissionRoleTitle(role: number = ACCOUNT_ROLE.REVIEWER) {
  return getRoleName(role);
  // switch (role) {
  //   case ACCOUNT_ROLE.EARLY_ADOPTER:
  //     return 'CREATING';
  //   case ACCOUNT_ROLE.COLLABORATOR:
  //     return 'COLLABORATING';
  //   case ACCOUNT_ROLE.PERSONAL:
  //     return 'CREATING';
  //   case ACCOUNT_ROLE.BAND:
  //     return 'CREATING';
  //   case ACCOUNT_ROLE.REVIEWER:
  //     return 'REVIEWING';
  //   default:
  //     return 'TRIAL CREATING';
  // }
}

export function getAccountTitle(role: number | null | undefined) {
  switch (role) {
    case ACCOUNT_ROLE.EARLY_ADOPTER:
      return 'EARLY ADOPTER';
    case ACCOUNT_ROLE.COLLABORATOR:
      return 'COLLABORATOR';
    case ACCOUNT_ROLE.PERSONAL:
      return 'PERSONAL';
    case ACCOUNT_ROLE.BAND:
      return 'BAND';
    case ACCOUNT_ROLE.REVIEWER:
      return 'REVIEWER';
    default:
      return 'NO ROLE';
  }
}

// apps/firebase/design-data/payment/payment.model.ts
export enum FEATURE_ID {
  ADD_ANNOTATIONS = 'addAnnotations',
  ADD_COLLABORATORS = 'addCollaborators',
  CHAT = 'chat',
  CLEAR_TRACKS = 'clearTracks',
  CREATE_PROJECTS = 'createProjects',
  CREATE_SONGS = 'createSongs',
  CREATE_TRACKS = 'createTracks',
  CREATE_MIXES = 'createMixes',
  COPY_TRACKS = 'copyTracks',
  DELETE_TRACKS = 'deleteTracks',
  EXPORT_SONG = 'exportSong',
  IMPORT_AUDIO_FILES = 'importAudioFiles',
  RECORD = 'record',
  // RENDER_MIX = 'renderMix',
  SHARE_TRACKS = 'shareTracks',
  TRACK_CONTROLS = 'trackControls'
}

export declare type PaymentFrequency = 'monthly' | 'yearly';

export interface PaymentTier {
  paymentFrequency: PaymentFrequency;
}

export interface SptPermissions {
  [FEATURE_ID.ADD_ANNOTATIONS]: boolean;
  [FEATURE_ID.ADD_COLLABORATORS]: boolean;
  [FEATURE_ID.CHAT]: boolean;
  [FEATURE_ID.CLEAR_TRACKS]: boolean;
  [FEATURE_ID.CREATE_PROJECTS]: boolean;
  [FEATURE_ID.CREATE_SONGS]: boolean;
  [FEATURE_ID.CREATE_TRACKS]: boolean;
  [FEATURE_ID.CREATE_MIXES]: boolean;
  [FEATURE_ID.COPY_TRACKS]: boolean;
  [FEATURE_ID.DELETE_TRACKS]: boolean;
  [FEATURE_ID.EXPORT_SONG]: boolean;
  [FEATURE_ID.IMPORT_AUDIO_FILES]: boolean;
  [FEATURE_ID.RECORD]: boolean;
  // [FEATURE_ID.RENDER_MIX]: boolean;
  [FEATURE_ID.SHARE_TRACKS]: boolean;
  [FEATURE_ID.TRACK_CONTROLS]: boolean;
}

// export interface SptPermissionState {
//   accountRole: ACCOUNT_ROLE;
//   projectRole: ACCOUNT_ROLE;
//
//   // final role used in UI
//   permissionRole: ACCOUNT_ROLE;
//   permissions: SptPermissions;
// }

export declare type FeatureCategory = 'daw' | 'collaboration';

export interface PermissionByRole {
  [key: string]: boolean;
}

export interface FeatureNameDescription {
  id: FEATURE_ID;
  featureName: string;
  description: string;
  category: FeatureCategory;
}

export interface RolePermmissions {
  [ACCOUNT_ROLE.EARLY_ADOPTER]: boolean;
  [ACCOUNT_ROLE.COLLABORATOR]: boolean;
  [ACCOUNT_ROLE.PERSONAL]: boolean;
  [ACCOUNT_ROLE.BAND]: boolean;
  [ACCOUNT_ROLE.REVIEWER]: boolean;
}

export declare type FeaturePermission = RolePermmissions &
  FeatureNameDescription &
  PermissionByRole;

export function getPermissionState(
  role: ACCOUNT_ROLE,
  _data: FeaturePermission[],
  _permissions: SptPermissions
): SptPermissions {
  if (!hasValue(role) || !hasValue(_data)) {
    return _permissions;
  }

  const data = JSON.parse(JSON.stringify(_data));

  return data.reduce((p: SptPermissions, d: FeaturePermission) => {
    p[d.id] = d[role];
    return p;
  }, <SptPermissions>{});
}

export function getProjectRole(
  p: ProjectEntity,
  uid: string | null | undefined
): ACCOUNT_ROLE {
  if (uid) {
    const isOwner = p.members[uid]?.role?.owner;
    const isCollaborator = p.members[uid]?.role?.collaborator;
    const isReviewer = p.members[uid]?.role?.reviewer;

    // order of least privilege
    if (isReviewer) {
      return ACCOUNT_ROLE.REVIEWER;
    } else if (isCollaborator) {
      return ACCOUNT_ROLE.COLLABORATOR;
    } else if (isOwner) {
      return ACCOUNT_ROLE.PERSONAL;
    } else {
      return ACCOUNT_ROLE.REVIEWER;
    }
  }

  return ACCOUNT_ROLE.USE_ACCOUNT_ROLE;
}
