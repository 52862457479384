import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AccountState} from '@spout/global-web/models';
import {where} from '@firebase/firestore';
import {selectAllProducts} from '../+product/product.selectors';
import {CustomFirestoreService} from '../../firebase';
import {AbstractConnectionService} from '../../firebase/abstract-connection.service';
import {firestoreCollectionQueryConfig} from '../../firebase/firestore-query';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {QueryEngineCache} from '../../services/query-engine-cache';
import {firestorePriceCollection} from '../database-paths';
import {deletePrices, updatePrices, upsertPrices} from './price.actions';
import {Price, Product} from './price.model';
import {priceFeatureKey} from './price.reducer';

@Injectable({
  providedIn: 'root'
})
export class PriceService extends AbstractConnectionService {
  private _priceQueryCache: QueryEngineCache<Price>;

  constructor(
    private _customFirestoreService: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    override _store: Store,
    private _zone: NgZone
  ) {
    super(priceFeatureKey, _connector, _store);

    const queryPriceConfig = firestoreCollectionQueryConfig(
      {
        queryConstrains: [where('active', '==', true)],
        queryMember: false,
        upsertManyAction: (prices: Price[]) => upsertPrices({prices}),
        updateManyAction: (prices: Price[]) =>
          updatePrices({prices: aggregateUpdates(prices)}),
        deleteManyAction: (ids: string[]) => deletePrices({ids}),
        mapFirestoreID: true,
        parentIDProperty: 'productID',
        parentIDValueMap: (p: Product) => p.id,
        disconnectFirestoreOnLogout: false
      },
      _zone,
      _store,
      _customFirestoreService
    );

    const pricePathGenerator = (p: Product) => firestorePriceCollection(p.id);

    this._priceQueryCache = new QueryEngineCache<Price>(
      queryPriceConfig,
      _store,
      selectAllProducts,
      pricePathGenerator,
      'id'
    );

    this._priceQueryCache.onConnect();
  }

  onConnect(user: AccountState) {
    this._connector.keyIsConnected(priceFeatureKey);

    // implement query
    // this._queryService.onConnect(firestorePriceCollection(), <string>user.uid);
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query
    // Unsubscribe to query before calling this
    // this._connector.keyIsDisconnected(priceFeatureKey);
    // this._priceQueryCache.onDisconnect();
  }
}
