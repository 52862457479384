<ng-template>
  <div
    class="ui-popover-panel"
    [id]="panelId"
    [ngClass]="_classList"
    (keydown)="_handleKeydown($event)"
    [@transformPopover]="_panelAnimationState"
    (@transformPopover.start)="_onAnimationStart($event)"
    (@transformPopover.done)="_onAnimationDone($event)"
    tabindex="-1"
    role="popover"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="ariaLabelledby || null"
    [attr.aria-describedby]="ariaDescribedby || null">
    <div class="ui-popover-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
