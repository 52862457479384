import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';
import {SptTransportService} from './spt-transport.service';

enum LATENCY_RESULT_CODE_ENUM {
  NONE = 0,
  SUCCESS = 1,
  AUDIO_PERMISSION_GRANTED = 2,
  MEDIA_STREAM = 3,
  WORKLET_LOADED = 4,
  // Error Codes
  MICROPHONE = 5,
  TOO_LOUD = 6,
  VARIANCE = 7,
  WORKLET_NOT_LOAD = 8,
  TESTING = 9
}

export interface LatencyResultCode {
  NONE: number;
  SUCCESS: number;
  MEDIA_STREAM: number;
  AUDIO_PERMISSION_GRANTED: number;
  WORKLET_LOADED: number;
  MICROPHONE: number;
  TOO_LOUD: number;
  VARIANCE: number;
  WORKLET_NOT_LOAD: number;
  TESTING: number;
}

export const LATENCY_RESULT_CODE: LatencyResultCode = {
  NONE: LATENCY_RESULT_CODE_ENUM.NONE,
  SUCCESS: LATENCY_RESULT_CODE_ENUM.SUCCESS,
  MEDIA_STREAM: LATENCY_RESULT_CODE_ENUM.MEDIA_STREAM,
  AUDIO_PERMISSION_GRANTED: LATENCY_RESULT_CODE_ENUM.AUDIO_PERMISSION_GRANTED,
  WORKLET_LOADED: LATENCY_RESULT_CODE_ENUM.WORKLET_LOADED,
  MICROPHONE: LATENCY_RESULT_CODE_ENUM.MICROPHONE,
  TOO_LOUD: LATENCY_RESULT_CODE_ENUM.TOO_LOUD,
  VARIANCE: LATENCY_RESULT_CODE_ENUM.VARIANCE,
  WORKLET_NOT_LOAD: LATENCY_RESULT_CODE_ENUM.WORKLET_NOT_LOAD,
  TESTING: LATENCY_RESULT_CODE_ENUM.TESTING
};

export enum LATENCY_UI_STATE {
  START = 'start',
  IN_PROGRESS = 'inProgress',
  SAVE = 'save',
  MANUAL_ADJUSTMENT = 'manualAdjustment'
}

export interface LatencySetup {
  code: number;
  inputStream?: MediaStream;
  error?: string;
  audioContext: AudioContext;
}

export interface LatencyMessage {
  state: number;
  latency: number;
}

export interface DetectedLatency {
  detected: number;
}

export interface AdjustedLatency {
  adjusted: number;
}

export class SptLatencyAudioWorkletNode extends AudioWorkletNode {
  latencyResultCode$: BehaviorSubject<number> = new BehaviorSubject<number>(
    LATENCY_RESULT_CODE.NONE
  );
  progressPercent$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentLatency$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  disableSaveLatencyBtn$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  detected$: ReplaySubject<DetectedLatency> =
    new ReplaySubject<DetectedLatency>(1);
  saveLatency$: ReplaySubject<DetectedLatency> =
    new ReplaySubject<DetectedLatency>(1);

  uiState$: BehaviorSubject<LATENCY_UI_STATE> =
    new BehaviorSubject<LATENCY_UI_STATE>(LATENCY_UI_STATE.START);

  constructor(
    private transport: SptTransportService,
    audioContext: AudioContext
  ) {
    super(audioContext, 'latency-worklet-processor', {
      processorOptions: {
        sampleRate: audioContext.sampleRate
      },
      outputChannelCount: [2]
    });

    const that = this;

    this.port.onmessage = event => {
      if (event.data === '___ready___') {
        // this.displayProgress();
      } else {
        that.onMessageFromAudioScope.call(that, event.data);
      }
    };

    this.transport.latency$.pipe(take(1)).subscribe((latency: number) => {
      if (latency > 0) {
        this.currentLatency$.next(latency);
        this.latencyResultCode$.next(LATENCY_RESULT_CODE.SUCCESS);
      } else {
        this.latencyResultCode$.next(LATENCY_RESULT_CODE.NONE);
      }
    });
  }

  onMessageFromAudioScope(message: LatencyMessage) {
    if ((message && message.latency == undefined) || message.latency == null) {
      return;
    }

    console.log(message);

    if (message.latency < 0) {
      this.latencyResultCode$.next(LATENCY_RESULT_CODE.TOO_LOUD);
    } else if (message.state === 11) {
      this.reset();

      if (message.latency < 1) {
        this.latencyResultCode$.next(LATENCY_RESULT_CODE.VARIANCE);
      } else {
        this.progressPercent$.next(100);
        this.detected$.next({detected: message.latency});
        this.currentLatency$.next(message.latency);
        this.disableSaveLatencyBtn$.next(false);
        this.latencyResultCode$.next(LATENCY_RESULT_CODE.SUCCESS);
        this.displaySave();
      }
    } else {
      this.latencyResultCode$.next(LATENCY_RESULT_CODE.TESTING);
      // let percentage = ((parseInt(message.state, 10) - 1) / 10) * 100;
      let percentage = ((message.state - 1) / 10) * 100;

      if (percentage < 1) {
        percentage = 1;
      } else if (percentage > 100) {
        percentage = 100;
      }

      this.progressPercent$.next(percentage);
    }
  }

  reset() {
    this.transport.setTypeTrack();
    this.progressPercent$.next(0);
  }

  displayStart() {
    this.uiState$.next(LATENCY_UI_STATE.START);
    this.progressPercent$.next(0);
  }

  displayProgress() {
    this.uiState$.next(LATENCY_UI_STATE.IN_PROGRESS);
    // this.progressPercent$.next(0);
  }

  displaySave() {
    this.uiState$.next(LATENCY_UI_STATE.SAVE);
  }

  startTest() {
    // console.log('startTest');
    this.displayProgress();
    setTimeout(() => {
      this.port.postMessage('___start___');
    }, 1000);
  }

  displayManualAdjustment() {
    this.uiState$.next(LATENCY_UI_STATE.MANUAL_ADJUSTMENT);
  }

  stopTest() {
    this.port.postMessage('___stop___');
  }
}
