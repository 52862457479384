import {Action, createReducer, on} from '@ngrx/store';
import {
  genresMusicianEntityAdapter,
  GenresMusicianState,
  intialGenreMusicianState
} from '@spout/global-web/models';
import {authLoggedOut} from '../+account/auth.actions';
import {
  addGenresMusician,
  addGenresMusicians,
  clearGenresMusicians,
  deleteGenresMusician,
  deleteGenresMusicians,
  genreMusicianAggregate,
  loadGenresMusicians,
  updateGenresMusician,
  updateGenresMusicians,
  upsertGenresMusician,
  upsertGenresMusicians
} from './genres-musician.actions';

const reducer = createReducer(
  intialGenreMusicianState,
  on(addGenresMusician, (state, action) =>
    genresMusicianEntityAdapter.addOne(action.genresMusician, state)
  ),
  on(upsertGenresMusician, (state, action) =>
    genresMusicianEntityAdapter.upsertOne(action.genresMusician, state)
  ),
  on(addGenresMusicians, (state, action) =>
    genresMusicianEntityAdapter.addMany(action.genresMusicians, state)
  ),
  on(upsertGenresMusicians, (state, action) =>
    genresMusicianEntityAdapter.upsertMany(action.genresMusicians, state)
  ),
  on(updateGenresMusician, (state, action) =>
    genresMusicianEntityAdapter.updateOne(action.genresMusician, state)
  ),
  on(updateGenresMusicians, (state, action) =>
    genresMusicianEntityAdapter.updateMany(action.genresMusicians, state)
  ),
  on(deleteGenresMusician, (state, action) =>
    genresMusicianEntityAdapter.removeOne(action.id, state)
  ),
  on(deleteGenresMusicians, (state, action) =>
    genresMusicianEntityAdapter.removeMany(action.ids, state)
  ),
  on(loadGenresMusicians, (state, action) =>
    genresMusicianEntityAdapter.setAll(action.genresMusicians, state)
  ),
  on(clearGenresMusicians, state =>
    genresMusicianEntityAdapter.removeAll(state)
  ),
  on(genreMusicianAggregate, (state, action) => {
    let _state = genresMusicianEntityAdapter.upsertMany(
      action.aggregate.added,
      state
    );
    _state = genresMusicianEntityAdapter.upsertMany(
      action.aggregate.modified,
      _state
    );
    _state = genresMusicianEntityAdapter.removeMany(
      action.aggregate.removed,
      _state
    );

    return _state;
  }),
  on(authLoggedOut, (state, action) => {
    return genresMusicianEntityAdapter.removeAll(state);
  })
);

export function genreMusicianReducer(
  state: GenresMusicianState | undefined,
  action: Action
) {
  return reducer(state, action);
}
