import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpCreateMixContentComponent} from './help-create-mix-content/help-create-mix-content.component';
import {HelpCreateMixComponent} from './help-create-mix.component';

@NgModule({
  declarations: [HelpCreateMixComponent, HelpCreateMixContentComponent],
  exports: [HelpCreateMixComponent, HelpCreateMixContentComponent],
  entryComponents: [HelpCreateMixComponent],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpCreateMixModule {}
