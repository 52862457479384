import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AudioFileMetaDataEntity, ProjectEntity} from '@spout/global-any/models';
import {firestoreFilesPath, firestoreProjectsPath} from '@spout/global-web/fns';
import {AccountState, AccountStateConnect} from '@spout/global-web/models';
import {
  DocumentChange,
  onSnapshot,
  query,
  QuerySnapshot,
  where
} from '@firebase/firestore';
import {BehaviorSubject} from 'rxjs';
import {CustomFirestoreService} from '../firebase/custom-firestore.service';
import {updateTotalCloudStorageUsed} from './account.actions';

@Injectable({
  providedIn: 'root'
})
export class TrackCloudStorageService {
  private onSnapshotOwnerUnsubscribe: (() => void) | undefined;

  private fileCollectionSubs: {
    [projectId: string]: () => void;
  } = {};

  private files: {[id: string]: {id: string; fileSize: number}} = {};
  private timer: any;

  data$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private sptFirestore: CustomFirestoreService,
    private zone: NgZone,
    private store: Store<AccountStateConnect>
  ) {}

  connect(user: AccountState) {
    const that = this;

    const _refOwner = this.sptFirestore.collectionRef(firestoreProjectsPath());
    const q = query(_refOwner, where('ownerUIDs', 'array-contains', user.uid));

    if (this.onSnapshotOwnerUnsubscribe) {
      this.onSnapshotOwnerUnsubscribe();
    }

    this.onSnapshotOwnerUnsubscribe = onSnapshot(
      q,
      (snapshot: QuerySnapshot) => {
        this.unsubscribeFileCollections();

        const projectsChanges: DocumentChange[] = snapshot.docChanges();
        let projectCount = 0;

        projectsChanges.forEach((r: DocumentChange) => {
          const project: ProjectEntity = <ProjectEntity>r.doc.data();

          this.fileCollectionSubs[project.id] = onSnapshot(
            this.sptFirestore.collectionRef(firestoreFilesPath(project)),
            (snapshot: QuerySnapshot) => {
              const filesChanges: DocumentChange[] = snapshot.docChanges();
              let fileCount = 0;

              filesChanges.forEach((f: DocumentChange) => {
                fileCount++;
                const file: AudioFileMetaDataEntity = <AudioFileMetaDataEntity>(
                  f.doc.data()
                );

                if (f.type === 'removed') {
                  delete this.files[file.id];
                } else {
                  this.files[file.id] = {
                    id: file.id,
                    fileSize:
                      file.fileSize !== undefined && file.fileSize !== null
                        ? file.fileSize
                        : 0
                  };
                }

                if (fileCount === filesChanges.length) {
                  // Project collection finished
                  that.aggregateFiles.apply(that);
                  projectCount++;
                }
              });
            }
          );
        });
      }
    );
  }

  disconnect() {
    if (this.onSnapshotOwnerUnsubscribe) {
      this.onSnapshotOwnerUnsubscribe();
    }

    this.unsubscribeFileCollections();
  }

  private aggregateFiles() {
    const total = Object.values(this.files).reduce(
      (total: number, {fileSize}: {id: string; fileSize: number}) =>
        total + fileSize,
      0
    );

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.zone.run(() => {
        this.store.dispatch(
          updateTotalCloudStorageUsed({totalCloudStorageUsed: total})
        );
      });
    }, 1000);
  }

  private unsubscribeFileCollections() {
    if (Object.keys(this.fileCollectionSubs).length > 0) {
      Object.values(this.fileCollectionSubs).forEach((sub: () => void) => {
        if (sub) {
          sub();
        }
      });
    }

    this.fileCollectionSubs = {};
  }
}
