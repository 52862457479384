import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {DeleteAccountComponent} from './delete-account.component';

@NgModule({
  declarations: [DeleteAccountComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  exports: [DeleteAccountComponent]
})
export class DeleteAccountModule {}
