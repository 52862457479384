import {Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {WINDOW} from '@spout/global-web/utils';

const logoPath = 'assets/social/';

export const SVG_LOGO = {
  DISCORD_DARK: `${logoPath}Discord-Logo-Black.svg`,
  DISCORD_LIGHT: `${logoPath}Discord-Logo-White.svg`,
  DISCORD_COLOR: `${logoPath}Discord-Logo-Color.svg`,

  FACEBOOK_DARK: `${logoPath}f_logo_RGB-Black_58.png`,
  FACEBOOK_LIGHT: `${logoPath}f_logo_RGB-White_58.png`,
  FACEBOOK_COLOR: `${logoPath}f_logo_RGB-Blue_58.png`,

  INSTAGRAM_DARK: `${logoPath}Instagram_Glyph_Black.svg`,
  INSTAGRAM_LIGHT: `${logoPath}Instagram_Glyph_White.svg`,
  INSTAGRAM_COLOR: `${logoPath}Instagram_Glyph_Gradient_RGB.svg`,

  SLACK_DARK: `${logoPath}Slack_Mark_Monochrome_Black.svg`,
  SLACK_LIGHT: `${logoPath}Slack_Mark_Monochrome_White.svg`,
  SLACK_COLOR: `${logoPath}Slack_Mark.svg`,

  TWITTER_DARK: `${logoPath}Twitter_Logo_black.svg`,
  TWITTER_LIGHT: `${logoPath}Twitter_Logo_white.svg`,
  TWITTER_COLOR: `${logoPath}Twitter_Logo_blue.svg`,

  TIKTOK_DARK: `${logoPath}tiktok_black.svg`,
  TIKTOK_LIGHT: `${logoPath}tiktok_white.svg`,
  TIKTOK_COLOR: `${logoPath}tiktok_color.svg`
};

export type SocialDesign = 'marketing' | 'footer';

@Component({
  selector: 'spt-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'spt-social'
  }
})
export class SocialComponent {
  logos = SVG_LOGO;

  @Input() design: SocialDesign = 'marketing';

  constructor(
    @Inject(WINDOW) private window: Window,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'DISCORD_DARK',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.DISCORD_DARK)
    );
    iconRegistry.addSvgIcon(
      'DISCORD_LIGHT',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.DISCORD_LIGHT)
    );
    iconRegistry.addSvgIcon(
      'DISCORD_COLOR',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.DISCORD_COLOR)
    );
    // iconRegistry.addSvgIcon(
    //   'FACEBOOK_DARK',
    //   sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.FACEBOOK_DARK)
    // );
    // iconRegistry.addSvgIcon(
    //   'FACEBOOK_LIGHT',
    //   sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.FACEBOOK_LIGHT)
    // );
    // iconRegistry.addSvgIcon(
    //   'FACEBOOK_COLOR',
    //   sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.FACEBOOK_COLOR)
    // );
    iconRegistry.addSvgIcon(
      'INSTAGRAM_DARK',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.INSTAGRAM_DARK)
    );
    iconRegistry.addSvgIcon(
      'INSTAGRAM_LIGHT',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.INSTAGRAM_LIGHT)
    );
    iconRegistry.addSvgIcon(
      'INSTAGRAM_COLOR',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.INSTAGRAM_COLOR)
    );
    iconRegistry.addSvgIcon(
      'SLACK_DARK',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.SLACK_DARK)
    );
    iconRegistry.addSvgIcon(
      'SLACK_LIGHT',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.SLACK_LIGHT)
    );
    iconRegistry.addSvgIcon(
      'SLACK_COLOR',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.SLACK_COLOR)
    );
    iconRegistry.addSvgIcon(
      'TWITTER_DARK',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.TWITTER_DARK)
    );
    iconRegistry.addSvgIcon(
      'TWITTER_LIGHT',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.TWITTER_LIGHT)
    );
    iconRegistry.addSvgIcon(
      'TWITTER_COLOR',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.TWITTER_COLOR)
    );
    iconRegistry.addSvgIcon(
      'TIKTOK_DARK',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.TIKTOK_DARK)
    );
    iconRegistry.addSvgIcon(
      'TIKTOK_LIGHT',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.TIKTOK_LIGHT)
    );
    iconRegistry.addSvgIcon(
      'TIKTOK_COLOR',
      sanitizer.bypassSecurityTrustResourceUrl(SVG_LOGO.TIKTOK_COLOR)
    );
  }

  openEmail() {
    this.window.open('mailto:inquiries@spoutsoftware.com', '_blank');
  }

  openDiscord() {
    this.window.open('https://discord.gg/jSd7vxY9Cz', '_blank');
  }

  openSlack() {
    this.window.open(
      'https://join.slack.com/t/spoutstudio/shared_invite/zt-1anaitm9u-ETA_U3p9rMNUjYtYTD6Nwg',
      '_blank'
    );
  }

  openFacebook() {
    this.window.open('https://www.facebook.com/spoutsoftware/', '_blank');
  }

  openInstagram() {
    this.window.open('https://www.instagram.com/spoutsoftware/', '_blank');
  }

  openTwitter() {
    this.window.open(
      'https://twitter.com/spoutsoftware?t=NVBmA93CWiperkGt9ovESw&s=01',
      '_blank'
    );
  }

  openTikTok() {
    this.window.open('https://www.tiktok.com/t/ZTdTyEdCR/', '_blank');
  }
}
