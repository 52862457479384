import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  AudioFileMetaData,
  AudioFileMetaDataEntity
} from '@spout/global-any/models';
import {firestoreFileByIdPath} from '@spout/global-web/fns';

import {DocumentReference, WriteBatch} from '@firebase/firestore';

import {Observable, Observer} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {Exists, CustomFirestoreService} from '../firebase';
import {selectAudioMetaDataEntityById} from './audio-metadata-storage.selectors';

@Injectable({
  providedIn: 'root'
})
export class AudioFileMetaDataFirestoreService {
  constructor(
    private sptFirestore: CustomFirestoreService,
    private store: Store
  ) {}

  createAudioFileConfig(file: Partial<AudioFileMetaDataEntity>) {
    return new Observable((observer: Observer<any>) => {
      // CREATE PROJECT IN FIRESTORE
      this.sptFirestore
        .setDocIfNotExist<AudioFileMetaDataEntity>(
          firestoreFileByIdPath(file),
          file
        )
        .subscribe(
          (r: Exists<AudioFileMetaDataEntity>) => {
            // DOES NOT DISPATCH ACTION
            // THIS DATA GOES NO WHERE
            observer.next(r.data);
          },
          error => {
            observer.error(error);
          }
        );
    });
  }

  updateAudioFileMetaData(
    file: AudioFileMetaData
  ): Observable<Exists<AudioFileMetaData>> {
    const that = this;
    return this.store.pipe(
      select(selectAudioMetaDataEntityById({id: file.id})),
      filter((f: AudioFileMetaDataEntity | undefined) => {
        return f !== undefined && f !== null;
      }),
      take(1),
      // unfreezePipe(),
      switchMap((fileEntity: AudioFileMetaDataEntity | undefined) => {
        const _file = {
          ...fileEntity,
          ...file
        };

        return that.sptFirestore.update<AudioFileMetaData>(
          firestoreFileByIdPath(_file),
          _file
        );
      })
    );
  }

  batchUpdateAudioFileMetaData(
    toUpdate: Partial<AudioFileMetaData>[]
  ): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      toUpdate.forEach((file: Partial<AudioFileMetaData>) => {
        const doc: DocumentReference = this.sptFirestore.docRef(
          firestoreFileByIdPath(file)
        );
        batch.update(doc, file);
      });

      batch.commit().then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  batchSetAudioFileMetaData(
    toSet: Partial<AudioFileMetaData>[]
  ): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      toSet.forEach((file: Partial<AudioFileMetaData>) => {
        const doc: DocumentReference = this.sptFirestore.docRef(
          firestoreFileByIdPath(file)
        );

        batch.set(doc, file);
      });

      batch.commit().then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  batchDeleteAudioFileMetaData(toDelete: AudioFileMetaData[]): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      toDelete.forEach((file: AudioFileMetaData) => {
        const doc: DocumentReference = this.sptFirestore.docRef(
          firestoreFileByIdPath(file)
        );

        batch.delete(doc);
      });

      batch.commit().then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
}
