import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  ChatEntity,
  HelpModel,
  MIXERRIGHTDRAWER_FEATURE_KEY,
  MixerRightDrawerState,
  MixerRightDrawerTypes
} from '@spout/global-web/models';

export const getMixerRightDrawerState =
  createFeatureSelector<MixerRightDrawerState>(MIXERRIGHTDRAWER_FEATURE_KEY);

// Lookup the 'MixerRightDrawer' feature state managed by NgRx
export const opened = (state: MixerRightDrawerState) => state.opened;

export const getMixerRightDrawerOpened = createSelector(
  getMixerRightDrawerState,
  opened
);

export const getMixerRightDrawerView = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState) => state.view
);

export const getInviteIsActive = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState) =>
    state.opened && state.view === MixerRightDrawerTypes.INVITE
);

export const getChatIsActive = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState) =>
    state.opened && state.view === MixerRightDrawerTypes.CHAT_TRACK
);

export const getHelpIsActive = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState) =>
    state.opened && state.view === MixerRightDrawerTypes.HELP
);

export const getNotificationIsActive = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState): boolean =>
    state.opened && state.view === MixerRightDrawerTypes.NOTFIICATION
);

export const openRightDrawerConversation = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState): ChatEntity | null =>
    state.openConversationInRightDrawer
);

export const openTrackConversation = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState): ChatEntity | null =>
    state.openConversationInTrack
);

export const getHelpView = createSelector(
  getMixerRightDrawerState,
  (state: MixerRightDrawerState): HelpModel => state.helpView
);
