import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import {BehaviorSubject, Observable, Observer, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {SptTransportService} from '../spt-transport.service';
import {SptVolumeTranslateService} from './spt-volume-translate.service';

export interface InstrumentProps {
  engine: string;
  steps?: boolean[];
  handleClick?: (engine: string, steps: boolean[]) => void;
  selected?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SptMetronomeService {
  private _volume = 25;
  private _volumeSub: Subscription = Subscription.EMPTY;
  private _bpmSub: Subscription = Subscription.EMPTY;
  private _loop: any; // this.toneService.Tone.Loop
  private _isConnectedToMaster = false;

  bpm$: BehaviorSubject<number> = new BehaviorSubject(120);
  volume$: BehaviorSubject<number> = new BehaviorSubject(25);
  volumeState$ = this.volume$.pipe(
    map((volume: number) => {
      return {
        mute: volume === 0,
        volume
      };
    })
  );
  synth: any;
  isPlaying$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // private ctx: AudioContext;
  // private sound: InstrumentEngine;
  // private loopId: number;
  // private loop: Loop;

  constructor(
    private store: Store,
    private volumeService: SptVolumeTranslateService,
    private sptTransportService: SptTransportService
  ) {
    // const synth = new this.toneService.Tone.Synth().toMaster();
    // const synth = new this.toneService.Tone.Synth();

    //play a middle 'C' for the duration of an 8th note

    this.bpm$.subscribe((bpm: number) => {
      sptTransportService.bpm = bpm;
    });
  }

  connect() {
    const that = this;

    if (that.synth) {
      that.synth.dispose();
    }

    //kickdrum generated by MembraneSynth
    // that.synth = kick();
    // const _kickFilter = kickFilter();
    // _kickFilter.toDestination();
    // that._isConnectedToMaster = true;
    //
    // that.synth.connect(_kickFilter);

    // that.synth.volume.value = this.volumeService.percentToDecibel(that._volume);

    // that._loop = new Loop(function (time) {
    //   that.synth.triggerAttackRelease('C3', '4n', time);
    // }, '4n');

    // that._loop.start(0);

    if (!that._volumeSub.closed) {
      that._volumeSub.unsubscribe();
    }

    // Metronome Volume
    // that._volumeSub = that.volume$.subscribe((volume: number) => {
    //   if (volume) {
    //     _kickFilter.toDestination();
    //   } else {
    //     _kickFilter.disconnect();
    //   }
    //
    //   if (that.synth) {
    //     that.synth.volume.value = this.volumeService.percentToDecibel(volume);
    //   }
    // });
  }

  connectPipe(): (source: Observable<any>) => Observable<any> {
    const that = this;
    return (source: Observable<any>) => {
      return new Observable((observer: Observer<any>) => {
        return source.subscribe({
          next(x) {
            that.connect.apply(that);

            observer.next(x);
          },
          error(err) {
            observer.error(err);
          },
          complete() {
            observer.complete();
          }
        });
      });
    };
  }

  disconnect() {
    const that = this;

    that._volumeSub.unsubscribe();
    that._bpmSub.unsubscribe();

    if (that.synth) {
      that.synth.dispose();
      that.synth = null;
    }
    if (that._loop) {
      (<any>that._loop).dispose();
      that._loop = null;
    }

    // that.isPlaying$.next(false);
  }

  disconnectPipe(): (source: Observable<any>) => Observable<any> {
    const that = this;
    return (source: Observable<any>) => {
      return new Observable((observer: Observer<any>) => {
        return source.subscribe({
          next(x) {
            that.disconnect();

            observer.next(x);
          },
          error(err) {
            observer.error(err);
          },
          complete() {
            observer.complete();
          }
        });
      });
    };
  }

  stop() {
    if (!this.sptTransportService.isStopped) {
      this._volumeSub.unsubscribe();

      if (this.synth) {
        this.synth.dispose();
        this.synth = null;
      }
      if (this._loop) {
        (<any>this._loop).dispose();
        this._loop = null;
      }

      // this.isPlaying$.next(false);
    }
  }
}
