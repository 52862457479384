import {Update} from '@ngrx/entity';
import {Action, Store} from '@ngrx/store';
import {CustomFirestoreService} from './custom-firestore.service';
import {
  DocumentChange,
  DocumentData,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
  QueryConstraint
} from '@firebase/firestore';
import {AggregateFirebaseSnapshotChanges} from './firebase.model';
import {
  addParentIDToAggregateDocChanges,
  aggregateDocChanges
} from './aggregate-doc-changes';
import {NgZone} from '@angular/core';

export interface FirestoreQueryConfig<T, Parent = any> {
  queryConstrains?: QueryConstraint[];
  queryMember: boolean;

  // Store
  upsertManyAction?: (payload: T[]) => Action;
  updateManyAction?: (payload: T[]) => Action;
  deleteManyAction?: (ids: string[]) => Action;
  updateManyAggregatedAction?: (payload: Update<T>[]) => Action;
  isRetrievedFromFirestoreAction?: () => Action;

  // Component Store
  upsertManyUpdater?: (payload: T[]) => void;
  updateManyUpdater?: (payload: T[]) => void;
  deleteManyUpdater?: (ids: string[]) => void;
  isRetrievedFromFirestoreUpdater?: () => void;

  updateManyAggregatedSelectedId?: (a: T) => string | number;

  // ID of firestore node becomes object id
  mapFirestoreID?: boolean;

  // Just logging
  logUpsert?: boolean;

  /**
   * When user logs out, do not disconnect from Firestore.
   */
  disconnectFirestoreOnLogout?: boolean;

  /**
   * If sub-collection, add parent id property
   * and parent id value passed on the parent object
   */
  parentIDProperty?: string | null;
  parentIDValueMap?: (p: Parent | any) => string | number | null;
}

export interface QueryModel<T, Parent = any> {
  onConnect(
    path: string,
    parentObject: Parent | any,
    uid: string | null,
    queryConstraints: QueryConstraint[]
  ): void;
  onDisconnect(): void;
  process(
    snapshot: DocumentChange<DocumentData>[],
    parentIDProperty: string | null | undefined,
    parentIDValue: string | number | null
  ): void;
}

export class FirestoreCollectionQuery<T, Parent = any>
  implements QueryModel<T>
{
  private _firebaseSub: (() => void) | undefined | null = null;

  constructor(
    private _config: FirestoreQueryConfig<T>,
    private _zone: NgZone,
    private _store: Store,
    private _customFirestore: CustomFirestoreService
  ) {}

  onConnect(
    path: string,
    parentObject: Parent | any = null,
    uid: string | null = null,
    queryConstraints: QueryConstraint[] = []
  ) {
    const that = this;

    if (this._config.queryMember && !uid) {
      return;
    }

    if (
      this._config.disconnectFirestoreOnLogout === undefined ||
      this._config.disconnectFirestoreOnLogout === null
    ) {
      this._config.disconnectFirestoreOnLogout = true;
    }

    if (this._firebaseSub) {
      this._firebaseSub();
    }

    const _pathRef = this._customFirestore.collectionRef(path);

    let _queryRef = undefined;
    let _queryConstraints: QueryConstraint[] = [];

    if (this._config.queryMember && uid) {
      _queryConstraints = [where('memberUIDs', 'array-contains', uid)];
    }

    if (queryConstraints && queryConstraints.length > 0) {
      _queryConstraints = [..._queryConstraints, ...queryConstraints];
    }

    if (
      this._config.queryConstrains &&
      this._config.queryConstrains.length > 0
    ) {
      _queryConstraints = [
        ..._queryConstraints,
        ...this._config.queryConstrains
      ];
    }

    if (_queryConstraints && _queryConstraints.length > 0) {
      _queryRef = query(_pathRef, ..._queryConstraints);
    }

    // if (this._config.queryMember && uid) {
    //   _queryRef = query(_pathRef, where('memberUIDs', 'array-contains', uid));
    // }

    let parentIDValue: string | number | null = null;

    if (
      this._config.parentIDProperty &&
      this._config.parentIDValueMap &&
      parentObject
    ) {
      parentIDValue = this._config.parentIDValueMap(parentObject);
    }

    this._firebaseSub = onSnapshot(
      _queryRef ? _queryRef : _pathRef,
      // .where('fileUploaded', '==', true)
      (snapshot: QuerySnapshot) => {
        that.process.apply(that, [
          snapshot.docChanges(),
          this._config.parentIDProperty,
          parentIDValue
        ]);
      },
      () => {
        /* noop */
      },
      () => {
        /* noop */
      }
    );
  }

  onDisconnect(uid: string | null = null) {
    if (this._config.disconnectFirestoreOnLogout && this._firebaseSub) {
      this._firebaseSub();
      this._firebaseSub = null;
    }
  }

  process(
    snapshot: DocumentChange<DocumentData>[],
    parentIDProperty: string | null | undefined,
    parentIDValue: string | number | null
  ) {
    const that = this;
    const mapFirestoreID: boolean =
      this._config.mapFirestoreID !== undefined &&
      this._config.mapFirestoreID !== null
        ? this._config.mapFirestoreID
        : false;
    let aggregate: AggregateFirebaseSnapshotChanges<T> = aggregateDocChanges<T>(
      snapshot,
      'id',
      mapFirestoreID
    );

    if (parentIDProperty) {
      aggregate = addParentIDToAggregateDocChanges(
        aggregate,
        parentIDProperty,
        parentIDValue
      );
    }

    that._zone.run(() => {
      if (aggregate.added.length) {
        if (this._config.upsertManyAction) {
          if (this._config.logUpsert) {
            console.log(JSON.stringify(aggregate.added[0], null, 2));
          }

          that._store.dispatch(this._config.upsertManyAction(aggregate.added));
        }

        if (this._config.upsertManyUpdater) {
          this._config.upsertManyUpdater(aggregate.added);
        }
      }

      if (aggregate.modified.length) {
        if (this._config.updateManyAction) {
          that._store.dispatch(
            this._config.updateManyAction(aggregate.modified)
          );
        }

        if (this._config.updateManyUpdater) {
          this._config.updateManyUpdater(aggregate.modified);
        }

        if (this._config.updateManyAggregatedAction) {
          this._config.updateManyAggregatedAction(
            this._aggregateUpdates<T>(
              aggregate.modified,
              this._config.updateManyAggregatedSelectedId
            )
          );
        }
      }

      if (aggregate.removed.length) {
        if (this._config.deleteManyAction) {
          that._store.dispatch(
            this._config.deleteManyAction(aggregate.removed)
          );
        }

        if (this._config.deleteManyUpdater) {
          this._config.deleteManyUpdater(aggregate.removed);
        }
      }

      if (this._config.isRetrievedFromFirestoreAction) {
        that._store.dispatch(this._config.isRetrievedFromFirestoreAction());
      }

      if (this._config.isRetrievedFromFirestoreUpdater) {
        this._config.isRetrievedFromFirestoreUpdater();
      }
    });
  }

  private _aggregateUpdates<T>(
    updates: T[],
    selectID?: (a: T) => string | number
  ): Update<T>[] {
    return updates.map((i: T) => {
      let id: string | number;
      if (selectID !== null && selectID !== undefined) {
        id = selectID(i);
      } else {
        id = (<any>i)['id'];
      }

      return <Update<T>>{
        id,
        changes: {...i}
      };
    });
  }
}

export interface FirestoreCollectionQueryConfig<T> {
  createFirestoreCollectionQuery: () => FirestoreCollectionQuery<T>;
}

export function firestoreCollectionQueryConfig<T>(
  _config: FirestoreQueryConfig<T>,
  _zone: NgZone,
  _store: Store,
  _customFirestore: CustomFirestoreService
): FirestoreCollectionQueryConfig<T> {
  return {
    createFirestoreCollectionQuery: () => {
      return new FirestoreCollectionQuery(
        _config,
        _zone,
        _store,
        _customFirestore
      );
    }
  };
}
