import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  decibelToPercentFn,
  percentToDecibelFn,
  ScaleFunction
} from '@spout/global-web/fns';
import {
  createSliderConfig,
  VOLUME_SLIDER_CONFIG,
  VolumeSliderConfig
} from '@spout/global-web/models';
import {Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SptVolumeTranslateService {
  config$: ReplaySubject<VolumeSliderConfig> =
    new ReplaySubject<VolumeSliderConfig>(1);
  notShowDecibels$: Observable<boolean> = this.config$.pipe(
    map((c: VolumeSliderConfig) => c.showDecibels === false)
  );
  sliderMin$: Observable<number> = this.config$.pipe(
    map((c: VolumeSliderConfig) => c.sliderMin)
  );
  sliderMax$: Observable<number> = this.config$.pipe(
    map((c: VolumeSliderConfig) => c.sliderMax)
  );

  constructor(private store: Store) {
    this.config(createSliderConfig(VOLUME_SLIDER_CONFIG));
    this.config$.next(createSliderConfig(VOLUME_SLIDER_CONFIG));
  }

  private _decibelToPercent: ScaleFunction = (v: number) => v;
  private _percentToDecibel: ScaleFunction = (v: number) => v;

  config(c: VolumeSliderConfig): void {
    this._decibelToPercent = decibelToPercentFn(c);
    this._percentToDecibel = percentToDecibelFn(c);
  }

  decibelToPercent(v: number): number {
    return this._decibelToPercent.call(this, v);
  }

  percentToDecibel(v: number): number {
    return this._percentToDecibel.call(this, v);
  }

  /**
   * Calculates volume using track and master volumes.
   * @param trackVolume
   * @param masterVolume
   */
  calculatedGainWithMaster(trackVolume: number, masterVolume: number): number {
    const _masterVolume =
      masterVolume !== null && masterVolume !== undefined ? masterVolume : 100;
    const _percentMasterVolume = (_masterVolume / 100) * trackVolume;
    return _percentMasterVolume / 100;
  }

  percentToDecibelWithMaster(v: number, masterVolume: number): number {
    const _masterVolume =
      masterVolume !== null && masterVolume !== undefined ? masterVolume : 100;
    const _percentMasterVolume = (_masterVolume / 100) * v;
    // const _percentMasterVolume = _masterVolume * v;
    // console.log(v, masterVolume, _percentMasterVolume, this._percentToDecibel.call(this, v),
    // this._percentToDecibel.call(this, _percentMasterVolume));
    return this._percentToDecibel.call(this, _percentMasterVolume);
  }
}
