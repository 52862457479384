import {createAction, props} from '@ngrx/store';
import {AccountState} from '@spout/global-web/models';

/**
 * User information comes from firestore snapshotChanges
 */
export const accountLoadedFromSnapshotChanges = createAction(
  '[Account] Account Loaded from snapshotChanges',
  props<{
    payload: AccountState;
  }>()
);

/**
 * User information comes onAuthStateChanged of firebase auth
 */
export const accountLoadedFromAuthStateChange = createAction(
  '[Account] Account Loaded From onAuthStateChanged',
  props<{
    payload: AccountState;
  }>()
);

export const accountLoaded = createAction(
  '[Account] Account Loaded',
  props<{
    account: AccountState;
  }>()
);

export const accountRole = createAction(
  '[Account] Account Role',
  props<{role: string}>()
);

export const accountUpdated = createAction(
  '[Account] Account Updated',
  props<{account: AccountState}>()
);

export const accountLoadError = createAction(
  '[Account] Account Load Error',
  props<{payload: any}>()
);

export const accountSaveFirebase = createAction(
  '[Account] Account Save Firebase',
  props<{payload: Partial<AccountState>}>()
);

export const accountSaveBetaTestCodeFirebase = createAction(
  '[Account] Account Save Beta Test Code Firebase',
  props<{payload: Partial<AccountState>}>()
);

// export const addSubscriptionIdToAccount = createAction(
//   '[Account] Add Subscription ID To Account',
//   props<{subscriptionId: string}>()
// );

// export const addSubscriptionToAccount = createAction(
//   '[Account] Add Subscription Account',
//   props<{subscription: CurrentSubscription}>()
// );
//
// export const addSubscriptionItemToAccount = createAction(
//   '[Account] Add Subscription SubscriptionItem To Account',
//   props<{subscription: Subscription}>()
// );
//
// export const addSubscriptionsToAccount = createAction(
//   '[Account] Add Subscriptions To Account',
//   props<{subscriptions: Subscription[]}>()
// );
// export const clearSubscriptions = createAction('[Account] Clear Subscriptions');

/*
export const loadPermissionsData = createAction(
  '[ Account ] Load permissions',
  props<{featurePermissions: FeaturePermission[]}>()
);
*/

// export const deletePromoCode = createAction('[ Account ] Delete Promo Code');

export const updateTotalCloudStorageUsed = createAction(
  '[ Account ] Total Cloud Storage Used',
  props<{totalCloudStorageUsed: number}>()
);
