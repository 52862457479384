import {Inject, Injectable, NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {createUint8ArrayExtensionFromBlob$} from '@spout/global-any/fns';
import {
  FileUint8ArrayType,
  GoogleMediaTrackConstraints,
  RenderMixData,
  SaveExportedMixAsFile
} from '@spout/global-any/models';
import {
  LoadPlayerAudioWorklet,
  RecordType,
  SHARED_BUFFER_RECORD_WORKER_FACTORY,
  TrackEntityAndAudioFileMetaDataEntity
} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {
  selectGoogMediaTrackConstraints,
  selectMediaDeviceInfo,
  selectMemoizedExportMergeCompressorAttack,
  selectMemoizedExportMergeCompressorKnee,
  selectMemoizedExportMergeCompressorRatio,
  selectMemoizedExportMergeCompressorRelease,
  selectMemoizedExportMergeCompressorThreshold,
  selectMemoizedRecordInputCompressorAttack,
  selectMemoizedRecordInputCompressorKnee,
  selectMemoizedRecordInputCompressorRatio,
  selectMemoizedRecordInputCompressorRelease,
  selectMemoizedRecordInputCompressorThreshold,
  selectMicrophoneGain
} from '../+device-storage/device-storage.selectors';
import {AudioFileSaveService} from '../+device-storage/services/audio-file-save.service';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {convertBlobToParsedRecordExport} from '../../../../fns/src/lib/audio';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {getAudioDeviceList} from './helpers/input-device';
import {SongMetricsService} from './services/song-metrics.service';
import {DetectedLatency} from './spt-latency.audioworklet';
import {SptRecorder} from './spt-recorder';
import {masterFullRewindEffect} from './spt-tone-transport-control.actions';
import {OutputNodes, SptTransportService} from './spt-transport.service';

@Injectable({
  providedIn: 'root'
})
export class SptRecorderService {
  devices$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject<
    MediaDeviceInfo[]
  >([]);
  private _onDestroy$: Subject<boolean> = new Subject();
  private workerSub: Subscription = Subscription.EMPTY;
  private depsSub: Subscription = Subscription.EMPTY;
  private workletAdded = false;
  private CONFIG = {
    // RECORDER_FACTORY_ID: SCRIPT_PROCESSOR_WORKER_FACTORY,
    RECORDER_FACTORY_ID: SHARED_BUFFER_RECORD_WORKER_FACTORY
  };

  sptRecorder$: ReplaySubject<SptRecorder>;
  microphoneError$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  detected$: Observable<number>;

  /**
   * Used for meta data information
   *
   * // Provided by libs/mixer-browser-desktop/theme/src/libspt-player/spt-record/spt-record.component.ts
   */
  audioFileEntity$: ReplaySubject<TrackEntityAndAudioFileMetaDataEntity>;

  constructor(
    private dss: DynamicStoreService,
    private store: Store,
    private zone: NgZone,
    private transport: SptTransportService,
    private deviceStorageService: DeviceStorageService,
    private metricsService: SongMetricsService,
    private fileSaveService: AudioFileSaveService,
    @Inject(SHARED_BUFFER_RECORD_WORKER_FACTORY)
    private sharedBufferFactory: () => Worker
  ) {
    const that = this;

    this.audioFileEntity$ =
      new ReplaySubject<TrackEntityAndAudioFileMetaDataEntity>(1);
    this.sptRecorder$ = new ReplaySubject<SptRecorder>(1);
    this.detected$ = this.sptRecorder$.pipe(
      mergeMap((recorder: SptRecorder) =>
        recorder.latencyWorkletNode.detected$.pipe(
          map((value: DetectedLatency) => value.detected)
        )
      )
    );

    navigator.permissions
      .query(<any>{name: 'microphone'})
      .then(function (result) {
        if (result.state === 'granted') {
          console.log('MICROPHONE PERMISSION GRANTED');
          that._connectInputs();
        } else if (result.state === 'prompt') {
          console.log('MICROPHONE PERMISSION PROMPT');
        } else if (result.state === 'denied') {
          console.log('MICROPHONE PERMISSION DENIED');
          that.microphoneError$.next(result.state);
        }
        result.onchange = function () {
          /* noop */
        };
      });

    this.transport.audioContextDistinct$
      .pipe(this.createRecorderWorklet(), distinctUntilKeyChanged('id'))
      .subscribe((sharedBufferWorker: SptRecorder) => {
        this.sptRecorder$.next(sharedBufferWorker);
        this.watchInputs(sharedBufferWorker);
      });
  }

  private _connectInputs() {
    // TODO Record Microphone or Export song
    combineLatest([
      this.sptRecorder$,
      this.transport.outputNodes$.pipe(distinctUntilKeyChanged('recordType'))
    ])
      .pipe(
        mergeMap(([recorder, o]) => {
          // console.log(recorder, o);
          return this.getRecordingInputStream().pipe(
            map((inputStream): [SptRecorder, OutputNodes, MediaStream] => {
              // console.log(inputStream);
              return [recorder, o, inputStream];
            })
          );
        })
      )
      .subscribe(
        ([recorder, o, inputStream]: [
          SptRecorder,
          OutputNodes,
          MediaStream
        ]) => {
          // recorder.disconnectAudioStream();

          if (o.recordType === RecordType.TRACK) {
            recorder.connectRecorder(inputStream);
          }

          if (o.recordType === RecordType.MIX_MASTER) {
            recorder.connectMixMerger(o);
          }

          if (o.recordType === RecordType.LATENCY_TEST) {
            recorder.connectLatencyTester(inputStream);
          }
        }
      );
  }

  refreshAudioDeviceList(): Observable<MediaDeviceInfo[]> {
    return getAudioDeviceList().pipe(
      tap((devices: MediaDeviceInfo[]) => {
        this.devices$.next(devices);
      })
    );
  }

  private watchInputs(sharedBufferWorker: SptRecorder) {
    this.store
      .pipe(
        select(selectMicrophoneGain),
        distinctUntilChanged(),
        sharedBufferWorker.setGain()
      )
      .subscribe((gain: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorThreshold()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorThreshold()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorKnee()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorKnee()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorRatio()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorRatio()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorAttack()),
        distinctUntilChanged(),
        sharedBufferWorker.setInputCompressorAttack()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedRecordInputCompressorRelease()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorRelease()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorThreshold()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorThreshold()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorKnee()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorKnee()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorRatio()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorRatio()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorAttack()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorAttack()
      )
      .subscribe((v: number) => {});

    this.store
      .pipe(
        select(selectMemoizedExportMergeCompressorRelease()),
        distinctUntilChanged(),
        sharedBufferWorker.setMergeCompressorRelease()
      )
      .subscribe((v: number) => {});
  }

  private getRecordingInputStream(): Observable<MediaStream> {
    // console.log('getRecordingInputStream');

    return combineLatest([
      this.store.pipe(
        select(selectMediaDeviceInfo),
        filter((a: MediaDeviceInfo) => a !== null && a !== undefined),
        distinctUntilChanged((a: MediaDeviceInfo, b: MediaDeviceInfo) => {
          return a?.deviceId === b?.deviceId;
        })
      ),
      this.store.pipe(
        select(selectGoogMediaTrackConstraints()),
        distinctUntilChanged(
          (a: GoogleMediaTrackConstraints, b: GoogleMediaTrackConstraints) => {
            return JSON.stringify(a) === JSON.stringify(b);
          }
        )
      )
      // this.dss.store(this.dss.DYN_STORE.REFRESH_AUDIO_CONTEXT),
    ]).pipe(
      filter(
        ([device, googConstraints]: [
          MediaDeviceInfo,
          GoogleMediaTrackConstraints
        ]) => hasValue(device) && hasValue(googConstraints)
      ),
      mergeMap(
        ([device, googConstraints]: [
          MediaDeviceInfo,
          GoogleMediaTrackConstraints
        ]) => {
          // console.log(JSON.stringify(googConstraints, null, 2));

          const constraints: any = <MediaStreamConstraints>{
            // eslint-disable-next-line max-len
            // https://source.chromium.org/chromium/chromium/src/+/master:third_party/blink/renderer/modules/mediastream/media_constraints_impl.cc?q=chromeMediaSourceId&ss=chromium%2Fchromium%2Fsrc

            audio: <MediaTrackConstraints>{
              mandatory: {
                // chromeMediaSource: 'desktop',
                // chromeMediaSourceId: device.deviceId,

                ...googConstraints

                // CAUSES MONO RECORDING
                // googHighpassFilter: false,
              }
            },
            video: false
          };

          // console.log('Supported Constraints', navigator.mediaDevices.getSupportedConstraints());

          // console.log(JSON.stringify(constraints, null, 4));

          // const supported = navigator.mediaDevices.getSupportedConstraints();

          // return from(
          //   // @ts-ignore
          //   <Promise<MediaStream>>window['sptGetUserMedia'](constraints)
          // )
          return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(
            mergeMap((inputStream: MediaStream) => {
              const audioTracks: MediaStreamTrack[] =
                inputStream.getAudioTracks();

              // const trackConstraints = transformGoogleToTrackConstraints(constraints.audio.mandatory);

              // console.log(trackConstraints);

              const constraints$: Observable<any>[] = [];

              for (const audioTrack of audioTracks) {
                // https://www.w3.org/TR/mst-content-hint/#dfn-apply-a-default
                // audioTrack.contentHint = 'music';

                // TODO check if this is correct
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                audioTrack.contentHint = 'music';

                constraints$.push(
                  from(
                    audioTrack.applyConstraints({
                      // channelCount: 2,
                      ...constraints.audio.mandatory
                      // channelCount: 2,
                      // sampleSize: 128, // only goes to 16 for some reason
                      // sampleRate: 48000,
                    })
                  )
                );
              }

              return combineLatest(constraints$).pipe(
                map(() => {
                  // console.log('Audio Trac`k Settings:');
                  audioTracks.forEach(audioTrack => {
                    // console.log('Settings: ', JSON.stringify(audioTrack.getSettings(), null, 4));
                    // console.log('Constraints: ', JSON.stringify(audioTrack.getConstraints(), null, 4));
                    // console.log('Capabilities: ', JSON.stringify(audioTrack.getCapabilities(), null, 4));
                  });

                  return inputStream;
                })
              );
            })
          );
        }
      )
    );

    // this.dss.dispatch(this.dss.DYN_STORE.REFRESH_AUDIO_CONTEXT, new Date().valueOf());
  }

  private createRecorderWorklet() {
    return map((audioContext: AudioContext) => {
      const options = {
        processorOptions: {
          sampleRate: audioContext.sampleRate
        },
        outputChannelCount: [2]
      };

      return new SptRecorder(
        audioContext,
        this.transport,
        this.sharedBufferFactory,
        this.dss,
        options
      );
    });
  }

  stopRecordingAndExport(exportFileType: string): void {
    // console.log('stopRecordingAndExport');
    const that = this;

    this.transport.recordType$
      .pipe(take(1))
      .subscribe((recordType: RecordType) => {
        // console.log(recordType);

        this.sptRecorder$.pipe(take(1)).subscribe((recorder: SptRecorder) => {
          // console.log('stop recording', this.trackId);
          recorder.stop().then((e: {type: string; blob: Blob}) => {
            if (recordType === RecordType.TRACK) {
              that.processRecordedData.apply(that, [recorder, e]);
            }

            if (recordType === RecordType.MIX_MASTER) {
              that.processExportedData.apply(that, [recorder, e]);
            }
          });
        });
      });
  }

  processRecordedData(recorder: SptRecorder, e: {type: string; blob: Blob}) {
    const that = this;
    this.audioFileEntity$
      .pipe(take(1))
      .subscribe(
        (
          trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity
        ) => {
          // that.store.dispatch(masterIsSavingRecordedFile({ isSavingRecordedFileTrackId: trackAudioBufferId.trackEntity.id }));

          from(
            convertBlobToParsedRecordExport(
              trackEntityAndAudioFileMetaDataEntity,
              e.blob,
              recorder.audioContext
            )
          )
            .pipe(
              map((data: LoadPlayerAudioWorklet) => {
                // console.log(data);
                data.recordOffsetMs = this.transport.recordOffsetMs;
                return data;
              })
            )
            .subscribe((data: LoadPlayerAudioWorklet) => {
              this.zone.run(() => {
                that.store.dispatch(masterFullRewindEffect());
                that.dss.emit(this.dss.DYN_STORE.SAVE_RECORDED_AUDIO, data);
              });
            });
        }
      );
  }

  processExportedData(recorder: SptRecorder, e: {type: string; blob: Blob}) {
    createUint8ArrayExtensionFromBlob$(e.blob)
      .pipe(
        switchMap((uint8ArrayType: FileUint8ArrayType) => {
          return this.dss
            .store<RenderMixData>(this.dss.DYN_STORE.RENDER_DATA)
            .pipe(
              take<RenderMixData>(1),
              map((value: RenderMixData) => {
                return {
                  ...value,
                  uint8ArrayType
                };
              })
            );
        })
      )
      .subscribe((payload: SaveExportedMixAsFile) => {
        this.fileSaveService.saveExportedAudioFile(payload).subscribe(() => {
          this.transport.setTypeTrack();
        });
      });
  }

  // clear(): void {
  //   // this.worker.postMessage({ command: AudioWorkerCommand.clear });
  // }

  destroy(): void {
    this._onDestroy$.next(true);
    this.workerSub.unsubscribe();
    this.depsSub.unsubscribe();

    // TODO
    // this.worker.postMessage({ command: AudioWorkerCommand.destroy });
  }
}
