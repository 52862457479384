import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';
import {EmailPassword} from '@spout/global-web/models';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'spt-signin-email',
  templateUrl: './signin-email.component.html',
  styleUrls: ['./signin-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninEmailComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  signInForm: UntypedFormGroup;
  inputType = 'password';

  @Output() forgotPassword: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() signInEmail: EventEmitter<EmailPassword> =
    new EventEmitter<EmailPassword>();
  @Output() dirtyAndValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private fb: UntypedFormBuilder, private _cd: ChangeDetectorRef) {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.signInForm.valueChanges.subscribe(() => {
      this.dirtyAndValid.next(this.signInForm.dirty && this.signInForm.valid);
      // this.cd.markForCheck();
    });
  }

  submitEmailPassword() {
    if (this.signInForm.dirty && this.signInForm.valid) {
      this.signInEmail.next(this.signInForm.value);
    }
  }

  showPassword(event: MatCheckboxChange) {
    this.inputType = event.checked ? 'text' : 'password';
    this._cd.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
