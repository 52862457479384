import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';

export interface InstrumentMusician {
  id: string;
}

export type InstrumentMusicianState = EntityState<InstrumentMusician>;

export const instrumentMusicianEntityAdapter: EntityAdapter<InstrumentMusician> =
  createEntityAdapter<InstrumentMusician>();

export const initialInstrumentMusicianState: InstrumentMusicianState =
  instrumentMusicianEntityAdapter.getInitialState({
    // additional entity state properties
  });
