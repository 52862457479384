import {
  DocumentChange,
  DocumentData,
  QueryDocumentSnapshot
} from '@firebase/firestore';
import {AggregateFirebaseSnapshotChanges} from './firebase.model';
import {
  getUpdatedAtSeconds,
  removeTimestampCTorFromDocumentSnapshot
} from './firestore.fns';

/**
 * @param changes
 * @param id
 * @param mapFirestoreId
 */
export function aggregateDocChanges<T>(
  changes: DocumentChange<DocumentData>[],
  id: string = 'id',
  mapFirestoreId = false
): AggregateFirebaseSnapshotChanges<T> {
  const accumulator: AggregateFirebaseSnapshotChanges<T> = {
    added: [],
    modified: [],
    removed: []
  };

  if (changes && changes.length) {
    return (<Array<any>>changes).reduce(function (
      acc: AggregateFirebaseSnapshotChanges<T>,
      change: DocumentChange<T>
    ) {
      const data: any = removeTimestampCTorFromDocumentSnapshot(
        <QueryDocumentSnapshot<DocumentData>>change.doc
      );

      (<any>data).updatedAtSeconds = getUpdatedAtSeconds(data);

      if (mapFirestoreId) {
        data.id = change.doc.id;
      }

      if (change.type === 'added') {
        acc.added.push(data);
      }

      if (change.type === 'modified') {
        acc.modified.push(data);
      }

      if (change.type === 'removed') {
        acc.removed.push((<any>data)[id]);
      }

      return acc;
    },
    accumulator);
  }

  return accumulator;
}

function addParentID<T>(
  entities: T[],
  parentIDProperty: string,
  parentIDValue: string | number | null
): T[] {
  return entities.map((e: T) => {
    return {
      ...e,
      [parentIDProperty]: parentIDValue
    };
  });
}

export function addParentIDToAggregateDocChanges<T>(
  changes: AggregateFirebaseSnapshotChanges<T>,
  parentIDProperty: string,
  parentIDValue: string | number | null
): AggregateFirebaseSnapshotChanges<T> {
  return {
    added: addParentID(changes.added, parentIDProperty, parentIDValue),
    modified: addParentID(changes.modified, parentIDProperty, parentIDValue),
    removed: addParentID(changes.removed, parentIDProperty, parentIDValue)
  };
}
