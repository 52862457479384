import {Location} from '@angular/common';
import {Inject, Injectable, NgZone} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {SptSystemInformation} from '@spout/global-any/models';
import {
  firestoreGetMusicianNotificationsPath,
  firestoreUserAccountDoc,
  getMusicianAccountDevicesPath,
  getMusicianAccountPath,
  getMusicianPublicPathByUser
} from '@spout/global-web/fns';
import {
  ACCOUNT_FEATURE_KEY,
  AccountState,
  AccountStateConnect,
  AggregateFirebaseSnapshotChanges,
  ApiNotifiation,
  APP_ROUTES,
  ENVIRONMENT,
  getRouteConfigLinkByName,
  IEnvironmentState,
  LOGOUT_REDIRECT,
  NotificationTypes,
  PublicProfile,
  FirebaseConnectionService,
  UserAccount
} from '@spout/global-web/models';
import {clone} from '@uiux/fn';
import {
  DocumentChange,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  QuerySnapshot
} from '@firebase/firestore';
import {Observable, of, Subscription, timer} from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take
} from 'rxjs/operators';
import {deviceIsElectron} from '../+device-detection/device-detection.selectors';
import {ga_LoginLoggedIn} from '../+google-analytics/actions';
import {upsertWebsocketRegistry} from '../+websocket-registry/websocket-registry.actions';
import {selectAuthAccountConnect$} from '../+websocket-registry/websocket-registry.selectors';
import {
  CustomFirestoreService,
  Exists,
  removeTimestampCTorFromDocumentSnapshot
} from '../firebase';
import {
  accountLoadedFromSnapshotChanges,
  accountUpdated
} from './account.actions';
import {addMissingUserAccountProperties} from './account.fns';
import {selectAccountState} from './account.selectors';
import {CreateDefaultEntitiesService} from './create-default-entities.service';
import {TrackCloudStorageService} from './track-cloud-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService implements FirebaseConnectionService<AccountState> {
  private watchLoginSub: Subscription = Subscription.EMPTY;
  private accountChangesSub: (() => void) | undefined;
  private notificationsSub: (() => void) | undefined;
  private subscriptionsSub: (() => void) | undefined;
  private promoCodeSub: (() => void) | undefined;
  private trialSub: (() => void) | undefined;
  private isCreatingDefaultEntities = false;

  constructor(
    private store: Store<AccountStateConnect>,
    private _firestore: CustomFirestoreService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private zone: NgZone,
    private locationService: Location,
    private storage: LocalStorage,
    public dialog: MatDialog,
    private defaultIdsService: CreateDefaultEntitiesService,
    private trackCloudStorage: TrackCloudStorageService,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState,
    @Inject(LOGOUT_REDIRECT) private logoutRedirect: string
  ) {
    // console.log('Account Service');

    const that = this;

    this.zone.run(() => {
      that.store.dispatch(
        upsertWebsocketRegistry({
          id: ACCOUNT_FEATURE_KEY
        })
      );
    });

    this.store
      .pipe(selectAuthAccountConnect$)
      .subscribe((s: AccountStateConnect) => {
        if (s.doConnect) {
          this.onConnect.call(this, s.user);
        } else {
          this.onDisconnect.call(this);
        }
      });
  }

  createAccountIfNotExist(user: UserAccount): Observable<UserAccount> {
    return this._firestore
      .setDocIfNotExist<UserAccount>(
        firestoreUserAccountDoc(<string>user.uid),
        addMissingUserAccountProperties(user)
      )
      .pipe(
        map((d: Exists<UserAccount>) => {
          return d.data;
        })
      );
  }

  updateUserAccount$(userAccount: UserAccount): Observable<UserAccount> {
    return this._firestore
      .upsertDoc$<UserAccount>(
        firestoreUserAccountDoc(<string>userAccount.uid),
        addMissingUserAccountProperties(userAccount)
      )
      .pipe(
        map((d: Exists<UserAccount>) => {
          return d.data;
        })
      );
  }

  saveMusicianDeviceSystemInformation(
    auth: AccountState,
    desktopInformation: SptSystemInformation,
    serial: string
  ): Observable<Exists<AccountState | any>> {
    const _computerSerial =
      serial && serial.length > 0
        ? serial
        : auth.uid
        ? auth.uid
        : 'deviceSerial';

    return this._firestore
      .setDocIfNotExist(
        getMusicianAccountDevicesPath(auth, _computerSerial),
        desktopInformation
      )
      .pipe(
        catchError((error: any) => {
          console.error(error);
          return of(<Exists<AccountState | any>>{
            data: null,
            exists: false
          });
        })
      );
  }

  saveToFirebase(account: Partial<AccountState>) {
    // console.log(account);

    this.store
      .pipe(
        selectAuthAccountConnect$,
        filter((d: AccountStateConnect) => d.doConnect),
        take(1),
        switchMap((d: AccountStateConnect) => {
          // console.log(d);

          let payload: AccountState = clone(d.user);

          payload = Object.assign(payload, account);

          return this._firestore.update<AccountState>(
            getMusicianAccountPath(d.user),
            addMissingUserAccountProperties(payload)
          );
        })
      )
      .subscribe((r: Exists<AccountState>) => {
        /* noop */
      });
  }

  onConnect(user: AccountState): void {
    const that = this;

    if (user && user.uid) {
      this.trackCloudStorage.connect(user);

      /**
       * SUBSCRIPTION
       */
      // if (this.subscriptionsSub) {
      //   this.subscriptionsSub();
      // }

      // if (this.trialSub) {
      //   this.trialSub();
      // }

      // this.trialSub = onSnapshot(
      //   this._firestore.docRef(firestoreTrial()),
      //   (r: DocumentSnapshot<DocumentData>) => {
      //     that.zone.run(() => {
      //       that.store.dispatch(
      //         addTrialToAccount(
      //           removeTimestampCTorFromDocumentSnapshot(r) as TrialModel
      //         )
      //       );
      //     });
      //   }
      // );
    }

    this._firestore.setWebSocketConnected(ACCOUNT_FEATURE_KEY);

    if (!this.accountChangesSub) {
      /**
       * ACCOUNT
       */
      this.accountChangesSub = onSnapshot(
        this._firestore.docRef(getMusicianAccountPath(user)),
        (_doc: DocumentSnapshot<DocumentData>) => {
          const account: AccountState =
            removeTimestampCTorFromDocumentSnapshot<AccountState>(_doc);

          if (account && account.uid) {
            this._firestore
              .upsertDoc(getMusicianPublicPathByUser(account.uid), <
                PublicProfile
              >{
                production: this.environment.production,
                stageName: account.stageName,
                photoURL: user.photoURL,
                uid: user.uid,
                displayName: user.displayName
              })
              .subscribe(() => {
                /* noop */
              });
          }

          if (_doc.exists()) {
            that.zone.run(() => {
              that.store.dispatch(
                accountLoadedFromSnapshotChanges({
                  payload: account
                })
              );
            });
          }

          // Only call if has email and stagenem
          if (
            _doc.exists() &&
            account &&
            account.stageName &&
            account.stageName.length &&
            !account.defaultProjectId &&
            !this.isCreatingDefaultEntities
          ) {
            this.isCreatingDefaultEntities = true;
            this.defaultIdsService.createDefaultEntities(account);
          }

          /**
           * NOTIFICATIONS
           */

          if (this.notificationsSub) {
            this.notificationsSub();
          }
          this.notificationsSub = onSnapshot(
            this._firestore.collectionRef(
              firestoreGetMusicianNotificationsPath(user)
            ),
            (_no: QuerySnapshot) => {
              this.processNotifications(_no.docChanges());
            }
          );

          /**
           * PROMO CODE
           */
          // if (account.promoCode && account.promoCode.length) {
          //   if (this.promoCodeSub) {
          //     this.promoCodeSub();
          //   }
          //
          //   this.promoCodeSub = onSnapshot(
          //     this._firestore.docRef(firestorePromoCodeDict(account.promoCode)),
          //     (_snapshot: DocumentSnapshot<DocumentData>) => {
          //       that.zone.run(() => {
          //         that.store.dispatch(
          //           loadPromoCode({
          //             promoCode: removeTimestampCTorFromDocumentSnapshot(
          //               _snapshot
          //             ) as PromoCode
          //           })
          //         );
          //       });
          //     }
          //   );
          // }
        }
      );
    }
  }

  onDisconnect() {
    if (this.accountChangesSub) {
      this.accountChangesSub();
    }
    if (this.notificationsSub) {
      this.notificationsSub();
    }
    if (this.subscriptionsSub) {
      this.subscriptionsSub();
    }
    if (this.promoCodeSub) {
      this.promoCodeSub();
    }
    if (this.trialSub) {
      this.trialSub();
    }

    this.trackCloudStorage.disconnect();

    this._firestore.setWebSocketDisconnected(ACCOUNT_FEATURE_KEY);
  }

  processNotifications(snapshot: DocumentChange[]) {
    const aggregate: AggregateFirebaseSnapshotChanges<ApiNotifiation> =
      snapshot.reduce(
        (
          acc: AggregateFirebaseSnapshotChanges<ApiNotifiation>,
          change: DocumentChange
        ) => {
          // console.log(change.doc.ref.path);

          // const data: ApiNotifiation = change.doc.data() as ApiNotifiation;
          const data: ApiNotifiation = removeTimestampCTorFromDocumentSnapshot(
            change.doc
          );

          acc.all.push(data);

          if (data.type === NotificationTypes.ResetBeta) {
            this.showResetModal();
          }

          if (change.type === 'added') {
            // console.log('New Project: ', change.doc.data());
            acc.added.push(data);
          }

          if (change.type === 'modified') {
            // console.log('Modified Project: ', change.doc.data());
            // acc.modified.push(<UpdateStr<ApiNotifiation>>{
            //   id: data.id,
            //   changes: data,
            // });
          }

          if (change.type === 'removed') {
            // console.log('Removed Track: ', data);
            acc.removed.push(data.type);
          }

          return acc;
        },
        {
          added: [],
          modified: [],
          removed: [],
          all: []
        }
      );
  }

  showResetModal() {
    // const dialogRef = this.dialog.open(ResetEnvironmentComponent, {
    //   width: '600px',
    //   minHeight: '500px'
    // });
  }

  watchElectronLogin() {
    // console.log('watchElectronLogin');
    this.watchLoginSub.unsubscribe();

    this.watchLoginSub = this.store
      .pipe(
        select(selectAccountState),
        // tap((auth: AuthState) => {
        //   console.log(auth);
        // }),
        distinctUntilKeyChanged('isLoggedIn'),
        mergeMap((auth: AccountState) => {
          // console.log(account, auth);

          return this.store.pipe(
            select(deviceIsElectron),
            map((isElectron: boolean) => {
              return {
                auth,
                url: this.locationService.path(),
                isElectron
              };
            })
          );
        })
      )
      .subscribe(
        ({
          auth,
          url,
          isElectron
        }: {
          auth: AccountState;
          url: string;
          isElectron: boolean;
        }) => {
          // console.log('isLoggedIn', auth);
          // console.log('url', url);
          // console.log('isElectron', isElectron);
          // console.log('url === `/${this.logoutRedirect}`', url === `/${this.logoutRedirect}`);

          /**
           * If Logged in
           * AND on the Login Route
           * THEN Navigate to the counsolelibs/mixer-browser-desktop/data-access/src/lib/payment/reset-dev-environment.service.t
           */
          if (auth.isLoggedIn && url.includes(APP_ROUTES.LOGIN_ELECTRON)) {
            this.store.dispatch(ga_LoginLoggedIn());

            this.zone.run(() => {
              this.router
                .navigate([getRouteConfigLinkByName(APP_ROUTES.MIXER_CONSOLE)])
                .then(() => {
                  /* noop */
                });
            });
          }

          /**
           * If NOT Logged in
           * AND NOT on the Login Route
           * THEN Navigate to login route
           */
          if (!auth.isLoggedIn && !url.includes(APP_ROUTES.LOGIN_ELECTRON)) {
            // console.log('NAVIGATE TO LOGIN');

            if (isElectron) {
              // libs/mixer-browser-desktop/feature-app/src/lib/api-options.ts
              this.zone.run(() => {
                this.router.navigate([this.logoutRedirect]);
              });

              timer(250)
                .pipe(
                  switchMap(() => {
                    localStorage.clear();
                    return this.storage.clear();
                  })
                )
                .subscribe(() => {
                  location.reload();
                });
            }
          }
        }
      );
  }
}
