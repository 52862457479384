import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  getProjectRoleTitle,
  MixEntity,
  Musician,
  ProjectEntity,
  Role,
  SongEntity,
  TrackEntity
} from '@spout/global-any/models';
import {createPassThroughSelector} from '@spout/global-web/fns';
import {
  ACCOUNT_ROLE,
  createViewerRole,
  FEATURE_ID,
  FeaturePermission
} from '@spout/global-web/models';
import {
  getIsCreatedByLoggedInMusicianFn_passThrough,
  selectUid
} from '../+account/account.selectors';
import {selectCurrentMixEntity} from '../+mixes/mix-storage.selectors';
import {selectCurrentProjectEntity} from '../+project/project-storage.selectors';
import {selectCurrentSongEntity_passThrough} from '../+songs/song-storage.selectors';
import {selectCurrentTrackEntity} from '../+tracks/track-storage.selectors';
import {SptPermissionState} from './permission.model';
import * as permissionReducer from './permission.reducer';

export const permissionState = createFeatureSelector<SptPermissionState>(
  permissionReducer.permissionsFeatureKey
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  permissionReducer.adapter.getSelectors();

export const selectPermissionEntities = createSelector(
  permissionState,
  selectEntities
);

export const selectAllPermissions = createSelector(permissionState, selectAll);

export const selectProjectRole = createSelector(
  selectCurrentProjectEntity,
  selectUid,
  (project: ProjectEntity | undefined | null, uid: string | null): Role => {
    if (project && uid) {
      if (project.members[uid]) {
        return project.members[uid].role;
      }
    }

    return createViewerRole();
  }
);

export const selectProjectRoleTitle = createSelector(
  selectProjectRole,
  (role: Role) => getProjectRoleTitle(role)
);

export const selectedIsProjectOwner = createSelector(
  selectCurrentProjectEntity,
  selectUid,
  (project: ProjectEntity | undefined | null, uid: string | null) => {
    if (project && uid) {
      if (project.members[uid]) {
        return project.members[uid].role.owner;
      } else {
        return false;
      }
    }

    return false;
  }
);

export const selectDawFeatures = createSelector(
  selectAllPermissions,
  (featurePermissions: FeaturePermission[]) =>
    featurePermissions.filter(d => d.category === 'daw')
);

export const selectCollaborationFeatures = createSelector(
  selectAllPermissions,
  (featurePermissions: FeaturePermission[]) =>
    featurePermissions.filter(d => d.category === 'collaboration')
);

export const selectAccountRole_passThrough = createPassThroughSelector(
  permissionState,
  (state: SptPermissionState) => state.accountRole
);

// export const selectPermissionRole_passThrough = createPassThroughSelector(
//   permissionState,
//   (state: SptPermissionState) => state.permissionRole
// );

export const selectIsTrackOwner_passThrough = createPassThroughSelector(
  selectCurrentTrackEntity,
  selectUid,
  (song: TrackEntity | undefined, uid: string | null) => {
    if (song && uid) {
      if (song.members[uid]) {
        return song.members[uid].role.owner;
      } else {
        return false;
      }
    }

    return false;
  }
);

export const selectedIsSongOwner_passThrough = createPassThroughSelector(
  selectCurrentSongEntity_passThrough,
  selectUid,
  (song: SongEntity | null, uid: string | null) => {
    if (song && uid) {
      if (song.members[uid]) {
        return song.members[uid].role.owner;
      } else {
        return false;
      }
    }

    return false;
  }
);

export const selectIsMixCreator = createSelector(
  selectCurrentMixEntity,
  selectUid,
  (mix: MixEntity | null, uid: string | null) => {
    if (mix && uid) {
      if (mix.members[uid]) {
        return mix.members[uid].role.owner;
      } else {
        return false;
      }
    }
    return false;
  }
);

/**
 * BEGIN PERMISSION ROLES
 */
export const selectPermissionRole = createSelector(
  permissionState,
  (state: SptPermissionState) => state.accountRole
);

export const selectIsEarlyAdopterAccountRole = createSelector(
  selectPermissionRole,
  (role: number | null | undefined) => role === ACCOUNT_ROLE.EARLY_ADOPTER
);

export const selectIsCollaboratorAccountRole = createSelector(
  selectPermissionRole,
  (role: number | null | undefined) => role === ACCOUNT_ROLE.COLLABORATOR
);

export const selectIsPersonalAccountRole = createSelector(
  selectPermissionRole,
  (role: number | null | undefined) => role === ACCOUNT_ROLE.PERSONAL
);

export const selectIsBandAccountRole = createSelector(
  selectPermissionRole,
  (role: number | null | undefined) => role === ACCOUNT_ROLE.BAND
);

export const selectIsReviewerAccountRole = createSelector(
  selectPermissionRole,
  (role: number | null | undefined) => role === ACCOUNT_ROLE.REVIEWER
);

/**
 * END PERMISSION ROLES
 */

/**
 * ADD_ANNOTATIONS
 */
export const selectCanAddAnnotations_passThrough = createSelector(
  permissionState,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.ADD_ANNOTATIONS]
);

/**
 * ADD_COLLABORATORS
 */
export const selectCanAddCollaborators_passThrough = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.ADD_COLLABORATORS]
);

/**
 * CHAT
 */
export const selectCanChat_passThrough = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CHAT]
);

/**
 * CLEAR_TRACKS
 */
export const selectCanClearTracks_passThrough = createSelector(
  permissionState,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.CLEAR_TRACKS]
);

/**
 * CREATE_PROJECTS
 */
export const selectCanClearProject_passThrough = createSelector(
  permissionState,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_PROJECTS]
);

/**
 * CREATE_SONGS
 */
export const selectCanCreateSongs_passThrough = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_SONGS]
);

/**
 * CREATE_TRACKS
 */
export const selectCanCreateTracks_passThrough = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_TRACKS]
);

/**
 * CREATE_MIXES
 */
export const selectCanCreateMixes_passThrough = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.CREATE_MIXES]
);

/**
 * COPY_TRACKS
 */
export const select_Can_CopyTracks = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.COPY_TRACKS]
);

/**
 * DELETE_TRACKS
 */
export const selectCanDeleteTracks_passThrough = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.DELETE_TRACKS]
);

/**
 * EXPORT_SONG
 */
export const selectCanExportSongs_passThrough = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.EXPORT_SONG]
);

/**
 * IMPORT_AUDIO_FILES
 */
export const selectCanImportAudioFiles_passThrough = createSelector(
  permissionState,
  (state: SptPermissionState) =>
    state.permissions[FEATURE_ID.IMPORT_AUDIO_FILES]
);

/**
 * RECORD
 */
export const select_Can_Record = createSelector(
  permissionState,

  (state: SptPermissionState) => state.permissions[FEATURE_ID.RECORD]
);

/**
 * SHARE_TRACKS
 */
export const selectCanShareTracks_passThrough = createSelector(
  permissionState,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.SHARE_TRACKS]
);

/**
 * TRACK_CONTROLS
 */
export const select_Can_TrackControls = createSelector(
  permissionState,
  (state: SptPermissionState) => state.permissions[FEATURE_ID.TRACK_CONTROLS]
);

/**
 * EDIT PROJECT, SONG, TRACK, MIX
 */
export const selectShowEditProject_passThrough = createPassThroughSelector(
  selectedIsProjectOwner,
  selectProjectRole,
  (isProjectOwner: boolean, role: Role) => {
    //
    return isProjectOwner && !role.collaborator;
  }
);

export const selectShowEditSong_passThrough = createPassThroughSelector(
  selectedIsSongOwner_passThrough,
  selectProjectRole,
  (isSongOwner: boolean, role: Role) => {
    //
    return isSongOwner && !role.collaborator;
  }
);

export const selectShowEditMix_passThrough = createPassThroughSelector(
  selectIsMixCreator,

  selectProjectRole,
  (isMixOwner: boolean, role: Role) => {
    //
    return isMixOwner && !role.collaborator;
  }
);

export const getShowEditTrackButtonFn_passThrough = (config: {
  createdBy: Musician;
}) =>
  createPassThroughSelector(
    getIsCreatedByLoggedInMusicianFn_passThrough(config),
    selectCanCreateTracks_passThrough,
    selectProjectRole,
    (isCreatedByLoggedInMusician: boolean, canCreate: boolean, role: Role) => {
      //
      return (isCreatedByLoggedInMusician && canCreate) || role.collaborator;
    }
  );

export const selectShowTrackVolumeButton = createSelector(
  selectIsMixCreator,
  (isMixOwner: boolean) => {
    //
    return isMixOwner;
  }
);

export const selectShowTrackOffsetButton = createSelector(
  selectIsMixCreator,
  (isMixOwner: boolean) => {
    //
    return isMixOwner;
  }
);

export const selectShowTrackColorButton_passThrough = createPassThroughSelector(
  selectIsMixCreator,
  (isMixOwner: boolean) => {
    //
    return isMixOwner;
  }
);

export const selectShowClearTrackButton_passThrough = createPassThroughSelector(
  selectCanClearTracks_passThrough,
  (canClearTrack: boolean) => {
    //
    return canClearTrack;
  }
);

export const selectShowShareTrackButton_passThrough = createPassThroughSelector(
  selectCanShareTracks_passThrough,
  (canShareTracks: boolean) => {
    //
    return canShareTracks;
  }
);

export const getShowShowDeleteTrackButtonFn_passThrough = (config: {
  createdBy: Musician;
}) =>
  createPassThroughSelector(
    getIsCreatedByLoggedInMusicianFn_passThrough(config),
    selectCanDeleteTracks_passThrough,
    (isCreatedByLoggedInMusician: boolean, canDelete: boolean) => {
      //
      return isCreatedByLoggedInMusician && canDelete;
    }
  );
