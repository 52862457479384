import {A11yModule} from '@angular/cdk/a11y';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {StripeErrorModalComponent} from './stripe-error-modal.component';

@NgModule({
  declarations: [StripeErrorModalComponent],
  exports: [StripeErrorModalComponent],
  imports: [CommonModule, A11yModule, MatButtonModule, MatDialogModule]
})
export class StripeErrorModalModule {}
