import {AppstoreInAppPurchase} from './+appstore/appstore-in-app-purchase.model';

export const IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION =
  'student.subscription.monthly';
export const IOS_PRODUCT_ID_MENTOR_SUBSCRIPTION = 'mentor.subscription.monthly';
export const IOS_PRODUCT_ID_TEACHER_SUBSCRIPTION =
  'teacher.subscription.monthly';

export const ANDROID_PRODUCT_ID_STUDENT_SUBSCRIPTION =
  'android.student.subscription.monthly';
export const ANDROID_PRODUCT_ID_MENTOR_SUBSCRIPTION =
  'android.mentor.subscription.monthly';
export const ANDROID_PRODUCT_ID_TEACHER_SUBSCRIPTION =
  'android.teacher.subscription.monthly';

export const IN_APP_IOS_SUBSCRIPTION_ID = {
  STUDENT: IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION,
  MENTOR: IOS_PRODUCT_ID_MENTOR_SUBSCRIPTION,
  TEACHER: IOS_PRODUCT_ID_TEACHER_SUBSCRIPTION
};

export const IN_APP_ANDROID_SUBSCRIPTION_ID = {
  STUDENT: ANDROID_PRODUCT_ID_STUDENT_SUBSCRIPTION,
  MENTOR: ANDROID_PRODUCT_ID_MENTOR_SUBSCRIPTION,
  TEACHER: ANDROID_PRODUCT_ID_TEACHER_SUBSCRIPTION
};

export const IN_APP_PLATFORM = {
  IOS: 'apple',
  ANDROID: 'android'
};

const PRICE = {
  STUDENT: '$4.99 USD',
  MENTOR: '$6.99 USD',
  TEACHER: '$9.99 USD'
};

const PLAN = {
  STUDENT: 'Student SubscriptionPlan',
  MENTOR: 'Mentor SubscriptionPlan',
  TEACHER: 'Teacher SubscriptionPlan'
};

/**
 *
 * @param s = subscription ID
 */
export function getInAppSubscriptionPrice(s: string): string {
  if (s === IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION) {
    return PRICE.STUDENT;
  }
  if (s === ANDROID_PRODUCT_ID_STUDENT_SUBSCRIPTION) {
    return PRICE.STUDENT;
  }
  if (s === IOS_PRODUCT_ID_MENTOR_SUBSCRIPTION) {
    return PRICE.MENTOR;
  }
  if (s === ANDROID_PRODUCT_ID_MENTOR_SUBSCRIPTION) {
    return PRICE.MENTOR;
  }
  if (s === IOS_PRODUCT_ID_TEACHER_SUBSCRIPTION) {
    return PRICE.TEACHER;
  }
  if (s === ANDROID_PRODUCT_ID_TEACHER_SUBSCRIPTION) {
    return PRICE.TEACHER;
  }

  return '';
}

/**
 *
 * @param s = subscription ID
 */
export function getInAppSubscriptionPlan(s: string): string {
  if (s === IOS_PRODUCT_ID_STUDENT_SUBSCRIPTION) {
    return PLAN.STUDENT;
  }
  if (s === ANDROID_PRODUCT_ID_STUDENT_SUBSCRIPTION) {
    return PLAN.STUDENT;
  }
  if (s === IOS_PRODUCT_ID_MENTOR_SUBSCRIPTION) {
    return PLAN.MENTOR;
  }
  if (s === ANDROID_PRODUCT_ID_MENTOR_SUBSCRIPTION) {
    return PLAN.MENTOR;
  }
  if (s === IOS_PRODUCT_ID_TEACHER_SUBSCRIPTION) {
    return PLAN.TEACHER;
  }
  if (s === ANDROID_PRODUCT_ID_TEACHER_SUBSCRIPTION) {
    return PLAN.TEACHER;
  }

  return '';
}

export function createStudentSubscription(
  id: string,
  platform: string,
  billingPeriodUnit: string | undefined,
  title: string | null = null,
  price: string | null = null
): AppstoreInAppPurchase {
  const _title: string = title ? title : getInAppSubscriptionPlan(id);
  const _price: string = price ? price : getInAppSubscriptionPrice(id);

  return {
    id,
    title: _title,
    owned: true,
    platform,
    price: _price,
    billingPeriodUnit
  };
}

export interface FoveaInAppPurchase {
  productId: string; // 'apple:student.subscription.monthly'
  platform: string; // 'apple'
  sandbox: boolean; // false
  purchaseId: string; // 'apple:190001192679089'
  purchaseDate: string; // '2022-01-16T02:45:28.000Z'
  lastRenewalDate: string; // '2022-01-16T02:45:27.000Z'
  expirationDate: string; // '2022-02-16T02:45:27.000Z'
  renewalIntent: string; // 'Renew'
  isExpired: boolean; // false
}

export interface FoveaInAppPurchaseValidationResponse {
  data: {
    applicationUsername: string; // user uid
    purchases: {
      // key 'apple:student.subscription.monthly'
      [key: string]: FoveaInAppPurchase;
    };
  };
}

export function getActiveInAppPurchase(
  response: FoveaInAppPurchaseValidationResponse
): FoveaInAppPurchase | undefined {
  if (response && response.data && response.data.purchases) {
    return Object.values(response.data.purchases).find(
      (p: FoveaInAppPurchase) => !p.isExpired
    );
  }
  return undefined;
}
