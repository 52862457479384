import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as PriceReducer from './price.reducer';
import {Dictionary} from '@ngrx/entity/src/models';
import {Price, PriceByIntervalDict} from './price.model';

export const selectPriceState = createFeatureSelector<PriceReducer.PriceState>(
  PriceReducer.priceFeatureKey
);

const {selectIds, selectEntities, selectAll, selectTotal} =
  PriceReducer.priceAdapter.getSelectors();

export const selectAllPrices = createSelector(
  selectPriceState,
  (state: PriceReducer.PriceState) => selectAll(state)
);
export const selectPriceEntities = createSelector(
  selectPriceState,
  (state: PriceReducer.PriceState) => selectEntities(state)
);
export const selectPriceIds = createSelector(
  selectPriceState,
  (state: PriceReducer.PriceState) => selectIds(state)
);
export const selectPriceTotal = createSelector(
  selectPriceState,
  (state: PriceReducer.PriceState) => selectTotal(state)
);

export const selectedPriceID = createSelector(
  selectPriceState,
  (state: PriceReducer.PriceState) => state.selectedPriceID
);

export const getPriceByID = (id: string) =>
  createSelector(
    selectPriceEntities,
    (entities: Dictionary<Price>): Price | undefined => {
      return entities[id];
    }
  );

export const getPriceIntervalByIDAndCommonProduct = (priceID: string) =>
  createSelector(
    selectAllPrices,
    selectPriceEntities,
    (prices: Price[], entities: Dictionary<Price>): PriceByIntervalDict => {
      console.log('getPriceIntervalByIDAndCommonProducts');
      console.log(entities);
      console.log(prices);
      const price: Price = <Price>entities[priceID];

      return prices
        .filter((p: Price) => p.product === price.product)
        .reduce(
          (a: PriceByIntervalDict, i: Price) => {
            a[i.interval] = i;

            return a;
          },
          <PriceByIntervalDict>{
            month: null,
            year: null
          }
        );
    }
  );
