import {Action, createReducer, on} from '@ngrx/store';
import {
  HelpModel,
  initialMixerRightDrawerState,
  MixerRightDrawerState,
  MixerRightDrawerTypes
} from '@spout/global-web/models';
import {clone} from '@uiux/fn';
import {authLoggedOut} from '../+account/auth.actions';
import {
  openChatTrackRightDrawer,
  openConversationInTrack,
  openConversationInTrackRightDrawer,
  openHelpRightDrawer,
  openHelpViewRightDrawer,
  openNotificationRightDrawer,
  toggleChatTrackRightDrawer,
  toggleHelpRightDrawer,
  toggleInviteRightDrawer,
  toggleNotificationRightDrawer,
  toggleRightDrawer
} from './mixer-right-drawer.actions';

const rightDrawerReducer = createReducer(
  initialMixerRightDrawerState,
  // on(openMixerRightDrawer, (state) => ({
  //   ...state,
  //   opened: true,
  // })),
  // on(closeMixerRightDrawer, (state) => ({
  //   ...state,
  //   opened: false,
  // })),
  on(toggleRightDrawer, state => ({
    ...state,
    opened: !state.opened
  })),
  on(toggleInviteRightDrawer, state => {
    if (state.view === MixerRightDrawerTypes.INVITE && state.opened) {
      return {
        ...state,
        opened: false
      };
    } else {
      return {
        ...state,
        opened: true,
        view: MixerRightDrawerTypes.INVITE
      };
    }
  }),
  on(toggleChatTrackRightDrawer, state => {
    if (state.view === MixerRightDrawerTypes.CHAT_TRACK && state.opened) {
      return {
        ...state,
        opened: false
      };
    } else {
      return {
        ...state,
        opened: true,
        view: MixerRightDrawerTypes.CHAT_TRACK
      };
    }
  }),
  on(toggleHelpRightDrawer, state => {
    if (state.view === MixerRightDrawerTypes.HELP && state.opened) {
      return {
        ...state,
        opened: false
      };
    } else {
      return {
        ...state,
        opened: true,
        view: MixerRightDrawerTypes.HELP
      };
    }
  }),
  on(toggleNotificationRightDrawer, state => {
    if (state.view === MixerRightDrawerTypes.NOTFIICATION && state.opened) {
      return {
        ...state,
        opened: false
      };
    } else {
      return {
        ...state,
        opened: true,
        view: MixerRightDrawerTypes.NOTFIICATION
      };
    }
  }),
  on(openChatTrackRightDrawer, state => {
    return {
      ...state,
      opened: true,
      view: MixerRightDrawerTypes.CHAT_TRACK
    };
  }),
  on(openConversationInTrackRightDrawer, (state, action) => {
    return {
      ...state,
      openConversationInRightDrawer: action.conversation,
      opened: true,
      view: MixerRightDrawerTypes.CHAT_TRACK
    };
  }),
  on(openNotificationRightDrawer, state => {
    return {
      ...state,
      opened: true,
      view: MixerRightDrawerTypes.NOTFIICATION
    };
  }),
  on(openConversationInTrack, (state, action) => {
    return {
      ...state,
      openConversationInTrack: action.conversation,
      opened: true,
      view: MixerRightDrawerTypes.CHAT_TRACK
    };
  }),
  on(authLoggedOut, (state, action) => {
    return clone(initialMixerRightDrawerState);
  }),
  on(openHelpRightDrawer, (state, action) => {
    return {
      ...state,
      opened: true,
      view: MixerRightDrawerTypes.HELP,
      helpView: HelpModel.TOC
    };
  }),
  on(openHelpViewRightDrawer, (state, action) => {
    return {
      ...state,
      opened: true,
      view: MixerRightDrawerTypes.HELP,
      helpView: action.helpView
    };
  })
);

export function mixerRightDrawerReducer(
  state: MixerRightDrawerState | undefined,
  action: Action
) {
  return rightDrawerReducer(state, action);
}
