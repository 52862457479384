<div *ngIf="design === 'marketing'" class="marketing-site">
  <h4 class="mat-h4">General Inquiries:</h4>
  <div class="social-icons">
    <button
      mat-icon-button
      tooltip="Email"
      [options]="{placement: 'top', tooltipClass: 'g-tooltip'}"
      color="primary"
      (click)="openEmail()">
      <mat-icon>email</mat-icon>
    </button>

    <a href="mailto:info@spoutsoftware.com" target="_blank"
      >info@spoutsoftware.com</a
    >
  </div>

  <h4 class="mat-h4">News:</h4>
  <div class="social-icons">
    <button
      mat-icon-button
      (click)="openFacebook()"
      tooltip="Facebook"
      [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
      <img [src]="logos.FACEBOOK_COLOR" alt="" />
    </button>
    <button
      mat-icon-button
      (click)="openTwitter()"
      tooltip="Twitter"
      [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
      <mat-icon svgIcon="TWITTER_COLOR"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="openInstagram()"
      tooltip="Instagram"
      [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
      <mat-icon svgIcon="INSTAGRAM_COLOR"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="openTikTok()"
      tooltip="TikTok"
      [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
      <mat-icon svgIcon="TIKTOK_COLOR"></mat-icon>
    </button>
  </div>

  <h4 class="mat-h4">Feedback, Ideas, and Support:</h4>
  <div class="social-icons">
    <button
      mat-icon-button
      (click)="openDiscord()"
      tooltip="Discord"
      [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
      <mat-icon svgIcon="DISCORD_COLOR"></mat-icon>
    </button>
    <!--    <button-->
    <!--      mat-icon-button-->
    <!--      (click)="openSlack()"-->
    <!--      tooltip="Slack"-->
    <!--      [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">-->
    <!--      <mat-icon svgIcon="SLACK_COLOR"></mat-icon>-->
    <!--    </button>-->
  </div>
</div>

<div *ngIf="design === 'footer'" class="footer">
  <button
    mat-icon-button
    tooltip="Email"
    [options]="{placement: 'top', tooltipClass: 'g-tooltip'}"
    color="primary"
    (click)="openEmail()">
    <mat-icon>email</mat-icon>
  </button>

  <button
    mat-icon-button
    (click)="openFacebook()"
    tooltip="Facebook"
    [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
    <img [src]="logos.FACEBOOK_LIGHT" alt="" />
  </button>
  <button
    mat-icon-button
    (click)="openTwitter()"
    tooltip="Twitter"
    [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
    <mat-icon svgIcon="TWITTER_LIGHT"></mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="openInstagram()"
    tooltip="Instagram"
    [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
    <mat-icon svgIcon="INSTAGRAM_LIGHT"></mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="openTikTok()"
    tooltip="TikTok"
    [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
    <mat-icon svgIcon="TIKTOK_LIGHT"></mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="openDiscord()"
    tooltip="Discord"
    [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">
    <mat-icon svgIcon="DISCORD_LIGHT"></mat-icon>
  </button>
  <!--  <button-->
  <!--    mat-icon-button-->
  <!--    (click)="openSlack()"-->
  <!--    tooltip="Slack"-->
  <!--    [options]="{placement: 'top', tooltipClass: 'g-tooltip'}">-->
  <!--    <mat-icon svgIcon="SLACK_LIGHT"></mat-icon>-->
  <!--  </button>-->
</div>
