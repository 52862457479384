import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  AudioFileMetaData,
  DeleteFilesFromSystem,
  DeleteProjectSystem
} from '@spout/global-any/models';
import {ENVIRONMENT, IEnvironmentState} from '@spout/global-web/models';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {selectUidHash$} from '../../+account/account.selectors';
import {DeviceStorageService} from './device-storage.service';

@Injectable()
export class AudioFileDeleteService {
  constructor(
    private device: DeviceStorageService,
    private store: Store,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {}

  deleteFilesFromFileSystem(
    files: AudioFileMetaData[]
  ): Observable<DeleteFilesFromSystem> {
    const that = this;

    return this.selectUidHash$().pipe(
      switchMap((account: string) => {
        return that.device.deleteFilesFromSystem({
          account,
          audioFileMetaData: files
        });
      })
    );
  }

  deleteProjectFromFileSystem(
    projectId: string
  ): Observable<DeleteProjectSystem> {
    const that = this;

    return this.selectUidHash$().pipe(
      switchMap((account: string) => {
        return that.device.deleteProjectFromSystem({
          account,
          projectId
        });
      })
    );
  }

  private selectUidHash$(): Observable<string> {
    return this.store.pipe(selectUidHash$, take(1));
  }
}
