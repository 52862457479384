import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {TooltipModule} from 'ng2-tooltip-directive';
import {SocialComponent} from './social.component';

@NgModule({
  declarations: [SocialComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, TooltipModule],
  exports: [SocialComponent]
})
export class SocialModule {}
