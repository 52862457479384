import {Injectable, NgZone} from '@angular/core';
import {AccountState} from '@spout/global-web/models';
import {CustomFirestoreService} from '../../firebase';
import {AbstractConnectionService} from '../../firebase/abstract-connection.service';
import {firestoreCollectionQueryConfig} from '../../firebase/firestore-query';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {QueryEngineCache} from '../../services/query-engine-cache';
import {firestoreUserInvoicesCollection} from '../database-paths';
import {invoiceFeatureKey} from './invoice.reducer';
import {Store} from '@ngrx/store';
import {Invoice} from './invoice.model';
import {
  deleteInvoices,
  updateInvoices,
  upsertInvoices
} from './invoice.actions';
import {selectAllSubscriptions, Subscription} from '../+subscriptions';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends AbstractConnectionService {
  private _priceQueryCache: QueryEngineCache<Invoice>;

  constructor(
    private _customFirestoreService: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    override _store: Store,
    private _zone: NgZone
  ) {
    super(invoiceFeatureKey, _connector, _store);

    const queryPriceConfig = firestoreCollectionQueryConfig(
      {
        queryMember: false,
        upsertManyAction: (invoices: Invoice[]) => upsertInvoices({invoices}),
        updateManyAction: (invoices: Invoice[]) =>
          updateInvoices({invoices: aggregateUpdates(invoices)}),
        deleteManyAction: (ids: string[]) => deleteInvoices({ids}),
        mapFirestoreID: true,
        logUpsert: false
      },
      _zone,
      _store,
      _customFirestoreService
    );

    const pricePathGenerator = (p: Subscription, uid: string) =>
      firestoreUserInvoicesCollection(p.id, uid);

    this._priceQueryCache = new QueryEngineCache<Invoice>(
      queryPriceConfig,
      _store,
      selectAllSubscriptions,
      pricePathGenerator,
      'id'
    );
  }

  onConnect(user: AccountState) {
    this._connector.keyIsConnected(invoiceFeatureKey);
    this._priceQueryCache.onConnect(user);
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query

    // Unsubscribe to query before calling this
    this._connector.keyIsDisconnected(invoiceFeatureKey);
    this._priceQueryCache.onDisconnect();
  }
}
