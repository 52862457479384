import {PortalInjector} from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  ComponentFactoryResolver,
  Input,
  Injector
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  CONTAINER_DATA,
  ContentModel
} from '../components/help/content/content.model';
import {SptComponentRef} from './spt-component-ref';
import {LoadComponentDirective} from './load-component.directive';

@Component({
  selector: 'spt-component-container',
  template: `<ng-template sptLoadComponent></ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentContainerComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean>;
  private _componentRef$: Subject<SptComponentRef>;

  @Input()
  set setLoadComponentRef(ref: SptComponentRef | undefined | null) {
    if (ref) {
      this._componentRef$.next(ref);
    }
  }

  @ViewChild(LoadComponentDirective, {static: true})
  adHost!: LoadComponentDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector
  ) {
    this._onDestroy$ = new Subject();
    this._componentRef$ = new Subject();

    this._componentRef$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((ref: SptComponentRef) => {
        this.loadComponent(ref);
      });
  }

  loadComponent(ref: SptComponentRef) {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(ref.component);

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<SptComponentRef>(
      componentFactory,
      0,
      this.createInjector({showBackHeader: true})
    );

    if (ref.data !== undefined && ref.data !== null) {
      componentRef.instance.data = ref.data;
    }
  }

  createInjector(dataToPass: ContentModel): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
