import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AccountState} from '@spout/global-web/models';
import {CustomFirestoreService} from '../../firebase';
import {AbstractConnectionService} from '../../firebase/abstract-connection.service';
import {FirestoreCollectionQuery} from '../../firebase/firestore-query';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {firestorePromoCodeCollection} from '../database-paths';
import {
  deletePromoCodes,
  updatePromoCodes,
  upsertPromoCodes
} from './promo-code.actions';
import {PromoCode} from './promo-code.model';
import {promoCodeFeatureKey} from './promo-code.reducer';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService extends AbstractConnectionService {
  private _queryService: FirestoreCollectionQuery<PromoCode>;

  constructor(
    private _customFirestoreService: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    override _store: Store,
    private _zone: NgZone
  ) {
    super(promoCodeFeatureKey, _connector, _store);

    this._queryService = new FirestoreCollectionQuery<PromoCode>(
      {
        queryMember: false,
        upsertManyAction: (promoCodes: PromoCode[]) =>
          upsertPromoCodes({promoCodes}),
        updateManyAction: (promoCodes: PromoCode[]) =>
          updatePromoCodes({promoCodes: aggregateUpdates(promoCodes)}),
        deleteManyAction: (ids: string[]) => deletePromoCodes({ids})
      },
      _zone,
      _store,
      _customFirestoreService
    );
  }

  onConnect(user: AccountState) {
    this._connector.keyIsConnected(promoCodeFeatureKey);
    // implement query
    this._queryService.onConnect(
      firestorePromoCodeCollection(),
      null,
      <string>user.uid
    );
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query
    this._queryService.onDisconnect();

    // Unsubscribe to query before calling this
    this._connector.keyIsDisconnected(promoCodeFeatureKey);
  }
}
