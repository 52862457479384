import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {LetModule, PushModule} from '@ngrx/component';

import {SptAlertMessageModule} from '../alert-message/spt-alert-message.module';
import {SptAExternalModule} from '../spt-a-external/spt-a-external.module';
import {LoginComponent} from './login.component';
import {SigninOathComponent} from './signin-oath/signin-oath.component';
import {SigninEmailComponent} from './signin-email/signin-email.component';
import {SignupEmailComponent} from './signup-email/signup-email.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {StripePaymentSelectionService} from 'libs/global-web/data-access/src/lib/+stripe/ui/stripe-payment-selection.service';

@NgModule({
  declarations: [
    LoginComponent,
    SigninOathComponent,
    SigninEmailComponent,
    SignupEmailComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LetModule,
    PushModule,
    SptAExternalModule,
    SptAlertMessageModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule
  ],
  providers: [StripePaymentSelectionService],
  exports: [LoginComponent]
})
export class LoginModule {}
