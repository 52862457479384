import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {PriceService} from './price.service';
import {PartialPriceState} from './price.reducer';

@Injectable({
  providedIn: 'root'
})
export class PriceEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<PartialPriceState>,
    private _priceService: PriceService
  ) {}
}
