// MASTER MASTER MASTER MASTER
import {Dictionary} from '@ngrx/entity/src/models';
// MASTER MASTER MASTER MASTER
// MASTER MASTER MASTER MASTER
// MASTER MASTER MASTER MASTER
import {createSelector} from '@ngrx/store';
import {
  AudioFileMetaDataEntity,
  MixAndTrackMixEntity,
  MixEntity,
  ProjectEntity,
  SongEntity,
  TrackEntity,
  TrackMix
} from '@spout/global-any/models';
import {getAllAudioBufferAndAudioEntitiesByTrackId} from '@spout/global-web/fns';
import {
  AudioComponentCombined,
  CompositeAudioBufferDataAndAudioMetaData,
  IsCurrentTrackAndTrackEntityAndAudioBufferMetaDataEntities,
  TrackComponentModel,
  TrackEntityAndAudioBufferMetaDataEntities
} from '@spout/global-web/models';
import {getIn, hasValue, hasValueIn} from '@uiux/fn';
import {
  getTrackEntityAndAudioBufferMetaDataEntityFn,
  selectAudioMetaDataEntities
} from '../+audio-file-meta-data/audio-metadata-storage.selectors';
import {selectDeviceStorageState} from '../+device-storage/device-storage.selectors';
import {
  selectAllMixes,
  selectCurrentMixEntity
} from '../+mixes/mix-storage.selectors';
import {selectAllProjects} from '../+project/project-storage.selectors';
import {
  getCurrentTrackMixByTrackIdFn,
  getTrackMixByTrackIdFn,
  selectCurrentTrackMixDict
} from '../+track-mix/track-mix.selectors';
import {
  getIsCurrentTrackEntityByIdFn,
  getTrackEntityByIdFn,
  selectAllTracks,
  selectCurrentTrackEntity,
  selectTrackEntities
} from '../+tracks/track-storage.selectors';
import {StudioAppState} from '../models/studio-app-state.model';

export const selectMasterVolume = createSelector(
  selectDeviceStorageState,
  state => state.masterVolume
);

export const getTrackModel = (props: {trackId: string}) =>
  createSelector(
    selectMasterVolume,
    selectTrackEntities,
    selectCurrentTrackMixDict,
    selectAudioMetaDataEntities,
    selectCurrentTrackEntity,
    (
      masterVolume: number | null,
      trackEntities: Dictionary<TrackEntity>,
      trackMixEntities: Dictionary<TrackMix>,
      audioFileMetaDataEntities: Dictionary<AudioFileMetaDataEntity>,
      currentTrackEntity: TrackEntity | undefined
    ): TrackComponentModel | null => {
      // console.log('trackEntities', trackEntities);
      // console.log('trackMixEntities', trackMixEntities);
      // console.log('currentTrackEntity', currentTrackEntity);
      // console.log('audioFileStoreEntities', audioFileMetaDataEntities);
      // console.log('props', props);

      if (
        hasValueIn(props, 'trackId') &&
        hasValue(trackMixEntities) &&
        hasValue(trackMixEntities[props.trackId]) &&
        hasValue(trackEntities) &&
        hasValue(trackEntities[props.trackId]) &&
        hasValue(currentTrackEntity)
      ) {
        const trackEntity = trackEntities[props.trackId];
        const trackMix = trackMixEntities[props.trackId];

        if (trackEntity && trackMix) {
          const fileData: CompositeAudioBufferDataAndAudioMetaData[] =
            getAllAudioBufferAndAudioEntitiesByTrackId(
              trackMix,
              audioFileMetaDataEntities
            );

          const allFilesUploaded = fileData
            .map((file: CompositeAudioBufferDataAndAudioMetaData) => {
              return getIn(file, 'audioFileMetaData.fileUploaded', false);
            })
            .reduce((allIsUploaded: boolean, fileIsUploaded: boolean) => {
              if (!allIsUploaded) {
                return allIsUploaded;
              }
              return fileIsUploaded;
            }, true);

          return <TrackComponentModel>{
            masterVolume,
            allFilesUploaded: hasValue(fileData) ? allFilesUploaded : false,
            trackEntity: trackEntity,
            trackMix,
            isCurrentTrack: currentTrackEntity?.id === props.trackId,
            fileData
          };
        }
      }

      return null;
    }
  );

export const getCurrentTrackHasAudioRecorded = createSelector(
  selectCurrentTrackEntity,
  selectTrackEntities,
  selectCurrentTrackMixDict,
  selectAudioMetaDataEntities,
  (
    currentTrack: TrackEntity | undefined,
    trackEntities: Dictionary<TrackEntity>,
    trackMixEntities: Dictionary<TrackMix>,
    audioFileMetaDataEntities: Dictionary<AudioFileMetaDataEntity>
  ): {hasAudio: boolean; track: TrackEntity | undefined} => {
    const trackId: string = currentTrack ? currentTrack.id : '';

    const trackEntity = trackEntities[trackId];
    const trackMix = trackMixEntities[trackId];

    if (
      trackEntity &&
      trackMix &&
      currentTrack &&
      hasValue(trackMixEntities) &&
      hasValue(trackEntities) &&
      hasValue(trackEntities[trackId])
    ) {
      const fileData: CompositeAudioBufferDataAndAudioMetaData[] =
        getAllAudioBufferAndAudioEntitiesByTrackId(
          trackMix,
          audioFileMetaDataEntities
        );

      const hasAudio = fileData.reduce(
        (
          _hasAudio: boolean,
          comp: CompositeAudioBufferDataAndAudioMetaData
        ) => {
          if (comp.snippet && !_hasAudio) {
            return comp.snippet.audioDuration > 0;
          }

          return _hasAudio;
        },
        false
      );

      return {
        hasAudio,
        track: currentTrack
      };
    }

    return {
      hasAudio: false,
      track: currentTrack
    };
  }
);

export const getDefaultProjectTrackAndMixBySong = createSelector(
  selectAllProjects,
  selectAllTracks,
  selectAllMixes,
  (
    projects: ProjectEntity[],
    tracks: TrackEntity[],
    mixes: MixEntity[],
    props: {song: SongEntity}
  ) => {
    if (hasValue(tracks) && hasValue(mixes)) {
      const _projects: ProjectEntity[] = projects.filter(
        (t: ProjectEntity) => t.id === props.song.projectId
      );

      const _tracks: TrackEntity[] = tracks.filter(
        (t: TrackEntity) =>
          t.projectId === props.song.projectId && t.songId === props.song.id
      );
      const _mixes: MixEntity[] = mixes.filter(
        (m: MixEntity) =>
          m.projectId === props.song.projectId && m.songId === props.song.id
      );

      const project = _projects.find((p: ProjectEntity) => p.isDefault);
      const track = _tracks.find((t: TrackEntity) => t.isDefault);
      const mix = _mixes.find((m: MixEntity) => m.isDefault);

      return {
        project: project ? project : _projects[0],
        track: track ? track : _tracks[0],
        mix: mix ? mix : _mixes[0]
      };
    }

    return null;
  }
);

export const getProjectTrackAndMixBySongId = (props: {song: SongEntity}) => {
  return createSelector(
    selectAllProjects,
    selectAllTracks,
    selectAllMixes,
    (projects: ProjectEntity[], tracks: TrackEntity[], mixes: MixEntity[]) => {
      // console.log('selected song id', props.song.id);
      // console.log('selected song projectId', props.song.projectId);
      // console.log('projects -->');
      // console.log(projects);
      // console.log('\n\ntracks -->');
      // console.log(tracks);
      // console.log('\n\nmixes -->');
      // console.log(mixes);

      if (hasValue(tracks) && hasValue(mixes)) {
        const _projects: ProjectEntity[] = projects.filter(
          (p: ProjectEntity) => p.id === props.song.projectId
        );

        const _tracks: TrackEntity[] = tracks.filter(
          (t: TrackEntity) =>
            t.projectId === props.song.projectId && t.songId === props.song.id
        );
        const _mixes: MixEntity[] = mixes.filter(
          (m: MixEntity) => m.songId === props.song.id
        );

        // console.log('\n\nmixes filtered -->');
        // console.log(_mixes);

        const project = _projects.find(
          (p: ProjectEntity) => p.id === props.song.projectId
        );
        const track = _tracks.find(
          (_track: TrackEntity) => _track.songId === props.song.id
        );
        const mix = _mixes.find(
          (_mix: MixEntity) => _mix.songId === props.song.id
        );

        // console.log('project -->');
        // console.log(project);
        // console.log('\n\ntrack -->');
        // console.log(track);
        // console.log('\n\nmix -->');
        // console.log(mix);

        if (project && track && mix) {
          return {
            project,
            track,
            mix
          };
        }
      }

      return null;
    }
  );
};

/*

export const currentTrackAndTrackMixEntitiesFn = (trackId: string) =>
  createSelector(selectTrackState, mixSate, (state: EntityState<TrackEntity>, mixState: MixState) => {
    const isCurrentTrack = getIsCurrentTrackEntityByIdFn({trackId})(state);
    const trackEntity: TrackEntity | null | undefined = getTrackEntityByIdFn({trackId})(state);
    const trackMix = getCurrentTrackMixByTrackIdFn({trackId})(state);
  });
*/

export const currentTrackByIdAndTrackMixEntitiesFn =
  (trackId: string) =>
  (
    state: StudioAppState
  ): IsCurrentTrackAndTrackEntityAndAudioBufferMetaDataEntities | null => {
    const isCurrentTrack = getIsCurrentTrackEntityByIdFn({trackId})(state);
    const trackEntity: TrackEntity | null | undefined = getTrackEntityByIdFn({
      trackId
    })(state);
    const trackMix: TrackMix | null = getCurrentTrackMixByTrackIdFn({trackId})(
      state
    );

    if (trackEntity && trackMix) {
      return {
        ...getTrackEntityAndAudioBufferMetaDataEntityFn({
          trackMix,
          trackEntity
        })(state),
        isCurrentTrack
      };
    }

    return null;
  };

export const selectCurrentMixAndTrackMixEntitiesByTrackId = (
  trackId: string
) => {
  return (state: StudioAppState): MixAndTrackMixEntity | null => {
    const trackMix: TrackMix | null = getTrackMixByTrackIdFn({trackId})(state);
    const mix: MixEntity | null = selectCurrentMixEntity(state);

    if (mix && trackMix) {
      return {
        mix,
        trackMix
      };
    }

    return null;
  };
};

export const selectAudioComponentCombinedFn =
  (trackId: string) =>
  (state: StudioAppState): AudioComponentCombined | null => {
    const trackEntity: TrackEntity | null | undefined = getTrackEntityByIdFn({
      trackId
    })(state);
    const trackMix: TrackMix | null = getTrackMixByTrackIdFn({trackId})(state);

    if (trackMix && trackEntity) {
      const t: TrackEntityAndAudioBufferMetaDataEntities =
        getTrackEntityAndAudioBufferMetaDataEntityFn({
          trackMix,
          trackEntity
        })(state);

      return {
        trackId,
        trackEntity,
        trackMix,
        t
      };
    }

    return null;
  };
