import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpRecordLatencyContentComponent} from './help-record-latency-content/help-record-latency-content.component';
import {HelpRecordLatencyComponent} from './help-record-latency.component';

@NgModule({
  declarations: [HelpRecordLatencyComponent, HelpRecordLatencyContentComponent],
  exports: [HelpRecordLatencyComponent, HelpRecordLatencyContentComponent],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpRecordLatencyModule {}
