import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {
  deviceDetectionInitialState,
  initialAccountState,
  initialWebsocketRegistryState
} from '@spout/global-web/models';
import {WINDOW_PROVIDERS} from '@spout/global-web/utils';
import {AccountEffects} from './+account/account.effects';
import {accountReducer} from './+account/account.reducer';
import {AccountService} from './+account/account.service';
import {AuthLogoutWatchService} from './+account/auth-logout-watch.service';
import {TrackCloudStorageService} from './+account/track-cloud-storage.service';
import {ConsoleStore} from './+console/console.store';
import {DeviceDetectionEffects} from './+device-detection/device-detection.effects';
import {deviceDetectionReducer} from './+device-detection/device-detection.reducer';
import {DeviceDetectionService} from './+device-detection/device-detection.service';
import {GoogleAnalyticsEffects} from './+google-analytics/google-analytics.effects';

/**
 * In App Purchase
 */
import {
  appStoreInitialStates,
  appStoreReducers,
  appStoresEffects
} from './+in-app-purchase/store';
import * as fromPermission from './+permission/permission.reducer';
import {PermissionEffects} from './+permission/permission.effects';
/**
 * Stripe
 */
import {
  stripeEffects,
  stripeInitialStates,
  stripeReducers,
  stripeServices
} from './+stripe/store';
import {websocketRegistryReducer} from './+websocket-registry/websocket-registry.reducer';
import {PresenceService} from './services/presence.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    StoreModule.forRoot(
      {
        account: accountReducer,
        deviceDetection: deviceDetectionReducer,
        webSocketRegistry: websocketRegistryReducer,
        permissions: fromPermission.reducer,
        // Stripe
        ...stripeReducers,
        ...appStoreReducers
      },
      {
        initialState: {
          account: initialAccountState,
          deviceDetection: deviceDetectionInitialState,
          webSocketRegistry: initialWebsocketRegistryState,
          permissions: fromPermission.permissionsInitialState,
          // Stripe
          ...stripeInitialStates,
          ...appStoreInitialStates
        },
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: false,
          strictActionTypeUniqueness: false
        }
      }
    ),
    EffectsModule.forRoot([
      AccountEffects,
      DeviceDetectionEffects,
      GoogleAnalyticsEffects,
      PermissionEffects,

      // Stripe
      ...stripeEffects,
      ...appStoresEffects
    ])
  ],
  providers: [
    WINDOW_PROVIDERS,
    AuthLogoutWatchService,
    AccountService,
    ConsoleStore,
    DeviceDetectionService,
    PresenceService,
    TrackCloudStorageService,
    ...stripeServices
  ]
})
export class DataAccessStudioSiteStoreModule {}
