import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {SptRecorderService} from '@spout/global-web/data-access';

@Component({
  selector: 'spt-help-latency-vs-offset-content',
  templateUrl: './help-latency-vs-offset-content.component.html',
  styleUrls: ['./help-latency-vs-offset-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpLatencyVsOffsetContentComponent {
  constructor(private _recorder: SptRecorderService) {}
  refresh(): void {
    this._recorder.refreshAudioDeviceList().subscribe(() => {});
  }
}
