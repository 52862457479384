import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  CurrentIds,
  SongEntity,
  TrackConfig,
  TrackEntity,
  TrackState
} from '@spout/global-any/models';

import {
  colorBackground,
  colorBorder,
  colorD3Path,
  colorFormFieldInput,
  colorIconText,
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  getDefaultEntityConfig,
  isCreatedByLoggedInMusician
} from '@spout/global-web/fns';
import {AccountState, TRACK_FEATURE_KEY} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {selectAccountState, selectUid} from '../+account/account.selectors';
import {selectCurrentIDsFromStore} from '../+device-storage/device-storage.selectors';
import {
  selectCurrentSongEntity,
  selectCurrentSongId
} from '../+songs/song-storage.selectors';

// TRACK TRACK TRACK TRACK
// TRACK TRACK TRACK TRACK
// TRACK TRACK TRACK TRACK
// TRACK TRACK TRACK TRACK

export const selectTrackState =
  createFeatureSelector<TrackState>(TRACK_FEATURE_KEY);
export const selectTrackState_passThrough =
  createPassThroughFeatureSelector<TrackState>(TRACK_FEATURE_KEY);

export const selectTrackEntities = createSelector(
  selectTrackState,
  (state: TrackState): Dictionary<TrackEntity> => {
    return state.entities;
  }
);

export const selectAllTracks = createSelector(
  selectTrackState,
  (state: TrackState): TrackEntity[] => {
    if (state.entities) {
      return Object.values(state.entities) as TrackEntity[];
    }

    return [];
  }
);

export const getAllTracksBySongIdFn = (props: {songId: string}) => {
  return createSelector(
    selectAllTracks,
    (tracks: TrackEntity[]): TrackEntity[] => {
      return tracks.filter(
        (track: TrackEntity) => track.songId === props.songId
      );
    }
  );
};

export const getAllTracksByProjectId = createSelector(
  selectAllTracks,
  (tracks: TrackEntity[], props: {projectId: string}): TrackEntity[] => {
    return tracks.filter(
      (track: TrackEntity) => track.projectId === props.projectId
    );
  }
);

export const getAllTracksByProjectIdFn = (projectId: string) =>
  createSelector(selectAllTracks, (tracks: TrackEntity[]) => {
    return tracks.filter((track: TrackEntity) => track.projectId === projectId);
  });

export const selectDefaultTrack = createSelector(
  selectAllTracks,
  selectAccountState,
  (entities: TrackEntity[], account: AccountState): TrackEntity | null => {
    return <TrackEntity>(
      getDefaultEntityConfig(
        <{isDefault: boolean; createdByUID: string}[]>entities,
        account
      )
    );
  }
);

export const selectCurrentTrackEntity = createSelector(
  selectAllTracks,
  selectCurrentSongEntity,
  selectCurrentIDsFromStore,
  (
    tracks: TrackEntity[],
    currentSong: SongEntity | null | undefined,
    ids: CurrentIds | null
  ): TrackEntity | undefined => {
    if (tracks && tracks.length && currentSong) {
      let songTracks: TrackEntity[] = tracks.filter(
        (t: TrackEntity) => t !== undefined && t.songId === currentSong.id
      );

      if (songTracks && songTracks.length && ids) {
        let currentTrack = songTracks.find(
          (t: TrackEntity) => t.id === ids.currentTrackId
        );

        if (currentTrack) {
          return currentTrack;
        }

        return songTracks[0];
      }
    }

    return (<TrackEntity[]>tracks).find(
      (track: TrackEntity) => track && ids && track.id === ids.currentTrackId
    );
  }
);

// export const selectIsTrackOwner = createPassThroughSelector(
//   selectCurrentTrackEntity,
//   selectUid,
//   (song: TrackEntity | undefined, uid: string | null) => {
//     if (song && uid) {
//       if (song.members[uid]) {
//         return song.members[uid].role.owner;
//       } else {
//         return false;
//       }
//     }
//
//     return false;
//   }
// );

export const getIsCurrentTrackEntityByIdFn = (props: {trackId: string}) =>
  createPassThroughSelector(
    selectCurrentTrackEntity,
    (currentTrack: TrackEntity | undefined): boolean =>
      currentTrack !== undefined && currentTrack.id === props.trackId
  );

export const selectedCurrentTrackEntityIsCreatedByLoggedInMusician =
  createPassThroughSelector(
    selectCurrentTrackEntity,
    selectUid,
    (
      trackEntity: TrackEntity | undefined,
      email: string | undefined
    ): boolean => {
      if (trackEntity && email) {
        return isCreatedByLoggedInMusician(email, trackEntity);
      }

      return false;
    }
  );

export const selectedNotCurrentTrackEntityIsCreatedByLoggedInMusician =
  createPassThroughSelector(
    selectCurrentTrackEntity,
    selectUid,
    (
      trackEntity: TrackEntity | undefined,
      uid: string | undefined
    ): boolean => {
      if (trackEntity && uid) {
        return !isCreatedByLoggedInMusician(uid, trackEntity);
      }
      return false;
    }
  );

export const selectCurrentTracks = createSelector(
  selectCurrentSongId,
  selectAllTracks,
  (_currentSongId: string | null, _allTracks: TrackEntity[]): TrackEntity[] => {
    return _allTracks.filter(
      (track: TrackEntity) => track.songId === _currentSongId
    );
  }
);

export const selectCurrentSongTrackCount = createPassThroughSelector(
  selectCurrentTracks,
  (tracks: TrackEntity[]): number => {
    return tracks ? tracks.length : 0;
  }
);

export const getNotHasSelectedTrack = createPassThroughSelector(
  selectCurrentTrackEntity,
  (currentTrack: TrackConfig | undefined): boolean => !hasValue(currentTrack)
);

export const selectCurrentTrackName = createPassThroughSelector(
  selectCurrentTrackEntity,
  (trackConfig: TrackEntity | undefined) =>
    trackConfig ? trackConfig.name : ''
);

export const selectCurrentTrackId = createPassThroughSelector(
  selectCurrentTrackEntity,
  (trackConfig: TrackEntity | undefined) => (trackConfig ? trackConfig.id : '')
);

export const selectedTrackIconTextColor = createPassThroughSelector(
  selectCurrentTrackEntity,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color
      ? colorIconText(currentTrack.color)
      : null
);

export const selectedTrackD3Path = createPassThroughSelector(
  selectCurrentTrackEntity,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color ? colorD3Path(currentTrack.color) : null
);

// export const selectedTrackColorConversation = createPassThroughSelector(selectCurrentTrackEntity, (currentTrack: TrackEntity) =>
//   currentTrack && currentTrack.color ? colorConversation(currentTrack.color) : null
// );

export const selectedTrackColorBackground = createPassThroughSelector(
  selectCurrentTrackEntity,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color
      ? colorBackground(currentTrack.color)
      : null
);

export const selectedTrackColorFormFieldInput = createPassThroughSelector(
  selectCurrentTrackEntity,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color
      ? colorFormFieldInput(currentTrack.color)
      : null
);

export const selectedTrackColorBorder = createPassThroughSelector(
  selectCurrentTrackEntity,
  (currentTrack: TrackEntity | undefined) =>
    currentTrack && currentTrack.color ? colorBorder(currentTrack.color) : null
);

export const getTrackEntityByIdFn = (props: {trackId: string}) =>
  createPassThroughSelector(
    selectTrackEntities,
    (entities: Dictionary<TrackEntity>): TrackEntity | null | undefined => {
      if (entities[props.trackId]) {
        return entities[props.trackId];
      }
      return null;
    }
  );

export const selectedColorIconTextCSS = createPassThroughSelector(
  selectCurrentTrackEntity,
  (track: TrackEntity | undefined): string | null | undefined => {
    if (track && track.color) {
      return colorIconText(track.color);
    }
    return null;
  }
);
