import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {TooltipModule} from 'ng2-tooltip-directive';
import {SptResetBtnComponent} from './spt-reset-btn.component';

@NgModule({
  declarations: [SptResetBtnComponent],
  exports: [SptResetBtnComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    OverlayModule,
    TooltipModule
  ]
})
export class SptResetBtnModule {}
