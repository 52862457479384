import {Observable, Observer} from 'rxjs';

export async function clearIndexedDBCached() {
  console.log('Clearing IndexedDB');
  const dbs = await (<any>window.indexedDB).databases();
  dbs.forEach((db: any) => {
    window.indexedDB.deleteDatabase(db.name);
  });
}

export function clearIndexedDBCached$(): Observable<boolean> {
  return new Observable((observer: Observer<any>) => {
    (<any>window.indexedDB).databases().then((dbs: any) => {
      dbs.forEach((db: any) => {
        window.indexedDB.deleteDatabase(db.name);
      });

      observer.next(true);
    });
  });
}
