import {Action, createReducer, on} from '@ngrx/store';
import {
  IEnvironmentState,
  initialEnvironmentState
} from '@spout/global-web/models';
import {loadEnvironment} from './environment.actions';

const reducer = createReducer(
  initialEnvironmentState,
  on(
    loadEnvironment,
    (state: IEnvironmentState, action: {payload: IEnvironmentState}) => {
      return {
        ...state,
        ...action.payload
      };
    }
  )
);

export function environmentReducer(
  state: IEnvironmentState | undefined,
  action: Action
) {
  return reducer(state, action);
}
