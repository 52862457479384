import {Injectable, NgZone} from '@angular/core';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {firestorePermissions} from '@spout/global-web/fns';
import {
  ACCOUNT_ROLE,
  AccountState,
  FeaturePermission
} from '@spout/global-web/models';
import {selectMetaDataRoleFromSubscription} from '../+stripe';
import {AbstractConnectionService} from '../firebase/abstract-connection.service';
import {CustomFirestoreService} from '../firebase/custom-firestore.service';
import {FirestoreCollectionQuery} from '../firebase/firestore-query';
import {FirestoreWebSocketConnectorService} from '../firebase/firestore-web-socket-connector.service';
import {
  addPermissionAccountRole,
  deletePermissions,
  updatePermissions,
  upsertPermissions
} from './permission.actions';
import {permissionsFeatureKey} from './permission.reducer';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends AbstractConnectionService {
  private _queryService: FirestoreCollectionQuery<FeaturePermission>;

  constructor(
    private _firestore: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    override _store: Store,
    private _zone: NgZone
  ) {
    super(permissionsFeatureKey, _connector, _store);

    const that = this;

    this._queryService = new FirestoreCollectionQuery<FeaturePermission>(
      {
        queryMember: false,
        upsertManyAction: (permissions: FeaturePermission[]) =>
          upsertPermissions({permissions}),
        updateManyAggregatedAction: (
          permissions: Update<FeaturePermission>[]
        ) => updatePermissions({permissions}),
        deleteManyAction: (ids: string[]) => deletePermissions({ids})
      },
      _zone,
      _store,
      _firestore
    );

    this._store
      .pipe(select(selectMetaDataRoleFromSubscription))
      .subscribe((role: ACCOUNT_ROLE) => {
        this._store.dispatch(addPermissionAccountRole({role}));
      });
  }

  onConnect(user: AccountState) {
    const that = this;

    this._connector.keyIsConnected(permissionsFeatureKey);

    this._queryService.onConnect(firestorePermissions());
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query
    this._queryService.onDisconnect();

    // Unsubscribe to query before calling this
    this._connector.keyIsDisconnected(permissionsFeatureKey);
  }
}
