import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {UpdateStr} from '@ngrx/entity/src/models';
import {select, Store} from '@ngrx/store';
import {
  AudioFileMetaDataEntity,
  CurrentIds,
  MixEntity,
  ProjectEntity,
  SongEntity,
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet
} from '@spout/global-any/models';
import {
  addUniqueItemToCollection,
  assignTrackMixAudioFileMetaMethod,
  createInitialAudioFileMetaDataEntity,
  createInitialMixEntityWithSong,
  createInitialProjectEntity,
  createInitialSongEntity,
  createInitialTrackEntity,
  createInitialTrackMixConfig,
  firestoreProjectByIdPath,
  firestoreProjectsPath,
  removeItemFromCollection,
  setTrackMixProps
} from '@spout/global-web/fns';
import {
  AccountState,
  AggregateFirebaseSnapshotChangesUpdate,
  ChatEntity
} from '@spout/global-web/models';
import {assignMethod, objectMethodAssign} from '@uiux/fn';
import {
  DocumentChange,
  DocumentData,
  onSnapshot,
  query,
  QuerySnapshot,
  where
} from '@firebase/firestore';

import {
  combineLatest,
  EMPTY,
  Observable,
  Observer,
  of,
  Subject,
  Subscription
} from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {selectAccountState} from '../+account/account.selectors';
import {ProfilesService} from '../+account/profiles.service';
import {getAudioMetaDataFilesByIDs} from '../+audio-file-meta-data/audio-metadata-storage.selectors';
import {getAllChatsByProjectId} from '../+chat/chat.selectors';
import {ChatService} from '../+chat/chat.service';
import {setDeviceStoreCurrentIdsFromTrackAndMixEntity} from '../+device-storage/device-storage.actions';
import {selectCurrentIDsFromStore} from '../+device-storage/device-storage.selectors';
import {AudioFileDeleteService} from '../+device-storage/services/audio-file-delete.service';
import {getMixEntitiesByProjectIdFn} from '../+mixes/mix-storage.selectors';
import {MixesService} from '../+mixes/mixes.service';
import {getSongsByProjectIdFn} from '../+songs/song-storage.selectors';
import {SongService} from '../+songs/song.service';
import {getAllTrackMixesByProjectId} from '../+track-mix/track-mix.selectors';
import {TrackMixesService} from '../+track-mix/track-mixes.service';
import {FirestoreTracksService} from '../+tracks/firestore-tracks.service';
import {getAllTracksByProjectIdFn} from '../+tracks/track-storage.selectors';
import {upsertWebsocketRegistry} from '../+websocket-registry/websocket-registry.actions';
import {selectAuthAccountConnect$} from '../+websocket-registry/websocket-registry.selectors';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';
import {
  addProjectAction,
  createProjectAction
} from '../actions/create-entites.actions';
import {SptPlayerCacheService} from '../audio/services/spt-player-cache.service';
import {
  CustomFirestoreService,
  Exists,
  removeTimestampCTorFromDocumentSnapshot
} from '../firebase';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {FirebaseStorageService} from '../services/firebase-storage.service';
import {releaseStoreToSelect} from '../services/release-store';
import {
  getProjectEntityById,
  selectCurrentProjectEntity
} from './project-storage.selectors';
import {
  addMusicianToCurrentProject,
  deleteProjectEffect,
  deleteProjects,
  removeMusicianFromCurrentProject,
  selectedProjectEntity,
  selectProject,
  selectProjectEffect,
  updateMusicianToCurrentProject,
  updateProject,
  updateProjects,
  upsertProjects
} from './project.actions';
import {ProjectService} from './project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectEffects {
  // initProjectEffect$ = createEffect(() => this.actions$.pipe(ofType(initProjectsEffects)), {
  //   dispatch: false,
  // });

  addProjectAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProjectAction),
      withLatestFrom(this.store.pipe(select(selectAccountState))),
      map(([action, account]) => {
        const projectEntity: ProjectEntity =
          createInitialProjectEntity(account);
        projectEntity.name = action.projectName;
        projectEntity.description = action.projectDescription;

        const songEntity = createInitialSongEntity(account, projectEntity);
        songEntity.name = `Song`;
        songEntity.description = `for ${action.projectName}`;

        const trackEntity: TrackEntity = createInitialTrackEntity(
          account,
          songEntity
        );
        trackEntity.name = `Reference Track`;
        trackEntity.description = `for ${songEntity.name}`;
        trackEntity.shared = true;
        trackEntity.isDefault = true;

        const audioFileEntity = createInitialAudioFileMetaDataEntity(
          account,
          songEntity
        );
        audioFileEntity.name = 'Audio Reference';

        const mixEntity = createInitialMixEntityWithSong(account, songEntity);
        mixEntity.name = `Mix`;
        mixEntity.description = `for ${songEntity.name}`;
        mixEntity.isDefault = true;

        const trackMix = objectMethodAssign<TrackMix>(
          createInitialTrackMixConfig(
            account,
            trackEntity,
            mixEntity.id,
            audioFileEntity
          )
        ).pipe(
          assignMethod<TrackMix>(setTrackMixProps, {isReference: true}),
          assignMethod<TrackMix, TrackMixAudioSnippet>(
            assignTrackMixAudioFileMetaMethod(audioFileEntity),
            {
              isDefault: true
            }
          )
        );

        return {
          projectEntity,
          songEntity,
          trackEntity,
          audioFileEntity,
          mixEntity,
          trackMix
        };
      }),
      switchMap(d => {
        return this.playerCache.muteAllPipe$().pipe(map(() => d));
      }),
      switchMap(
        ({
          projectEntity,
          songEntity,
          trackEntity,
          audioFileEntity,
          mixEntity,
          trackMix
        }) => {
          releaseStoreToSelect();
          return this.upsertFirestoreDoc(projectEntity).pipe(
            map(() => {
              return createProjectAction({
                project: projectEntity,
                song: songEntity,
                mix: mixEntity,
                track: trackEntity,
                file: audioFileEntity,
                trackMix
              });
            })
          );
        }
      )
    )
  );

  selectProjectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectProjectEffect),
      mergeMap(action => {
        return new Observable((observer: Observer<any>) => {
          observer.next(
            selectProject({
              project: action.project
            })
          );
        });
      })
    )
  );

  createDefaultProjectEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createDefaultConfigsToFirestore),
        switchMap(action => {
          if (action.project) {
            return this.projectService.createProject(action.project);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  updateProjectEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateProject),
        mergeMap(action => {
          return this.sptFirestore.update<ProjectEntity>(
            firestoreProjectByIdPath(action.project.changes),
            action.project.changes
          );
        })
      ),
    {dispatch: false}
  );

  addMusicianToCurrentProject$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(addMusicianToCurrentProject),
        switchMap(action => {
          return this.store.pipe(
            select(selectCurrentProjectEntity),
            take(1),
            switchMap((projectEntity: ProjectEntity | undefined | null) => {
              if (projectEntity) {
                let payload = <ProjectEntity>{
                  members: {
                    ...projectEntity?.members,
                    [action.musician.uid]: action.musician
                  },
                  memberUIDs: addUniqueItemToCollection(
                    projectEntity?.memberUIDs,
                    action.musician.uid
                  ),
                  owners: {
                    ...projectEntity?.owners
                  }
                };

                if (action.musician.role.owner) {
                  payload = {
                    ...payload,
                    owners: {
                      ...projectEntity?.owners,
                      ...payload.owners,
                      [action.musician.uid]: action.musician
                    },
                    ownerUIDs: addUniqueItemToCollection(
                      projectEntity?.ownerUIDs,
                      action.musician.uid
                    )
                  };
                }

                return this.sptFirestore
                  .update<ProjectEntity>(
                    firestoreProjectByIdPath(projectEntity),
                    payload
                  )
                  .pipe(
                    map((r: Exists<ProjectEntity>) => {
                      return r.data;
                    })
                  );
              }

              return EMPTY;
            })
          );
        })
      );
    },
    {dispatch: false}
  );

  updateMusicianToCurrentProject$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateMusicianToCurrentProject),
        switchMap(action => {
          return this.store.pipe(
            select(selectCurrentProjectEntity),
            take(1),
            switchMap((projectEntity: ProjectEntity | undefined | null) => {
              if (projectEntity) {
                let payload = <ProjectEntity>{
                  members: {
                    ...projectEntity?.members,
                    [action.musician.uid]: action.musician
                  },
                  memberUIDs: addUniqueItemToCollection(
                    projectEntity?.memberUIDs,
                    action.musician.uid
                  ),
                  owners: {
                    ...projectEntity?.owners
                  }
                };

                if (action.musician.role.owner) {
                  payload = {
                    ...payload,
                    owners: {
                      ...projectEntity?.owners,
                      ...payload.owners,
                      [action.musician.uid]: action.musician
                    },
                    ownerUIDs: addUniqueItemToCollection(
                      projectEntity?.ownerUIDs,
                      action.musician.uid
                    )
                  };
                } else {
                  delete payload.owners[action.musician.uid];
                  payload = {
                    ...payload,
                    ownerUIDs: removeItemFromCollection(
                      payload.ownerUIDs,
                      action.musician.uid
                    )
                  };
                }

                return this.sptFirestore
                  .update<ProjectEntity>(
                    firestoreProjectByIdPath(projectEntity),
                    payload
                  )
                  .pipe(
                    map((r: Exists<ProjectEntity>) => {
                      return r.data;
                    })
                  );
              }

              return EMPTY;
            })
          );
        })
      );
    },
    {dispatch: false}
  );

  removeMusicianFromCurrentProject$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(removeMusicianFromCurrentProject),
        switchMap(action => {
          return this.store.pipe(
            select(selectCurrentProjectEntity),
            take(1),
            switchMap((projectEntity: ProjectEntity | undefined | null) => {
              if (projectEntity) {
                let payload = <ProjectEntity>{
                  members: {
                    ...projectEntity?.members
                  },
                  memberUIDs: removeItemFromCollection(
                    projectEntity?.memberUIDs,
                    action.musician.uid
                  ),
                  owners: {
                    ...projectEntity?.owners
                  },
                  ownerUIDs: removeItemFromCollection(
                    projectEntity?.ownerUIDs,
                    action.musician.uid
                  )
                };

                delete payload.members[action.musician.uid];
                delete payload.owners[action.musician.uid];

                return this.sptFirestore
                  .update<ProjectEntity>(
                    firestoreProjectByIdPath(projectEntity),
                    payload
                  )
                  .pipe(
                    map((r: Exists<ProjectEntity>) => {
                      return r.data;
                    })
                  );
              }

              return EMPTY;
            })
          );
        })
      );
    },
    {dispatch: false}
  );

  selectedProjectEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeviceStoreCurrentIdsFromTrackAndMixEntity),
      switchMap(action =>
        this.store.pipe(
          select(getProjectEntityById(action.track?.projectId)),
          take(1),
          switchMap((projectEntity: ProjectEntity | undefined) => {
            if (projectEntity) {
              return of(selectedProjectEntity({projectEntity}));
            }
            return EMPTY;
          })
        )
      )
    )
  );

  deleteProjectEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteProjectEffect),
        switchMap(action =>
          combineLatest([
            this.store.pipe(
              select(getAllTrackMixesByProjectId(action.project.id))
            ),
            this.store.pipe(
              select(getAllTracksByProjectIdFn(action.project.id))
            ),
            this.store.pipe(
              select(getMixEntitiesByProjectIdFn(action.project.id))
            ),
            this.store.pipe(select(getAllChatsByProjectId(action.project.id))),
            this.store.pipe(select(getSongsByProjectIdFn(action.project.id)))
          ]).pipe(
            take(1),
            switchMap(
              ([trackMixes, tracks, mixes, chats, songs]: [
                TrackMix[],
                TrackEntity[],
                MixEntity[],
                ChatEntity[],
                SongEntity[]
              ]) => {
                const audioFileMetaDataIds: string[] = trackMixes
                  .map((t: TrackMix) => Object.keys(t.audioSnippets))
                  // @ts-ignore
                  .flat();

                return this.store.pipe(
                  select(getAudioMetaDataFilesByIDs(audioFileMetaDataIds)),
                  switchMap((files: AudioFileMetaDataEntity[]) => {
                    return combineLatest([
                      // files
                      this.fileDeleteService.deleteProjectFromFileSystem(
                        action.project.id
                      ),
                      this._storage
                        .deleteAudioMetaDataFilesFromStorage(files)
                        .pipe(catchError(() => of(true))),

                      // track mixes
                      this._trackMixService
                        .batchDeleteFirestoreTrackMixes(trackMixes)
                        .pipe(catchError(() => of(true))),

                      // chats
                      this._chatService
                        .deleteChats(chats)
                        .pipe(catchError(() => of(true))),

                      // tracks
                      this._trackService
                        .deleteTracks(tracks)
                        .pipe(catchError(() => of(true))),

                      // mixes
                      this._mixService
                        .deleteMixes(mixes)
                        .pipe(catchError(() => of(true))),

                      // songs
                      this._songService
                        .deleteSongs(songs)
                        .pipe(catchError(() => of(true))),

                      // project
                      this._projectService
                        .deleteProject(action.project)
                        .pipe(catchError(() => of(true)))
                    ]).pipe(
                      // TODO NEXT STEPS - PROJECT IS NOT REMOVED FROM STORE WHEN DELETED FROM FIREBASE
                      // TODO - SEE IF THE ISSUE IS IN THE PROJECT SERVICE ( WHERE PROJECTS ARE LOADED )
                      // TODO - HOW DOES THE USER AND REMOVE USER CHANGE PROJECTS AFTER IT'S DELETED
                      // TODO - ONLY ALLOW PROJECT OWNERS TO DELETE THEIR OWN PROJECTS
                      // TODO - PUT NAME OF CREATOR IN PROJECT LIST ( SMALL TEXT ) - and user icon

                      switchMap(() =>
                        this.deleteFirestoreProjectPath(
                          firestoreProjectByIdPath(action.project)
                        )
                      )
                    );
                  })
                );
              }
            )
          )
        )
      ),
    {dispatch: false}
  );

  private memberOfProjectsSub: Subscription = Subscription.EMPTY;
  private projectsIOwnSub: Subscription = Subscription.EMPTY;
  private CONNECTION_KEY = 'projects_feature_key';
  private _onDestroy$: Subject<boolean> = new Subject();

  private onSnapshotMemberUnsubscribe: (() => void) | undefined;
  private onSnapshotOwnerUnsubscribe: (() => void) | undefined;

  constructor(
    private dss: DynamicStoreService,
    private actions$: Actions,
    private sptFirestore: CustomFirestoreService,
    private store: Store,
    private projectService: ProjectService,
    private router: Router,
    private playerCache: SptPlayerCacheService,
    private profilesService: ProfilesService,
    private zone: NgZone,
    private fileDeleteService: AudioFileDeleteService,
    private _storage: FirebaseStorageService,

    private _trackMixService: TrackMixesService,
    private _chatService: ChatService,
    private _trackService: FirestoreTracksService,
    private _mixService: MixesService,
    private _songService: SongService,
    private _projectService: ProjectService
  ) {
    // console.log('PROJECT EFFECTS');
    const that = this;
    that.zone.run(() => {
      that.store.dispatch(
        upsertWebsocketRegistry({
          id: that.CONNECTION_KEY
        })
      );
    });

    this.store.pipe(selectAuthAccountConnect$).subscribe(s => {
      if (s.doConnect) {
        this.onConnect.call(this, s.user);
      } else {
        this.onDisconnect.call(this);
      }
    });

    this.store
      .pipe(select(selectCurrentProjectEntity))
      .subscribe((projectEntity: ProjectEntity | undefined | null) => {
        if (projectEntity) {
          that.zone.run(() => {
            this.store.dispatch(selectedProjectEntity({projectEntity}));
          });
        }
      });
  }

  // ngrxOnInitEffects(): Action {
  //   // console.log('ngrxOnInitEffects LOGIN PROJECT EFFECTS', ProjectActionTypes.InitProjectsEffects);
  //   return { type: ProjectActionTypes.InitProjectsEffects };
  // }

  upsertFirestoreDoc(
    project: Partial<ProjectEntity>
  ): Observable<Exists<ProjectEntity>> {
    return this.sptFirestore.upsertDoc<ProjectEntity>(
      firestoreProjectByIdPath(project),
      project
    );

    // return this.store.pipe(
    //   select(getProjectById, { id: project.id }),
    //   filter((projectEntity: ProjectEntity) => {
    //     return projectEntity !== undefined && projectEntity !== null;
    //   }),
    //   take(1),
    //   switchMap((projectEntity: ProjectEntity) => {
    //     const config: ProjectEntity = Object.assign(projectEntity, project);
    //
    //     return this.sptFirestore.upsert<ProjectEntity>(firestoreProjectByIdPath(config), config);
    //   })
    // );
  }

  private deleteFirestoreProjectPath(path: string) {
    return new Observable((observer: Observer<any>) => {
      console.log('Deleting', path);
      const recursiveDeleteFn =
        this.sptFirestore.httpsCallable('recursiveDelete');

      recursiveDeleteFn({path: path})
        .then(function (result: any) {
          console.log('Delete success: ' + JSON.stringify(result, null, 2));
          observer.next(true);
          observer.complete();
        })
        .catch(function (err: any) {
          console.warn(err);
          observer.next(true);
          observer.complete();
        });
    });
  }

  onConnect(user: AccountState): void {
    const that = this;

    this.sptFirestore.setWebSocketConnected(this.CONNECTION_KEY);

    const _ref = this.sptFirestore.collectionRef(firestoreProjectsPath());
    const _refOwner = this.sptFirestore.collectionRef(firestoreProjectsPath());

    const owner$: Observable<DocumentChange[]> = new Observable(
      (observer: Observer<DocumentChange[]>) => {
        const q = query(
          _refOwner,
          where('ownerUIDs', 'array-contains', user.uid)
        );
        // Snapshots always return object, even if no data
        this.onSnapshotOwnerUnsubscribe = onSnapshot(
          q,
          (snapshot: QuerySnapshot) => {
            observer.next(snapshot.docChanges());
            // that.processProjects.apply(that, [snapshot, user.auth.uid]);
          }
        );
      }
    );

    const member$: Observable<DocumentChange[]> = new Observable(
      (observer: Observer<DocumentChange[]>) => {
        const q = query(_ref, where('memberUIDs', 'array-contains', user.uid));

        this.onSnapshotMemberUnsubscribe = onSnapshot(
          q,
          (snapshot: QuerySnapshot) => {
            observer.next(snapshot.docChanges());
            // that.processProjects.apply(that, [snapshot, user.auth.uid]);
          }
        );
      }
    );

    combineLatest([owner$, member$])
      .pipe(
        map(([owner, member]: [DocumentChange[], DocumentChange[]]) => {
          return [...owner, ...member];
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((r: DocumentChange[]) => {
        if (user.uid) {
          that.processProjects.apply(that, [r, user.uid]);
        }
      });
  }

  /**
   *
   * @param snapshot
   * @param createdByUID
   * @param isOwnerSet = if this set are the projects created by the loggeed
   * in user
   */
  processProjects(snapshot: DocumentData[], createdByUID: string) {
    const that = this;
    // const source = snapshot.metadata.hasPendingWrites ? 'Local' : 'Server';
    // console.log(source, " data: ", result);
    // if ( snapshot.metadata.hasPendingWrites ) {
    //   return;
    // }

    // const changes: firebase.firestore.DocumentChange[] = snapshot.docChanges();
    this.store
      .select(selectCurrentIDsFromStore)
      .pipe(take(1))
      .subscribe((ids: CurrentIds) => {
        that.zone.run(() => {
          const aggregate: AggregateFirebaseSnapshotChangesUpdate<ProjectEntity> =
            snapshot.reduce(
              (
                acc: AggregateFirebaseSnapshotChangesUpdate<ProjectEntity>,
                change: DocumentData
              ) => {
                const data: ProjectEntity =
                  removeTimestampCTorFromDocumentSnapshot(change['doc']);

                acc.all.push(data);

                // if (data.id === ids.currentProjectId) {
                //   acc.selected = data;
                // }
                //
                // if (data.isDefault) {
                //   acc.default = data;
                // }

                if (change['type'] === 'added') {
                  // console.log('New Project: ', change.doc.data());
                  acc.added.push(data);
                }

                if (change['type'] === 'modified') {
                  // console.log('Modified Project: ', change.doc.data());
                  acc.modified.push(<UpdateStr<ProjectEntity>>{
                    id: data.id,
                    changes: data
                  });
                }

                if (change['type'] === 'removed') {
                  // console.log('Removed Track: ', data);
                  acc.removed.push(data.id);
                }

                return acc;
              },
              <AggregateFirebaseSnapshotChangesUpdate<ProjectEntity>>{
                added: [],
                modified: [],
                removed: [],
                all: []
              }
            );

          if (aggregate.all.length) {
            that.profilesService.addProfileByProjects(aggregate.all);
          }

          // console.log(aggregate);

          if (aggregate.added.length) {
            that.store.dispatch(
              upsertProjects({
                projects: aggregate.added
              })
            );
          }

          if (aggregate.modified.length) {
            that.store.dispatch(
              updateProjects({
                projects: <UpdateStr<ProjectEntity>[]>aggregate.modified
              })
            );
          }

          if (aggregate.removed.length) {
            // since selectors are memoized, get the entire state

            // TODO if project selected, select default project
            that.store.dispatch(
              deleteProjects({
                ids: aggregate.removed
              })
            );
          }
        });
      });
  }

  onDisconnect() {
    this._onDestroy$.next(true);
    // console.log('LOGIN PROJECT EFFECTS DISCONNECT');
    this.memberOfProjectsSub.unsubscribe();
    this.projectsIOwnSub.unsubscribe();
    if (this.onSnapshotMemberUnsubscribe) {
      this.onSnapshotMemberUnsubscribe();
    }
    if (this.onSnapshotOwnerUnsubscribe) {
      this.onSnapshotOwnerUnsubscribe();
    }

    this.sptFirestore.setWebSocketDisconnected(this.CONNECTION_KEY);
  }
}
