import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  Subscriber,
  Subscription,
  SubscriptionLike
} from 'rxjs';

function createErrorClass<T>(createImpl: (_super: any) => any): T {
  const _super = (instance: any) => {
    Error.call(instance);
    instance.name = instance.constructor.name;
    instance.stack = new Error().stack;
  };

  const ctorFunc = createImpl(_super);
  ctorFunc.prototype = Object.create(Error.prototype);
  ctorFunc.prototype.constructor = ctorFunc;
  return ctorFunc;
}

function unlockAudioContext(audioCtx: AudioContext) {
  if (audioCtx.state !== 'suspended') return;
  const b = document.body;
  const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
  events.forEach(e => b.addEventListener(e, unlock, false));
  function unlock() {
    audioCtx.resume().then(clean);
  }
  function clean() {
    events.forEach(e => b.removeEventListener(e, unlock));
  }
}

// tslint:disable-next-line:no-empty-interface
type ObjectUnsubscribedError = Error;

interface ObjectUnsubscribedErrorCtor {
  // tslint:disable-next-line
  new (): ObjectUnsubscribedError;
}

/**
 * An error thrown when an action is invalid because the object has been
 * unsubscribed.
 *
 * @see {@link Subject}
 * @see {@link BehaviorSubject}
 *
 * @class ObjectUnsubscribedError
 */
const ObjectUnsubscribedError: ObjectUnsubscribedErrorCtor = createErrorClass(
  _super =>
    // tslint:disable-next-line:no-shadowed-variable
    function ObjectUnsubscribedError(this: any) {
      _super(this);
      this.message = 'object unsubscribed';
    }
);

export class SptAudioContextSubject extends Subject<AudioContext> {
  private _audioContext!: AudioContext;
  sampleRate$: BehaviorSubject<number>;

  get sampleRate() {
    return this._audioContext.sampleRate;
  }

  get audioContext() {
    return this._audioContext;
  }

  constructor() {
    super();

    // @ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    this._audioContext = new AudioContext({
      latencyHint: 0
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._audioContext['id'] = Date.now();
    this.next(this._audioContext);
    this.sampleRate$ = new BehaviorSubject<number>(
      this._audioContext.sampleRate
    );
  }

  get audioWorklet(): AudioWorklet {
    return this._audioContext.audioWorklet;
  }

  get currentTime(): number {
    return this._audioContext.currentTime;
  }

  protected _subscribe(subscriber: Subscriber<AudioContext>): Subscription {
    // @ts-ignore
    const subscription = super._subscribe(subscriber);
    if (
      subscription &&
      !(<SubscriptionLike>subscription).closed &&
      this._audioContext
    ) {
      subscriber.next(this._audioContext);
    }
    return subscription;
  }

  // set sampleRate(sampleRate: number) {
  //   // initialize
  //   if (!this._audioContext) {
  //     this._audioContext = new AudioContext({
  //       latencyHint: 0,
  //       sampleRate
  //     });
  //
  //     if (this._audioContext) {
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       this._audioContext['id'] = Date.now();
  //       this.sampleRate$.next(this._audioContext.sampleRate);
  //       this.next(this._audioContext);
  //     }
  //   }
  // }

  getValue(): AudioContext {
    if (this.hasError) {
      throw this.thrownError;
    } else if (this.closed) {
      throw new ObjectUnsubscribedError();
    }
    return this._audioContext;
  }

  override next(value: AudioContext): void {
    super.next(value);
  }
}
