import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {ChatEntity, ConversationMessage} from '@spout/global-web/models';

export const loadChat = createAction('[Chat] Load Chat');

export const loadChatFailure = createAction(
  '[Chat] Load Chat Failure',
  props<{error: any}>()
);

export const addChat = createAction(
  '[Chat/API] Add Chat',
  props<{chat: ChatEntity}>()
);

export const upsertChat = createAction(
  '[Chat/API] Upsert Chat',
  props<{chat: ChatEntity}>()
);

export const addChats = createAction(
  '[Chat/API] Add Chats',
  props<{chats: ChatEntity[]}>()
);

export const upsertChats = createAction(
  '[Chat/API] Upsert Chats',
  props<{chats: ChatEntity[]}>()
);

export const updateChat = createAction(
  '[Chat/API] Update Chat',
  props<{chat: Update<ChatEntity>}>()
);

export const updateChats = createAction(
  '[Chat/API] Update Chats',
  props<{chats: Update<ChatEntity>[]}>()
);

export const deleteChat = createAction(
  '[Chat/API] Delete Chat',
  props<{id: string}>()
);

export const deleteChats = createAction(
  '[Chat/API] Delete Chats',
  props<{ids: string[]}>()
);

export const clearChats = createAction('[Chat/API] Clear Chats');

export const sendMessage = createAction(
  '[Chat/API] Send Conversation',
  props<{message: ConversationMessage}>()
);

export const updateConversation = createAction(
  '[Chat/API] Update Conversation',
  props<{conversation: ConversationMessage}>()
);

export const deleteConversation = createAction(
  '[Chat/API] Delete Conversation',
  props<{chats: ChatEntity[]}>()
);

export const deleteMessage = createAction(
  '[Chat/API] Delete Message',
  props<{message: ConversationMessage}>()
);

export const showArchivedChats = createAction(
  '[Chat/API] Show Archived Chats',
  props<{showArchived: boolean}>()
);
