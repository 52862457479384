import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectDeviceIsSmallScreen} from '@spout/global-web/data-access';

@Component({
  selector: 'spt-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'spt-app-footer',
    '[class.show-border]': 'border === true'
  }
})
export class AppFooterComponent implements OnInit {
  deviceIsSmallScreen$ = this._store.pipe(select(selectDeviceIsSmallScreen));

  private _border = false;
  @Input()
  set border(border: boolean) {
    this._border = border;
    this._cd.detectChanges();
  }

  get border() {
    return this._border;
  }

  constructor(private _store: Store, private _cd: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
