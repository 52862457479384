import {Injectable, NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getMusicianAccountPathByUID} from '@spout/global-web/fns';
import {AccountState} from '@spout/global-web/models';
import {EMPTY, Observable, of} from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import {CustomFirestoreService} from '../firebase';
import {getUserFromFirestoreEffect, upsertSptUser} from './spt-user.actions';
import {SptUser} from './spt-user.model';
import {getSptUserByUID} from './spt-user.selectors';

@Injectable({
  providedIn: 'root'
})
export class SptUserService {
  private _gettingUser: {[uid: string]: boolean} = {};

  constructor(
    private _store: Store,
    private _sptFirestore: CustomFirestoreService,
    private _zone: NgZone
  ) {}

  getSptUserStageName(uid: string): Observable<string> {
    return this._store.pipe(
      select(getSptUserByUID(uid)),
      map((user: SptUser | null | undefined) => {
        return user ? user : {uid: 'none', stageName: 'none'};
      }),
      distinctUntilKeyChanged('uid'),
      tap((user: SptUser) => {
        // console.log(uid);
        if (user.uid === 'none' && !this._gettingUser[uid]) {
          this._gettingUser[uid] = true;
          this._getUser(uid);
        }
      }),
      filter((user: SptUser) => user.uid !== 'none'),
      map((user: SptUser | null | undefined) => {
        return user ? user.stageName : '';
      })
    );
  }

  private _getUser(uid: string): void {
    this._sptFirestore
      .docData$<AccountState>(getMusicianAccountPathByUID(uid))
      .subscribe((_user: AccountState) => {
        if (
          _user &&
          _user.uid !== undefined &&
          _user.uid !== null &&
          _user.stageName !== undefined &&
          _user.stageName !== null
        ) {
          this._zone.run(() => {
            this._store.dispatch(
              upsertSptUser({
                sptUser: {
                  // @ts-ignore
                  uid: _user.uid,
                  // @ts-ignore
                  stageName: _user.stageName
                }
              })
            );
          });
        }
      });
  }
}
