import {
  AbstractControl,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

export const passwordsNotMatch: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const password: UntypedFormControl = control.get(
    'password'
  ) as UntypedFormControl;
  const confirmPassword: UntypedFormControl = control.get(
    'confirmPassword'
  ) as UntypedFormControl;

  // Trigger change detection to update error state of input component
  //
  setTimeout(() => {
    /* noop */
  }, 0);

  // console.log(confirmPassword);

  if (
    password &&
    confirmPassword &&
    password.value &&
    confirmPassword.value &&
    password.value !== confirmPassword.value
  ) {
    password.setErrors({passwordsNotMatch: true, required: false});
    confirmPassword.setErrors({passwordsNotMatch: true, required: false});

    // Trigger change detection to update error state of input component
    //
    setTimeout(() => {
      /* noop */
    }, 0);

    return {passwordsNotMatch: true};
  } else if (
    password &&
    confirmPassword &&
    password.value &&
    confirmPassword.value &&
    password.value === confirmPassword.value
  ) {
    // password.setErrors({passwordsNotMatch: false, required: false});
    // confirmPassword.setErrors({passwordsNotMatch: false, required: false});

    password.setErrors(null);
    confirmPassword.setErrors(null);
  }

  // Trigger change detection to update error state of input component
  //
  setTimeout(() => {
    /* noop */
  }, 0);

  return null;
};
