import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {LetModule, PushModule} from '@ngrx/component';
import {DownloadSpoutComponent} from './download-spout.component';

@NgModule({
  declarations: [DownloadSpoutComponent],
  imports: [
    CommonModule,
    LetModule,
    PushModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [DownloadSpoutComponent]
})
export class DownloadSpoutModule {}
