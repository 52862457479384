import {Injectable, NgZone} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  dispatchNgrxFirestoreAggregate,
  firestoreInstrumentsPath
} from '@spout/global-web/fns';
import {
  AccountState,
  AccountStateConnect,
  INSTRUMENT_FEATURE_KEY
} from '@spout/global-web/models';
import {onSnapshot, QuerySnapshot} from '@firebase/firestore';
import {
  upsertWebsocketRegistry,
  websocketIsDisconnectedAction
} from '../+websocket-registry/websocket-registry.actions';
import {
  GetAuthAccountConnect,
  selectAuthAccountConnect$
} from '../+websocket-registry/websocket-registry.selectors';
import {CustomFirestoreService} from '../firebase';
import {OnWebsocketMonitor} from '../websocket-monitor/websocket-monitor';
import {instrumentAggregate} from './instruments.actions';

@Injectable()
export class InstrumentEffects implements OnWebsocketMonitor {
  private _instrumentDatabase: (() => void) | undefined;

  constructor(
    private actions$: Actions,
    private store: Store<AccountStateConnect>,
    private sptFirestore: CustomFirestoreService,
    private zone: NgZone
  ) {
    const that = this;
    that.zone.run(() => {
      this.store.dispatch(
        upsertWebsocketRegistry({
          id: INSTRUMENT_FEATURE_KEY
        })
      );
    });

    this.store
      .pipe(selectAuthAccountConnect$)
      .subscribe((s: GetAuthAccountConnect) => {
        if (s.doConnect) {
          this.onConnect.call(this, s.user);
        } else {
          this.onDisconnect.call(this, s.user);
        }
      });
  }

  onConnect(user: AccountState): void {
    const that = this;
    if (!this._instrumentDatabase) {
      that.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: INSTRUMENT_FEATURE_KEY
          })
        );
      });

      this._instrumentDatabase = onSnapshot(
        this.sptFirestore.collectionRef(firestoreInstrumentsPath()),
        (snapshot: QuerySnapshot) => {
          dispatchNgrxFirestoreAggregate<AccountStateConnect>(
            this.store,
            snapshot,
            instrumentAggregate,
            this.zone
          );
        },
        (err: Error) => {
          console.error(err);
        },
        () => {
          /* noop */
        }
      );
    }
  }

  onDisconnect(user: AccountState): void {
    const that = this;
    if (this._instrumentDatabase) {
      this._instrumentDatabase();

      that.zone.run(() => {
        this.store.dispatch(
          websocketIsDisconnectedAction({
            id: INSTRUMENT_FEATURE_KEY
          })
        );
      });
    }
  }
}
