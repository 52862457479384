import {createSelector} from '@ngrx/store';
import {
  CombinedTrackEntityMix,
  MixEntity,
  SongEntity,
  TrackEntity
} from '@spout/global-any/models';
import {colorIconText} from '@spout/global-web/fns';
import {BulletChartData} from '@spout/global-web/ui';
import {aggregateFileSize} from '../+audio-file-meta-data/audio-metadata-storage.selectors';
import {selectAllMixes} from '../+mixes/mix-storage.selectors';
import {selectCurrentSongEntity} from '../+songs/song-storage.selectors';
import {selectSubscriptionStorage} from '../+stripe';
import {selectAllTracks} from '../+tracks/track-storage.selectors';

export const selectAllMixesByCurrentSong = createSelector(
  selectCurrentSongEntity,
  selectAllMixes,
  (song: SongEntity | null, mixes: MixEntity[]): MixEntity[] => {
    if (song && mixes) {
      return mixes.filter((mix: MixEntity) => mix.songId === song.id);
    }

    return [];
  }
);

export const gettracksAndMixBySongId = createSelector(
  selectAllTracks,
  (
    tracks: TrackEntity[],
    props: {mix: MixEntity}
  ): CombinedTrackEntityMix[] => {
    return tracks
      .filter((track: TrackEntity) => track.songId === props.mix.songId)
      .map((track: TrackEntity) => {
        return {
          track,
          mix: props.mix,
          trackTextColor: colorIconText(track.color)
        };
      });
  }
);

export const selectAggregateStorageGB = createSelector(
  aggregateFileSize,
  selectSubscriptionStorage,
  (fileSize: number, subscriptionStorage: number): BulletChartData => {
    const GigaSizeString = fileSize / Math.pow(1024, 3);
    const progress = Number.parseFloat(GigaSizeString.toFixed(2));

    return {
      min: 0,
      max: subscriptionStorage,
      progress,
      units: 'GB'
    };
  }
);

export const selectAggregateStorageMB = createSelector(
  aggregateFileSize,
  selectSubscriptionStorage,
  (fileSize: number, subscriptionStorage: number): BulletChartData => {
    const GigaSizeString = fileSize / Math.pow(1024, 2);
    const progress = Number.parseFloat(GigaSizeString.toFixed(2));

    return {
      min: 0,
      max: subscriptionStorage,
      progress,
      units: 'MB'
    };
  }
);
