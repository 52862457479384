// The Transport’s position in Bars:Beats:Sixteenths. Setting the value will jump to that position right away.
export interface SptPosition {
  bars: string;
  beats: string;
  sixteenths: string;
}

export interface SptProgressEvent {
  seconds: string;
  position: SptPosition;
}

export enum VOLUME {
  SLIDER_MAX = 100,
  SLIDER_MIN = 0,
  DECIBEL_MAX = 36,
  DECIBEL_MIN = -36
}

export interface VolumeSliderConfig {
  // length of slider in px
  showDecibels: boolean;
  length: number;
  sliderMin: number;
  sliderMax: number;
  decibelMin: number;
  decibelMax: number;
  initialValue: number;
}

/**
 * NOTE DO NOT USE THIS CONFIG DIRECTLY,
 * RUN THROUGH `createSliderConfig` function below
 */
export const VOLUME_SLIDER_CONFIG: VolumeSliderConfig = {
  showDecibels: false,
  length: 0,
  sliderMin: VOLUME.SLIDER_MIN,
  sliderMax: VOLUME.SLIDER_MAX,
  decibelMin: VOLUME.DECIBEL_MIN,
  decibelMax: VOLUME.DECIBEL_MAX,
  initialValue: 0
};

export function createSliderConfig(c: VolumeSliderConfig): VolumeSliderConfig {
  return {
    ...c,
    length: c.sliderMax - c.sliderMin,
    initialValue: (c.sliderMin + c.sliderMax) / 2
  };
}
