import {copyUint8Array} from './helpers';

export function convertUint8ArrayToAudioBuffer(
  uint8Array: Uint8Array,
  audioContext: AudioContext
): Promise<AudioBuffer> {
  return audioContext.decodeAudioData(copyUint8Array(uint8Array).buffer);
}

export async function convertBlobToAudioBuffer(
  blob: Blob,
  audioContext: AudioContext
): Promise<AudioBuffer> {
  const arrayBuffer: ArrayBuffer = await blob.arrayBuffer();
  return audioContext.decodeAudioData(arrayBuffer);
}

export async function convertArrayBufferToAudioBuffer(
  arrayBuffer: ArrayBuffer,
  audioContext: AudioContext
): Promise<AudioBuffer> {
  return audioContext.decodeAudioData(arrayBuffer);
}
