import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation
} from '@angular/core';
import {SptComponentRef} from './content/content-dictionary';
import {SptComponentRefService} from './spt-component-ref.service';

@Component({
  selector: 'spt-help-right-drawer',
  templateUrl: './help-right-drawer.component.html',
  styleUrls: ['./help-right-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpRightDrawerComponent {
  ref: SptComponentRef | null = null;

  constructor(public cmpRef: SptComponentRefService) {}
}
