import {Dictionary} from '@ngrx/entity';
import {
  AudioFileMetaData,
  AudioFileMetaDataEntity,
  TrackMix,
  TrackMixAudioSnippet
} from '@spout/global-any/models';
import {CompositeAudioBufferDataAndAudioMetaData} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {getAllEntities} from './get-all-entities';

export function getAllAudioBufferAndAudioEntitiesByTrackId(
  trackMix: TrackMix,
  audioFileMetaDataEntities: Dictionary<AudioFileMetaDataEntity>
): CompositeAudioBufferDataAndAudioMetaData[] {
  if (trackMix) {
    return (
      Object.values<TrackMixAudioSnippet>(trackMix.audioSnippets)
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        .sort((a: TrackMixAudioSnippet, b: TrackMixAudioSnippet) => {
          if (a && b && a.start > b.start) {
            return 1;
          }
          if (a && b && a.start < b.start) {
            return -1;
          }
          return 0;
        })
        .map(
          (
            snippet: TrackMixAudioSnippet
          ): CompositeAudioBufferDataAndAudioMetaData | null => {
            if (
              audioFileMetaDataEntities &&
              snippet &&
              snippet.audioFileMetaDataEntityId
            ) {
              return <CompositeAudioBufferDataAndAudioMetaData>{
                snippet,
                audioFileMetaData:
                  audioFileMetaDataEntities[snippet.audioFileMetaDataEntityId]
              };
            }

            return null;
          }
        )
        .reduce(
          (
            a: CompositeAudioBufferDataAndAudioMetaData[],
            i: CompositeAudioBufferDataAndAudioMetaData | null
          ) => {
            if (i !== null && i !== undefined) {
              a.push(i);
            }

            return a;
          },
          []
        )
    );
  }

  return [];
}

export function getAllAudioBufferDataByTrackId(
  trackMixes: {[trackId: string]: TrackMix},
  audioStoreDataEntities: Dictionary<AudioFileMetaDataEntity>,
  props: {trackId: string}
): AudioFileMetaDataEntity[] {
  if (
    hasValue(trackMixes) &&
    hasValue(trackMixes[props.trackId]) &&
    hasValue(trackMixes[props.trackId].audioSnippets) &&
    hasValue(audioStoreDataEntities)
  ) {
    return <AudioFileMetaDataEntity[]>getAllEntities(
      trackMixes[props.trackId].audioSnippets
    )
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      .sort((a: TrackMixAudioSnippet, b: TrackMixAudioSnippet) => {
        if (a && b && a.start > b.start) {
          return 1;
        }
        if (a && b && a.start < b.start) {
          return -1;
        }
        return 0;
      })
      .map((snippet: TrackMixAudioSnippet) => {
        return <AudioFileMetaDataEntity>(
          audioStoreDataEntities[snippet.audioFileMetaDataEntityId]
        );
      })
      .filter((d: AudioFileMetaDataEntity) => d !== null && d !== undefined);
  }

  return [];
}

export function getAllBufferMetaDataByTrackId(
  tracks: {[trackId: string]: TrackMix},
  audioFileMetaDataEntities: {[p: string]: AudioFileMetaData},
  props: {trackId: string}
): AudioFileMetaData[] {
  if (hasValue(tracks) && hasValue(audioFileMetaDataEntities)) {
    return (
      getAllEntities(tracks[props.trackId].audioSnippets)
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        .sort((a: TrackMixAudioSnippet, b: TrackMixAudioSnippet) => {
          if (a && b && a.start > b.start) {
            return 1;
          }
          if (a && b && a.start < b.start) {
            return -1;
          }
          return 0;
        })
        .map((snippet: TrackMixAudioSnippet) => {
          return audioFileMetaDataEntities[snippet.audioFileMetaDataEntityId];
        })
    );
  }

  return [];
}
