import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  AudioFileMetaDataEntity,
  ProjectEntity,
  TrackEntity,
  TrackMix
} from '@spout/global-any/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  isCreatedByLoggedInMusician
} from '@spout/global-web/fns';
import {
  AUDIO_FILE_META_DATA_FEATURE_KEY,
  AudioFileMetaDataState,
  TrackAudioBufferId,
  TrackEntityAndAudioBufferMetaDataEntities,
  TrackIdAndAudioBufferIds
} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {selectUid} from '../+account/account.selectors';
import {selectAllProjectsIOwn} from '../+project/project-storage.selectors';

export const audioMetaDataState = createFeatureSelector<AudioFileMetaDataState>(
  AUDIO_FILE_META_DATA_FEATURE_KEY
);
export const audioMetaDataStatePassThrough =
  createPassThroughFeatureSelector<AudioFileMetaDataState>(
    AUDIO_FILE_META_DATA_FEATURE_KEY
  );

// const { selectEntities } = createEntitySelectorsFactory<AudioFileMetaDataEntity>(audioMetaDataState);

export const selectAudioMetaDataEntities = createSelector(
  audioMetaDataState,
  (state: AudioFileMetaDataState): Dictionary<AudioFileMetaDataEntity> => {
    return state.entities;
  }
);

export const aggregateFileSize = createSelector(
  selectAudioMetaDataEntities,
  selectUid,
  (entities: Dictionary<AudioFileMetaDataEntity>, uid: string): number => {
    const audioMetaDataFileEntities = Object.values(entities);

    if (audioMetaDataFileEntities && audioMetaDataFileEntities.length) {
      return (<AudioFileMetaDataEntity[]>audioMetaDataFileEntities).reduce(
        (a: number, i: AudioFileMetaDataEntity) => {
          if (i && i.fileSize && isCreatedByLoggedInMusician(uid, i)) {
            return a + i.fileSize;
          }

          return a;
        },
        0
      );
    }

    return 0;
  }
);

export const getAudioMetaDataFilesByIDs = (ids: string[]) =>
  createSelector(
    selectAudioMetaDataEntities,
    (dict: Dictionary<AudioFileMetaDataEntity>) =>
      ids
        .map((id: string) => <AudioFileMetaDataEntity>dict[id])
        .filter((entity: AudioFileMetaDataEntity) => entity !== undefined)
  );

export const getAudioMetaDataByIdMemoized = () =>
  createSelector(
    selectAudioMetaDataEntities,
    (
      entities: Dictionary<AudioFileMetaDataEntity>,
      props: {audioFileMetaDataId: string}
    ): AudioFileMetaDataEntity | undefined => {
      return entities[props.audioFileMetaDataId];
    }
  );

export const getAudioMetaDataById = createSelector(
  selectAudioMetaDataEntities,
  (
    entities: Dictionary<AudioFileMetaDataEntity>,
    props: {audioFileMetaDataId: string}
  ) => {
    return entities[props.audioFileMetaDataId];
  }
);

export const getAudioMetaDataByIdFn = (props: {
  audioFileMetaDataId: string;
}) => {
  return createSelector(
    selectAudioMetaDataEntities,
    (entities: Dictionary<AudioFileMetaDataEntity>) => {
      return entities[props.audioFileMetaDataId];
    }
  );
};

/**
 * Use to track-audio AudioBuffer Ids used in a track-audio
 */
export const getTrackAudioBufferIdsMemoized = () =>
  createSelector(
    audioMetaDataState,
    (
      audioBufferState: AudioFileMetaDataState,
      props: {trackMix: TrackMix}
    ): TrackAudioBufferId[] => {
      if (audioBufferState && audioBufferState.entities) {
        const allAudioBuffers: AudioFileMetaDataEntity[] = <
          AudioFileMetaDataEntity[]
        >Object.values(audioBufferState.entities);

        if (
          hasValue(allAudioBuffers) &&
          props.trackMix &&
          hasValue(props.trackMix.audioSnippets)
        ) {
          const trackAudioPlayerId: TrackAudioBufferId[] = [];

          for (let i = 0; i < allAudioBuffers.length; i++) {
            if (props.trackMix.audioSnippets[allAudioBuffers[i].id]) {
              trackAudioPlayerId.push(<TrackAudioBufferId>{
                trackId: props.trackMix.trackId,
                audioFileMetaDataId: allAudioBuffers[i].id
              });
            }
          }

          return trackAudioPlayerId;
        }
      }

      return [];
    }
  );

export const getTrackIDAndAudioBufferIdsMemoized = () =>
  createSelector(
    audioMetaDataState,
    (
      audioBufferState: AudioFileMetaDataState,
      props: {trackMix: TrackMix}
    ): TrackIdAndAudioBufferIds => {
      const r: TrackIdAndAudioBufferIds = {
        trackId: props.trackMix.trackId,
        audioFileMetaDataIds: []
      };

      if (audioBufferState.ids && audioBufferState.ids.length) {
        const allAudioBuffers: AudioFileMetaDataEntity[] = <
          AudioFileMetaDataEntity[]
        >Object.values(audioBufferState.entities);

        if (
          hasValue(allAudioBuffers) &&
          props.trackMix &&
          hasValue(props.trackMix.audioSnippets)
        ) {
          for (let i = 0; i < allAudioBuffers.length; i++) {
            if (props.trackMix.audioSnippets[allAudioBuffers[i].id]) {
              r.audioFileMetaDataIds.push(allAudioBuffers[i].id);
            }
          }
        }
      }

      return r;
    }
  );

/**
 * @deprecated
 */
export const getTrackEntityAndAudioBufferMetaDataEntityMemoized = () =>
  createSelector(
    audioMetaDataState,
    (
      audioBufferState: AudioFileMetaDataState,
      props: {trackMix: TrackMix; trackEntity: TrackEntity}
    ): TrackEntityAndAudioBufferMetaDataEntities => {
      const r: TrackEntityAndAudioBufferMetaDataEntities = {
        trackEntity: props.trackEntity,
        audioFileMetaDataEntities: []
      };

      if (audioBufferState.ids && audioBufferState.ids.length) {
        const allAudioBuffers: AudioFileMetaDataEntity[] = <
          AudioFileMetaDataEntity[]
        >Object.values(audioBufferState.entities);

        if (
          hasValue(allAudioBuffers) &&
          props.trackMix &&
          hasValue(props.trackMix.audioSnippets)
        ) {
          for (let i = 0; i < allAudioBuffers.length; i++) {
            if (props.trackMix.audioSnippets[allAudioBuffers[i].id]) {
              r.audioFileMetaDataEntities.push(allAudioBuffers[i]);
            }
          }
        }
      }

      return r;
    }
  );

export const getTrackEntityAndAudioBufferMetaDataEntityFn = (props: {
  trackMix: TrackMix;
  trackEntity: TrackEntity;
}) =>
  createPassThroughSelector(
    audioMetaDataStatePassThrough,
    (
      audioBufferState: AudioFileMetaDataState
    ): TrackEntityAndAudioBufferMetaDataEntities => {
      const r: TrackEntityAndAudioBufferMetaDataEntities = {
        trackEntity: props.trackEntity,
        audioFileMetaDataEntities: []
      };

      if (audioBufferState.ids && audioBufferState.ids.length) {
        const allAudioBuffers: AudioFileMetaDataEntity[] = <
          AudioFileMetaDataEntity[]
        >Object.values(audioBufferState.entities);

        if (
          hasValue(allAudioBuffers) &&
          props.trackMix &&
          hasValue(props.trackMix.audioSnippets)
        ) {
          for (let i = 0; i < allAudioBuffers.length; i++) {
            if (props.trackMix.audioSnippets[allAudioBuffers[i].id]) {
              r.audioFileMetaDataEntities.push(allAudioBuffers[i]);
            }
          }
        }
      }

      return r;
    }
  );

export const selectAllAudioMetaData = createSelector(
  selectAudioMetaDataEntities,
  (
    entities: Dictionary<AudioFileMetaDataEntity>
  ): AudioFileMetaDataEntity[] => {
    if (entities && Object.keys(entities).length) {
      return <AudioFileMetaDataEntity[]>Object.values(entities);
    }

    return [];
  }
);

export const selectAllAudioMetaDataFromProjectsIOwn = createSelector(
  selectAllProjectsIOwn,
  selectAudioMetaDataEntities,
  (
    projects: ProjectEntity[],
    entities: Dictionary<AudioFileMetaDataEntity>
  ) => {
    if (
      projects &&
      projects.length &&
      entities &&
      Object.keys(entities).length
    ) {
      const files: AudioFileMetaDataEntity[] = <AudioFileMetaDataEntity[]>(
        Object.values(entities)
      );

      return projects.reduce(
        (a: AudioFileMetaDataEntity[], p: ProjectEntity) => {
          const f: AudioFileMetaDataEntity[] = files.filter(
            (file: AudioFileMetaDataEntity) => {
              return (file.projectId = p.id);
            }
          );

          return [...a, ...f];
        },
        []
      );
    }

    return [];
  }
);

// export const selectDefaultFileEntity = createSelector(
//   selectAllAudioMetaData,
//   selectAccountState,
//   (entities: AudioFileMetaDataEntity[], account: AccountState): AudioFileMetaDataEntity | null => {
//     return <AudioFileMetaDataEntity>(
//       getDefaultEntityConfig(
//         <{ isDefault: boolean; createdByUID: string }[]>entities,
//         account
//       )
//     );
// );

export const selectAudioMetaDataEntityById = (props: {id: string}) => {
  return createSelector(
    selectAudioMetaDataEntities,
    (entities: Dictionary<AudioFileMetaDataEntity>) => {
      return entities[props.id];
    }
  );
};

export const getSyncedAudioMetaDataByProjectId = createSelector(
  selectAllAudioMetaData,
  (entities: AudioFileMetaDataEntity[], props: {projectId: string}) => {
    return entities.filter(audioMetaData => {
      return audioMetaData.projectId === props.projectId;
    });
  }
);
