import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {DIALOG_COMPONENT, DialogQueue} from '@spout/global-web/models';

export const loadDialogQueues = createAction(
  '[DialogQueue/API] Load DialogQueues',
  props<{dialogQueues: DialogQueue[]}>()
);

export const addDialogQueue = createAction(
  '[DialogQueue/API] Add DialogQueue',
  props<{dialogQueue: DialogQueue}>()
);

export const upsertDialogQueue = createAction(
  '[DialogQueue/API] Upsert DialogQueue',
  props<{dialogQueue: DialogQueue}>()
);

export const addDialogQueues = createAction(
  '[DialogQueue/API] Add DialogQueues',
  props<{dialogQueues: DialogQueue[]}>()
);

export const upsertDialogQueues = createAction(
  '[DialogQueue/API] Upsert DialogQueues',
  props<{dialogQueues: DialogQueue[]}>()
);

export const updateDialogQueue = createAction(
  '[DialogQueue/API] Update DialogQueue',
  props<{dialogQueue: Update<DialogQueue>}>()
);

export const updateDialogQueues = createAction(
  '[DialogQueue/API] Update DialogQueues',
  props<{dialogQueues: Update<DialogQueue>[]}>()
);

export const deleteDialogQueue = createAction(
  '[DialogQueue/API] Delete DialogQueue',
  props<{id: string}>()
);

export const deleteDialogQueues = createAction(
  '[DialogQueue/API] Delete DialogQueues',
  props<{ids: string[]}>()
);

export const clearDialogQueues = createAction(
  '[DialogQueue/API] Clear DialogQueues'
);

export const openDialog = createAction(
  '[DialogQueue/API] Open Dialog',
  props<{id: DIALOG_COMPONENT}>()
);
export const closeDialog = createAction(
  '[DialogQueue/API] Close Dialog',
  props<{id: DIALOG_COMPONENT; destroy?: boolean}>()
);
