import {HttpClient} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity} from '@spout/global-any/models';
import {AccountStateConnect} from '@spout/global-web/models';
import {SptRecorderService} from '../audio/spt-recorder.service';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {CustomFirestoreService} from '../firebase';
import {ProjectNode} from './project.node';

@Injectable({
  providedIn: 'root'
})
export class ProjectFactoryService {
  constructor(
    private store: Store<AccountStateConnect>,
    private sptFirestore: CustomFirestoreService,
    private _recorder: SptRecorderService,
    private httpClient: HttpClient,
    private dss: DynamicStoreService,
    private zone: NgZone
  ) {}

  // PROJECT NODE
  createProjectNode(projectConfig: ProjectEntity): ProjectNode {
    return new ProjectNode(
      projectConfig,
      this.sptFirestore,
      this._recorder,
      this.httpClient,
      this.dss,
      this.store,
      this.zone
    );
  }
}
