import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ROUTER_ERROR, ROUTER_NAVIGATION} from '@ngrx/router-store';
import {select, Store} from '@ngrx/store';
import {
  CloseAndNavigateTo,
  getSegmentRoute,
  MIXER_ROUTES,
  mixerRouteUrlConfigs,
  SpoutRouterState
} from '@spout/global-web/models';
import {getIn} from '@uiux/fn';
import {of, timer} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {
  closeAndNavigate,
  closeAuxRoute,
  routerCurrentRoute,
  routerLoadError
} from './router.actions';
import {getRouterState} from './router.selectors';

@Injectable({
  providedIn: 'root'
})
export class RouterEffects {
  request$ = createEffect(() =>
    this.actions$.pipe(
      // ofType(ROUTER_NAVIGATED),
      ofType(ROUTER_NAVIGATION),
      map((action: any) => {
        const payload: any = {
          state: action.payload.routerState,
          config: mixerRouteUrlConfigs[action.payload.routerState.url]
        };

        // root.children.primary.children.aux-route.segments
        const tree = this._router.parseUrl(action.payload.routerState.url);

        // regex for just in case - forms\:(.*)\)

        let route;
        if (
          getIn(tree, 'root.children.primary.children.forms.segments', null)
        ) {
          route = getSegmentRoute(
            tree,
            'root.children.primary.children.forms.segments'
          );
        } else if (
          getIn(tree, 'root.children.primary.children.primary.segments', null)
        ) {
          route = getSegmentRoute(
            tree,
            'root.children.primary.children.primary.segments'
          );
        }

        if (route && mixerRouteUrlConfigs[route]) {
          payload.config = mixerRouteUrlConfigs[route];
        }

        return routerCurrentRoute({
          payload
        });
      })
    )
  );

  // standardRedirecRoutes = ['/mixer/console', '/login'];

  closeAndNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeAndNavigate),
        switchMap((action: CloseAndNavigateTo) =>
          this.store.pipe(
            select(getRouterState),
            take(1),
            tap((spoutRouterState: SpoutRouterState) => {
              if (
                spoutRouterState.auxRouteIsOpen &&
                action.routeConfig.id !== spoutRouterState.currentRouteConfig.id
              ) {
                this.zone.run(() => {
                  this.store.dispatch(closeAuxRoute());
                });
              }
            }),
            switchMap((spoutRouterState: SpoutRouterState) => {
              if (
                spoutRouterState.auxRouteIsOpen &&
                action.routeConfig.name !== MIXER_ROUTES.CONSOLE
              ) {
                return timer(300).pipe(map(() => spoutRouterState));
              } else {
                return of(spoutRouterState);
                // return timer(300).pipe(map(() => action));
              }
            }),
            tap((spoutRouterState: SpoutRouterState) => {
              if (
                action.routeConfig.id !== spoutRouterState.currentRouteConfig.id
              ) {
                if (action.extras && action.extras.relativeToActivatedRoute) {
                  this.zone.run(() => {
                    this._router.navigate(action.commands, {
                      relativeTo: this.activatedRoute
                    });
                  });
                } else {
                  this.zone.run(() => {
                    this._router.navigate(action.commands);
                  });
                }
              }
            })
          )
        )
      ),
    {dispatch: false}
  );

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_ERROR),
      map((action: any) => {
        return routerLoadError(action.error);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private zone: NgZone
  ) {}
}
