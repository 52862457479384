import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {firestoreMessageByIdPath} from '@spout/global-web/fns';
import {DocumentReference, WriteBatch} from '@firebase/firestore';
import {from} from 'rxjs';
import {CustomFirestoreService} from '../firebase/custom-firestore.service';
import {ChatEntity} from '@spout/global-web/models';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    public sptFirestore: CustomFirestoreService,
    public store: Store
  ) {}

  deleteChats(chats: ChatEntity[]) {
    const batch: WriteBatch = this.sptFirestore.writeBatch();

    chats.forEach((_chat: ChatEntity) => {
      const doc: DocumentReference = this.sptFirestore
        // .collection(fileCollection(file))
        .docRef(firestoreMessageByIdPath(_chat));

      batch.delete(doc);
    });

    return from(batch.commit());
  }
}
