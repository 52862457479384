import {Action, createReducer, on} from '@ngrx/store';
import {concatElectronBuildVersion} from '@spout/global-any/models';
import {
  initialStudioState,
  StudioInstalled,
  StudioVersionState
} from '@spout/global-web/models';
import {loadInstalledElectron, StudioActions} from './studio.actions';

const reducer = createReducer(
  initialStudioState,

  on(StudioActions.loadStudios, (state: StudioVersionState) => state),

  on(StudioActions.hasUiUpdate, (state: StudioVersionState, action) => {
    return {
      ...state,
      serviceWorker: {
        ...state.serviceWorker,
        checkingForUpdate: false,
        hasUiUpdate: action.hasUiUpdate
      }
    };
  }),

  on(loadInstalledElectron, (state: StudioVersionState, action) => {
    return {
      ...state,
      installed: <StudioInstalled>{
        ...state.installed,
        electron: action.installed.build,
        electronAppBuild: concatElectronBuildVersion(action.installed.build),
        platform: action.installed.platform
      }
    };
  })
);

export function studioReducer(
  state: StudioVersionState | undefined,
  action: Action
): StudioVersionState {
  return reducer(state, action);
}
