import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {SptRecorderService} from '@spout/global-web/data-access';

@Component({
  selector: 'spt-help-create-mix-content',
  templateUrl: './help-create-mix-content.component.html',
  styleUrls: ['./help-create-mix-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpCreateMixContentComponent {
  constructor(private recorder: SptRecorderService) {}
  refresh(): void {
    this.recorder.refreshAudioDeviceList().subscribe(() => {});
  }
}
