<div class="column">
  <ng-container *ngrxLet="hasSubscription$; let hasSubscription">
    <div *ngIf="hasSubscription" class="column">
      <ng-container *ngrxLet="payment.selectTrialEnds$; let t">
        <div class="mat-h4 primary">
          My Subscription
          <span class="accent" *ngIf="t.isInTrial"
            >( Trial ends {{ t.trialEnds | date }} )</span
          >
        </div>
      </ng-container>

      <div class="subscription-table">
        <table
          class="mat-elevation-z2"
          mat-table
          [dataSource]="subscriptionItemized">
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Product Column -->
          <ng-container matColumnDef="itemName">
            <!--        <th mat-header-cell *matHeaderCellDef>Product</th>-->
            <td mat-cell *matCellDef="let item">
              <div class="mat-h3">{{ item.itemName }}</div>
              <div class="product-description">
                {{ item.itemDescription }}
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef class="primary total-cost">
              Total
            </td>
          </ng-container>

          <!-- Price Column -->
          <ng-container matColumnDef="itemPrice">
            <!--                <th mat-header-cell *matHeaderCellDef>Cost</th>-->
            <td mat-cell *matCellDef="let item">
              <span *ngIf="!item.isIncludedStorage">
                {{ item.itemPrice | currency }}
                <span class="accent" *ngIf="payment.selectIsInTrial$ | ngrxPush"
                  >( $0.00 trial )</span
                >
              </span>
              <span *ngIf="item.isIncludedStorage">Included</span>
            </td>
            <td mat-footer-cell *matFooterCellDef class="primary total-cost">
              {{ payment.totalCost$ | ngrxPush | currency: "USD" }}
              <span class="accent" *ngIf="payment.selectIsInTrial$ | ngrxPush"
                >( $0.00 trial )</span
              >
              <span class="time-period">
                /
                {{ (payment.isPerMonth$ | ngrxPush) ? "month" : "year" }}</span
              >
            </td>
          </ng-container>

          <!--      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="showCancelProgress | ngrxPush"></mat-progress-bar>
      </div>
    </div>
    <div *ngIf="hasSubscription" class="column">
      <div class="change-plan">
        <ng-container *ngrxLet="isElectron$; let isElectron">
          <ng-container *ngIf="isElectron">
            <button
              class="push-top-20"
              mat-flat-button
              color="primary"
              (click)="onUpdateSubscription()">
              Change Plan and Storage Subscription
            </button>
          </ng-container>

          <ng-container *ngIf="!isElectron">
            <ng-container *ngIf="payment.cancelAtPeriodEnd$ | ngrxPush">
              <spt-subscription-end
                [subscription]="
                  payment.selectCurrentSubscription$ | ngrxPush
                "></spt-subscription-end>
            </ng-container>

            <div class="change-plan-actions">
              <div class="mat-caption">
                <span
                  ><sup>*</sup>Cancelling subscription does not delete your
                  personal data.</span
                >
                <br />

                <span class="accent"
                  ><sup>**</sup>Delete account from the Spout Studio app to
                  <strong>cancel subscription</strong> and completely remove
                  your data.</span
                >
              </div>

              <div>
                <button
                  mat-flat-button
                  color="primary"
                  (click)="onUpdateSubscription()">
                  Update Subscription
                </button>
                <button
                  mat-flat-button
                  color="primary"
                  *ngIf="!(payment.cancelAtPeriodEnd$ | ngrxPush)"
                  (click)="onCancelSubscriptionEndOfPeriod()">
                  Cancel Subscription At End Of Period <sup>*</sup>
                </button>

                <button
                  mat-flat-button
                  color="primary"
                  *ngIf="!(payment.cancelAtPeriodEnd$ | ngrxPush)"
                  (click)="onCancelSubscription()">
                  Cancel Subscription Immediately <sup>*</sup>
                </button>

                <button
                  mat-flat-button
                  color="accent"
                  *ngIf="!(payment.cancelAtPeriodEnd$ | ngrxPush)"
                  (click)="onDeleteAccount()">
                  Delete Account <sup>**</sup>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div *ngIf="!hasSubscription" class="column">
      <button
        class="subscribe-btn"
        mat-flat-button
        color="primary"
        (click)="onSubscribeToAPlan()">
        Subscribe To A SubscriptionPlan
      </button>
    </div>
  </ng-container>
</div>
