import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {
  AccountState,
  APP_ROUTES,
  DeviceDetectionState,
  IxRouteConfig,
  MIXER_ROUTES,
  mixerRouteUrlConfigs,
  ROUTER_FEATURE_KEY,
  SpoutRouteOnboarding,
  SpoutRouterState
} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {pipe} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {selectAccountState} from '../+account/account.selectors';
import {deviceState} from '../+device-detection/device-detection.selectors';
import {StudioAppState} from '../models/studio-app-state.model';

// Lookup the 'Router' feature state managed by NgRx
export const getRouterState =
  createFeatureSelector<SpoutRouterState>(ROUTER_FEATURE_KEY);

export const getCurrentRouteConfig = createSelector(
  getRouterState,
  (state: SpoutRouterState) => state.currentRouteConfig
);

export const getAuxRouteIsOpen = (state: StudioAppState): boolean => {
  if (state[ROUTER_FEATURE_KEY]) {
    const auxRouteIsOpen = (<SpoutRouterState>state[ROUTER_FEATURE_KEY])
      .auxRouteIsOpen;
    return auxRouteIsOpen !== undefined && auxRouteIsOpen !== null
      ? auxRouteIsOpen
      : false;
  }

  return false;
};

export const getCurrentRouteName = createSelector(
  getCurrentRouteConfig,
  (currentRoute: IxRouteConfig) => currentRoute.name
);

export const getCurrentRouteRoute = createSelector(
  getCurrentRouteConfig,
  (currentRoute: IxRouteConfig) => currentRoute.routerLink
);

export const getCurrentRouteNameToUpperCase = createSelector(
  getCurrentRouteName,
  (currentRoute: string) => currentRoute.toUpperCase()
);

export const getRouteError = createSelector(
  getRouterState,
  (state: SpoutRouterState) => state.error
);

export const getIsMixer = createSelector(
  getRouterState,
  (state: SpoutRouterState) => {
    return hasValue(state.url) && state.url.includes(APP_ROUTES.MIXER);
  }
);

export const getNotIsMixer = createSelector(
  getRouterState,
  (state: SpoutRouterState) => {
    return hasValue(state.url) && !state.url.includes(APP_ROUTES.MIXER);
  }
);

export const getIsMixerConsole = createSelector(
  getRouterState,
  (state: SpoutRouterState) => {
    return hasValue(state.url) && state.url.includes(APP_ROUTES.MIXER_CONSOLE);
  }
);

export const getIsNotMixer = createSelector(
  getIsMixer,
  (isMixer: boolean) => !isMixer
);

export const getCurrentRouteBack = createSelector(
  getCurrentRouteConfig,
  (currentRoute: IxRouteConfig) => currentRoute.back
);

export const getIsCreatingProject = createSelector(
  getRouterState,
  (state: SpoutRouterState) => {
    const config: IxRouteConfig = mixerRouteUrlConfigs[state.url];
    return config && config.routerLink
      ? config.routerLink.includes(MIXER_ROUTES.ADD_PROJECT)
      : false;
  }
);

export const getNotLoginPage = createSelector(
  getRouterState,
  (state: SpoutRouterState) => {
    const config: IxRouteConfig = mixerRouteUrlConfigs[state.url];
    return config && config.routerLink
      ? !config.routerLink.includes(APP_ROUTES.LOGIN_ELECTRON)
      : true;
  }
);

export const getIsCreatingSong = createSelector(
  getRouterState,
  (state: SpoutRouterState) => {
    return hasValue(state.url) && state.url.includes(MIXER_ROUTES.ADD_SONG);
  }
);

export const getIsCreatingProjectOrSong = createSelector(
  getIsCreatingProject,
  getIsCreatingSong,
  (isCreatingProject, isCreatingSong) => isCreatingProject || isCreatingSong
);

export const selectRedirectParams = createSelector(
  deviceState,
  selectAccountState,
  getRouterState,
  (
    device: DeviceDetectionState,
    auth: AccountState,
    router: SpoutRouterState
  ) => {
    return <SpoutRouteOnboarding>{
      isLoggedIn: auth.isLoggedIn,
      isInitialLoad: router.isInitialLoad,
      isBrowser: device.isBrowser,
      isElectron: device.isElectron,
      url: router.url
    };
  }
);

export const getRedirectParams = pipe(
  select(selectRedirectParams),
  filter((d: SpoutRouteOnboarding) => {
    return hasValue(d.isElectron) && hasValue(d.isBrowser) && hasValue(d.url);
  }),
  distinctUntilChanged((a: SpoutRouteOnboarding, b: SpoutRouteOnboarding) => {
    return (
      a.isLoggedIn === b.isLoggedIn &&
      a.url === b.url &&
      a.isBrowser === b.isBrowser &&
      a.isElectron &&
      b.isElectron
    );
  })
);

export const routerQuery = {
  getAuxRouteIsOpen,
  getCurrentRouteBack,
  getCurrentRouteRoute,
  getRouterState,
  getCurrentRouteConfig,
  getRouteError,
  getIsCreatingProject,
  getIsCreatingSong,
  getIsCreatingProjectOrSong,
  getIsMixer,
  getIsMixerConsole,
  getIsNotMixer,
  getCurrentRouteName,
  getCurrentRouteNameToUpperCase
};
