import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {
  initialAccountState,
  initialWebsocketRegistryState
} from '@spout/global-web/models';
import {AccountEffects} from './+account/account.effects';
import {accountReducer} from './+account/account.reducer';
import {websocketRegistryReducer} from './+websocket-registry/websocket-registry.reducer';

/**
 * Stripe
 */

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forRoot(
      {
        account: accountReducer,
        webSocketRegistry: websocketRegistryReducer
      },
      {
        initialState: {
          account: initialAccountState,
          webSocketRegistry: initialWebsocketRegistryState
        },
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    EffectsModule.forRoot([AccountEffects])
  ],
  providers: []
})
export class WebPlatformLoginDataAccessModule {}
