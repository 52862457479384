import {ProductWizardStore} from '../../+subscriptions';

export function selectTrialEnds(state: ProductWizardStore): {
  trialEnds: number;
  isInTrial: boolean;
} {
  const trialEndSeconds = state?.currentSubscription?.trial_end?.seconds
    ? state?.currentSubscription?.trial_end?.seconds
    : 0;

  return {
    isInTrial: state?.currentSubscription?.status === 'trialing',
    trialEnds: trialEndSeconds * 1000
  };
}
