import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProjectEntity, SongEntity} from '@spout/global-any/models';
import {
  DocumentChange,
  DocumentData,
  onSnapshot,
  QuerySnapshot
} from '@firebase/firestore';
import {deleteSongs, upsertSongs} from '../../+songs/song.actions';
import {CustomFirestoreService} from '../../firebase';
import {aggregateDocChanges, firestoreSongsPath} from '@spout/global-web/fns';
import {
  AccountState,
  AggregateFirebaseSnapshotChangesEntities
} from '@spout/global-web/models';
import {Subscription} from 'rxjs';
import {QueryModel} from './query.model';

export class QuerySongsService implements QueryModel<SongEntity> {
  private songsSub: Subscription = Subscription.EMPTY;
  private otherSongSub: (() => void) | undefined;
  private mySongSub: (() => void) | undefined;

  constructor(
    private sptFirebase: CustomFirestoreService,
    private store: Store,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AccountState) {
    const that = this;

    this.otherSongSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreSongsPath(this.config)),
      (snapshot: QuerySnapshot) => {
        if (user.uid) {
          that.process.apply(that, [snapshot.docChanges(), user.uid]);
        }
      }
    );
  }

  onDisconnect(user?: AccountState) {
    // SONG

    this.songsSub.unsubscribe();

    if (this.otherSongSub) {
      this.otherSongSub();
    }

    if (this.mySongSub) {
      this.mySongSub();
    }
  }

  process(snapshot: DocumentChange<DocumentData>[], createdByUID: string) {
    const that = this;
    const aggregate: AggregateFirebaseSnapshotChangesEntities<DocumentData> =
      aggregateDocChanges<DocumentData>(snapshot, createdByUID);

    // console.log('processSongs', aggregate);
    that.zone.run(() => {
      if (aggregate.added.length) {
        // aggregate.added.forEach((added) => {
        //   console.log('SONG ADDED', added.id);
        // });

        that.store.dispatch(
          upsertSongs({
            songs: <SongEntity[]>aggregate.added
          })
        );
      }

      if (aggregate.modified.length) {
        // aggregate.modified.forEach((modified) => {
        //   console.log('SONG MODIFIED', modified.id);
        // });

        that.store.dispatch(
          upsertSongs({
            songs: <SongEntity[]>aggregate.modified
          })
        );
      }

      if (aggregate.removed.length) {
        // aggregate.removed.forEach((removed) => {
        //   console.log('SONG REMOVED', removed);
        // });

        that.store.dispatch(
          deleteSongs({
            ids: aggregate.removed
          })
        );
      }
    });
  }
}
