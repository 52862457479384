import {AccountState} from '@spout/global-web/models';
import {
  FirestoreCollectionQuery,
  FirestoreCollectionQueryConfig
} from '../firebase/firestore-query';
import {select, Store} from '@ngrx/store';
import {MemoizedSelector} from '@ngrx/store/src/selector';
import {Subscription} from 'rxjs';

interface QueryCacheItem<T> {
  item: T;
  query: FirestoreCollectionQuery<T>;
}

export class QueryEngineCache<T, Parent = any> {
  private _sub: Subscription = Subscription.EMPTY;
  private _cache: {[key: string]: QueryCacheItem<T>} = {};

  constructor(
    private _config: FirestoreCollectionQueryConfig<T>,
    private _store: Store,
    private selectAll: MemoizedSelector<any, any>,
    private _pathGeneratorFn: (...args: any[]) => string,
    private _idProperty: string = 'id'
  ) {}

  onConnect(user: AccountState | null = null) {
    const that = this;
    this._sub.unsubscribe();

    this._sub = this._store
      .pipe(select(that.selectAll))
      .subscribe((items: T[]) => {
        for (let i = 0; i < items.length; i++) {
          const item: T = items[i];

          if (!that._cache[(<any>item)[this._idProperty]]) {
            that._cache[(<any>item)[this._idProperty]] = {
              item,
              query: that._config.createFirestoreCollectionQuery()
            };
          }

          let _path = '';
          if (user && user?.uid) {
            _path = this._pathGeneratorFn(item, user?.uid);
          } else {
            _path = this._pathGeneratorFn(item);
          }

          that._cache[(<any>item)[this._idProperty]].query.onConnect(
            _path,
            item,
            (<AccountState>user)?.uid
          );
        }
      });
  }

  onDisconnect() {
    this._sub.unsubscribe();

    for (const prop in this._cache) {
      if (this._cache[prop]) {
        this._cache[prop].query.onDisconnect();
      }
    }
  }

  deleteMany(ids: string[]) {
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      if (this._cache[id]) {
        this._cache[id].query.onDisconnect();
        delete this._cache[id];
      }
    }
  }
}
