import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  ElectronNativeAudio,
  SaveExportedMixAsFile,
  SaveExportedMixAsFileAccount
} from '@spout/global-any/models';
import {
  ENVIRONMENT,
  IEnvironmentState,
  SaveUint8ArrayTypeToDevice
} from '@spout/global-web/models';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {selectUidHash$} from '../../+account/account.selectors';
import {DeviceStorageService} from './device-storage.service';

@Injectable({providedIn: 'root'})
export class AudioFileSaveService {
  constructor(
    private device: DeviceStorageService,
    private store: Store,

    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {}

  saveNativeAudioFile(
    parsedRecordExport: SaveUint8ArrayTypeToDevice
  ): Observable<ElectronNativeAudio> {
    return this.store.pipe(
      selectUidHash$,
      take(1),
      switchMap((account: string) => {
        return this.device.saveNativeAudioFile(<ElectronNativeAudio>{
          account,
          audioFileMetaData: parsedRecordExport.audioFileMetaDataEntity,
          uint8ArrayType: parsedRecordExport.uint8ArrayType
        });
      })
    );
  }

  saveExportedAudioFile(
    parsedRecordExport: SaveExportedMixAsFile
  ): Observable<SaveExportedMixAsFileAccount> {
    return this.store.pipe(
      selectUidHash$,
      take(1),
      switchMap((account: string) => {
        return this.device.saveExportedFile(<SaveExportedMixAsFileAccount>{
          ...parsedRecordExport,
          account
        });
      })
    );
  }
}
