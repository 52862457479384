import {from} from 'rxjs';
import {copyUint8Array} from './helpers';

export interface InputConvertBlobToFloat32Array {
  blob: Blob;
  audioContext: AudioContext;
}

/**
 * Convert WAV file to Float32Array
 * @param blob: Blob
 * @param audioContext: AudioContext
 */
export async function convertBlobToFloat32array(
  blob: Blob,
  audioContext: AudioContext
): Promise<Float32Array[]> {
  return new Promise(async resolve => {
    let arrayBuffer: ArrayBuffer | null = await blob.arrayBuffer();
    let audioBuffer: AudioBuffer | null = await audioContext.decodeAudioData(
      arrayBuffer
    );

    const inputs: Float32Array[] = [];
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      inputs[channel] = audioBuffer.getChannelData(channel);
    }

    // garbage collect
    arrayBuffer = null;
    audioBuffer = null;

    return inputs;
  });
}

export const convertBlobToFloat32array$ = (
  input: InputConvertBlobToFloat32Array
) => from(convertBlobToFloat32array(input.blob, input.audioContext));

export interface InputConvertArrayBufferToFloat32Array {
  arrayBuffer: ArrayBuffer;
  audioContext: AudioContext;
}

/**
 *
 * @param arrayBuffer: ArrayBuffer
 * @param audioContext: AudioContext
 */
export async function convertArrayBufferToFloat32array(
  arrayBuffer: ArrayBuffer,
  audioContext: AudioContext
): Promise<Float32Array[][]> {
  return new Promise(async resolve => {
    let audioBuffer: AudioBuffer | null = await audioContext.decodeAudioData(
      arrayBuffer
    );

    // console.log(audioBuffer);

    if (audioBuffer) {
      const inputs: Float32Array[] = [];
      for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        inputs[channel] = audioBuffer.getChannelData(channel);
      }

      // garbage collect
      // audioBuffer = null;

      // console.log(inputs);

      resolve([inputs]);
    }

    resolve([[]]);
  });
}

export function convertAudioBufferToFloat32Array(
  audioBuffer: AudioBuffer | undefined | null
): Float32Array[][] {
  if (audioBuffer) {
    const inputs: Float32Array[] = [];
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      inputs[channel] = audioBuffer.getChannelData(channel);
    }

    return [inputs];
  }

  return [[]];
}

export async function convertUint8ArrayToFloat32ArrayAudio(
  uint8Array: Uint8Array,
  audioContext: AudioContext
): Promise<Float32Array[][]> {
  let audioBuffer: AudioBuffer | null = await audioContext.decodeAudioData(
    copyUint8Array(uint8Array).buffer
  );
  return convertAudioBufferToFloat32Array(audioBuffer);
}
