import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {MixEntity, TrackMix} from '@spout/global-any/models';

export const loadMixs = createAction(
  '[MixConfig/API] Load Mixs',
  props<{mixConfigs: MixEntity[]}>()
);

export const addMix = createAction(
  '[MixConfig/API] Add MixConfig',
  props<{mix: MixEntity}>()
);

export const upsertMix = createAction(
  '[MixConfig/API] Upsert MixConfig',
  props<{mix: MixEntity}>()
);

export const addMixs = createAction(
  '[MixConfig/API] Add Mixs',
  props<{mixConfigs: MixEntity[]}>()
);

export const upsertModifiedMixsFromFirestore = createAction(
  '[MixConfig/API] Upsert Mixes Modified From Firestore',
  props<{mixConfigs: MixEntity[]}>()
);
export const upsertMixsFromFirestore = createAction(
  '[MixConfig/API] Upsert Mixes From Firestore',
  props<{mixConfigs: MixEntity[]}>()
);
export const upsertMixsFromStorage = createAction(
  '[MixConfig/API] Upsert Mixes From Storage',
  props<{mixConfigs: MixEntity[]}>()
);

export const updateMix = createAction(
  '[MixConfig/API] Update MixConfig',
  props<{mix: Update<MixEntity>}>()
);

export const updateMixs = createAction(
  '[MixConfig/API] Update Mixs',
  props<{mixConfigs: Update<MixEntity>[]}>()
);

export const deleteMix = createAction(
  '[MixConfig/API] Delete MixConfig',
  props<{id: string}>()
);

export const deleteMixs = createAction(
  '[MixConfig/API] Delete Mixs',
  props<{ids: string[]}>()
);

export const clearMixs = createAction('[MixConfig/API] Clear Mixs');

export const setTrackMixAsMaster = createAction(
  '[MixConfig/API] Set Track Mix As Master',
  props<{trackMix: TrackMix}>()
);
