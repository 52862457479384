<h1 mat-dialog-title class="warn">Cannot Delete Track</h1>
<mat-dialog-content>
  <div class="pad-top-2 push-buttom-4">
    This track is a master track. Select another track to be the master track
    before deleting this track.
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="ok.emit(true)">OK</button>
</mat-dialog-actions>
