import {
  SONG_METRICS,
  MixMetrics,
  TrackReporting,
  WaveformSongMetrics,
  WaveformTrackMetrics
} from '@spout/global-web/models';
import {max, min} from 'd3-array';

export function parseAudioTrackMetrics(w: TrackReporting[]): MixMetrics {
  const duration = max(w.map(v => v.trackDuration));
  return {
    duration:
      duration && duration > SONG_METRICS.BASE_TRACK_DURATION
        ? duration
        : SONG_METRICS.BASE_TRACK_DURATION,
    // minSampleRate: min(w.map(v => v.minSampleRate)) || 0,
    // maxSampleRate: max(w.map(v => v.maxSampleRate)) || 0,

    // overridden by metrics service using transport audio context
    contextSampleRate: 0,
    hasAudio: duration !== undefined && duration > 0,
    defaultDuration: SONG_METRICS.BASE_TRACK_DURATION,
    playerIds: w.reduce((a: string[], r: TrackReporting) => {
      a = [...a, ...r.playerIds];
      return a;
    }, [])
  };
}

export function parseSongWaveformMetrics(
  w: WaveformTrackMetrics[]
): WaveformSongMetrics {
  // 210s is 3.5 minutes, the minimum time to show in a track
  // to show some visual time before tracks are recorded
  // const duration: number[] = [...w.map((v) => v.duration), SONG_METRICS.BASE_TRACK_LENGTH];
  const aggregateDurationAndLength: {
    duration: number[];
    lengthOfSamples: number[];
  } = w.reduce(
    (
      a: {duration: number[]; lengthOfSamples: number[]},
      t: WaveformTrackMetrics
    ) => {
      if (t.hasData) {
        a.duration.push(t.trackDuration);
        a.lengthOfSamples.push(t.length);
      } else {
        a.duration.push(0);
        a.lengthOfSamples.push(0);
      }

      return a;
    },
    {duration: [], lengthOfSamples: []}
  );

  const duration: number = max(aggregateDurationAndLength.duration) || 0;
  const length: number = max(aggregateDurationAndLength.lengthOfSamples) || 0;
  const sampleRate: number = max(w.map(v => v.sample_rate)) || 0;
  const pixels_per_second: number = max(w.map(v => v.pixels_per_second)) || 0;

  const durationGreaterThanBaseDuration =
    duration > SONG_METRICS.BASE_TRACK_DURATION;

  const metrics: WaveformSongMetrics = {
    // duration: max(duration),
    songDuration: durationGreaterThanBaseDuration
      ? duration
      : SONG_METRICS.BASE_TRACK_DURATION,
    length:
      length !== 0
        ? length
        : Math.floor(SONG_METRICS.BASE_TRACK_DURATION * pixels_per_second),
    channels: max(w.map(v => v.channels)) || 2,
    sample_rate: sampleRate,
    scale: max(w.map(v => v.scale)) || 1,
    seconds_per_pixel: max(w.map(v => v.seconds_per_pixel)) || 0,
    pixels_per_second: max(w.map(v => v.pixels_per_second)) || 0,
    bits: max(w.map(v => v.bits)) || 0
  };

  // console.log(metrics);

  return metrics;
}
