import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
  CustomFirestoreService,
  deviceIsElectron,
  ga_CancelSubscription,
  ga_DeleteAccountMarketing,
  ga_SubscribeToPlan,
  ga_UpdateSubscription,
  ItemizedCost,
  PriceInterval,
  selectActiveSubscriptionsUIDisplay,
  selectHasActiveSubscription,
  selectSubscriptionId,
  StripePaymentSelectionService,
  SubscriptionUIdisplay
} from '@spout/global-web/data-access';
import {
  AccountState,
  ENVIRONMENT,
  IEnvironmentState
} from '@spout/global-web/models';
import {WINDOW} from '@spout/global-web/utils';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {CancelSubscriptionErrorComponent} from './modals/cancel-subscription-error/cancel-subscription-error.component';
import {CancelSubscriptionSuccessComponent} from './modals/cancel-subscription-success/cancel-subscription-success.component';
import {DeleteAccountComponent} from './modals/delete-account/delete-account.component';

@Component({
  selector: 'spt-my-subscription',
  templateUrl: './my-subscription.component.html',
  styleUrls: ['./my-subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StripePaymentSelectionService]
})
export class MySubscriptionComponent implements OnDestroy, OnInit {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _intervalSub$: Subject<boolean> = new Subject<boolean>();

  selectedPlan: string | undefined;
  selectedInterval: PriceInterval = 'month';

  displayedColumns: string[] = ['itemName', 'itemPrice'];
  subscriptionItemized = new MatTableDataSource<ItemizedCost>([]);

  showCancelProgress: BehaviorSubject<boolean>;

  @Input() ga = 'settings_';

  hasSubscription$: Observable<boolean>;
  currentSubscriptions$: Observable<SubscriptionUIdisplay | null>;
  isElectron$: Observable<boolean>;

  constructor(
    private _firestore: CustomFirestoreService,
    public payment: StripePaymentSelectionService,
    private router: Router,
    private store: Store<AccountState>,
    private dialog: MatDialog,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState,
    @Inject(WINDOW) private window: Window
  ) {
    this.showCancelProgress = new BehaviorSubject<boolean>(false);

    this.hasSubscription$ = this.store.pipe(
      select(selectHasActiveSubscription)
    );

    this.currentSubscriptions$ = this.store.pipe(
      select(selectActiveSubscriptionsUIDisplay)
    );

    this.payment.itemizedCheckoutList$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((d: ItemizedCost[]) => {
        this.subscriptionItemized.data = [...d];
      });

    this.isElectron$ = this.store.pipe(select(deviceIsElectron));
  }

  ngOnInit() {
    this.payment.init(()=>{
     this.getPricesByInterval(this.selectedInterval);
     });
    // this.paymentWizardFirestoreService.init();
   
  }

  onSubscribeToAPlan() {
    this.store.dispatch(ga_SubscribeToPlan(this.ga));
    this.updateSubscription.call(this);
  }

  onUpdateSubscription() {
    this.store.dispatch(ga_UpdateSubscription(this.ga));
    this.updateSubscription.call(this);
  }

  private updateSubscription() {
    this.store
      .pipe(select(deviceIsElectron), take(1))
      .subscribe((isElectron: boolean) => {
        if (isElectron) {
          if (this.environment.production) {
            this.window.open('https://spoutstudio.io/subscribe?s=2');
          } else {
            this.window.open('https://dev.spoutstudio.io/subscribe?s=2');
          }
        } else {
          this.router.navigate(['subscribe'], {
            queryParams: {
              s: 2
            }
          });
        }

        // this.router.navigate(['subscribe'], {
        //   queryParams: {
        //     s: 2
        //   }
        // });
      });
  }

  onCancelSubscriptionEndOfPeriod() {
    this.store.dispatch(ga_CancelSubscription(this.ga));

    this.showCancelProgress.next(true);
    this.store
      .pipe(select(selectSubscriptionId), take(1))
      .subscribe((subscriptionId: string | null) => {
        if (subscriptionId) {
          const cancelSubscriptionEndOfPeriod = this._firestore.httpsCallable(
            'cancelSubscriptionEndOfPeriod'
          );

          cancelSubscriptionEndOfPeriod(subscriptionId)
            .then(() => {
              this.dialog.open(CancelSubscriptionSuccessComponent, {
                backdropClass: 'studio-dialog-backdrop',
                width: '600px'
              });
              this.showCancelProgress.next(false);
            })
            .catch((error: any) => {
              this.dialog.open(CancelSubscriptionErrorComponent, {
                backdropClass: 'studio-dialog-backdrop',
                width: '600px',
                data: error
              });
              this.showCancelProgress.next(false);
            });
        } else {
          this.showCancelProgress.next(false);
        }
      });
  }

  onDeleteAccount() {
    this.store.dispatch(ga_DeleteAccountMarketing(this.ga));
    this.dialog.open(DeleteAccountComponent, {
      backdropClass: 'studio-dialog-backdrop',
      width: '600px'
    });
  }

  onCancelSubscription() {
    this.store.dispatch(ga_CancelSubscription(this.ga));

    this.showCancelProgress.next(true);
    this.store
      .pipe(select(selectSubscriptionId), take(1))
      .subscribe((subscriptionId: string | null) => {
        if (subscriptionId) {
          const cancelSubscription =
            this._firestore.httpsCallable('cancelSubscription');

          cancelSubscription(subscriptionId)
            .then(() => {
              this.dialog.open(CancelSubscriptionSuccessComponent, {
                backdropClass: 'studio-dialog-backdrop',
                width: '600px'
              });
              this.showCancelProgress.next(false);
            })
            .catch((error: any) => {
              this.dialog.open(CancelSubscriptionErrorComponent, {
                backdropClass: 'studio-dialog-backdrop',
                width: '600px',
                data: error
              });
              this.showCancelProgress.next(false);
            });
        } else {
          this.showCancelProgress.next(false);
        }
      });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._intervalSub$.next(true);
  }

  private getPricesByInterval(interval: PriceInterval) {
    this._intervalSub$.next(true);
  }
}
