import {HelpModel} from '../help.model';
import {ChatEntity} from './chat.model';

export interface MixerRightDrawerState {
  opened: boolean;
  view: string;
  openConversationInRightDrawer: ChatEntity | null;
  openConversationInTrack: ChatEntity | null;
  helpView: HelpModel;
}

export enum MixerRightDrawerTypes {
  INVITE = 'INVITE',
  CHAT_TRACK = 'CHAT_TRACK',
  HELP = 'HELP',
  NOTFIICATION = 'NOTFIICATION'
}

export const initialMixerRightDrawerState: MixerRightDrawerState = {
  // set initial required properties
  opened: true,
  view: MixerRightDrawerTypes.HELP,
  openConversationInRightDrawer: null,
  openConversationInTrack: null,
  helpView: HelpModel.GETTING_STARTED_CREATING
};
