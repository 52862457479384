import {
  baseElectronVersion,
  BuildVersionElectron,
  BuildVersionUi,
  concatBuildVersion,
  concatElectronBuildVersion,
  concatUiBuildVersion,
  EnvPlatform,
  ui
} from '@spout/global-any/models';

export interface StudioInstalled {
  ui: BuildVersionUi;
  uiString: string;
  electron: BuildVersionElectron;
  electronString: string;
  electronAppBuild: string | null;
  platform: EnvPlatform | null;
}

export interface ServiceWorkerStatus {
  checkingForUpdate: boolean;
  hasUiUpdate: boolean;
}

export interface StudioVersionState {
  loaded: boolean;
  serviceWorker: ServiceWorkerStatus;
  installed: StudioInstalled;
}

export const initialStudioState: StudioVersionState = {
  loaded: false,
  serviceWorker: {
    checkingForUpdate: true,
    hasUiUpdate: false
  },
  installed: {
    ui: ui,
    uiString: concatUiBuildVersion(ui),
    electron: baseElectronVersion,
    electronString: concatElectronBuildVersion(baseElectronVersion),
    electronAppBuild: null,
    platform: null
  }
};
