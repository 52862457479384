<!--  SIGN UP WITH EMAIL  -->
<!--<h3>Sign up with email</h3>-->

<form class="example-form" [formGroup]="signUpForm">
  <mat-form-field class="full-width" appearance="fill" floatLabel="always">
    <mat-label>Email</mat-label>
    <input
      type="email"
      matInput
      formControlName="email"
      required
      placeholder="Ex. pat@example.com" />

    <mat-error *ngIf="signUpForm.controls['email'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field
    class="full-width password"
    appearance="fill"
    floatLabel="always">
    <mat-label>Password</mat-label>
    <input
      [type]="inputType"
      autocomplete="current-password"
      matInput
      formControlName="password"
      required />

    <mat-error *ngIf="signUpForm.controls['password'].hasError('required')">
      Password is <strong>required</strong>
    </mat-error>

    <mat-error
      *ngIf="
        signUpForm.controls['confirmPassword'].hasError('passwordsNotMatch')
      ">
      Passwords do not match
    </mat-error>
  </mat-form-field>

  <mat-form-field
    class="full-width password"
    appearance="fill"
    floatLabel="always">
    <mat-label>Confirmed Password</mat-label>
    <input
      [type]="inputType"
      autocomplete="current-password"
      matInput
      formControlName="confirmPassword" />

    <mat-error *ngIf="signUpForm.controls['password'].hasError('required')">
      Confirm Password is <strong>required</strong>
    </mat-error>

    <mat-error
      *ngIf="
        signUpForm.controls['confirmPassword'].hasError('passwordsNotMatch')
      ">
      Passwords do not match
    </mat-error>
  </mat-form-field>

  <mat-checkbox
    style="float: right; margin-bottom:20px"
    (change)="showPassword($event)"
    [checked]="inputType === 'text'">
    Show Password
  </mat-checkbox>
  <br />


  <button
    mat-flat-button
    e2e="cta-promo"
    style="
      background-color: #ff4081;
      background: #ff4081;
      color: white;
      display: block;
      width:100%;
    "
    [disabled]="signUpForm.invalid && signUpForm.dirty"
    (click)="applyPromo()">
    Sign Up & Apply Promo
  </button>
  <br />
  <button
    style="
      background-color: #FFF;
      border:none;
      color: #5b5b5b;
      display: block;
      width:100%;
    "
    e2e="cta-sign-in"
    (click)="submitEmailPassword()"
    [disabled]="signUpForm.invalid && signUpForm.dirty">
    Sign Up Without Promo
  </button>
</form>
