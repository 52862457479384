import {
  AudioFileMetaData,
  TrackMixAudioSnippet
} from '@spout/global-any/models';

export function createInitialTrackMixAudioSnippet(
  file: AudioFileMetaData
): TrackMixAudioSnippet {
  return {
    audioFileMetaDataEntityId: file.id,
    start: 0,
    offsetMs: 0,
    stop: 0,
    audioDuration: 0,
    sampleRate: 44100,
    isDefault: false
  };
}
