<h1 mat-dialog-title>Delete Account</h1>
<div mat-dialog-content>
  <div>
    Use the Spout Studio App to delete your account. The following actions will
    be
    <span class="warn-bold">permanent</span>.
  </div>
  <ul>
    <li>Cancel subscriptions.</li>
    <!--              <li>Delete Stripe customer reference.</li>-->
    <!--              <li>Delete Algolia customer search reference.</li>-->
    <!--              <li>Delete Firebase authentication ( login ).</li>-->
    <li>Delete projects, songs, mixes, tracks created by THIS ACCOUNT ONLY.</li>
    <!--              <li>-->
    <!--                Delete Backblaze B2 files ( audio files stored in the cloud).-->
    <!--              </li>-->
    <li>
      Delete all audio and midi files from your device, cloud storage, and
      remote devices.
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" mat-dialog-close>Close</button>
</div>
