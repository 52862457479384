import {Inject, Injectable} from '@angular/core';
import {Actions, OnInitEffects} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {ENVIRONMENT, IEnvironmentState} from '@spout/global-web/models';
import {loadEnvironment} from './environment.actions';

@Injectable()
export class EnvironmentEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    @Inject(ENVIRONMENT) public environment: IEnvironmentState
  ) {}

  ngrxOnInitEffects(): Action {
    return loadEnvironment({
      payload: this.environment
    });
  }
}
