import {StorageAndPriceByIntervalDict} from '../../+product';
import {ProductWizardStore} from '../../+subscriptions';

export function updateStorageQuantity(
  state: ProductWizardStore,
  {
    quantity,
    storage
  }: {quantity: number; storage: StorageAndPriceByIntervalDict}
) {
  if (quantity > 0) {
    return {
      ...state,
      additionalStoragePerGB: quantity,
      storageProduct: storage.storageProduct
      // baseMonthlyStoragePrice: storage[state.interval]
    };
  }

  return {
    ...state,
    additionalStoragePerGB: 0
  };
}
