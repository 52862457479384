import {Dictionary} from '@ngrx/entity/src/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import {
  getAudioMetaDataEntitiesOfTrackMix,
  getTrackMixOfTrackAndMix
} from '@spout/global-any/fns';
import {
  AudioFileMetaData,
  AudioFileMetaDataEntity,
  CombinedTrackAndTrackMix,
  CurrentMixByTrackId,
  CurrentMixSongIdMixes,
  MixEntity,
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet,
  TrackState,
  UserPermissions
} from '@spout/global-any/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector
} from '@spout/global-web/fns';
import {
  TrackEntityAndAudioFileMetaDataEntity,
  trackMixesFeatureKey,
  TrackMixState
} from '@spout/global-web/models';
import {getIn, hasValue} from '@uiux/fn';
import {
  selectAllAudioMetaData,
  selectAudioMetaDataEntities
} from '../+audio-file-meta-data/audio-metadata-storage.selectors';
import {
  selectCurrentMixEntity,
  selectMixEntities
} from '../+mixes/mix-storage.selectors';
import {selectCurrentSongId} from '../+songs/song-storage.selectors';
import {
  selectCurrentTrackEntity,
  selectCurrentTracks
} from '../+tracks/track-storage.selectors';

export const trackMixState =
  createFeatureSelector<TrackMixState>(trackMixesFeatureKey);
export const trackMixStatePassThrough =
  createPassThroughFeatureSelector<TrackState>(trackMixesFeatureKey);

export const selectTrackMixEntities = createSelector(
  trackMixState,
  (state: TrackMixState): Dictionary<TrackMix> => {
    return state.entities;
  }
);

export const selectAllTrackMixes = createSelector(
  selectTrackMixEntities,
  (entities: Dictionary<TrackMix>): TrackMix[] => {
    if (hasValue(entities)) {
      return <TrackMix[]>Object.values(entities);
    }

    return [];
  }
);

export const selectAllTrackMixesPassThrough = createPassThroughSelector(
  selectTrackMixEntities,
  (entities: Dictionary<TrackMix>): TrackMix[] => {
    if (hasValue(entities)) {
      return <TrackMix[]>Object.values(entities);
    }

    return [];
  }
);

export const getTrackMixesByMixId = createSelector(
  selectAllTrackMixes,
  (mixes: TrackMix[], props: {mixId: string}): TrackMix[] => {
    return mixes.filter((trackMix: TrackMix) => trackMix.mixId === props.mixId);
  }
);

export const getTrackMixByMixId = createSelector(
  selectAllTrackMixes,
  (mixes: TrackMix[], props: {id: string}): TrackMix | undefined => {
    return mixes.find((trackMix: TrackMix) => trackMix.id === props.id);
  }
);

export const selectCurrentTrackMixes = createSelector(
  selectCurrentMixEntity,
  selectAllTrackMixes,
  (mix: MixEntity | null, trackMixes: TrackMix[]) => {
    if (hasValue(mix) && hasValue(trackMixes)) {
      return trackMixes.filter(
        (trackMix: TrackMix) => mix && trackMix.mixId === mix.id
      );
    }

    return [];
  }
);

export const selectCurrentTrackMixDict = createSelector(
  selectCurrentMixEntity,
  selectAllTrackMixes,
  (
    mix: MixEntity | null,
    trackMixes: TrackMix[]
  ): {[trackId: string]: TrackMix} => {
    if (hasValue(mix) && hasValue(trackMixes)) {
      return trackMixes.reduce(
        (a: {[trackId: string]: TrackMix}, trackMix: TrackMix) => {
          a[trackMix.trackId] = trackMix;
          return a;
        },
        {}
      );
    }

    return {};
  }
);

export const selectCurrentAudioFileMetaDataForRecord = createSelector(
  selectCurrentMixEntity,
  selectCurrentTrackEntity,
  selectAudioMetaDataEntities,
  selectCurrentTrackMixDict,
  (
    currentMix: MixEntity | null,
    currentTrack: TrackEntity | undefined,
    e: Dictionary<AudioFileMetaDataEntity>,
    trackMixesDict: {[trackId: string]: TrackMix}
  ): AudioFileMetaDataEntity[] => {
    if (currentTrack && currentMix && hasValue(e)) {
      // console.log('Current TrackEntity', currentTrack);
      // console.log('Current MixEntity', currentMix);
      // console.log('AudioMetaData Entities', e);

      // const audioSnippets = currentMix.trackMixes[currentTrack.id].audioSnippets;
      const audioSnippets = getIn(
        trackMixesDict,
        `${currentTrack.id}.audioSnippets`,
        null
      );

      if (hasValue(audioSnippets)) {
        // console.log('Audio Snippets', audioSnippets);
        const mixFileIds: string[] = Object.keys(audioSnippets);

        // console.log('Mix File Ids', mixFileIds);

        return <AudioFileMetaDataEntity[]>(
          mixFileIds.map(
            (audioFileMetaDataEntityId: string) => e[audioFileMetaDataEntityId]
          )
        );
      }

      return [];
    }

    return [];
  }
);

export const selectCurrentTrackEntityAndAudioMetaDataEntity = createSelector(
  selectCurrentMixEntity,
  selectCurrentTrackEntity,
  selectAudioMetaDataEntities,
  selectCurrentTrackMixDict,
  (
    currentMix: MixEntity | null,
    currentTrack: TrackEntity | undefined,
    e: Dictionary<AudioFileMetaDataEntity>,
    trackMixesDict: {[trackId: string]: TrackMix}
  ): {
    trackEntity: TrackEntity | null;
    audioFileMetaDataEntity: AudioFileMetaDataEntity | null;
  } => {
    if (currentTrack && currentMix && hasValue(e)) {
      // console.log('Current TrackEntity', currentTrack);
      // console.log('Current MixEntity', currentMix);
      // console.log('AudioMetaData Entities', e);

      // const audioSnippets = currentMix.trackMixes[currentTrack.id].audioSnippets;
      const audioSnippets = getIn(
        trackMixesDict,
        `${currentTrack.id}.audioSnippets`,
        null
      );

      if (hasValue(audioSnippets)) {
        // console.log('Audio Snippets', audioSnippets);
        const mixFileIds: string[] = Object.keys(audioSnippets);

        // console.log('Mix File Ids', mixFileIds);

        const audioFileMetaDataEntities: (
          | (AudioFileMetaData & UserPermissions)
          | undefined
        )[] = mixFileIds.map(
          (audioFileMetaDataEntityId: string) => e[audioFileMetaDataEntityId]
        );

        if (audioFileMetaDataEntities.length) {
          return <TrackEntityAndAudioFileMetaDataEntity>{
            trackEntity: currentTrack,
            audioFileMetaDataEntity:
              audioFileMetaDataEntities[audioFileMetaDataEntities.length - 1]
          };
        }

        return {
          trackEntity: currentTrack,
          audioFileMetaDataEntity: null
        };
      }

      return {
        trackEntity: currentTrack,
        audioFileMetaDataEntity: null
      };
    }

    return {
      trackEntity: null,
      audioFileMetaDataEntity: null
    };
  }
);

export const selectTrackMixSourcesByCurrentMix = createSelector(
  selectCurrentMixEntity,
  selectCurrentTracks,
  selectAllAudioMetaData,
  selectAllTrackMixes,
  (
    mix: MixEntity | null,
    currentTracks: TrackEntity[],
    audioFileMetaData: AudioFileMetaDataEntity[],
    trackMixes: TrackMix[]
  ): CombinedTrackAndTrackMix[] => {
    if (
      hasValue(mix) &&
      hasValue(currentTracks) &&
      hasValue(audioFileMetaData) &&
      hasValue(trackMixes)
    ) {
      const combined: (CombinedTrackAndTrackMix | null)[] = (<TrackEntity[]>(
        currentTracks
      )).map((track: TrackEntity) => {
        if (mix) {
          const trackMix: TrackMix | null = getTrackMixOfTrackAndMix(
            mix,
            track,
            trackMixes
          );

          if (!trackMix) {
            return null;
          }

          return <CombinedTrackAndTrackMix>{
            track,
            trackMix,
            audioFileMetaDataEntities: getAudioMetaDataEntitiesOfTrackMix(
              trackMix,
              audioFileMetaData
            )
          };
        }

        return null;
      });

      if (hasValue(combined)) {
        return <CombinedTrackAndTrackMix[]>combined.filter(
          (com: CombinedTrackAndTrackMix | null) => {
            return hasValue(com);
          }
        );
      }

      return [];
    }

    return [];
  }
);

export const getCurrentTrackMixByTrackIdFn = (props: {trackId: string}) =>
  createPassThroughSelector(
    selectCurrentMixEntity,
    selectAllTrackMixesPassThrough,
    (mix: MixEntity | null, trackMixes: TrackMix[]): TrackMix | null => {
      if (mix && trackMixes) {
        const found = trackMixes.find((trackMix: TrackMix) => {
          return (
            trackMix.trackId === props.trackId && trackMix.mixId === mix.id
          );
        });

        if (found) {
          return found;
        }
      }

      return null;
    }
  );

export const getTrackMixesByTrackId = createSelector(
  selectAllTrackMixes,
  (trackMixes: TrackMix[], props: {trackId: string}): TrackMix[] => {
    if (hasValue(trackMixes)) {
      return trackMixes.filter((trackMix: TrackMix) => {
        return trackMix.trackId === props.trackId;
      });
    }
    return [];
  }
);

export const getTrackMixByTrackIdFn = (props: {trackId: string}) =>
  createSelector(
    selectCurrentMixEntity,
    selectAllTrackMixes,
    (mix: MixEntity | null, trackMixes: TrackMix[]): TrackMix | null => {
      if (mix && trackMixes) {
        const found = trackMixes.find((trackMix: TrackMix) => {
          return (
            trackMix.trackId === props.trackId && trackMix.mixId === mix.id
          );
        });

        if (found) {
          return found;
        }
      }

      return null;
    }
  );

export const selectMixByMixId = createSelector(
  selectCurrentTrackEntity,
  selectMixEntities,
  (
    track: TrackEntity | undefined,
    entities: Dictionary<MixEntity>,
    props: {mixId: string}
  ): CurrentMixByTrackId | null => {
    const mixConfig = entities[props.mixId];

    if (track && Object.values(entities) && mixConfig !== undefined) {
      return {
        track,
        mix: mixConfig
      };
    }

    return null;
  }
);

export const sidenavSelectMixComponent = createSelector(
  selectCurrentSongId,
  selectCurrentMixEntity,
  selectMixEntities,
  (
    songId: string | null,
    currentMix: MixEntity | null,
    entities: Dictionary<MixEntity>
  ): CurrentMixSongIdMixes => {
    if (songId && (<MixEntity[]>Object.values(entities)).length) {
      return (<MixEntity[]>Object.values(entities))
        .filter(
          (mix: MixEntity | undefined) =>
            mix !== undefined && mix.songId === songId
        )
        .reduce(
          (a: CurrentMixSongIdMixes, i: MixEntity | undefined) => {
            if (i) {
              a.mixes.push(i);
            }

            return a;
          },
          <CurrentMixSongIdMixes>{
            songId,
            mixes: [],
            currentMix: null
          }
        );
    }
    return {
      songId,
      mixes: [],
      currentMix: null
    };
  }
);

export const getAudioSnippetsByTrackID = (trackID: string) => {
  return createSelector(selectAllTrackMixes, (trackMixes: TrackMix[]) => {
    return trackMixes
      .filter((t: TrackMix) => t.trackId === trackID)
      .map(
        (t: TrackMix) => <TrackMixAudioSnippet[]>Object.values(t.audioSnippets)
      );
  });
};

export const getOffsetByTrackID = (trackID: string) => {
  return createSelector(
    selectAllTrackMixes,
    selectCurrentMixEntity,
    (trackMixes: TrackMix[], currentMix: MixEntity | null): number => {
      if (currentMix) {
        const audioSnippets: TrackMixAudioSnippet[] = trackMixes
          .filter(
            (t: TrackMix) => t.trackId === trackID && t.mixId == currentMix.id
          )
          .map((t: TrackMix) => Object.values(t.audioSnippets))
          .flat();

        return audioSnippets.reduce(
          (offset: number, a: TrackMixAudioSnippet) => {
            if (a.offsetMs !== 0) {
              return a.offsetMs;
            }

            return offset;
          },
          0
        );
      }

      return 0;
    }
  );
};

export const getAllTrackMixesBySongId = (songId: string) =>
  createSelector(selectAllTrackMixes, (trackMixes: TrackMix[]): TrackMix[] =>
    trackMixes.filter((t: TrackMix) => t.songId === songId)
  );

export const getAllTrackMixesByProjectId = (projectId: string) =>
  createSelector(selectAllTrackMixes, (trackMixes: TrackMix[]): TrackMix[] =>
    trackMixes.filter((t: TrackMix) => t.projectId === projectId)
  );
