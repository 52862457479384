import {Injectable, NgZone} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import {Store} from '@ngrx/store';
import {selectIsLoggedIn$} from '@spout/global-web/data-access';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
// import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardStudioSiteService implements CanActivate, CanLoad {
  constructor(
    private store: Store,
    private zone: NgZone,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      selectIsLoggedIn$,
      // tap((isLoggedIn: boolean) => { console.log('AUTH GAURD isLoggedIn', isLoggedIn);  }),
      distinctUntilChanged(),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return true;
        } else {
          this.navigateHome();
          return false;
        }
      })
    );
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      selectIsLoggedIn$,
      distinctUntilChanged(),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return true;
        } else {
          this.navigateHome();

          return false;
        }
      })
    );
  }

  private navigateHome() {
    this.zone.run(() => {
      this.router.navigate(['login']).then(() => {
        /* noop */
      });
      this.router.navigate(['signup']).then(() => {
        /* noop */
      });
    });
  }
}
