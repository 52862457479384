import {EntityType, ProjectEntity, TreeConfig} from '@spout/global-any/models';
import {
  AccountState,
  applyInitialAuthor,
  createAuthorMusician
} from '@spout/global-web/models';
import {createInitialConfig} from './create-initial-config';

export function createInitialProjectEntity(
  account: AccountState
): ProjectEntity {
  const initialConfig: Partial<TreeConfig> = createInitialConfig();

  const config = {
    ...initialConfig,
    entityType: EntityType.PROJECT
  };

  return applyInitialAuthor<any, ProjectEntity>(
    <ProjectEntity>config,
    createAuthorMusician(account)
  );
}

export function setProjectEntityProps(
  projectEntity: ProjectEntity,
  props: Partial<ProjectEntity>
): ProjectEntity {
  return {
    ...projectEntity,
    ...props
  };
}
