import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {DYN_STORE, RecordOptions} from '@spout/global-web/models';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {upsertDeviceStorage} from '../+device-storage/device-storage.actions';
import {clearTrackEffect, deleteTrackEffect} from '../+tracks/track.actions';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {SptMetronomeService} from './services/spt-metronome.service';
import {SptPlayerCacheService} from './services/spt-player-cache.service';
import {SptScrubberService} from './services/spt-scrubber.service';
import {SptRecorderService} from './spt-recorder.service';
import {
  masterFastForwardEffect,
  masterFastRewindEffect,
  masterFullForwardEffect,
  masterFullRewindEffect,
  masterStopEffect,
  masterVolume,
  playMetronomeEffect,
  playMetronomeOnRecord,
  playMetronomeOnRecordEffect,
  playMixOnRecord,
  playMixOnRecordEffect,
  stopMetronomeEffect,
  transportStartPlaying,
  transportStartPlayingEffect,
  transportStartRecording,
  transportStartRecordingEffect,
  transportStop,
  transportStopRecording
} from './spt-tone-transport-control.actions';
import {selectRecordOptions} from './spt-tone-transport-control.selectors';
import {SptTransportService} from './spt-transport.service';

@Injectable({
  providedIn: 'root'
})
export class SptTransportEffects {
  /**
   * INIT
   */
  init$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        map(() => {
          this.dss.dispatch(DYN_STORE.IS_BEGINNING_OF_SONG, true);
          this.dss.dispatch(DYN_STORE.IS_END_OF_SONG, false);
        })
      );
    },
    {dispatch: false}
  );

  /**
   * RECORD START
   */
  startRecording$ = createEffect(() => {
    const that = this;
    return this.actions$.pipe(
      ofType(transportStartRecordingEffect),
      switchMap(action => {
        return this.store.pipe(
          select(selectRecordOptions),
          take(1),

          // switchMap((r: RecordOptions) => {
          //   // console.log(r);
          //   if (r.playMetronomeOnRecord) {
          //     return of(r).pipe(this.metronome.connectPipe());
          //   } else {
          //     return of(r).pipe(this.metronome.disconnectPipe());
          //   }
          // }),

          switchMap((r: RecordOptions) => {
            // console.log(r);
            if (r.playMixOnRecord) {
              return this.playerCache
                .unmuteTracksActiveInPlaylistPipe$()
                .pipe(map(() => r));
            } else {
              return this.playerCache.muteAllPipe$().pipe(map(() => r));
            }
          }),

          map((r: RecordOptions) => {
            this.sptTransportService.playAndRecord();
            return transportStartRecording();
          })
        );

        // return this.recorder.getRecorderWorklet().pipe(
        //   switchMap(worker => {
        //     // this.metronome.disconnect();
        //     // If metronome is playing
        //     this.sptTransportService.stop();
        //
        //
        //   })
        // );
      })
    );
  });

  /**
   * RECORD STOP
   */
  masterStopRecordingEffect$ = createEffect(() => {
    const that = this;
    return this.actions$.pipe(
      ofType(transportStopRecording),

      map(action => {
        this._recorder.stopRecordingAndExport(action.fileExportType);
        this.sptTransportService.stop();
        this.dss.emit(DYN_STORE.REAPPLY_TRACK_MIXES, true);
        return transportStop();
      })
    );
  });

  /**
   * RECORD - PLAY MIX WHILE RECORD
   *
   */
  playMixOnRecordEffect$ = createEffect(() => {
    const that = this;
    return this.actions$.pipe(
      ofType(playMixOnRecordEffect),
      map(action => {
        return playMixOnRecord({
          playMixOnRecord: action.playMixOnRecord
        });
      })
    );
  });

  /**
   * RECORD - PLAY METRONOME WHILE RECORD
   */
  playMetronomeOnRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(playMetronomeOnRecordEffect),
      map(action => {
        return playMetronomeOnRecord({
          playMetronomeOnRecord: action.playMetronomeOnRecord
        });
      })
    );
  });

  /**
   * VOLUME MASTER
   */
  masterVolume$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(masterVolume),
      map(action => {
        // this.sptPlayer.volume(action.volume);

        return upsertDeviceStorage({
          storage: {
            masterVolume: action.volume
          }
        });
      })
    );
  });

  /**
   * METRONOME START
   */
  playMetronomeEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(playMetronomeEffect),
        switchMap(action => {
          return this.playerCache.muteAllPipe$().pipe(map(() => action));
        }),
        this.metronome.connectPipe(),
        tap(() => {
          this.metronome.isPlaying$.next(true);
          this.sptTransportService.play();
        })
      );
    },
    {dispatch: false}
  );

  /**
   * METRONOME STOP
   */
  stopMetronomeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stopMetronomeEffect),
      tap(() => {
        this.sptTransportService.stop();
        // this.metronome.isPlaying$.next(false);
      }),
      // this.metronome.disconnectPipe(),
      switchMap(action => {
        return this.playerCache
          .unmuteTracksActiveInPlaylistPipe$()
          .pipe(map(() => action));
      }),
      map(() => {
        return transportStop();
      })
    );
  });

  /**
   * PLAYER START
   */
  masterStartPlaying$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(transportStartPlayingEffect),
      tap(() => {
        this.sptTransportService.stop();
      }),
      switchMap(action => {
        return this.playerCache
          .unmuteTracksActiveInPlaylistPipe$()
          .pipe(map(() => action));
      }),
      map(startPlaying => {
        this.sptTransportService.play();
      }),
      map(() => {
        return transportStartPlaying();
      })
    );
  });

  /**
   * PLAYER STOP
   */
  masterStop$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(masterStopEffect),
      map(() => {
        // this.sptPlayer.stopPlayerAndTransport();
        this.sptTransportService.stop();

        if (this.metronome.isPlaying$.value) {
          this.playerCache.cache.muteAll();
          this.sptTransportService.play();
        }

        return transportStop();
      })
    );
  });

  /**
   * PLAYER FULL REWIND
   */
  masterFullRewindEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(masterFullRewindEffect),
        map(() => {
          this.sptScrubberService.fullRewind();
          // return transportStop();
        })
      );
    },
    {dispatch: false}
  );

  /**
   * PLAYER FULL FORWARD
   */
  masterFullForwardEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(masterFullForwardEffect),
      map(() => {
        this.sptScrubberService.fullForward();
        return transportStop();
      })
    );
  });

  /**
   * PLAYER FAST REWIND
   */
  masterFastRewindEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(masterFastRewindEffect),
        map(() => {
          this.sptScrubberService.fastRewind();
          // return transportStop();
        })
      );
    },
    {dispatch: false}
  );

  /**
   * PLAYER FAST FORWARD
   */
  masterFastForwardEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(masterFastForwardEffect),
        map(() => {
          this.sptScrubberService.fastForward();
          // return transportStop();
        })
      );
    },
    {dispatch: false}
  );

  clearTrackEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearTrackEffect, deleteTrackEffect),
        map(action => {
          this.sptScrubberService.fullRewind();
        })
      ),
    {dispatch: false}
  );

  constructor(
    private dss: DynamicStoreService,
    private sptScrubberService: SptScrubberService,
    private actions$: Actions,
    private playerCache: SptPlayerCacheService,
    private metronome: SptMetronomeService,
    private toneService: SptPlayerCacheService,
    private store: Store,
    private _recorder: SptRecorderService,
    private sptTransportService: SptTransportService
  ) {}
}
