import {Action, createReducer, on} from '@ngrx/store';
import {
  genreAdapter,
  genreInitialState,
  GenreState
} from '@spout/global-web/models';
import {authLoggedOut} from '../+account/auth.actions';
import {
  addGenre,
  addGenres,
  clearGenres,
  deleteGenre,
  deleteGenres,
  genreAggregate,
  genreLoaded,
  genreRoot,
  loadGenres,
  updateGenre,
  updateGenres,
  upsertGenre,
  upsertGenres
} from './genre.actions';

const reducer = createReducer(
  genreInitialState,
  on(addGenre, (state, action) => genreAdapter.addOne(action.genre, state)),
  on(upsertGenre, (state, action) =>
    genreAdapter.upsertOne(action.genre, state)
  ),
  on(addGenres, (state, action) => genreAdapter.addMany(action.genres, state)),
  on(upsertGenres, (state, action) =>
    genreAdapter.upsertMany(action.genres, state)
  ),
  on(updateGenre, (state, action) =>
    genreAdapter.updateOne(action.genre, state)
  ),
  on(updateGenres, (state, action) =>
    genreAdapter.updateMany(action.genres, state)
  ),
  on(deleteGenre, (state, action) => genreAdapter.removeOne(action.id, state)),
  on(deleteGenres, (state, action) =>
    genreAdapter.removeMany(action.ids, state)
  ),
  on(loadGenres, (state, action) => genreAdapter.setAll(action.genres, state)),
  on(clearGenres, state => genreAdapter.removeAll(state)),
  on(genreAggregate, (state, action) => {
    let _state = genreAdapter.upsertMany(action.aggregate.added, state);
    _state = genreAdapter.upsertMany(action.aggregate.modified, _state);
    _state = genreAdapter.removeMany(action.aggregate.removed, _state);

    if (action.aggregate.root) {
      _state.rootNode = action.aggregate.root;
    }

    return _state;
  }),
  on(genreLoaded, (state, action) => {
    state = {
      ...state,
      ...action.payload,
      loaded: true
    };
    return state;
  }),
  on(authLoggedOut, (state, action) => {
    state = {
      ...genreInitialState
    };
    return state;
  }),
  on(genreRoot, (state, action) => {
    return {
      ...state,
      rootNode: action.root
    };
  })
);

export function genreReducer(state: GenreState | undefined, action: Action) {
  return reducer(state, action);
}
