import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {AudioFileMetaDataEntity} from '@spout/global-any/models';

export const addAudioFileMetaDataEntity = createAction(
  '[AudioFileMetaData Entity/API] Add File',
  props<{file: AudioFileMetaDataEntity}>()
);

export const upsertAudioFileMetaDataEntity = createAction(
  '[AudioFileMetaData Entity/API] Upsert File',
  props<{file: AudioFileMetaDataEntity}>()
);

export const addAudioFileMetaDataEntities = createAction(
  '[AudioFileMetaData Entity/API] Add Files',
  props<{files: AudioFileMetaDataEntity[]}>()
);

export const upsertAudioFileMetaDataEntities = createAction(
  '[AudioFileMetaData Entity/API] Upsert Files',
  props<{files: AudioFileMetaDataEntity[]}>()
);

export const updateAudioFileMetaDataEntity = createAction(
  '[AudioFileMetaData Entity/API] Update File',
  props<{file: Update<AudioFileMetaDataEntity>}>()
);

export const updateAudioFileMetaDataEntityEffect = createAction(
  '[AudioFileMetaData Entity/API] Update File Effect',
  props<{
    projectId: string;
    file: Update<AudioFileMetaDataEntity>;
  }>()
);

export const updateAudioFileMetaDataEntities = createAction(
  '[AudioFileMetaData Entity/API] Update Files',
  props<{files: Update<AudioFileMetaDataEntity>[]}>()
);

export const deleteAudioFileMetaDataEntity = createAction(
  '[AudioFileMetaData Entity/API] Delete File',
  props<{id: string}>()
);

export const deleteAudioFileMetaDataEntities = createAction(
  '[AudioFileMetaData Entity/API] Delete Files',
  props<{ids: string[]}>()
);

export const clearAudioFileMetaDataEntities = createAction(
  '[AudioFileMetaData Entity/API] Clear Files'
);
