import {Component, Inject} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {
  TRACK_DIALOG_MESSAGE,
  trackDialogTypeMatches,
  TrackItemDialogData
} from './track-item-dialog.model';

@Component({
  selector: 'spt-track-item-dialog',
  templateUrl: './track-item-dialog.component.html',
  styleUrls: ['./track-item-dialog.component.scss']
  // encapsulation: ViewEncapsulation.None,
})
export class TrackItemDialogComponent {
  dialogType = TRACK_DIALOG_MESSAGE;

  constructor(
    public dialogRef: MatDialogRef<TrackItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TrackItemDialogData<any>
  ) {
    // console.log(this.data);
  }

  matches(type: string): boolean {
    return trackDialogTypeMatches(this.data, type);
  }

  ok() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
