import {AggregateFirebaseSnapshotChangesEntities} from '@spout/global-web/models';
import {aggregateDocChanges} from './aggregate-doc-changes';
import {QuerySnapshot, DocumentChange} from '@firebase/firestore';

/**
 *
 * @param snapshot - firebase.firestore.QuerySnapshot from firestore collection query
 * @param id - id key used to map Enitities in reducer / store
 * @param rootName - name of node that is 'root'
 */
export function aggregateFirestoreSnapshotEntities<T>(
  snapshot: QuerySnapshot,
  id: string = 'id',
  rootName: string = 'root'
): AggregateFirebaseSnapshotChangesEntities<T> {
  return aggregateDocChanges<T>(
    <DocumentChange<T>[]>snapshot.docChanges(),
    id,
    rootName
  );
}
