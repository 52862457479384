import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {LetModule, PushModule} from '@ngrx/component';
import {StripeSubscribeDialogComponent} from './stripe-subscribe-dialog/stripe-subscribe-dialog.component';
import {StripeSubscribeViewModule} from './stripe-subscribe/stripe-subscribe-view.module';

@NgModule({
  declarations: [StripeSubscribeDialogComponent],
  imports: [
    CommonModule,
    LetModule,
    PushModule,
    StripeSubscribeViewModule,
    MatButtonModule
  ],
  exports: [StripeSubscribeDialogComponent]
})
export class StripeSubscribeModule {}
