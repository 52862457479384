import {Injectable, NgZone} from '@angular/core';
import {DYN_STORE} from '@spout/global-web/models';
import {
  BehaviorSubject,
  Observable,
  Observer,
  ReplaySubject,
  Subject
} from 'rxjs';
import {filter, take} from 'rxjs/operators';

interface StoreItem<T> {
  id: string;
  store: ReplaySubject<T>;
  hasValue: boolean;
}

interface EmitterItem<T> {
  id: string;
  event: Subject<T>;
}

interface SingletonItem<T> {
  id: string;
  store: BehaviorSubject<T>;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicStoreService {
  DYN_STORE = DYN_STORE;

  _storeCache: {[key: string]: StoreItem<any>} = {};
  _eventCache: {[key: string]: EmitterItem<any>} = {};
  _singletonCache: {[key: string]: SingletonItem<any>} = {};

  constructor(private zone: NgZone) {
    // console.log('DynamicStoreService');
  }

  store<T>(
    id: string,
    config?: {defaultValue: T; runInZone: boolean}
  ): Observable<T> {
    if (config && config.runInZone) {
      return this.getStore<T>(id, config?.defaultValue).store.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter((v: any) => v !== null),
        this.runInZone
      );
    }

    return this.getStore<T>(id, config?.defaultValue).store.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filter((v: any) => v !== null)
    );
  }

  // pipe<T>(id: string, defaultValue?: T): ReplaySubject<T> {
  //   return this.getStore(id, defaultValue).store;
  // }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch(id: string, value: any): void {
    this.getStore(id, null).store.next(value);
    this.getStore(id, null).hasValue = true;
  }

  dispatchIfChanged<T>(id: string, value: T): void {
    const that = this;
    if (this._storeCache[id] && this._storeCache[id].hasValue) {
      this._storeCache[id].store.pipe(take(1)).subscribe((storedValue: T) => {
        if (value !== storedValue) {
          that.dispatch(id, value);
        }
      });
    }
  }

  destroyStore(id: string): void {
    this.getStore(id).store.complete();
    delete this._storeCache[id];
  }

  event<T>(id: string, config?: {runInZone: boolean}): Observable<T> {
    if (config && config.runInZone) {
      return this.getEmitter<T>(id).event.pipe(this.runInZone);
    }

    return this.getEmitter<T>(id).event;
  }

  emit<T>(id: string, value: T): void {
    this.getEmitter(id).event.next(value);
  }

  destroyEmitter(id: string): void {
    this.getEmitter(id).event.complete();
    delete this._eventCache[id];
  }

  getSingletonValue<T>(id: string): Observable<T> {
    return this.createSingleton(id)
      .store // response behave like ReplaySubject
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter((v: any) => v !== null)
      );
  }

  /**
   * A value is changed only once
   * @param id
   * @param defaultValue
   */
  setSingletonValue<T>(id: string, defaultValue: T): void {
    if (
      this.createSingleton(id, defaultValue).store.getValue() === null &&
      defaultValue
    ) {
      this.createSingleton(id).store.next(defaultValue);
    }
  }

  destroySingleton(id: string): void {
    this.createSingleton(id).store.complete();
    delete this._singletonCache[id];
  }

  private runInZone<T>(sub: Observable<T>): Observable<T> {
    const that = this;
    return new Observable((observer: Observer<T>) => {
      sub.subscribe({
        next: function (x: T) {
          that.zone.run(() => {
            observer.next(x);
          });
        },
        error: function (e: any) {
          that.zone.run(() => {
            observer.error(e);
          });
        },
        complete: function () {
          that.zone.run(() => {
            observer.complete();
          });
        }
      });
    });
  }

  private getStore<T>(id: string, defaultValue: T | null = null): StoreItem<T> {
    if (!this._storeCache[id]) {
      this._storeCache[id] = {
        id,
        store: new ReplaySubject<any>(1),
        hasValue: false
      };

      if (defaultValue) {
        this._storeCache[id].store.next(defaultValue);
        this._storeCache[id].hasValue = true;
      }
    }

    return this._storeCache[id];
  }

  private getEmitter<T>(id: string): EmitterItem<T> {
    if (!this._eventCache[id]) {
      this._eventCache[id] = {
        id,
        event: new Subject()
      };
    }

    return this._eventCache[id];
  }

  private createSingleton<T>(
    id: string,
    defaultValue: T | null = null
  ): SingletonItem<T> {
    if (!this._singletonCache[id]) {
      this._singletonCache[id] = {
        id,
        store: new BehaviorSubject<T | null>(defaultValue)
      };
    }

    return this._singletonCache[id];
  }
}
