import {
  audioMetaDataState,
  selectAllAudioMetaData
} from '../+audio-file-meta-data/audio-metadata-storage.selectors';
import {
  selectCurrentIDsFromStore,
  selectDeviceStorageState
} from '../+device-storage/device-storage.selectors';
import {
  selectAllMixes,
  selectCurrentMixEntity,
  selectCurrentMixEntityNamePassThrough
} from '../+mixes/mix-storage.selectors';
import {
  selectAllProjects,
  selectCurrentProjectEntity
} from '../+project/project-storage.selectors';
import {
  selectAllSongs,
  selectCurrentSongEntity,
  selectCurrentSongId,
  selectCurrentSongNamePassThrough
} from '../+songs/song-storage.selectors';
import {
  selectCurrentAudioFileMetaDataForRecord,
  selectCurrentTrackEntityAndAudioMetaDataEntity
} from '../+track-mix/track-mix.selectors';
import {
  selectAllTracks,
  selectCurrentTrackEntity,
  selectCurrentTrackName,
  selectCurrentTracks,
  selectedColorIconTextCSS
} from '../+tracks/track-storage.selectors';

export function releaseStoreToSelect() {
  selectAllProjects.release();
  selectAllSongs.release();
  selectAllMixes.release();
  selectAllTracks.release();
  selectAllAudioMetaData.release();

  selectCurrentProjectEntity.release();
  selectCurrentSongEntity.release();
  selectCurrentMixEntity.release();
  selectCurrentTrackEntity.release();
  selectCurrentAudioFileMetaDataForRecord.release();
  selectCurrentTrackEntityAndAudioMetaDataEntity.release();

  selectCurrentIDsFromStore.release();
  selectCurrentMixEntityNamePassThrough.release();
  selectCurrentTracks.release();
  selectCurrentSongNamePassThrough.release();
  selectCurrentTrackName.release();
  selectCurrentSongId.release();

  selectDeviceStorageState.release();
  audioMetaDataState.release();

  selectedColorIconTextCSS.release();
}
