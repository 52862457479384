import {Action, createReducer, on} from '@ngrx/store';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {SptUser} from './spt-user.model';
import * as SptUserActions from './spt-user.actions';

export const sptUsersFeatureKey = 'sptUsers';

export interface SptUserState extends EntityState<SptUser> {
  // additional entities state properties
}

function userUID(user: SptUser) {
  return user.uid;
}

export const adapter: EntityAdapter<SptUser> = createEntityAdapter<SptUser>({
  selectId: userUID
});

export const initialState: SptUserState = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(SptUserActions.addSptUser, (state, action) =>
    adapter.addOne(action.sptUser, state)
  ),
  on(SptUserActions.upsertSptUser, (state, action) =>
    adapter.upsertOne(action.sptUser, state)
  ),
  on(SptUserActions.addSptUsers, (state, action) =>
    adapter.addMany(action.sptUsers, state)
  ),
  on(SptUserActions.upsertSptUsers, (state, action) =>
    adapter.upsertMany(action.sptUsers, state)
  ),
  on(SptUserActions.updateSptUser, (state, action) =>
    adapter.updateOne(action.sptUser, state)
  ),
  on(SptUserActions.updateSptUsers, (state, action) =>
    adapter.updateMany(action.sptUsers, state)
  ),
  on(SptUserActions.deleteSptUser, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(SptUserActions.deleteSptUsers, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(SptUserActions.loadSptUsers, (state, action) =>
    adapter.setAll(action.sptUsers, state)
  ),
  on(SptUserActions.clearSptUsers, state => adapter.removeAll(state))
);
