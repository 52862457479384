import {Injectable, NgZone} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {CustomFirestoreService} from '../firebase';
import {PermissionService} from './permission.service';

@Injectable()
export class PermissionEffects {
  constructor(
    private actions$: Actions,
    private _permissionsService: PermissionService,
    private _firestore: CustomFirestoreService,
    private _store: Store,
    private _zone: NgZone
  ) {}
}
