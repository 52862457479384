import {pipe} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

export function distinctUntilJsonChanged<T>() {
  return pipe(
    map((d: T) => {
      try {
        return JSON.stringify(d);
      } catch (_) {
        return d;
      }
    }),
    distinctUntilChanged(),
    map((d: string | T) => {
      if (typeof d === 'string') {
        return JSON.parse(d);
      }
      return d;
    })
  );
}
