import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {LetModule, PushModule} from '@ngrx/component';
import {SubscriptionEndModule} from '../subscription-end/subscription-end.module';
import {DeleteAccountModule} from './modals/delete-account/delete-account.module';
import {MySubscriptionComponent} from './my-subscription.component';
import {CancelSubscriptionSuccessComponent} from './modals/cancel-subscription-success/cancel-subscription-success.component';
import {CancelSubscriptionErrorComponent} from './modals/cancel-subscription-error/cancel-subscription-error.component';

@NgModule({
  declarations: [
    MySubscriptionComponent,
    CancelSubscriptionSuccessComponent,
    CancelSubscriptionErrorComponent
  ],
  exports: [
    MySubscriptionComponent,
    CancelSubscriptionSuccessComponent,
    CancelSubscriptionErrorComponent
  ],
  imports: [
    CommonModule,
    LetModule,
    PushModule,
    FormsModule,
    ReactiveFormsModule,

    SubscriptionEndModule,
    DeleteAccountModule,

    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule
  ]
})
export class SptMySubscriptionModule {}
