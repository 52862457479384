import {Injectable, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {TreeConfig} from '@spout/global-any/models';
import {
  difference,
  dispatchNgrxFirestoreAggregate,
  firestoreGetMusicianGenrePath,
  firestoreGetMusicianGenresPath
} from '@spout/global-web/fns';
import {
  AccountState,
  AccountStateConnect,
  genresMusicianFeatureKey,
  GenresMusicianState
} from '@spout/global-web/models';
import {onSnapshot, QuerySnapshot, WriteBatch} from '@firebase/firestore';
import {from, Observable} from 'rxjs';
import {map, mergeMap, take} from 'rxjs/operators';
import {accountSaveFirebase} from '../+account/account.actions';
import {
  upsertWebsocketRegistry,
  websocketIsDisconnectedAction
} from '../+websocket-registry/websocket-registry.actions';
import {
  GetAuthAccountConnect,
  selectAuthAccountConnect$
} from '../+websocket-registry/websocket-registry.selectors';
import {CustomFirestoreService} from '../firebase';
import {selectAllGenresMusician} from './genre-musician.selectors';
import {
  genreMusicianAggregate,
  genreMusicianAggregateEffect
} from './genres-musician.actions';

@Injectable()
export class GenresMusicianEffects {
  saveGenresToFirestore = createEffect(
    () =>
      this.actions$.pipe(
        ofType(genreMusicianAggregateEffect),
        map(action => {
          this.saveToFirebase(action.genres).subscribe(
            () => {
              this.zone.run(() => {
                this.store.dispatch(
                  accountSaveFirebase({
                    payload: {
                      hasGenres: true
                    }
                  })
                );
              });
            },
            () => {
              /* noop */
            }
          );
        })
      ),
    {dispatch: false}
  );

  private _databaseSub: (() => void) | undefined;

  constructor(
    private actions$: Actions,
    private store: Store<AccountStateConnect & GenresMusicianState>,
    private sptFirestore: CustomFirestoreService,
    private zone: NgZone
  ) {
    const that = this;
    this.zone.run(() => {
      that.store.dispatch(
        upsertWebsocketRegistry({
          id: genresMusicianFeatureKey
        })
      );
    });

    this.store
      .pipe(selectAuthAccountConnect$)
      .subscribe((s: AccountStateConnect) => {
        if (s.doConnect) {
          this.onConnect.call(this, s.user);
        } else {
          this.onDisconnect.call(this, s.user);
        }
      });
  }

  onConnect(user: AccountState): void {
    const that = this;
    if (!this._databaseSub) {
      this.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: genresMusicianFeatureKey
          })
        );
      });

      this._databaseSub = onSnapshot(
        this.sptFirestore.collectionRef(firestoreGetMusicianGenresPath(user)),
        (snapshot: QuerySnapshot) => {
          dispatchNgrxFirestoreAggregate<AccountStateConnect>(
            this.store,
            snapshot,
            genreMusicianAggregate,
            this.zone
          );
        },
        (err: Error) => {
          console.error(err);
        },
        () => {
          /* noop */
        }
      );
    }
  }

  onDisconnect(user: AccountState): void {
    const that = this;
    if (this._databaseSub) {
      this._databaseSub();

      that.zone.run(() => {
        that.store.dispatch(
          websocketIsDisconnectedAction({
            id: genresMusicianFeatureKey
          })
        );
      });
    }
  }

  saveToFirebase(toSave: TreeConfig[]): Observable<any> {
    return this.store.pipe(
      select(selectAllGenresMusician),
      take(1),
      mergeMap((selected: TreeConfig[]) =>
        this.store.pipe(
          selectAuthAccountConnect$,
          map((s: GetAuthAccountConnect) => ({
            user: s.user
          })),
          mergeMap(({user}) => {
            const batch: WriteBatch = this.sptFirestore.writeBatch();

            // If user has no selected genres
            if (!selected.length) {
              toSave.map((genre: TreeConfig) => {
                const doc = this.sptFirestore.docRef(
                  firestoreGetMusicianGenrePath(user, genre.id)
                );
                batch.set(doc, genre);
              });
            } else {
              // TreeConfig[]
              // Add Genres
              difference(toSave, selected, 'id').map((genre: TreeConfig) => {
                const doc = this.sptFirestore.docRef(
                  firestoreGetMusicianGenrePath(user, genre.id)
                );
                batch.set(doc, genre);
              });

              // TreeConfig[]
              // Delete Genres that are not in selectedGenres
              difference(selected, toSave, 'id').map((genre: TreeConfig) => {
                const doc = this.sptFirestore.docRef(
                  firestoreGetMusicianGenrePath(user, genre.id)
                );
                batch.delete(doc);
              });
            }

            return from(batch.commit());
          })
        )
      )
    );
  }
}
