import {Injectable, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {MixEntity, TrackEntity} from '@spout/global-any/models';

import {EMPTY} from 'rxjs';
import {concatMap, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {setDeviceStoreCurrentIdsFromTrackAndMixEntity} from '../+device-storage/device-storage.actions';
import {selectCurrentMixEntity} from '../+mixes/mix-storage.selectors';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';
import {
  addAudioTrackEntityToSongAndMixAction,
  createProjectAction,
  createSongAction,
  createTrackAction
} from '../actions/create-entites.actions';
import {CustomFirestoreService} from '../firebase';
import {releaseStoreToSelect} from '../services/release-store';
import {CreateTrackService} from './create-track.service';
import {FirestoreTracksService} from './firestore-tracks.service';
import {selectCurrentTracks} from './track-storage.selectors';
import {
  deleteTrackEffect,
  loadTracks,
  setTrackAsMaster,
  updateTrack,
  updateTracks
} from './track.actions';

@Injectable({
  providedIn: 'root'
})
export class TracksEffects {
  loadTracks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadTracks),
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        concatMap(() => EMPTY)
      ),
    {dispatch: false}
  );

  updateTrackEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTrack),
        switchMap(action => {
          return this.firestoreTrackService.updateTrack(action.track.changes);
        })
      ),
    {dispatch: false}
  );

  setTrackAsMaster$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setTrackAsMaster),
      mergeMap(action => {
        return this.store.pipe(
          select(selectCurrentTracks),
          take(1),
          map((currentTrack: TrackEntity[]) => {
            const tracks: Update<TrackEntity>[] = currentTrack.map(
              (_track: TrackEntity) => {
                return {
                  id: _track.id,
                  changes: _track
                };
              }
            );

            return updateTracks({
              tracks
            });
          })
        );
      })
    );
  });

  createTrackEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        // NOTE do not add createTracksAction, that is handled in the import-files.service
        ofType(
          createDefaultConfigsToFirestore,
          createProjectAction,
          createSongAction,
          createTrackAction
        ),
        mergeMap(action => {
          return this.firestoreTrackService.createTrackFirestore(
            <TrackEntity>action.track
          );
        })
      ),
    {dispatch: false}
  );

  saveDeviceStoreTrackEntityEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAudioTrackEntityToSongAndMixAction),
      switchMap(() => {
        const that = this;
        return this.createTrackService
          .createAudioTrackAndAudioFileMetaData()
          .pipe(
            take(1),
            tap(({trackEntity, audioFileMetaDataEntity, mixes, trackMixes}) => {
              releaseStoreToSelect();

              this.store
                .pipe(select(selectCurrentMixEntity), take<MixEntity | null>(1))
                .subscribe((currentMix: MixEntity | null) => {
                  if (currentMix) {
                    that.zone.run(() => {
                      that.store.dispatch(
                        setDeviceStoreCurrentIdsFromTrackAndMixEntity({
                          track: trackEntity,
                          mix: currentMix
                        })
                      );
                    });
                  }
                });
            }),
            map(({trackEntity, audioFileMetaDataEntity, mixes, trackMixes}) => {
              return createTrackAction({
                mixConfigs: mixes.map((mix: MixEntity) => {
                  return {
                    id: mix.id,
                    changes: mix
                  };
                }),
                track: trackEntity,
                file: audioFileMetaDataEntity,
                trackMixs: trackMixes
              });
            })
          );
      })
    )
  );

  deleteTrackEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTrackEffect),
        map(action => {
          this.firestoreTrackService.deleteTrack(action.trackModel.trackEntity);
        })
      ),
    {dispatch: false}
  );

  constructor(
    private createTrackService: CreateTrackService,
    private actions$: Actions,
    private sptFirestore: CustomFirestoreService,
    private store: Store,
    private firestoreTrackService: FirestoreTracksService,
    private zone: NgZone
  ) {}
}
