import {Injectable, NgZone} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {Role} from '@spout/global-any/models';
import {HelpModel} from '@spout/global-web/models';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {selectProjectRole} from '../+permission/permissions.selectors';
import {openHelpViewRightDrawer} from './mixer-right-drawer.actions';

@Injectable({
  providedIn: 'root'
})
export class MixerRightDrawerEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private zone: NgZone
  ) {
    const that = this;
    this.store
      .pipe(
        select(selectProjectRole),
        distinctUntilChanged(),
        map((projectRole: Role) => {
          let helpView: HelpModel;

          if (projectRole.collaborator) {
            helpView = HelpModel.GETTING_STARTED_COLLABORATION;
          } else if (projectRole.reviewer) {
            helpView = HelpModel.GETTING_STARTED_REVIEWING;
          } else {
            helpView = HelpModel.GETTING_STARTED_CREATING;
          }

          return openHelpViewRightDrawer({helpView: helpView});
        })
      )
      .subscribe((action: Action) => {
        that.zone.run(() => {
          that.store.dispatch(action);
        });
      });
  }
}
