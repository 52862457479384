import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {UpdateSubscriptionErrorComponent} from './update-subscription-error.component';

@NgModule({
  declarations: [UpdateSubscriptionErrorComponent],
  exports: [UpdateSubscriptionErrorComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule]
})
export class UpdateSubscriptionErrorModule {}
