import {Action, createReducer, on} from '@ngrx/store';
import {
  audioFileMetaDataAdapter,
  AudioFileMetaDataState,
  initialMetaDataState
} from '@spout/global-web/models';
import {authLoggedOut} from '../+account/auth.actions';
import {
  createProjectAction,
  createSongAction,
  createTrackAction
} from '../actions/create-entites.actions';
import {
  addAudioFileMetaDataEntities,
  addAudioFileMetaDataEntity,
  clearAudioFileMetaDataEntities,
  deleteAudioFileMetaDataEntities,
  deleteAudioFileMetaDataEntity,
  updateAudioFileMetaDataEntities,
  updateAudioFileMetaDataEntity,
  upsertAudioFileMetaDataEntities,
  upsertAudioFileMetaDataEntity
} from './audio-meta-data.actions';

const reducer = createReducer(
  initialMetaDataState,
  on(addAudioFileMetaDataEntity, (state, action) =>
    audioFileMetaDataAdapter.addOne(action.file, state)
  ),
  on(createProjectAction, (state, action) =>
    audioFileMetaDataAdapter.addOne(action.file, state)
  ),
  on(createSongAction, (state, action) =>
    audioFileMetaDataAdapter.addOne(action.file, state)
  ),
  on(createTrackAction, (state, action) =>
    audioFileMetaDataAdapter.addOne(action.file, state)
  ),
  on(upsertAudioFileMetaDataEntity, (state, action) =>
    audioFileMetaDataAdapter.upsertOne(action.file, state)
  ),
  on(addAudioFileMetaDataEntities, (state, action) =>
    audioFileMetaDataAdapter.addMany(action.files, state)
  ),
  on(upsertAudioFileMetaDataEntities, (state, action) =>
    audioFileMetaDataAdapter.upsertMany(action.files, state)
  ),
  on(updateAudioFileMetaDataEntity, (state, action) =>
    audioFileMetaDataAdapter.updateOne(action.file, state)
  ),
  on(updateAudioFileMetaDataEntities, (state, action) =>
    audioFileMetaDataAdapter.updateMany(action.files, state)
  ),
  on(deleteAudioFileMetaDataEntity, (state, action) =>
    audioFileMetaDataAdapter.removeOne(action.id, state)
  ),
  on(deleteAudioFileMetaDataEntities, (state, action) =>
    audioFileMetaDataAdapter.removeMany(action.ids, state)
  ),
  on(clearAudioFileMetaDataEntities, state =>
    audioFileMetaDataAdapter.removeAll(state)
  ),
  on(authLoggedOut, (state, action) => {
    return audioFileMetaDataAdapter.removeAll(state);
  })
);

export function audioFileMetaDataReducer(
  state: AudioFileMetaDataState | undefined,
  action: Action
) {
  return reducer(state, action);
}
