import {Component, Inject, OnInit} from '@angular/core';
import {WINDOW} from '@spout/global-web/utils';

@Component({
  selector: 'spt-app-idle',
  templateUrl: './app-idle-dialog.component.html',
  styleUrls: ['./app-idle-dialog.component.scss']
})
export class AppIdleDialogComponent {
  constructor(@Inject(WINDOW) private _window: Window) {}

  reload() {
    this._window.location.reload();
  }
}
