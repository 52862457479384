// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AudioFileMetaDataEntity,
  TrackMixAudioSnippet
} from '@spout/global-any/models';

export function resetAudioFileMetaDataEntity(
  a: AudioFileMetaDataEntity
): AudioFileMetaDataEntity {
  return {
    ...a,
    bits: 0,
    fileUploaded: false, // NOTE: Muy Importante!!!
    bufferSize: 0,
    length: 0,
    fileSize: 0,
    scale: 0,
    sampleRate: 0,
    duration: 0,
    maxY: 0,
    minY: 0,
    desktopInformation: null
  };
}

export function resetTrackMixAudioSnippet(
  s: TrackMixAudioSnippet
): TrackMixAudioSnippet {
  return {
    ...s,
    start: 0,
    offsetMs: 0,
    stop: 0,
    audioDuration: 0,
    sampleRate: 0
  };
}
