<h1 mat-dialog-title class="warn">Clear Track Audio</h1>
<mat-dialog-content>
  <div class="pad-top-2 push-buttom-4">
    This will erase all recorded audio on this track.
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="ok.emit(true)">OK</button>

  <button mat-flat-button (click)="cancel.emit(true)" class="push-left-20">
    Cancel
  </button>
</mat-dialog-actions>
