import {Action, createReducer, on} from '@ngrx/store';
import {parseAlert, parseError} from '@spout/global-web/fns';
import {AccountState, initialAccountState} from '@spout/global-web/models';
import {
  accountLoadedFromAuthStateChange,
  accountLoadedFromSnapshotChanges,
  updateTotalCloudStorageUsed
} from './account.actions';
import {accountIsLoaded} from './account.fns';

import {
  authAlert,
  authError,
  authIsOffline,
  authIsOnline,
  authLoggedOut
} from './auth.actions';

const reducer = createReducer(
  initialAccountState,
  on(
    accountLoadedFromAuthStateChange,
    (state: AccountState, action): AccountState => {
      return {
        ...state,
        ...action.payload
      };
    }
  ),

  on(
    accountLoadedFromSnapshotChanges,
    (state: AccountState, action): AccountState => {
      const _state = {
        ...state,
        ...action.payload
      };

      return {
        ..._state,
        isRetrievedFromFirestore: accountIsLoaded(_state)
      };
    }
  ),
  on(authLoggedOut, (state: AccountState, action): AccountState => {
    return {
      ...initialAccountState
    };
  }),

  on(authAlert, (state: AccountState, action): AccountState => {
    return {
      ...state,
      authError: parseAlert(action.payload),
      isLoggedIn: false
    };
  }),
  on(authError, (state: AccountState, action): AccountState => {
    return {
      ...state,
      authError: parseError(action.payload)
    };
  }),
  on(authIsOnline, (state: AccountState): AccountState => {
    return <AccountState>{
      ...state,
      isOnboarded: true
    };
  }),
  on(authIsOffline, (state: AccountState): AccountState => {
    return <AccountState>{
      ...state,
      isOnline: false
    };
  }),

  // on(addSubscriptionToAccount, (state: AccountState, action): AccountState => {
  //   return <AccountState>{
  //     ...state,
  //     subscription: action.subscription
  //   };
  // }),

  // on(
  //   addSubscriptionItemToAccount,
  //   (state: AccountState, action): AccountState => {
  //     let accountRole = action.subscription?.price?.metadata?.accountRole;
  //
  //     accountRole =
  //       accountRole !== null && accountRole !== 'null' ? accountRole : null;
  //
  //     if (
  //       accountRole &&
  //       !(
  //         state.promoCode &&
  //         state.promoCode.accountRole &&
  //         state.promoCode.accountRole.length
  //       )
  //     ) {
  //       const permissionRole = getPermissionsRole(state);
  //
  //       const _state: AccountState = {
  //         ...state,
  //         accountRole,
  //         permissionRole,
  //         permissions: getPermissionState(
  //           permissionRole,
  //           state.featurePermissions,
  //           state.permissions
  //         )
  //       };
  //
  //       return {
  //         ..._state
  //       };
  //     }
  //
  //     return {
  //       ...state,
  //       subscriptionItemEntities: {
  //         ...state.subscriptionItemEntities,
  //         [action.subscription.id]: action.subscription
  //       }
  //     };
  //   }
  // ),

  // on(selectedProjectEntity, (state: AccountState, action): AccountState => {
  //   const _state: AccountState = {
  //     ...state,
  //     projectRole: getProjectRole(action.projectEntity, state.account.uid)
  //   };
  //
  //   const permissionRole = getPermissionsRole(_state);
  //
  //   return {
  //     ..._state,
  //     permissionRole,
  //     permissions: getPermissionState(
  //       permissionRole,
  //       state.featurePermissions,
  //       state.permissions
  //     )
  //   };
  // }),

  // on(
  //   addSubscriptionIdToAccount,
  //   (state: AccountState, action): AccountState => {
  //     return {
  //       ...state,
  //       subscriptionId: action.subscriptionId
  //     };
  //   }
  // ),

  // on(loadPermissionsData, (state: AccountState, action): AccountState => {
  //   const permissionRole = getPermissionsRole(state);
  //
  //   const _state: AccountState = {
  //     ...state,
  //     featurePermissions: action.featurePermissions,
  //     permissionRole,
  //     permissions: getPermissionState(
  //       permissionRole,
  //       action.featurePermissions,
  //       state.permissions
  //     )
  //   };
  //
  //   return {
  //     ..._state
  //     // isInTrialPeriod: isInTrialPeriod(_state)
  //   };
  // }),

  // on(loadPromoCode, (state: AccountState, action): AccountState => {
  //   return {
  //     ...state,
  //     promoCode: action.promoCode
  //   };
  // }),
  //
  // on(deletePromoCode, (state: AccountState, action): AccountState => {
  //   const _state: AccountState = {
  //     ...state,
  //     promoCode: null
  //   };
  //
  //   return {
  //     ..._state
  //   };
  // }),
  on(
    updateTotalCloudStorageUsed,
    (state: AccountState, action): AccountState => {
      return {
        ...state,
        totalCloudStorageUsed: action.totalCloudStorageUsed
      };
    }
  )
);

export function accountReducer(
  state: AccountState | undefined,
  action: Action
): AccountState {
  return reducer(state, action);
}
