import {Dictionary} from '@ngrx/entity/src/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
  MemoizedSelectorWithProps
} from '@ngrx/store';
import {
  CurrentIds,
  ProjectEntity,
  SongEntity,
  SongState
} from '@spout/global-any/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  getDefaultEntityConfig,
  isCreatedByLoggedInMusician
} from '@spout/global-web/fns';
import {AccountState, SONG_FEATURE_KEY} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {selectAccountState, selectUid} from '../+account/account.selectors';
import {selectCurrentIDsFromStore} from '../+device-storage/device-storage.selectors';
import {
  selectCurrentProjectEntity,
  selectCurrentProjectId
} from '../+project/project-storage.selectors';

// SONG SONG SONG SONG
// SONG SONG SONG SONG
// SONG SONG SONG SONG
// SONG SONG SONG SONG
export const selectSongState =
  createFeatureSelector<SongState>(SONG_FEATURE_KEY);
export const selectSongStatePassThrough =
  createPassThroughFeatureSelector<SongState>(SONG_FEATURE_KEY);

export const selectSongEntities = createSelector(
  selectSongState,
  (state: SongState): Dictionary<SongEntity> => {
    return state.entities;
  }
);

export const selectAllSongs = createSelector(
  selectSongEntities,
  (entities: Dictionary<SongEntity>): SongEntity[] => {
    if (Object.keys(entities).length) {
      return <SongEntity[]>Object.values(entities);
    }

    return [];
  }
);

export const selectAllSongs_passThrough = createPassThroughSelector(
  selectSongEntities,
  (entities: Dictionary<SongEntity>): SongEntity[] => {
    if (Object.keys(entities).length) {
      return <SongEntity[]>Object.values(entities);
    }

    return [];
  }
);

export const selectDefaultSong = createSelector(
  selectAllSongs,
  selectAccountState,
  (entities: SongEntity[], account: AccountState): SongEntity | null => {
    return entities && account
      ? <SongEntity>(
          getDefaultEntityConfig(
            <{isDefault: boolean; createdByUID: string}[]>entities,
            account
          )
        )
      : null;
  }
);

export const selectCurrentSongId = createSelector(
  selectCurrentIDsFromStore,
  (ids: CurrentIds): string | null => {
    return ids.currentSongId;
  }
);

export const selectCurrentSongs = createSelector(
  selectCurrentProjectId,
  selectAllSongs,
  (_currentProjectId: string | null, _allSongs: SongEntity[]) => {
    return _allSongs.filter(
      (track: SongEntity) => track.projectId === _currentProjectId
    );
  }
);

export const getSongsByProjectId = createSelector(
  selectAllSongs,
  (songs: SongEntity[], props: {projectId: string}) => {
    return songs.filter(
      (song: SongEntity) => song.projectId === props.projectId
    );
  }
);

export const getSongsByProjectIdFn = (projectId: string) =>
  createSelector(selectAllSongs, (songs: SongEntity[]): SongEntity[] => {
    return songs.filter((song: SongEntity) => song.projectId === projectId);
  });

export const getNumberSongsByProject = (projectId: string) =>
  createSelector(selectAllSongs, (songs: SongEntity[]): number => {
    return songs.filter((song: SongEntity) => song.projectId === projectId)
      .length;
  });

export const getSongById = createSelector(
  selectSongEntities,
  (entities: Dictionary<SongEntity>, props: {id: string}) => entities[props.id]
);

export const selectCurrentSongEntity = createSelector(
  selectAllSongs,
  selectCurrentProjectEntity,
  selectCurrentIDsFromStore,
  (
    songs: SongEntity[],
    currentProject: ProjectEntity | null | undefined,
    ids: CurrentIds
  ): SongEntity | null => {
    if (songs && songs.length && currentProject) {
      let projectSongs: SongEntity[] = songs.filter(
        (s: SongEntity) => s.projectId === currentProject.id
      );

      if (projectSongs && projectSongs.length && ids) {
        let currentSong = projectSongs.find(
          (s: SongEntity) => s.id === ids.currentSongId
        );

        if (currentSong) {
          return currentSong;
        }

        return projectSongs[0];
      }
    }

    return null;
  }
);

export const selectCurrentSongEntity_passThrough: MemoizedSelector<
  any,
  SongEntity | null
> = createSelector(
  selectAllSongs,
  selectCurrentIDsFromStore,
  (songs: SongEntity[], ids: CurrentIds): SongEntity | null => {
    if (songs && songs.length && ids) {
      const song = songs.find(
        (song: SongEntity) => song.id === ids.currentSongId
      );
      return song ? song : null;
    }
    return null;
  }
);

// export const selectedIsSongOwner = createSelector(
//   selectCurrentSongEntity,
//   selectUid,
//   (song: SongEntity | null, uid: string | null) => {
//     if (song && uid) {
//       if (song.members[uid]) {
//         return song.members[uid].role.owner;
//       } else {
//         return false;
//       }
//     }
//
//     return false;
//   }
// );

export const selectCurrentSongSampleRate = createSelector(
  selectCurrentSongEntity,
  (song: SongEntity | null) => {
    if (song && song.sampleRate !== null && song.sampleRate !== undefined) {
      return song.sampleRate;
    }

    return null;
  }
);

export const selectCurrentSongNamePassThrough = createPassThroughSelector(
  selectCurrentSongEntity_passThrough,
  (song: SongEntity | null) => (song ? song.name : null)
);

export const selectCurrentSongIsDefault = createSelector(
  selectCurrentSongEntity,
  (song: SongEntity | null) => (hasValue(song) && song ? song.isDefault : false)
);

export const selectSongIsCreatedByLoggedInMusician = createSelector(
  selectUid,
  selectCurrentSongEntity,
  (uid: string | null, song: SongEntity | null) => {
    return uid && song ? isCreatedByLoggedInMusician(uid, song) : false;
  }
);

export const selectSongNotCreatedByLoggedInMusician = createSelector(
  selectSongIsCreatedByLoggedInMusician,
  isCreatedByLoggedInMusician => !isCreatedByLoggedInMusician
);

export const selectShareSongTooltip = createSelector(
  selectCurrentSongEntity,
  (song: SongEntity | null) => {
    if (song && song.isDefault) {
      return 'Songs in a personal project are not sharable. Create a new project to share.';
    } else {
      return 'Share song with collaborators';
    }
  }
);

export const currentSongs = createSelector(
  selectCurrentProjectId,
  selectAllSongs,
  (projectId: string | null, songs: SongEntity[]) => {
    return songs.filter((song: SongEntity) => song.projectId === projectId);
  }
);
