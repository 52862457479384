import {Dictionary} from '@ngrx/entity/src/models';
import {createSelector} from '@ngrx/store';
import {TimeStamp} from '@spout/global-any/models';
import {
  selectCreatedAt,
  selectSubscribedAtFromAccount,
  selectUid
} from '../+account/account.selectors';

import {
  ProductWithPrices,
  selectAllPrices,
  selectPriceEntities,
  StorageAndPriceByIntervalDict
} from './+prices';
import {
  Price,
  Product,
  selectAllProducts,
  selectEarlyAdopterPlanWithPrices,
  selectProductEntities
} from './+product';
import {selectStorageOfSelectedPromo} from './+promo-codes';
import {
  IsTrialWithDaysRemaining,
  ProductWizardStore,
  selectActiveSubscription,
  selectActiveSubscriptionItems,
  selectHasActiveSubscription,
  selectSubscriptionStorage,
  selectTrialDays,
  Subscription,
  SubscriptionItem,
  SubscriptionProductAndPrice,
  subscriptionProductsAndPrices
} from './+subscriptions';
import {
  getStoragePlan,
  getSubscriptionPlan
} from './+subscriptions/subscription.fns';
import {
  PlanPriceOption,
  StripeProductWithPrices,
  StripeProductWithPricesCurrentSubscription
} from './stripe.model';

export const selectProductWithPrices = createSelector(
  selectAllProducts,
  selectAllPrices,
  (products: Product[], prices: Price[]): StripeProductWithPrices[] => {
    return products.map((product: Product) => {
      return <StripeProductWithPrices>{
        product,
        prices: prices.filter((price: Price) => price.product === product.id)
      };
    });
  }
);

export const selectProductsWithPricesCurrentSubscription = createSelector(
  selectAllProducts,
  selectAllPrices,
  subscriptionProductsAndPrices,
  (
    products: Product[],
    prices: Price[],
    s: SubscriptionProductAndPrice
  ): StripeProductWithPricesCurrentSubscription[] => {
    return products
      .filter((_p: Product) => _p.metadata.productType === 'plan')
      .map((product: Product) => {
        const _prices: PlanPriceOption[] = prices
          .filter((price: Price) => price.product === product.id)
          .map((price: Price) => {
            return <PlanPriceOption>{
              price,
              isCurrentSubscriptionPrice:
                s.priceEntities[price.id] !== undefined
            };
          });

        return <StripeProductWithPricesCurrentSubscription>{
          product,
          prices: _prices,
          isCurrentSubscriptionPlan: s.productEntities[product.id] !== undefined
        };
      })
      .sort(
        (
          a: StripeProductWithPricesCurrentSubscription,
          b: StripeProductWithPricesCurrentSubscription
        ) => {
          if (
            a.product.metadata?.sortOrder !== null &&
            b.product.metadata?.sortOrder !== null
          ) {
            return (
              <number>a.product.metadata.sortOrder -
              <number>b.product.metadata.sortOrder
            );
          }

          return 0;
        }
      );
  }
);

export const selectStorageProductsWithPriceCurrentSubscription = createSelector(
  selectAllProducts,
  selectAllPrices,
  subscriptionProductsAndPrices,
  (
    products: Product[],
    prices: Price[],
    s: SubscriptionProductAndPrice
  ): StripeProductWithPricesCurrentSubscription[] => {
    return products
      .filter((_p: Product) => _p.metadata.featureList === 'storage')
      .map((product: Product) => {
        const _prices: PlanPriceOption[] = prices
          .filter((price: Price) => price.product === product.id)
          .map((price: Price) => {
            return <PlanPriceOption>{
              price,
              isCurrentSubscriptionPrice:
                s.priceEntities[price.id] !== undefined
            };
          });

        return <StripeProductWithPricesCurrentSubscription>{
          product,
          prices: _prices,
          isCurrentSubscriptionPlan: s.productEntities[product.id] !== undefined
        };
      })
      .sort(
        (
          a: StripeProductWithPricesCurrentSubscription,
          b: StripeProductWithPricesCurrentSubscription
        ) => {
          if (
            a.product.metadata?.sortOrder !== null &&
            b.product.metadata?.sortOrder !== null
          ) {
            return (
              <number>a.product.metadata.sortOrder -
              <number>b.product.metadata.sortOrder
            );
          }

          return 0;
        }
      );
  }
);

export const selectSubscriptionPlanPrice = createSelector(
  selectActiveSubscriptionItems,
  (s: SubscriptionItem[]): SubscriptionItem | null => getSubscriptionPlan(s)
);

export const selectSubscriptionStoragePrice = createSelector(
  selectActiveSubscriptionItems,
  (s: SubscriptionItem[]): SubscriptionItem | null => getStoragePlan(s)
);

export const selectStorageByIntervalIDDict = createSelector(
  selectAllProducts,
  selectAllPrices,
  (products: Product[], prices: Price[]): StorageAndPriceByIntervalDict => {
    const storageProduct: Product | undefined = products.find(
      (p: Product) => p.metadata.featureList === 'storage'
    );
    const storagePricePerIntervalDict = <StorageAndPriceByIntervalDict>{
      monthlyStoragePrice: null,
      yearlyStoragePrice: null,
      storageProduct: null
    };

    if (storageProduct) {
      return prices
        .filter((p: Price) => p.product === storageProduct.id)
        .reduce(
          (a: StorageAndPriceByIntervalDict, i: Price) => {
            if (i.unit_amount === 0) {
              if (i.interval === 'month') {
                a.baseMonthlyStoragePrice = i;
              } else {
                a.baseYearlyStoragePrice = i;
              }
            } else {
              if (i.interval === 'month') {
                a.monthlyStoragePrice = i;
              } else {
                a.yearlyStoragePrice = i;
              }
            }

            return a;
          },
          <StorageAndPriceByIntervalDict>{
            baseMonthlyStoragePrice: null,
            monthlyStoragePrice: null,
            baseYearlyStoragePrice: null,
            yearlyStoragePrice: null,
            storageProduct: storageProduct
          }
        );
    }

    return storagePricePerIntervalDict;
  }
);

export const selectSubscriptionPriceIDsAndStorageQuantity = createSelector(
  selectTrialDays,
  selectActiveSubscription,
  selectActiveSubscriptionItems,
  selectProductEntities,
  selectPriceEntities,
  selectStorageByIntervalIDDict,
  selectEarlyAdopterPlanWithPrices,
  (
    trialDays: number,
    currentSubscription: Subscription | null,
    s: SubscriptionItem[],
    productEntities: Dictionary<Product>,
    priceEntities: Dictionary<Price>,
    storage: StorageAndPriceByIntervalDict,
    earlyAdopter: ProductWithPrices | null
  ): ProductWizardStore => {
    // console.log(s);

    const productWizardStore = s.reduce(
      (a: ProductWizardStore, i: SubscriptionItem) => {
        if (i.price.product.metadata.productType === 'plan') {
          a.currentSubscriptionProduct = i;
          a.currentSubscriptionProductPrice = i.price;

          a.selectedProduct = productEntities[i.price.product.id] || null;
          a.selectedProductPrice = priceEntities[i.price.id] || null;

          a.earlyAdopterAgreementSigned =
            productEntities[i.price.product.id]?.metadata.featureList ===
            'earlyAdopter';
        }
        if (i.price.product.metadata.featureList === 'storage') {
          a.currentSubscriptionStorageProduct = i;
          a.currentSubscriptionStoragePrice = i.price;

          /**
           * If unit price is 0, storage is included with Plan
           * else the user has bought additional storage
           */
          a.additionalStoragePerGB = i.price.unit_amount === 0 ? 0 : i.quantity;
        }

        if (a.selectedProductPrice?.interval) {
          a.interval = a.selectedProductPrice?.interval;
        }

        return a;
      },
      <ProductWizardStore>{
        trialDays,

        currentSubscription,
        currentSubscriptionProduct: null,
        currentSubscriptionStorageProduct: null,
        currentSubscriptionProductPrice: null,
        currentSubscriptionStoragePrice: null,

        selectedProduct: null,
        selectedProductPrice: null,

        ...storage,

        earlyAdopterAgreementSigned: false,

        additionalStoragePerGB: 0,
        interval: 'month'
      }
    );

    // if (!productWizardStore.selectedProduct && earlyAdopter?.product) {
    //   productWizardStore.selectedProduct = earlyAdopter.product;
    //   productWizardStore.selectedProductPrice = earlyAdopter.prices.reduce(
    //     (a: Price | null, p: Price) => {
    //       if (p.interval === 'month') {
    //         return p;
    //       }
    //       return p;
    //     },
    //     null
    //   );
    // }

    return productWizardStore;
  }
);

export const selectSubscriptionPlanPriceID = createSelector(
  selectSubscriptionPlanPrice,
  (s: SubscriptionItem | null): string | null => {
    if (s) {
      return s.price.id;
    }

    return null;
  }
);

export const selectSubscriptionStoragePriceID = createSelector(
  selectSubscriptionStoragePrice,
  (s: SubscriptionItem | null): string | null => {
    if (s) {
      return s.price.id;
    }

    return null;
  }
);

export const selectAdditionalStorageQuantity = createSelector(
  selectSubscriptionStoragePrice,
  (s: SubscriptionItem | null) => {
    if (s) {
      return s.quantity;
    }

    return 0;
  }
);

/**
 * One Day in milliseconds.
 * milliseconds * seconds * minutes * hours
 */
const oneDay = 1000 * 60 * 60 * 24;

export function accountYoungerThanNumberTrialDays(
  createdAt: TimeStamp | null,
  trialDays: number | null
): boolean {
  if (createdAt && createdAt.seconds && trialDays) {
    const now = Date.now().valueOf();
    const start = new Date(createdAt.seconds * 1000);
    const diffInDays = (now - start.valueOf()) / oneDay;
    return diffInDays <= trialDays;
  }

  return false;
}

/**
 *
 * @param subscribedAt = unix date when user initially subscribed
 * @param trialDays = number of trial days available for first time subscribers
 */
export function calcTrialDaysRemaining(
  subscribedAt: number | null,
  trialDays: number | null
) {
  if (subscribedAt && trialDays) {
    const now = Date.now().valueOf();

    // return days remaining for trial
    return trialDays - Math.floor((now - subscribedAt) / oneDay);
  }

  return trialDays ? trialDays : 0;
}

export interface TrialDaysUIDCheckout {
  trialDays: number;
  trialEnd: number;
  uid: string | null;
  subscribedAt: number | null;
}

export const getTrialDaysRemaining = createSelector(
  selectCreatedAt,
  selectTrialDays,
  (createdAt: TimeStamp | null, trialDays: number): number => {
    let createdAtUnix = null;

    if (createdAt !== null && createdAt.seconds !== null) {
      createdAtUnix = new Date(createdAt.seconds * 1000).valueOf();
    }

    return calcTrialDaysRemaining(createdAtUnix, trialDays);
  }
);

export const selectIsTrialAndDaysRemaining = createSelector(
  getTrialDaysRemaining,
  selectHasActiveSubscription,
  selectCreatedAt,
  selectTrialDays,
  (
    trialDaysRemaining: number,
    hasActiveSubscription: boolean,
    createdAt: TimeStamp | null,
    trialDays: number
  ): IsTrialWithDaysRemaining => ({
    trialDaysRemaining,
    hasActiveSubscription,
    isInTrial:
      accountYoungerThanNumberTrialDays(createdAt, trialDays) &&
      !hasActiveSubscription
  })
);

export const trialDaysUIDCheckout = createSelector(
  selectUid,
  selectSubscribedAtFromAccount,
  selectTrialDays,
  (
    uid: string | null,
    subscribedAt: number | null,
    trialDays: number
  ): TrialDaysUIDCheckout => {
    const daysRemaining = calcTrialDaysRemaining(subscribedAt, trialDays);

    return {
      trialDays: daysRemaining,
      trialEnd: Date.now().valueOf() + oneDay * daysRemaining,
      uid,
      subscribedAt
    };
  }
);

export const totalStorageForAccount = createSelector(
  selectStorageOfSelectedPromo,
  selectSubscriptionStorage,
  (promoStorage, subscriptionStorage) => {
    return Math.max(promoStorage, subscriptionStorage);
  }
);
