import {LegacyFloatLabelType} from '@angular/material/legacy-form-field';
import {MatLegacyFormFieldAppearance} from '@angular/material/legacy-form-field';
export interface IFormAppearance {
  FLOAT_LABEL: LegacyFloatLabelType;
  APPEARANCE: MatLegacyFormFieldAppearance;
}

export const SPT_FORM_OPTIONS: IFormAppearance = {
  FLOAT_LABEL: 'always',
  APPEARANCE: 'fill'
};
