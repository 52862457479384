<spt-help-content [showDivider]="showBackHeader">
  <spt-help-content-header *ngIf="showBackHeader">{{
    helpTitle
  }}</spt-help-content-header>
  <spt-help-tooltip-header
    *ngIf="!showBackHeader"
    (closeModal)="closeModal()"
    >{{ helpTitle }}</spt-help-tooltip-header
  >
  <spt-help-content-body>
    <div *ngIf="showBackHeader" class="getting-started-title">
      {{ helpTitle }}
    </div>
    <spt-help-sample-rate-content></spt-help-sample-rate-content>
  </spt-help-content-body>
</spt-help-content>
