import {Action, createReducer, on} from '@ngrx/store';
import {SongState} from '@spout/global-any/models';
import {initialSongState, songAdapter} from '@spout/global-web/models';
import {authLoggedOut} from '../+account/auth.actions';
import {
  createProjectAction,
  createSongAction
} from '../actions/create-entites.actions';
import {
  addSong,
  addSongs,
  clearSongs,
  currentSongHasMaster,
  deleteSong,
  deleteSongs,
  updateSong,
  updateSongs,
  upsertSong,
  upsertSongs
} from './song.actions';

const reducer = createReducer(
  initialSongState,
  on(addSong, (state, action) => songAdapter.addOne(action.song, state)),
  on(upsertSong, (state, action) => songAdapter.upsertOne(action.song, state)),
  on(addSongs, (state, action) => songAdapter.addMany(action.songs, state)),
  on(upsertSongs, (state, action) =>
    songAdapter.upsertMany(action.songs, state)
  ),
  on(updateSong, (state, action) => songAdapter.updateOne(action.song, state)),
  on(updateSongs, (state, action) =>
    songAdapter.updateMany(action.songs, state)
  ),
  on(deleteSong, (state, action) => songAdapter.removeOne(action.id, state)),
  on(deleteSongs, (state, action) => songAdapter.removeMany(action.ids, state)),
  on(clearSongs, state => songAdapter.removeAll(state)),

  on(currentSongHasMaster, (state, action) => {
    return {
      ...state,
      currentSongHasMaster: action.currentSongHasMaster
    };
  }),
  on(createProjectAction, (state, action) => {
    return songAdapter.addOne(action.song, state);
  }),
  on(createSongAction, (state, action) => {
    return songAdapter.addOne(action.song, state);
  }),
  on(authLoggedOut, (state, action) => {
    return songAdapter.removeAll(state);
  })
);

export function songsReducer(state: SongState | undefined, action: Action) {
  return reducer(state, action);
}
