import {AuthError} from '@spout/global-web/models';

export const ERROR_MESSAGES: any = {
  createUserWithEmailAndPassword:
    'createUserWithEmailAndPassword failed: First argument "email" must be a valid string.',
  providerId: "Cannot read property 'providerId' of null"
};

export function parseErrorMessage(message: string | null): string | null {
  if (message === ERROR_MESSAGES.createUserWithEmailAndPassword) {
    return 'Invalid email.';
  } else {
    return message;
  }
}

export function parseError(error: AuthError): AuthError {
  return {
    code: error.code,
    message: parseErrorMessage(error.message)
  };
}
