import {APP_ROUTES, MIXER_ROUTES} from './APP_ROUTES';
import {getRouteConfigByName} from './get-route-config';
import {CloseAndNavigateTo} from './router.model';

export function getConsoleRoute(): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_CONSOLE);

  // To Navigate back to console
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  routeConfig.outlets.primary = [MIXER_ROUTES.CONSOLE];

  return <CloseAndNavigateTo>{
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      // MIXER_ROUTES.CONSOLE,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig
  };
}

export function getMixerHomeRoute(): CloseAndNavigateTo {
  return getConsoleRoute();
}

export function getAddProjectRoute(): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_ADD_PROJECT);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig
  };
}

export function getEditProjectRoute(projectId: string): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_EDIT_PROJECT);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig,
    extras: {
      queryParams: {
        projectId
      }
    }
  };
}

export function getAddSongRoute(projectId: string): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_ADD_SONG);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig,
    extras: {
      queryParams: {
        projectId
      }
    }
  };
}

export function getEditSongRoute(songId: string): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_EDIT_SONG);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig,
    extras: {
      queryParams: {
        songId
      }
    }
  };
}

export function getAddMixRoute(
  songId: string,
  mixId: string
): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_ADD_MIX);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig,
    extras: {
      queryParams: {
        songId,
        mixId
      }
    }
  };
}

export function getCopyMixRoute(
  songId: string,
  mixId: string
): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_COPY_MIX);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig,
    extras: {
      queryParams: {
        songId,
        mixId
      }
    }
  };
}

export function getEditMixRoute(mixId: string): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_EDIT_MIX);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig,
    extras: {
      queryParams: {
        mixId
      }
    }
  };
}

export function getAddTrackRoute(): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_ADD_TRACK);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig
  };
}

export function getEditTrackRoute(trackId: string): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_EDIT_TRACK);

  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig,
    extras: {
      queryParams: {
        trackId
      }
    }
  };
}

export function getLatencyTestRoute(): CloseAndNavigateTo {
  const routeConfig = getRouteConfigByName(APP_ROUTES.MIXER_LATENCY_TEST);
  return {
    commands: [
      '/',
      APP_ROUTES.MIXER,
      APP_ROUTES.MIXER_PAGES,
      {
        outlets: routeConfig.outlets
      }
    ],
    routeConfig: routeConfig
  };
}
