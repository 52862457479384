import {createFeatureSelector, createSelector} from '@ngrx/store';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {TreeConfig, TreeNode} from '@spout/global-any/models';
import {GENRE_FEATURE_KEY, GenreState} from '@spout/global-web/models';

// Lookup the 'Genre' feature state managed by NgRx
export const getGenreState =
  createFeatureSelector<GenreState>(GENRE_FEATURE_KEY);

const selectAllGenres = createSelector(
  getGenreState,
  (state: GenreState): TreeConfig[] => {
    if (state && state.entities) {
      return <TreeConfig[]>Object.values(state.entities);
    }
    return [];
  }
);

export const getGenreRootTreeNode = createSelector(
  getGenreState,
  selectAllGenres,
  (state: GenreState, children: TreeConfig[]): TreeNode | null => {
    if (state.rootNode && state.rootNode.id && children && children.length) {
      const rootNode: TreeNode = new TreeNode(
        state.rootNode,
        null,
        null,
        null,
        true
      );
      rootNode.addChildren(children);

      return rootNode;
    }

    return null;
  }
);

// export const getGenresSelected = createSelector(getGenreState, (state: GenreState) => state.genresSelected);
