import {Injectable, NgZone} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {SongEntity, TrackEntity} from '@spout/global-any/models';
import {
  currentIdSet,
  firestoreTrackByIdPath,
  mergeEntities
} from '@spout/global-web/fns';

import {DocumentReference, WriteBatch} from '@firebase/firestore';
import {EMPTY, from, Observable, Observer} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {upsertDeviceStorage} from '../+device-storage/device-storage.actions';
import {Exists, CustomFirestoreService} from '../firebase';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {getTrackEntityByIdFn} from './track-storage.selectors';

@Injectable({
  providedIn: 'root'
})
export class FirestoreTracksService {
  constructor(
    private dss: DynamicStoreService,
    private sptFirestore: CustomFirestoreService,
    private store: Store,
    private zone: NgZone
  ) {}

  createTrackFirestore(track: Partial<TrackEntity>): Observable<TrackEntity> {
    // console.log('CREATE TRACK', track-audio);
    const that = this;
    that.zone.run(() => {
      this.store.dispatch(
        upsertDeviceStorage({
          storage: {
            currentIds: currentIdSet(track)
          }
        })
      );
    });

    return new Observable((observer: Observer<any>) => {
      this.sptFirestore
        .setDocIfNotExist<SongEntity>(firestoreTrackByIdPath(track), track)
        .subscribe((r: Exists<SongEntity>) => {
          observer.next(r.data);
        });
    });
  }

  updateTrack(track: Partial<TrackEntity>): Observable<Exists<TrackEntity>> {
    if (track && track.id) {
      return this.store.pipe(
        select(getTrackEntityByIdFn({trackId: track.id})),
        take<TrackEntity | null | undefined>(1),
        switchMap((trackEntity: TrackEntity | null | undefined) => {
          let config;

          if (trackEntity) {
            config = mergeEntities(trackEntity, track);
          } else {
            config = track;
          }

          // console.log('trackEntity', config, firestoreTrackByIdPath(config));

          return this.sptFirestore.upsertDoc<TrackEntity>(
            firestoreTrackByIdPath(config),
            config
          );
        })
      );
    }

    return EMPTY;
  }

  upsertTrack(track: Partial<TrackEntity>): void {
    if (track && track.id) {
      this.store
        .pipe(select(getTrackEntityByIdFn({trackId: track.id})), take(1))
        .subscribe((trackEntity: TrackEntity | null | undefined) => {
          this.sptFirestore
            .setDocIfNotExist<SongEntity>(firestoreTrackByIdPath(track), track)
            .subscribe((r: Exists<SongEntity>) => {
              /* noop */
            });
        });
    }
  }

  deleteTrack(track: TrackEntity): void {
    this.sptFirestore.deleteDoc$(firestoreTrackByIdPath(track)).subscribe(
      () => {
        /* noop */
      },
      error => {
        /* noop */
      }
    );
  }

  deleteTracks(tracks: TrackEntity[]) {
    const batch: WriteBatch = this.sptFirestore.writeBatch();

    tracks.forEach((_track: TrackEntity) => {
      const doc: DocumentReference = this.sptFirestore
        // .collection(fileCollection(file))
        .docRef(firestoreTrackByIdPath(_track));

      batch.delete(doc);
    });

    return from(batch.commit());
  }
}
