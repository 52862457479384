import {NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AudioFileMetaDataEntity, ProjectEntity} from '@spout/global-any/models';
import {
  DocumentChange,
  DocumentData,
  onSnapshot,
  QuerySnapshot
} from '@firebase/firestore';
import {upsertAudioFileMetaDataEntities} from '../../+audio-file-meta-data/audio-meta-data.actions';
import {deleteAudioFilesFromDevice} from '../../+device-storage/device-storage.actions';
import {CustomFirestoreService} from '../../firebase';
import {aggregateDocChanges, firestoreFilesPath} from '@spout/global-web/fns';
import {
  AggregateFirebaseSnapshotChangesEntities,
  AccountStateConnect,
  AccountState
} from '@spout/global-web/models';
import {Subscription} from 'rxjs';

import {QueryModel} from './query.model';

export class QueryAudioFileMetaDataService
  implements QueryModel<AudioFileMetaDataEntity>
{
  // FILE
  private filesSub: Subscription = Subscription.EMPTY;
  private allFilesSub: (() => void) | undefined;

  constructor(
    private sptFirebase: CustomFirestoreService,
    private store: Store<AccountStateConnect>,
    private config: ProjectEntity,
    private zone: NgZone
  ) {}

  onConnect(user: AccountState) {
    const that = this;
    // BEGIN FILES

    this.allFilesSub = onSnapshot(
      this.sptFirebase.collectionRef(firestoreFilesPath(this.config)),
      (snapshot: QuerySnapshot) => {
        if (user && user.uid) {
          that.process.apply(that, [snapshot.docChanges(), user.uid]);
        }
      }
    );

    // END FILES
  }

  onDisconnect(user?: AccountState) {
    // Files

    this.filesSub.unsubscribe();

    if (this.allFilesSub) {
      this.allFilesSub();
    }
  }

  process(snapshot: DocumentChange<DocumentData>[], createdByUID: string) {
    const aggregate: AggregateFirebaseSnapshotChangesEntities<DocumentData> =
      aggregateDocChanges<DocumentData>(snapshot, createdByUID);
    // console.log('processFiles', aggregate);
    if (aggregate.added.length) {
      // aggregate.added.forEach((added) => {
      //   console.log('FILES ADDED', added.id);
      // });

      this.zone.run(() => {
        this.store.dispatch(
          upsertAudioFileMetaDataEntities({
            files: <AudioFileMetaDataEntity[]>aggregate.added
          })
        );
      });
    }

    if (aggregate.modified.length) {
      // aggregate.modified.forEach((modified) => {
      //   console.log('FILES MODIFIED', modified.id);
      // });

      // const changes: Update<AudioFileMetaDataEntity>[] = aggregate.modified.reduce(
      //   (_changes: Update<AudioFileMetaDataEntity>[], i: AudioFileMetaDataEntity) => {
      //     _changes.push({
      //       id: i.id,
      //       changes: i,
      //     });
      //
      //     return _changes;
      //   },
      //   []
      // );

      this.zone.run(() => {
        this.store.dispatch(
          upsertAudioFileMetaDataEntities({
            files: <AudioFileMetaDataEntity[]>aggregate.modified
          })
        );
      });
    }

    if (aggregate.removed.length) {
      // aggregate.removed.forEach((removed) => {
      //   console.log('FILES REMOVED', removed);
      // });

      this.zone.run(() => {
        this.store.dispatch(
          deleteAudioFilesFromDevice({
            ids: aggregate.removed
          })
        );
      });
    }
  }
}
