import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {
  ElectronDialogText,
  exportDirectoryDialogText
} from '@spout/global-any/models';

import {map, switchMap} from 'rxjs/operators';
import {selectUidHash$} from '../+account/account.selectors';
import {
  getExportDirectoryEffect,
  initCreateStorageDirectory,
  moveWorkspaceEffect,
  openDirectoryEffect,
  saveDeviceStoreDirectoryPathEffect,
  upsertDeviceStorage
} from './device-storage.actions';
import {DeviceStorageService} from './services/device-storage.service';

@Injectable()
export class DeviceWorkspaceEffects implements OnInitEffects {
  saveDeviceStoreDirectoryPathEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveDeviceStoreDirectoryPathEffect),
      switchMap(action =>
        this.store.pipe(
          selectUidHash$,
          map(account => {
            const storage = {
              cacheDirectory: action.cacheDirectory
            };

            // TODO ERROR NOTIFICATION
            this.device
              .saveDeviceStorage({
                account,
                storage
              })
              .subscribe(() => {
                /* noop */
              });

            return upsertDeviceStorage({storage});
          })
        )
      )
    );
  });

  moveWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveWorkspaceEffect),
      switchMap(action => {
        return this.store.pipe(
          selectUidHash$,
          switchMap((account: string) => {
            return this.device.moveWorkspace(account).pipe(
              map(newWorkspacePath => {
                return saveDeviceStoreDirectoryPathEffect({
                  cacheDirectory: newWorkspacePath
                });
              })
            );
          })
        );
      })
    )
  );

  openWorkspace$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openDirectoryEffect),
        switchMap(({directory}) => {
          return this.device.openDirectory(directory);
        })
      ),
    {dispatch: false}
  );

  getExportDirectory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExportDirectoryEffect),
      switchMap(action => {
        return this.store.pipe(
          selectUidHash$,
          switchMap((account: string) => {
            const payload: ElectronDialogText = {
              ...exportDirectoryDialogText,
              account
            };

            return this.device.getNewExportDirectory(payload).pipe(
              map((exportDirectory: string) => {
                return upsertDeviceStorage({
                  storage: {
                    exportDirectory
                  }
                });
              })
            );
          })
        );
      })
    )
  );

  initCreateStorageDirectory = createEffect(() =>
    this.actions$.pipe(
      ofType(initCreateStorageDirectory),
      switchMap(() =>
        this.device.getDefaultCacheDirectoryPath().pipe(
          switchMap((newWorkspacePath: string) =>
            this.device.createWorkspaceDirectory(newWorkspacePath).pipe(
              map((cacheDirectory: string) =>
                saveDeviceStoreDirectoryPathEffect({
                  cacheDirectory
                })
              )
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private device: DeviceStorageService,
    private store: Store
  ) {}

  ngrxOnInitEffects(): Action {
    return initCreateStorageDirectory();
  }
}
