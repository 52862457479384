import {Inject, Injectable, NgZone} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Actions, OnInitEffects} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {
  DIALOG_COMPONENT,
  initialDialogQueue,
  PartialDialogQueueState
} from '@spout/global-web/models';
import {WINDOW} from '@spout/global-web/utils';
import {
  CheckIfAccountIsOnboarded,
  selectIsOnboarded$
} from '../+account/account.selectors';
import {loadDialogQueues, openDialog} from './dialog-queue.actions';

@Injectable()
export class DialogQueueEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private store: Store<PartialDialogQueueState>,
    private dialog: MatDialog,
    private zone: NgZone,
    @Inject(WINDOW) private window: Window
  ) {
    const that = this;
    this.store
      .pipe(selectIsOnboarded$)
      .subscribe((c: CheckIfAccountIsOnboarded) => {
        if (!c.accountIsOnboarded) {
          that.zone.run(() => {
            that.store.dispatch(openDialog({id: DIALOG_COMPONENT.ONBOARD}));
          });
        }
      });
  }

  ngrxOnInitEffects(): Action {
    return loadDialogQueues({
      dialogQueues: initialDialogQueue
    });
  }
}
