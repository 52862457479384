import {Dictionary} from '@ngrx/entity/src/models';
import {
  PriceInterval,
  ProductEntities,
  ProductMetaData
} from '../+prices/price.model';
import {ACCOUNT_ROLE} from '@spout/global-web/models';
import {Price, Product} from './product.model';

export function convertProductValuesStringToNumber(products: Product[]) {
  return products.map((p: Product) => {
    const _p = {
      ...p,
      metadata: <any>{
        ...p.metadata,
        addon: p.metadata.addon === 'null' ? null : p.metadata.addon,
        description:
          p.metadata.description === 'null' ? null : p.metadata.description,
        trial:
          <string>p.metadata.trial === 'null'
            ? null
            : parseInt(<string>p.metadata.trial, 10),
        sortOrder:
          <string>p.metadata.sortOrder === 'null'
            ? null
            : parseInt(<string>p.metadata.sortOrder, 10),
        baseStorageGB: parseInt(<string>p.metadata.baseStorageGB, 10),
        accountRole:
          p.metadata.accountRole === 'null'
            ? null
            : parseInt(<string>p.metadata.accountRole, 10)
      }
    };

    return _p;
  });
}

function getProductByRole(
  role: number,
  productEntities: Dictionary<Product>
): Product | null | undefined {
  return (<Product[]>Object.values(productEntities)).reduce(
    (a: Product | null | undefined, p: Product) => {
      if (p.metadata.accountRole === role) {
        return p;
      }

      return a;
    },
    null
  );
}

export function productEntityByRole(
  productEntities: Dictionary<Product>
): ProductEntities {
  return {
    [ACCOUNT_ROLE.EARLY_ADOPTER]: getProductByRole(
      ACCOUNT_ROLE.EARLY_ADOPTER,
      productEntities
    ),
    [ACCOUNT_ROLE.COLLABORATOR]: getProductByRole(
      ACCOUNT_ROLE.COLLABORATOR,
      productEntities
    ),
    [ACCOUNT_ROLE.PERSONAL]: getProductByRole(
      ACCOUNT_ROLE.PERSONAL,
      productEntities
    ),
    [ACCOUNT_ROLE.BAND]: getProductByRole(ACCOUNT_ROLE.BAND, productEntities)
  };
}

function getPriceByProductAndInterval(
  priceEntities: Dictionary<Price>,
  productId: string | undefined,
  interval: string
): Price {
  return (<Price[]>Object.values(priceEntities)).reduce(
    (a: Price, p: Price) => {
      if (p.productID === productId && p.interval === interval) {
        return p;
      }

      return a;
    },
    <Price>{}
  );
}

export function monthlyPriceEntityByRole(
  priceEntities: Dictionary<Price>,
  productEntites: Dictionary<Product>,
  interval: string
) {
  return {
    [ACCOUNT_ROLE.EARLY_ADOPTER]: getPriceByProductAndInterval(
      priceEntities,
      getProductByRole(ACCOUNT_ROLE.EARLY_ADOPTER, productEntites)?.id,
      interval
    ),
    [ACCOUNT_ROLE.COLLABORATOR]: getPriceByProductAndInterval(
      priceEntities,
      getProductByRole(ACCOUNT_ROLE.COLLABORATOR, productEntites)?.id,
      interval
    ),
    [ACCOUNT_ROLE.PERSONAL]: getPriceByProductAndInterval(
      priceEntities,
      getProductByRole(ACCOUNT_ROLE.PERSONAL, productEntites)?.id,
      interval
    ),
    [ACCOUNT_ROLE.BAND]: getPriceByProductAndInterval(
      priceEntities,
      getProductByRole(ACCOUNT_ROLE.BAND, productEntites)?.id,
      interval
    )
  };
}

function findPriceByInterval(
  priceEntities: Dictionary<Price>,
  product: Product,
  interval: PriceInterval
): Price | null {
  return (<Price[]>Object.values(priceEntities)).reduce(
    (found: Price | null, price: Price) => {
      if (price.productID === product.id && price.interval === interval) {
        return price;
      }
      return found;
    },
    null
  );
}

export function selectStorageAddonPriceMonthly(
  productEntities: Dictionary<Product>,
  priceEntities: Dictionary<Price>
): Price | null {
  return (<Product[]>Object.values(productEntities)).reduce(
    (a: Price | null, product: Product) => {
      if (product.metadata.addon === 'storage') {
        return findPriceByInterval(priceEntities, product, 'month');
      }
      return a;
    },
    null
  );
}
