import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {LetModule, PushModule} from '@ngrx/component';
import {AlertMessageContainerComponent} from './alert-message-container.component';
import {SuccessMessageComponent} from './success-message/success-message.component';
import {WarnMessageComponent} from './warn-message/warn-message.component';

@NgModule({
  declarations: [
    AlertMessageContainerComponent,
    SuccessMessageComponent,
    WarnMessageComponent
  ],
  exports: [
    AlertMessageContainerComponent,
    SuccessMessageComponent,
    WarnMessageComponent
  ],
  imports: [CommonModule, LetModule, PushModule, MatButtonModule, MatIconModule]
})
export class SptAlertMessageModule {}
