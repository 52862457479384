/**
 * https://firebase.google.com/docs/analytics/events?platform=web&authuser=0
 *
 * Recommended for all apps
 * https://support.google.com/firebase/answer/9267735?ref_topic=6317484&authuser=0&visit_id=637676510524744564-2509524093&rd=1
 *
 * Recommended events
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
 */

import {InjectionToken} from '@angular/core';
import {AnalyticsCallOptions, EventParams} from '@firebase/analytics';

export interface GAEventItem {
  eventName: string;

  // node_modules/@firebase/analytics/dist/analytics-public.d.ts:275
  eventParams?: EventParams;
  options?: AnalyticsCallOptions;
}

export const GA_CONSOLE_LOG_EVENTS = new InjectionToken(
  'GA_CONSOLE_LOG_EVENTS'
);

export function createGAEventItemName(eventName: string): GAEventItem {
  return {
    eventName
  };
}

export function addGAEventParams(
  e: GAEventItem,
  eventParams: EventParams
): GAEventItem {
  return {
    ...e,
    eventParams
  };
}

export function addGAEventOptions(
  e: GAEventItem,
  options: AnalyticsCallOptions
): GAEventItem {
  return {
    ...e,
    options
  };
}

export function createGAEvent(
  eventName: string,
  eventParams?: EventParams,
  options?: AnalyticsCallOptions
): GAEventItem {
  let e = {
    eventName
  };

  if (eventParams) {
    e = addGAEventParams(e, eventParams);
  }

  if (options) {
    e = addGAEventOptions(e, options);
  }

  return e;
}
