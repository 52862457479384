import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {TrackMix} from '@spout/global-any/models';
import {firestoreTrackMixByIdPath} from '@spout/global-web/fns';

import {DocumentReference, WriteBatch} from '@firebase/firestore';
import {EMPTY, Observable, Observer} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {Exists, CustomFirestoreService} from '../firebase';
import {getTrackMixByMixId} from './track-mix.selectors';

@Injectable({
  providedIn: 'root'
})
export class TrackMixesService {
  constructor(
    private sptFirestore: CustomFirestoreService,
    private store: Store
  ) {}

  createTrackMixInFirestore(mix: Partial<TrackMix>): Observable<TrackMix> {
    return new Observable((observer: Observer<any>) => {
      // CREATE PROJECT IN FIRESTORE
      this.sptFirestore
        .setDocIfNotExist<TrackMix>(firestoreTrackMixByIdPath(mix), mix)
        .subscribe(
          (r: Exists<TrackMix>) => {
            // DOES NOT DISPATCH ACTION
            // THIS DATA GOES NO WHERE
            observer.next(r.data);
          },
          error => {
            observer.error(error);
          }
        );
    });
  }

  batchCreateTrackMixesInFirestore(
    trackMixes: TrackMix[]
  ): Observable<TrackMix[]> {
    const that = this;
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      trackMixes.forEach((trackMix: TrackMix) => {
        const doc: DocumentReference = this.sptFirestore
          // .collection(fileCollection(file))
          .docRef(firestoreTrackMixByIdPath(trackMix));

        batch.set(doc, that.sptFirestore.payloadForSet(trackMix));
      });

      batch.commit().then(
        () => {
          observer.next(trackMixes);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  updateTrackMixToFirestore(
    mixConfig: Partial<TrackMix>
  ): Observable<Exists<TrackMix>> {
    if (mixConfig && mixConfig.id) {
      return this.store.pipe(
        select(getTrackMixByMixId, {id: mixConfig.id}),
        take<TrackMix | undefined>(1),
        switchMap((mixEntity: TrackMix | undefined) => {
          let mix: Partial<TrackMix>;

          if (mixEntity) {
            mix = {
              ...mixEntity,
              ...mixConfig,
              audioSnippets: {
                ...mixEntity.audioSnippets,
                ...mixConfig.audioSnippets
              }
            };
          } else {
            mix = {
              ...mixConfig
            };
          }

          return this.sptFirestore.update<TrackMix>(
            firestoreTrackMixByIdPath(mix),
            mix
          );
        })
      );
    }

    return EMPTY;
  }

  batchUpdateFirestoreTrackMixes(mixes: TrackMix[]): Observable<TrackMix[]> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      mixes.forEach((mix: TrackMix) => {
        const doc: DocumentReference = this.sptFirestore
          // .collection(fileCollection(file))
          .docRef(firestoreTrackMixByIdPath(mix));

        batch.update(doc, <any>mix);
      });

      batch.commit().then(
        () => {
          observer.next(mixes);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  batchDeleteFirestoreTrackMixes(trackMixes: TrackMix[]): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      trackMixes.forEach((trackMix: TrackMix) => {
        const doc: DocumentReference = this.sptFirestore
          // .collection(fileCollection(file))
          .docRef(firestoreTrackMixByIdPath(trackMix));

        batch.delete(doc);
      });

      batch.commit().then(
        () => {
          observer.next(true);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }
}
