import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {StorageMap} from '@ngx-pwa/local-storage';
import {AudioFileMetaDataEntity, ProjectEntity} from '@spout/global-any/models';
import {
  clearIndexedDBCached,
  firestoreProjectByIdPath,
  getMusicianAccountPathByUID,
  getMusicianPublicPathByUser
} from '@spout/global-web/fns';
import {AccountState} from '@spout/global-web/models';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  Observer,
  of,
  ReplaySubject,
  Subject
} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {selectAccountState} from '../../+account/account.selectors';
import {logout} from '../../+account/auth.actions';
import {selectAllAudioMetaData} from '../../+audio-file-meta-data/audio-metadata-storage.selectors';
import {AudioFileDeleteService} from '../../+device-storage/services/audio-file-delete.service';
import {DeviceStorageService} from '../../+device-storage/services/device-storage.service';
import {selectAllProjectsIOwn} from '../../+project/project-storage.selectors';
import {selectCustomerID} from '../../+stripe';

import {CustomFirestoreService} from '../../firebase';
import {FirebaseStorageService} from '../firebase-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DeleteAccountService {
  isCleaning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  notCleaning$ = this.isCleaning$.pipe(map(isCleaning => !isCleaning));
  deleteProgress$: ReplaySubject<string> = new ReplaySubject<string>(1);
  complete$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private device: DeviceStorageService,
    private store: Store,
    private fileDeleteService: AudioFileDeleteService,
    private storage: StorageMap,
    private _storage: FirebaseStorageService,
    private sptFirestore: CustomFirestoreService
  ) {}

  deleteEntireAccount() {
    this.isCleaning$.next(true);
    this.store
      .pipe(
        select(selectAccountState),
        take<AccountState>(1),
        filter<AccountState>(
          (a: AccountState) => a && a.uidHash !== null && a.uid !== null
        ),
        this.deleteAudioFilesFromWorkspace$(),
        this.deleteAudioFilesFromCloud$(),
        this.deleteAlgoliaIndex$(),
        this.deleteSripeAccount$(),
        this.deleteProjects$(),
        this.deletePublicAccount$(),
        this.deleteUserAccount$()

        // Need to keep user uid for testing collaboration
        // this.deleteUserAuth$()
      )
      .subscribe(() => {
        this.deleteProgress$.next('Logging out.');

        this.isCleaning$.next(false);

        this.store.dispatch(logout());

        localStorage.clear();
        this.storage.clear();
        clearIndexedDBCached().then(() => {
          /* noop */
        });

        this.sptFirestore.clearIndexedDbPersistence().catch(error => {
          console.error('Could not enable persistence:', error.code);
        });
        this.isCleaning$.next(false);
        this.complete$.next(true);
        this.complete$.complete();
      });
  }

  private deleteAudioFilesFromCloud$() {
    return switchMap((a: AccountState) => {
      return this.store.pipe(
        select(selectAllAudioMetaData),
        take(1),
        this.deleteFilesFromBackblaze$(),
        map(() => a)
      );
    });
  }

  private deleteFilesFromBackblaze$() {
    return switchMap((files: AudioFileMetaDataEntity[]) => {
      if (files && files.length) {
        this.deleteProgress$.next('Deleting audio files from Backblaze B2.');
        return this._storage.deleteAudioMetaDataFilesFromStorage(files).pipe(
          take(1),
          map(() => files)
        );
      }

      return of(files);
    });
  }

  private deleteAudioFilesFromWorkspace$() {
    return switchMap((a: AccountState) => {
      this.deleteProgress$.next('Deleting audio files from file system.');
      return this.device
        .cleanWorkspace({
          account: a.uidHash ? a.uidHash : ''
        })
        .pipe(
          take(1),
          map(() => a)
        );
    });
  }

  private deleteSripeAccount$() {
    return switchMap((a: AccountState) => {
      return this.store.pipe(select(selectCustomerID)).pipe(
        switchMap((customerID: string | null) => {
          return new Observable((observer: Observer<AccountState>) => {
            if (customerID) {
              console.log('Deleting deleteStripeCustomerById', customerID);
              this.deleteProgress$.next('Deleting Stripe Account.');
              const deleteStripeCustomerByIdFn =
                this.sptFirestore.httpsCallable('deleteStripeCustomerById');

              deleteStripeCustomerByIdFn(customerID)
                .then(function (result) {
                  console.log(
                    'Delete success: ' + JSON.stringify(result, null, 2)
                  );
                  observer.next(a);
                  observer.complete();
                })
                .catch(function (err) {
                  console.warn(err);
                  observer.next(a);
                  observer.complete();
                });
            } else {
              observer.next(a);
              observer.complete();
            }
          });
        })
      );
    });
  }

  private deleteAlgoliaIndex$() {
    return switchMap((a: AccountState): Observable<AccountState> => {
      this.deleteProgress$.next('Deleting Algolia Index.');
      return new Observable((observer: Observer<AccountState>) => {
        console.log('Deleting deleteAlgoliaIndex', a.uid);
        const deleteAlgoliaIndexFn =
          this.sptFirestore.httpsCallable('deleteAlgoliaIndex');

        deleteAlgoliaIndexFn(a.uid)
          .then(function (result) {
            console.log('Delete success: ' + JSON.stringify(result, null, 2));
            observer.next(a);
            observer.complete();
          })
          .catch(function (err) {
            console.warn(err);
            observer.next(a);
            observer.complete();
          });
      });
    });
  }

  private deleteProjects$() {
    const that = this;
    return switchMap((a: AccountState): Observable<AccountState> => {
      this.deleteProgress$.next('Deleting Projects I Own.');
      return this.store.pipe(
        select(selectAllProjectsIOwn),
        map((projects: ProjectEntity[]) => {
          // Get Project Paths
          return projects.map((project: ProjectEntity) => {
            return firestoreProjectByIdPath(project);
          });
        }),
        switchMap((paths: string[]) => {
          if (paths && paths.length) {
            const toDelete$ = paths.map((path: string) => {
              return that.deleteDevFirestorePath(path);
            });
            return forkJoin(toDelete$);
          } else {
            return of(true);
          }
        }),
        map(() => a)
      );
    });
  }

  private deletePublicAccount$() {
    const that = this;
    return switchMap((a: AccountState): Observable<AccountState> => {
      this.deleteProgress$.next('Deleting Public Account.');
      return that
        .deleteDevFirestorePath(getMusicianPublicPathByUser(<string>a.uid))
        .pipe(map(() => a));
    });
  }

  private deleteUserAccount$() {
    const that = this;
    return switchMap((a: AccountState) => {
      this.deleteProgress$.next('Deleting User Account.');
      return that
        .deleteDevFirestorePath(getMusicianAccountPathByUID(<string>a.uid))
        .pipe(map(() => a));
    });
  }

  private deleteDevFirestorePath(path: string) {
    return new Observable((observer: Observer<any>) => {
      console.log('Deleting', path);
      const recursiveDeleteFn =
        this.sptFirestore.httpsCallable('recursiveDelete');

      recursiveDeleteFn({path: path})
        .then(function (result: any) {
          console.log('Delete success: ' + JSON.stringify(result, null, 2));
          observer.next(true);
          observer.complete();
        })
        .catch(function (err: any) {
          console.warn(err);
          observer.next(true);
          observer.complete();
        });
    });
  }

  private deleteUserAuth$() {
    return switchMap((a: AccountState) => {
      this.deleteProgress$.next('Deleting User Auth.');

      return new Observable((observer: Observer<AccountState>) => {
        const deleteUserAuthFn =
          this.sptFirestore.httpsCallable('deleteUserAuth');

        deleteUserAuthFn()
          .then(function (result: any) {
            console.log('Delete success: ' + JSON.stringify(result, null, 2));
            observer.next(a);
            observer.complete();
          })
          .catch(function (err: any) {
            console.warn(err);
            observer.next(a);
            observer.complete();
          });
      });
    });
  }
}
