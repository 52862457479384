import {DeferredPromise} from '@spout/global-web/fns';
import {DYN_STORE} from '@spout/global-web/models';
import {Observable} from 'rxjs';

import {
  distinctUntilKeyChanged,
  map,
  mergeMap,
  take,
  tap
} from 'rxjs/operators';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {SptLatencyAudioWorkletNode} from './spt-latency.audioworklet';
import SptRecorderAudioWorklet, {OutputData} from './spt-recorder.audioworklet';
import {SptMergerAudioworklet} from './spt-merger.audioworklet';
import {OutputNodes, SptTransportService} from './spt-transport.service';

export class SptRecorder {
  private numOutputChannels = 2;
  private recorderWorkletNode: SptRecorderAudioWorklet | null;
  private stopPromise: DeferredPromise<any> | null;
  private startPromise: DeferredPromise<any> | undefined;
  private microphone: AudioNode | undefined;
  private mergeCompressorNode: DynamicsCompressorNode | undefined;
  private inputCompressorNode: DynamicsCompressorNode | undefined;
  // private inputGainParam: AudioParam | undefined;
  private inputGain: GainNode | undefined;

  /**
   * Use to reset after latency testing
   * @private
   */
  private _previousGainValue: number | null | undefined = null;

  private gainSilentOutput: GainNode | undefined;
  private analyzer: AnalyserNode | undefined;

  output: OutputData = {
    // gainInput$: new ReplaySubject<number>(1),
  };

  id: number = new Date().valueOf();

  onInitialized: (() => void) | undefined;

  latencyWorkletNode: SptLatencyAudioWorkletNode;

  // oscillatorTest: OscillatorNode;

  constructor(
    public audioContext: AudioContext,
    private transport: SptTransportService,
    private workerFactory: () => Worker,
    private dss: DynamicStoreService,
    private options: any
  ) {
    const that = this;
    this.numOutputChannels = 2; // stereo output, even if input is mono`

    this.recorderWorkletNode = new SptRecorderAudioWorklet(
      this.transport,
      audioContext,
      this.workerFactory,
      this.output,
      this.options
    );

    this.latencyWorkletNode = new SptLatencyAudioWorkletNode(
      this.transport,
      audioContext
    );

    // this.inputGainParam = (<any>this.recorderWorkletNode.parameters).get('gain');

    // this.oscillatorTest.start();

    this.recorderWorkletNode.port.onmessage = function (event: MessageEvent) {
      const data = event.data;

      if (data.message === 'RECORDING_STARTED' && that.startPromise) {
        that.startPromise.resolve(data.timestampStartRecording);
      }

      if (data.message === 'RECORD_OUTPUT_VOLUME_WITH_GAIN') {
        that.dss.emit(DYN_STORE.RECORD_OUTPUT_VOLUME_WITH_GAIN, {
          left: data.left,
          right: data.right
        });
        return;
      }
    }.bind(this);

    // this._callback = function() {};
    this.stopPromise = null;
  }

  private _createNodes(stream: MediaStream) {
    if (!this.mergeCompressorNode) {
      this.mergeCompressorNode = this.audioContext.createDynamicsCompressor();
    }

    if (!this.inputGain) {
      this.inputGain = new GainNode(this.audioContext);
      this.inputGain.gain.value = 1;
    }

    if (!this.microphone) {
      this.microphone = this.audioContext.createMediaStreamSource(stream);
    }

    if (!this.inputCompressorNode) {
      this.inputCompressorNode = this.audioContext.createDynamicsCompressor();
    }

    if (!this.analyzer) {
      this.analyzer = this.audioContext.createAnalyser();
    }

    if (!this.gainSilentOutput) {
      this.gainSilentOutput = this.audioContext.createGain();
      this.gainSilentOutput.gain.value = 0;
    }
  }

  connectRecorder(stream: MediaStream) {
    this.disconnectAudioStream();
    this._createNodes(stream);
    // this.latencyWorkletNode.stopTest();

    // this.microphone = this.audioContext.createMediaStreamSource(stream);
    if (
      this.microphone &&
      this.inputGain &&
      this.inputCompressorNode &&
      this.analyzer &&
      this.gainSilentOutput &&
      this.recorderWorkletNode
    ) {
      if (this._previousGainValue) {
        this.inputGain.gain.value = this._previousGainValue;
      }

      this.microphone
        .connect(this.inputGain)
        .connect(this.inputCompressorNode)
        .connect(this.analyzer);

      this.inputCompressorNode
        .connect(this.recorderWorkletNode)
        .connect(this.gainSilentOutput) // prevent input audio ( actively recorded audio ) from playing in speakers
        .connect(this.audioContext.destination);

      this.dss.setSingletonValue(
        DYN_STORE.RECORD_INPUT_ANALYZER,
        this.analyzer
      );

      // TODO TEST
      // this.oscillatorTest = this.audioContext.createOscillator();
      // this.oscillatorTest.type = 'square';
      // this.oscillatorTest.frequency.setValueAtTime(440, this.audioContext.currentTime); // value in hertz
      // END TEST

      // gainSilentOutput -> this.audioContext.destination

      // this.oscillatorTest.connect(this.workletNode).connect(audioContext.destination);
      // this.oscillatorTest.start();
    }
  }

  connectLatencyTester(stream: MediaStream) {
    // console.log('connectLatencyTester');
    const that = this;

    this.disconnectAudioStream();
    this._createNodes(stream);

    if (
      this.microphone &&
      this.inputGain &&
      this.inputCompressorNode &&
      this.analyzer &&
      this.gainSilentOutput &&
      this.recorderWorkletNode &&
      this.latencyWorkletNode
    ) {
      // Preserve gain value
      this._previousGainValue = this.inputGain.gain.value;
      this.inputGain.gain.value = 1;

      this.microphone
        .connect(this.inputGain)
        .connect(this.inputCompressorNode)
        .connect(this.analyzer);

      this.inputCompressorNode
        // .connect(this.recorderWorkletNode)
        .connect(this.latencyWorkletNode)
        // .connect(this.gainSilentOutput) // prevent input audio ( actively recorded audio ) from playing in speakers
        .connect(this.audioContext.destination);

      this.dss.setSingletonValue(
        DYN_STORE.RECORD_INPUT_ANALYZER,
        this.analyzer
      );

      this.latencyWorkletNode.startTest();
    }
  }

  connectMixMerger(o: OutputNodes) {
    const that = this;
    // this.disconnectAudioStream();

    this.transport.mergerWorklet$
      .pipe(take(1))
      .subscribe((merger: SptMergerAudioworklet) => {
        // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode
        // https://mdn.github.io/webaudio-examples/compressor-example/
        if (
          // that.mergeCompressorNode &&
          that.recorderWorkletNode &&
          that.gainSilentOutput
        ) {
          /**
           * Use Merge Compressor Node
           */
          // merger.connect(that.mergeCompressorNode);
          // that.mergeCompressorNode.connect(that.recorderWorkletNode);

          /**
           * Don't use Merge Compressor Node
           */
          merger.connect(that.recorderWorkletNode);

          that.recorderWorkletNode.connect(that.gainSilentOutput);
          that.gainSilentOutput.connect(that.audioContext.destination);
        }
      });
  }

  disconnectAudioStream() {
    if (this.microphone) {
      this.microphone.disconnect();
    }

    if (this.inputGain) {
      this.inputGain.disconnect();
    }

    if (this.analyzer) {
      this.analyzer.disconnect();
    }

    if (this.recorderWorkletNode) {
      this.recorderWorkletNode.disconnect();
    }

    if (this.latencyWorkletNode) {
      this.latencyWorkletNode.disconnect();
    }

    if (this.gainSilentOutput) {
      this.gainSilentOutput.disconnect();
    }

    if (this.mergeCompressorNode) {
      this.mergeCompressorNode.disconnect();
    }

    if (this.inputCompressorNode) {
      this.inputCompressorNode.disconnect();
    }
  }

  setGain() {
    return mergeMap((g: number) => {
      return this.transport.audioContext$.pipe(
        distinctUntilKeyChanged<any>('id'),
        tap((audioContext: AudioContext) => {
          // if (this.inputGainParam) {
          //   this.inputGainParam.setValueAtTime(g, audioContext.currentTime);
          // }
          if (!this.inputGain) {
            this.inputGain = audioContext.createGain();
          }

          this._previousGainValue = g;

          this.inputGain.gain.setValueAtTime(g, audioContext.currentTime);
        }),
        map(() => {
          return g;
        })
      );
    });
  }

  setInputCompressorThreshold() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.threshold.setValueAtTime(
            v,
            audioContext.currentTime
          );
          return v;
        })
      );
    });
  }

  setInputCompressorKnee() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.knee.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setInputCompressorRatio() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.ratio.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setInputCompressorAttack() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.attack.setValueAtTime(
            v,
            audioContext.currentTime
          );
          return v;
        })
      );
    });
  }

  setInputCompressorRelease() {
    return mergeMap((v: number) => {
      return this._getInputCompressorNode().pipe(
        map(({audioContext, compressorInputNode}) => {
          compressorInputNode.release.setValueAtTime(
            v,
            audioContext.currentTime
          );
          return v;
        })
      );
    });
  }

  private _getInputCompressorNode(): Observable<{
    compressorInputNode: DynamicsCompressorNode;
    audioContext: AudioContext;
  }> {
    return this.transport.audioContext$.pipe(
      distinctUntilKeyChanged<any>('id'),
      map((audioContext: AudioContext) => {
        // if (this.inputGainParam) {
        //   this.inputGainParam.setValueAtTime(g, audioContext.currentTime);
        // }
        if (!this.inputCompressorNode) {
          this.inputCompressorNode = audioContext.createDynamicsCompressor();
        }

        return {compressorInputNode: this.inputCompressorNode, audioContext};
      })
    );
  }

  setMergeCompressorThreshold() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.threshold.setValueAtTime(
            v,
            audioContext.currentTime
          );
          return v;
        })
      );
    });
  }

  setMergeCompressorKnee() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.knee.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setMergeCompressorRatio() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.ratio.setValueAtTime(v, audioContext.currentTime);
          return v;
        })
      );
    });
  }

  setMergeCompressorAttack() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.attack.setValueAtTime(
            v,
            audioContext.currentTime
          );
          return v;
        })
      );
    });
  }

  setMergeCompressorRelease() {
    return mergeMap((v: number) => {
      return this._getMergeCompressorNode().pipe(
        map(({audioContext, compressorMergeNode}) => {
          compressorMergeNode.release.setValueAtTime(
            v,
            audioContext.currentTime
          );
          return v;
        })
      );
    });
  }

  private _getMergeCompressorNode(): Observable<{
    compressorMergeNode: DynamicsCompressorNode;
    audioContext: AudioContext;
  }> {
    return this.transport.audioContext$.pipe(
      distinctUntilKeyChanged<any>('id'),
      map((audioContext: AudioContext) => {
        // if (this.inputGainParam) {
        //   this.inputGainParam.setValueAtTime(g, audioContext.currentTime);
        // }
        if (!this.mergeCompressorNode) {
          this.mergeCompressorNode = audioContext.createDynamicsCompressor();
        }

        return {compressorMergeNode: this.mergeCompressorNode, audioContext};
      })
    );
  }

  // record(recordStartLatencyMS: number = 0) {
  //   // TODO TEST
  //   // this.oscillatorTest.start();
  //
  //   this.startPromise = Deferred();
  //   this.workletNode.port.postMessage({ message: 'START_RECORDING', recordStartLatencyMS });
  //   return this.startPromise.promise;
  // }

  stop(): Promise<any> {
    // TODO TEST
    // this.oscillatorTest.stop();

    if (this.recorderWorkletNode) {
      return this.recorderWorkletNode.stop();
    }

    return new Promise<any>(resolve => {
      resolve(true);
    });
  }

  dispose() {
    // this._callback = function() {};
    this.recorderWorkletNode = null;
  }
}
