import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpNavBackComponent} from './help-nav-back.component';

@NgModule({
  declarations: [HelpNavBackComponent],
  exports: [HelpNavBackComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule]
})
export class HelpNavBackModule {}
