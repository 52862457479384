import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatIconModule} from '@angular/material/icon';
import {LetModule, PushModule} from '@ngrx/component';
import {NoEmailWarningComponent} from './no-email-warning.component';

@NgModule({
  declarations: [NoEmailWarningComponent],
  exports: [NoEmailWarningComponent],
  imports: [
    CommonModule,
    LetModule,
    PushModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule
  ]
})
export class NoEmailWarningModule {}
