import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {TrackEntity} from '@spout/global-any/models';
import {TrackComponentModel} from '@spout/global-web/models';

export const loadTracks = createAction(
  '[Track] Load Tracks',
  props<{tracks: TrackEntity[]}>()
);

export const addTrack = createAction(
  '[Track] Add Track',
  props<{track: TrackEntity}>()
);

export const createTrack = createAction(
  '[Track] Create Track',
  props<{track: TrackEntity}>()
);

export const createTrackEffect = createAction(
  '[Track] Create Track Effect',
  props<{track: TrackEntity}>()
);

export const upsertTrack = createAction(
  '[Track] Upsert Track',
  props<{track: TrackEntity}>()
);

export const addTracks = createAction(
  '[Track] Add Tracks',
  props<{tracks: TrackEntity[]}>()
);

export const upsertTracks = createAction(
  '[Track] Upsert Tracks',
  props<{tracks: TrackEntity[]}>()
);

export const updateTrack = createAction(
  '[Track] Update Track',
  props<{track: Update<TrackEntity>}>()
);

export const updateTracks = createAction(
  '[Track] Update Tracks',
  props<{tracks: Update<TrackEntity>[]}>()
);

export const deleteTrack = createAction(
  '[Track] Delete Track',
  props<{id: string}>()
);

export const deleteTracks = createAction(
  '[Track] Delete Tracks',
  props<{ids: string[]}>()
);

export const clearTracks = createAction('[Track] Clear Tracks');

export const selectTrack = createAction(
  '[Track] Select Track',
  props<{track: TrackEntity}>()
);

export const setTrackAsMaster = createAction(
  '[Track] Set Track As Master',
  props<{id: string}>()
);

export const deleteTrackEffect = createAction(
  '[Track] Delete Track Effect',
  props<{trackModel: TrackComponentModel}>()
);

export const clearTrackEffect = createAction(
  '[Track] Clear Track Effect',
  props<{trackModel: TrackComponentModel}>()
);

export const syncTrackProgress = createAction(
  '[Track] Sync Progress',
  props<{
    sync: boolean;
    track: TrackEntity;
  }>()
);

/**
 * @deprecated - autosync
 */
export const syncTrackEffect = createAction(
  '[Track] Sync Effect',
  props<{
    sync: boolean;
    trackModel: TrackComponentModel;
  }>()
);

export const syncTrackDialogEffect = createAction(
  '[Track] Sync Dialog Effect',
  props<{
    sync: boolean;
    trackModel: TrackComponentModel;
  }>()
);

export const shareTrackEffect = createAction(
  '[Track] Share Effect',
  props<{
    share: boolean;
    trackModel: TrackComponentModel;
  }>()
);

export const shareTrackDialogEffect = createAction(
  '[Track] Share Dialog Effect',
  props<{
    share: boolean;
    trackModel: TrackComponentModel;
  }>()
);

export const addMixFromSidenav = createAction('[Track] Add Mix From Sidenav');
