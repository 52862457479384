import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';

import {SptUser} from './spt-user.model';

export const loadSptUsers = createAction(
  '[SptUser/API] Load SptUsers',
  props<{sptUsers: SptUser[]}>()
);

export const addSptUser = createAction(
  '[SptUser/API] Add SptUser',
  props<{sptUser: SptUser}>()
);

export const upsertSptUser = createAction(
  '[SptUser/API] Upsert SptUser',
  props<{sptUser: SptUser}>()
);

export const addSptUsers = createAction(
  '[SptUser/API] Add SptUsers',
  props<{sptUsers: SptUser[]}>()
);

export const upsertSptUsers = createAction(
  '[SptUser/API] Upsert SptUsers',
  props<{sptUsers: SptUser[]}>()
);

export const updateSptUser = createAction(
  '[SptUser/API] Update SptUser',
  props<{sptUser: Update<SptUser>}>()
);

export const updateSptUsers = createAction(
  '[SptUser/API] Update SptUsers',
  props<{sptUsers: Update<SptUser>[]}>()
);

export const deleteSptUser = createAction(
  '[SptUser/API] Delete SptUser',
  props<{id: string}>()
);

export const deleteSptUsers = createAction(
  '[SptUser/API] Delete SptUsers',
  props<{ids: string[]}>()
);

export const clearSptUsers = createAction('[SptUser/API] Clear SptUsers');

export const getUserFromFirestoreEffect = createAction(
  '[SptUser/API] Get SptUserFromFirestore',
  props<{uid: string}>()
);
