import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { createSelector, select, Store } from '@ngrx/store';
import {
  AuthLogoutWatchService,
  DeviceDetectionService,
  logout,
  PresenceService,
  selectDeviceIsSmallScreen,
  selectIsLoggedIn$,
  selectIsSubscribed$,
  selectUserName,
  selectUserInitial,
  StripePaymentSelectionService,
  marketingWizardWide,
  getIsSubscribed,
  getSubscribedAt,
  getState,
  selectHasActiveSubscription,
  selectActiveSubscriptionsUIDisplay,
  selectActiveSubscriptions,
  Subscription,
  SubscriptionUIdisplay,
  SubscriptionItem,
  SubscriptionUIItem
} from '@spout/global-web/data-access';
import {
  APP_ROUTES,
  AccountState,
  ENVIRONMENT,
  IEnvironmentState
} from '@spout/global-web/models';
import { SvgLoaderService } from '@spout/global-web/utils';
import {
  Coupon,
  Invoice,
  selectAllInvoices
} from 'libs/global-web/data-access/src/lib/+stripe/+invoices';

import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

export interface SptNavLink {
  title: string;
  link: string;
  icon: string;
}

@Component({
  selector: 'spt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit, OnDestroy {
  isSvgIcon = false;
  ROUTES = APP_ROUTES;
  loaded = false;
  loading = false;
  loggedIn = false;
  currentRoute$ = new Subject<string>();
  currentSubscriptions$ = this.store.pipe(
    select(selectActiveSubscriptionsUIDisplay)
  );
  hasSubscription$ = this.store.pipe(selectIsSubscribed$);
  userInitial$ = this.store.pipe(select(selectUserInitial));
  isLoggedIn$ = this.store.pipe(selectIsLoggedIn$);
  notLoggedIn$ = this.isLoggedIn$.pipe(
    map((isLoggedIn: boolean) => !isLoggedIn)
  );
  stageName$ = this.store.pipe(select(selectUserName));
  deviceIsSmallScreen$ = this.store.pipe(select(selectDeviceIsSmallScreen));
  deviceNotIsSmallScreen$ = this.deviceIsSmallScreen$.pipe(
    map((isSmall: boolean) => !isSmall)
  );
  showReminder$: Subject<boolean>;
  showPromo$: Subject<boolean>;
  sidenavOpened$: BehaviorSubject<boolean>;
  selectActiveSubscriptionsUIDisplay$ = createSelector(
    selectActiveSubscriptions,
    selectAllInvoices,
    (
      subs: Subscription[],
      invoices: Invoice[]
    ): SubscriptionUIdisplay | null => {
      return subs.reduce((a: SubscriptionUIdisplay | null, s: Subscription) => {
       // console.log('selectActiveSubscriptionsUIDisplay');
        if (!a) {
          var result = <SubscriptionUIdisplay>{
            subscription: s,
            subscriptionID: s.id,
            hasCoupon: false,
            coupon: undefined,
            items: s.items
  
              .map((i: SubscriptionItem) => {
                return <SubscriptionUIItem>{
                  itemId: i.id,
                  itemPriceProductName: i.price.product.name,
                  itemPriceUnit_amount: 0,
                  itemPriceCurrency: i.price.currency,
                  itemPlanInterval: i.plan.interval,
                  itemPriceProductMetadataAccountRole:
                    i.price.product.metadata.accountRole
                };
              })
          };
          console.log(result);
          return result;
        }

        return a;
      }, null);
    }
  );

  activeLink: string | null = null;
  private _onDestroy$: Subject<boolean> = new Subject();
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    public payment: StripePaymentSelectionService,
    private svgLoaderService: SvgLoaderService,
    private updates: SwUpdate,
    private presence: PresenceService,
    private authLogoutWatch: AuthLogoutWatchService,
    private cd: ChangeDetectorRef,
    private deviceDetection: DeviceDetectionService,
    @Inject(ENVIRONMENT) public environment: IEnvironmentState
  ) {
    this.showReminder$ = new Subject<boolean>();
    this.showPromo$ = new Subject<boolean>();
    this.sidenavOpened$ = new BehaviorSubject<boolean>(false);
    this.svgLoaderService.addSvgIconSet();
    this.deviceDetection.registerSmall();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        if (event.url !== "/login") this.init();
        // Hide progress spinner or progress bar
        this.currentRoute$.next(event.url);
        console.log(event);
      }
    });

  }
  navigate(routeStoreName: string) {
    if (routeStoreName === 'home') {
      this.activeLink = null;
      this.cd.detectChanges();
    }

    this.router.navigate([routeStoreName]).then(() => {
      /* noop */
    });
  }

  logout() {
    this.loggedIn = false;
    this.store.dispatch(logout());
  }
  onClickPromo(isLoggedIn: boolean) {
    let personal_monthly = this.environment?.production == false ? 'price_1IZmBNFr7h7OltJtiT03hVUf' : 'price_1L7zVXFr7h7OltJt6dwDoVEr';

    if (isLoggedIn) {
      this.payment.signEarlyAdopterAgreement(true);
      this.payment.updatePrice(personal_monthly);
      this.payment.updateStorageQty(0);
      this.payment.onCheckout(true);
    } else {
      this.navigate('signup-with-promo');
    }
  }
  onClickRenew(isLoggedIn: boolean) {
    let personal_monthly = this.environment?.production == false ? 'price_1IZmBNFr7h7OltJtiT03hVUf' : 'price_1L7zVXFr7h7OltJt6dwDoVEr';

    if (isLoggedIn) {
      this.payment.signEarlyAdopterAgreement(true);
      this.payment.updatePrice(personal_monthly);
      this.payment.updateStorageQty(0);
      this.payment.onCheckout(false);
    } else {
      this.navigate('subscribe');
    }
  }
  init() {
  if(this.loading === true)return;
  this.loading = true; 

    setTimeout(() => {
      this.currentRoute$.next(this.router.url);
      this.store
        .pipe(
          select(this.selectActiveSubscriptionsUIDisplay$),
          distinctUntilChanged(),
          takeUntil(this._onDestroy$)
        )
        .subscribe((result: any) => {
         console.log('selectActiveSubscriptionsUIDisplay | ' + result);
         console.log(result);
          if (this.loggedIn == true) {
            if (result == null) {
            console.log("RESULT IS NULL")
              this.showPromo$.next(true);
              this.showReminder$.next(false);
            this.cd.detectChanges();
               this.loading = false; 
         
              return;
            }
            console.log("RESULT IS NOT NULL")

            var hasActiveSub = result?.subscription?.ended_at == null;
            console.log("ACTIVE SUB ? "+hasActiveSub);
            this.showReminder$.next(hasActiveSub === false);

            this.cd.detectChanges();
          }
          else if (this.loggedIn == false) {
            console.log("NOT LOGGED IN YET")

            this.showReminder$.next(false);
            this.showPromo$.next(true);
            this.cd.detectChanges();
            this.loading = false;
          }
        });
    }, 1000);
    this.store
      .pipe(
        select(getState),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((account: AccountState | null) => {
        if (this.loaded) return;
        console.log('AccountState | ');
        console.log(account);
        this.loaded = account?.isRetrievedFromFirestore == true;
        this.loggedIn = account?.isLoggedIn == true;
        this.cd.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.presence.init();
    this.payment.init(() => {
      this.init();
    });
  }
  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
