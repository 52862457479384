import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpGettingStartedCollaborationContentComponent} from './help-getting-started-collaboration-content/help-getting-started-collaboration-content.component';
import {HelpGettingStartedCollaborationComponent} from './help-getting-started-collaboration.component';

@NgModule({
  declarations: [
    HelpGettingStartedCollaborationComponent,
    HelpGettingStartedCollaborationContentComponent
  ],
  exports: [
    HelpGettingStartedCollaborationComponent,
    HelpGettingStartedCollaborationContentComponent
  ],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpGettingStartedCollaborationModule {}
