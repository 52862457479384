import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'spt-help-content-body',
  templateUrl: './help-content-body.component.html',
  styleUrls: ['./help-content-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpContentBodyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
