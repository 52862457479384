import {
  EntityType,
  ProjectEntity,
  SongEntity,
  TreeConfig
} from '@spout/global-any/models';
import {
  AccountState,
  applyInitialAuthor,
  createAuthorMusician
} from '@spout/global-web/models';
import {createInitialConfig} from './create-initial-config';

export function createInitialSongEntity(
  account: AccountState,
  project: Partial<ProjectEntity>
): SongEntity {
  const initialConfig: Partial<TreeConfig> = createInitialConfig();

  const config = {
    ...initialConfig,
    entityType: EntityType.SONG,
    createdBy: null,

    owners: null,
    projectId: project.id
  };

  return applyInitialAuthor<any, SongEntity>(
    config,
    createAuthorMusician(account)
  );
}

export function setSongEntityProps(
  song: SongEntity,
  props: Partial<SongEntity>
): SongEntity {
  return {
    ...song,
    ...props
  };
}
