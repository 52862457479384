import {PriceInterval} from '../../+prices';
import {PriceByIntervalDict, Product} from '../../+product';
import {ProductWizardStore} from '../../+subscriptions';

export function updateStoragePrice(
  state: ProductWizardStore,
  productWithPrice: {
    price: PriceByIntervalDict;
    product: Product;
    interval: PriceInterval;
    storage: PriceByIntervalDict;
  }
) {
  return {
    ...state,
    // selectedProduct: productWithPrice.storageProduct,
    storageProduct: productWithPrice.product,
    baseMonthlyStoragePrice: productWithPrice.price[productWithPrice.interval],
    interval: productWithPrice.interval
  };
}
