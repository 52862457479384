import {
  AudioFileMetaData,
  FileUint8ArrayType
} from './entities/audio-file-meta-data';

export enum FileErrorCode {
  FILE_DOES_NOT_EXIST = 'FILE_DOES_NOT_EXIST'
}

export enum ElectronStatusCode {
  SUCCESS = 'success',
  ERROR = 'error'
}

export const ElectronErrorCodes = {
  NO_CACHE_DIRECTORY: 'NO_CACHE_DIRECTORY',
  CANNOT_CREATE_PROJECT_DIRECTORY: 'CANNOT_CREATE_PROJECT_DIRECTORY',
  ERROR_SAVING_FILE: 'ERROR_SAVING_FILE',
  FILE_DOES_NOT_EXIST: 'FILE_DOES_NOT_EXIST',
  FILE_NOT_UPLOADED: 'FILE_NOT_UPLOADED'
};

export interface WebWorkerBlobResponse {
  fileMetaData: AudioFileMetaData;
  arrayBuffer: ArrayBuffer;
}

// export interface ElectronAudioResponse {
//   config: FileConfig;
//   arrayBuffer?: ArrayBuffer;
// }

export interface ElectronFileError {
  status: string;
  fileMetaData: AudioFileMetaData;
  error: {
    code: string;
    message: string;
    error: any;
  };
}

export interface ElectronNativeAudioResponse {
  audioMetaData: AudioFileMetaData;
  uint8ArrayType: FileUint8ArrayType;
  error?: ElectronFileError;
}

export interface ElectronNativeAudio {
  account: string;
  audioFileMetaData: AudioFileMetaData;
  uint8ArrayType?: FileUint8ArrayType;
  error?: ElectronFileError;
}
