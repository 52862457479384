import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Inject, Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {createBlobFromUint8Array} from '@spout/global-any/fns';
import {AudioFileMetaDataEntity} from '@spout/global-any/models';
import {EndpointModels, ENDPOINTS} from '@spout/global-web/models';
import {UploadTask} from '@firebase/storage';
import {forkJoin, Observable, Observer, of, Subject} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {updateAudioFileMetaDataEntityEffect} from '../+audio-file-meta-data/audio-meta-data.actions';
import {createUploadErrorNotification} from '../+notifications/helpers/notification.creators';
import {upsertNotification} from '../+notifications/notifications.actions';
import {openNotificationRightDrawer} from '../+right-drawer/mixer-right-drawer.actions';
import {CustomFirestoreService} from '../firebase';
import {getAudioStorageFilePath} from './proxy-service/proxy-form-data';
import {
  SpoutProxyServerServiceB2UploadParams,
  SpoutProxyServerServiceB2UploadResponse,
  UploadStatusSpoutProxyServerServiceB2UploadResponse
} from './proxy-service/proxy.models';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {
  constructor(
    private client: HttpClient,
    private _firestore: CustomFirestoreService,
    private store: Store,
    private zone: NgZone,
    @Inject(ENDPOINTS) public endpoints: EndpointModels
  ) {}

  /**
   * https://firebase.google.com/docs/storage/web/upload-files
   * @param files
   * @param trackName
   */
  uploadStorage(
    files: Set<SpoutProxyServerServiceB2UploadParams>,
    trackName: string | undefined
  ): {
    [
      key: string
    ]: Observable<UploadStatusSpoutProxyServerServiceB2UploadResponse>;
  } {
    const that = this;

    // this will be the  resulting map
    const status: {
      [
        key: string
      ]: Observable<UploadStatusSpoutProxyServerServiceB2UploadResponse>;
    } = {};

    files.forEach(file => {
      // Create the file metadata
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      //
      /** @type {any} */
      const metadata = {
        contentType: 'audio/wav'
      };

      // create a new progress-subject for every file
      const uploadTask: UploadTask = this._firestore.uploadBytesResumable(
        getAudioStorageFilePath(file),
        createBlobFromUint8Array(file.file, file.type)
      );

      // console.log(getAudioStorageFilePath(file));

      // console.log(uploadTask);

      // create a new progress-subject for every file
      const progress =
        new Subject<UploadStatusSpoutProxyServerServiceB2UploadResponse>();

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        snapshot => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const percentProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // console.log('Upload is ' + progress + '% done');

          if (percentProgress < 100) {
            // pass the percentage into the progress-stream
            progress.next({
              progress: percentProgress,
              result: null
            });
          }

          // switch (snapshot.state) {
          //   case 'paused':
          //     console.log('Upload is paused');
          //     break;
          //   case 'running':
          //     console.log('Upload is running');
          //     break;
          // }
        },
        error => {
          that.zone.run(() => {
            that.store.dispatch(
              upsertNotification({
                notification: createUploadErrorNotification(
                  `The ${trackName ? trackName : ''} track failed to upload.`
                )
              })
            );

            that.store.dispatch(openNotificationRightDrawer());
          });

          progress.error(error);

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //   console.log('File available at', downloadURL);
          // });

          progress.next({
            progress: 100,
            result: <SpoutProxyServerServiceB2UploadResponse>{
              type: file.type,
              projectId: file.projectId,
              id: file.id,
              fileUploaded: true,
              storageLocation: 'google'
            }
          });

          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          setTimeout(() => {
            progress.complete();
          }, 250);
        }
      );

      // Save every progress-observable in a map of all observables
      status[file.id] = progress;
    });

    // return the map of progress.observables
    return status;
  }

  deleteAudioMetaDataFilesFromStorage(
    files: AudioFileMetaDataEntity[]
  ): Observable<any> {
    const requests$ = files.map((file: AudioFileMetaDataEntity) => {
      if (!file) {
        return of(true);
      }

      return this._firestore
        .deleteStorageFile(getAudioStorageFilePath(file))
        .pipe(catchError(() => of(true)));
    });

    return forkJoin(requests$);
  }

  downloadStorage(file: AudioFileMetaDataEntity) {
    const that = this;

    const downloadPath = getAudioStorageFilePath(file);

    return new Observable(
      (observer: Observer<{progress: number; result: Blob | null}>) => {
        that._firestore
          .getDownloadURL(downloadPath)
          .then((downloadURL: string) => {
            // BEGIN DOWNLOAD
            const req = new HttpRequest('GET', downloadURL, {
              headers: new HttpHeaders({
                Accept: 'application/octet-stream'
                // 'ngsw-bypass': 'true'
              }),
              reportProgress: true,
              responseType: 'blob'
            });

            this.client
              .request(req)
              .pipe(retry(3))
              .subscribe(
                (event: HttpProgressEvent | HttpEvent<unknown> | any) => {
                  if (
                    event &&
                    event.total !== undefined &&
                    event.type === HttpEventType.DownloadProgress
                  ) {
                    // calculate the progress percentage
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const progress = Math.round(
                      (100 * event.loaded) / event.total
                    );

                    observer.next({
                      progress,
                      result: null
                    });
                  } else if (event instanceof HttpResponse) {
                    // console.log(event.body);
                    // event.body is a blob
                    if (file.fileSize === undefined || file.fileSize === null) {
                      that.store.dispatch(
                        updateAudioFileMetaDataEntityEffect({
                          projectId: file.projectId,
                          file: {
                            id: file.id,
                            changes: {
                              fileSize: (<Blob>event.body).size
                            }
                          }
                        })
                      );
                    }

                    observer.next({
                      progress: 100,
                      result: <Blob>event.body
                    });
                  }
                }
              );

            // END DOWNLOAD
          })
          .catch(error => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
          });
      }
    );
  }
}
