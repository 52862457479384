import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {
  monthlyPriceEntityByRole,
  productEntityByRole,
  selectStorageAddonPriceMonthly
} from './product.fns';
import * as ProductReducer from './product.reducer';
import {ProductState} from './product.reducer';
import {Dictionary} from '@ngrx/entity/src/models';
import {
  Price,
  PriceEntities,
  Product,
  PRODUCT_FEATURE_LIST,
  ProductEntities,
  ProductWithPrices
} from './product.model';
import {selectAccountState} from '../../+account/account.selectors';
import {
  selectHasActiveSubscription,
  selectTrialDays,
  TrialParams
} from '../+subscriptions';
import {PromoCode, selectPromoCodeEntities} from '../+promo-codes';
import {pipe} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {selectAllPrices, selectPriceEntities} from '../+prices';
import {AccountState} from '@spout/global-web/models';

export const oneDay = 1000 * 60 * 60 * 24;

export const selectProductState =
  createFeatureSelector<ProductReducer.ProductState>(
    ProductReducer.productFeatureKey
  );

const {selectIds, selectEntities, selectAll, selectTotal} =
  ProductReducer.productAdapter.getSelectors();

export const selectAllProducts = createSelector(
  selectProductState,
  (state: ProductState) => selectAll(state)
);
export const selectProductEntities = createSelector(
  selectProductState,
  (state: ProductState) => selectEntities(state)
);
export const selectProductIds = createSelector(
  selectProductState,
  (state: ProductState) => selectIds(state)
);
export const selectProductTotal = createSelector(
  selectProductState,
  (state: ProductState) => selectTotal(state)
);
export const selectedProductID = createSelector(
  selectProductState,
  (state: ProductReducer.ProductState) => state.selectedProductID
);

export const getProductByID = (id: string) =>
  createSelector(selectProductEntities, (entities: Dictionary<Product>) => {
    return entities[id];
  });

export const selectProductsWiPrices = createSelector(
  selectAllProducts,
  selectAllPrices,
  (products: Product[], prices: Price[]): ProductWithPrices[] => {
    return products.map((product: Product) => {
      return {
        product,
        prices: prices.filter((price: Price) => price.productID === product.id)
      };
    });
  }
);

export const selectEarlyAdopterPlanWithPrices = createSelector(
  selectProductsWiPrices,
  (p: ProductWithPrices[]): ProductWithPrices | null =>
    p
      .filter(
        (_p: ProductWithPrices) =>
          _p.product.metadata.featureList === PRODUCT_FEATURE_LIST.EARLY_ADOPTER
      )
      .reduce(
        (p: ProductWithPrices | null, i: ProductWithPrices | undefined) =>
          p === null ? (i !== undefined ? i : null) : null,
        null
      )
);

export const selectProductByRoleDict = createSelector(
  selectProductEntities,
  (entities: Dictionary<Product>): ProductEntities => {
    return productEntityByRole(entities);
  }
);

export const selectPriceByMonthDict = createSelector(
  selectProductEntities,
  selectPriceEntities,
  (
    productEntities: Dictionary<Product>,
    priceEntities: Dictionary<Price>
  ): PriceEntities => {
    return monthlyPriceEntityByRole(priceEntities, productEntities, 'month');
  }
);

export const selectPriceByYearDict = createSelector(
  selectProductEntities,
  selectPriceEntities,
  (
    productEntities: Dictionary<Product>,
    priceEntities: Dictionary<Price>
  ): PriceEntities => {
    return monthlyPriceEntityByRole(priceEntities, productEntities, 'year');
  }
);

export const selectStorageAddonMonthly = createSelector(
  selectProductEntities,
  selectPriceEntities,
  (
    productEntities: Dictionary<Product>,
    priceEntities: Dictionary<Price>
  ): Price | null => {
    return selectStorageAddonPriceMonthly(productEntities, priceEntities);
  }
);
