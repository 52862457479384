import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FirebaseConfig} from '@spout/global-any/secrets';
import {IEnvironmentState} from '@spout/global-web/models';

export const environmentFeatureKey = 'environment';

export const getEnvironmentState = createFeatureSelector<IEnvironmentState>(
  environmentFeatureKey
);

export const getFirebaseProject = createSelector(
  getEnvironmentState,
  (state: IEnvironmentState) => state.firebase
);

export const getFirebaseProjectId = createSelector(
  getFirebaseProject,
  (firebase: FirebaseConfig | null) => {
    if (firebase) {
      return firebase.projectId;
    }

    return '';
  }
);
