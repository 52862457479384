import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {
  AudioFileMetaDataEntity,
  MixEntity,
  ProjectEntity,
  TrackEntity,
  TrackMix
} from '@spout/global-any/models';

import {
  createInitialAudioFileMetaDataEntity,
  createInitialMixEntityWithSong,
  createInitialSongEntity,
  createInitialTrackEntity,
  createInitialTrackMixConfig,
  setSongEntityProps,
  setTrackMixProps
} from '@spout/global-web/fns';
import {
  AccountState,
  ChatEntity,
  getAddSongRoute
} from '@spout/global-web/models';
import {assignMethod, objectMethodAssign} from '@uiux/fn';
import {combineLatest, concat, EMPTY, from, Observable, of} from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {selectAccountState} from '../+account/account.selectors';
import {getAudioMetaDataFilesByIDs} from '../+audio-file-meta-data/audio-metadata-storage.selectors';
import {getAllChatsBySongId} from '../+chat/chat.selectors';
import {ChatService} from '../+chat/chat.service';
import {setDeviceStoreCurrentIdsFromTrackAndMixEntity} from '../+device-storage/device-storage.actions';
import {AudioFileDeleteService} from '../+device-storage/services/audio-file-delete.service';
import {getMixEntitiesBySongIdPassThrough} from '../+mixes/mix-storage.selectors';
import {MixesService} from '../+mixes/mixes.service';
import {
  getProjectEntityById,
  selectCurrentProjectId
} from '../+project/project-storage.selectors';
import {selectedProjectEntity} from '../+project/project.actions';
import {closeAndNavigate} from '../+router/router.actions';
import {getAllTrackMixesBySongId} from '../+track-mix/track-mix.selectors';
import {TrackMixesService} from '../+track-mix/track-mixes.service';
import {FirestoreTracksService} from '../+tracks/firestore-tracks.service';
import {getAllTracksBySongIdFn} from '../+tracks/track-storage.selectors';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';
import {
  addSongToProjectAction,
  createProjectAction,
  createSongAction
} from '../actions/create-entites.actions';
import {SptPlayerCacheService} from '../audio/services/spt-player-cache.service';
import {masterFullRewindEffect} from '../audio/spt-tone-transport-control.actions';
import {CustomFirestoreService} from '../firebase';
import {getProjectTrackAndMixBySongId} from '../selectors/composite-storage.selectors';
import {FirebaseStorageService} from '../services/firebase-storage.service';
import {releaseStoreToSelect} from '../services/release-store';
import {
  addSongFromSidenav,
  deleteSongEffect,
  selectSongEffect,
  updateSong
} from './song.actions';
import {SongService} from './song.service';

@Injectable({
  providedIn: 'root'
})
export class SongsEffects {
  addSongToProjectAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSongToProjectAction),
      mergeMap(action => {
        return this.store.pipe(
          select(selectAccountState),
          take(1),
          map((account: AccountState) => {
            releaseStoreToSelect();

            let songEntity = createInitialSongEntity(account, action.project);

            songEntity = setSongEntityProps(songEntity, {
              name: action.songName,
              description: action.songDescription
            });

            const trackEntity: TrackEntity = createInitialTrackEntity(
              account,
              songEntity
            );
            trackEntity.name = `Audio Master`;
            trackEntity.description = `for ${action.songName}`;
            trackEntity.isDefault = true;

            const audioFileEntity = createInitialAudioFileMetaDataEntity(
              account,
              trackEntity
            );
            audioFileEntity.name = 'Audio Master';

            const mix = createInitialMixEntityWithSong(account, songEntity);
            mix.name = `Mix`;
            mix.description = `for ${action.songName}`;
            mix.isDefault = true;

            const trackMix = objectMethodAssign<TrackMix>(
              createInitialTrackMixConfig(
                account,
                trackEntity,
                mix.id,
                audioFileEntity
              )
            ).pipe(
              assignMethod<TrackMix>(setTrackMixProps, {isReference: true})
            );

            return {
              songEntity,
              trackEntity,
              audioFileEntity,
              mixEntity: mix,
              trackMix
            };
          }),
          switchMap(d => {
            return this.playerCache.muteAllPipe$().pipe(map(() => d));
          }),

          switchMap(d => {
            return this.playerCache.disconnectAll$().pipe(map(() => d));
          }),

          take(1),
          switchMap(
            ({
              songEntity,
              trackEntity,
              audioFileEntity,
              mixEntity,
              trackMix
            }) => {
              return concat([
                masterFullRewindEffect(),
                createSongAction({
                  song: songEntity,
                  track: trackEntity,
                  mix: mixEntity,
                  file: audioFileEntity,
                  trackMix
                }),
                setDeviceStoreCurrentIdsFromTrackAndMixEntity({
                  track: trackEntity,
                  mix: mixEntity
                })
              ]);
            }
          )
        );
      })
    )
  );

  createDefaultSongEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          createDefaultConfigsToFirestore,
          createSongAction,
          createProjectAction
        ),
        switchMap(action => {
          if (action.song) {
            return this.songService.createSong(action.song);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  updateSongEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateSong),
        mergeMap(action => {
          return this.songService.updateSong(action.song.changes);
        })
      ),
    {dispatch: false}
  );

  selectSongEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectSongEffect),
      switchMap(action => {
        return this.playerCache.disconnectAll$().pipe(map(() => action));
      }),
      switchMap(action => {
        return this.store.pipe(
          select(getProjectEntityById(action.song.projectId)),
          take(1),
          switchMap((project: ProjectEntity | undefined) => {
            return this.store.pipe(
              select(getProjectTrackAndMixBySongId({song: action.song})),
              take(1),

              tap(
                (
                  r: {
                    project: ProjectEntity | undefined;
                    track: TrackEntity | undefined;
                    mix: MixEntity | undefined;
                  } | null
                ) => {
                  releaseStoreToSelect();
                }
              ),
              concatMap(
                (
                  r: {
                    project: ProjectEntity | undefined;
                    track: TrackEntity | undefined;
                    mix: MixEntity | undefined;
                  } | null
                ) => {
                  if (r && r.track && r.track.id && r.mix && project) {
                    return from([
                      masterFullRewindEffect(),
                      setDeviceStoreCurrentIdsFromTrackAndMixEntity(
                        r as {track: TrackEntity; mix: MixEntity}
                      ),
                      selectedProjectEntity({projectEntity: project})
                    ]);
                  }
                  return EMPTY;
                }
              )
            );
          })
        );

        // end
      })
    )
  );

  addSongFromSidenav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSongFromSidenav),
      withLatestFrom(this.store.pipe(select(selectCurrentProjectId))),
      switchMap(([_action, projectId]): Observable<Action> => {
        if (projectId) {
          return of(closeAndNavigate(getAddSongRoute(projectId)));
        }
        return EMPTY;
      })
    )
  );

  deleteSong$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteSongEffect),
        switchMap(action =>
          combineLatest([
            this.store.pipe(select(getAllTrackMixesBySongId(action.song.id))),
            this.store.pipe(
              select(getAllTracksBySongIdFn({songId: action.song.id}))
            ),
            this.store.pipe(
              select(
                getMixEntitiesBySongIdPassThrough({songId: action.song.id})
              )
            ),
            this.store.pipe(select(getAllChatsBySongId(action.song.id)))
          ]).pipe(
            take(1),
            switchMap(
              ([trackMixes, tracks, mixes, chats]: [
                TrackMix[],
                TrackEntity[],
                MixEntity[],
                ChatEntity[]
              ]) => {
                const audioFileMetaDataIds: string[] = trackMixes
                  .map((t: TrackMix) => Object.keys(t.audioSnippets))
                  // @ts-ignore
                  .flat();

                return this.store.pipe(
                  select(getAudioMetaDataFilesByIDs(audioFileMetaDataIds)),
                  switchMap((files: AudioFileMetaDataEntity[]) => {
                    this.playerCache.deleteTracks(
                      tracks.map((t: TrackEntity) => t.id)
                    );

                    return combineLatest([
                      // files
                      this.fileDeleteService.deleteFilesFromFileSystem(files),
                      this._storage
                        .deleteAudioMetaDataFilesFromStorage(files)
                        .pipe(catchError(() => of(true))),

                      // track mixes
                      this._trackMixService
                        .batchDeleteFirestoreTrackMixes(trackMixes)
                        .pipe(catchError(() => of(true))),

                      // chats
                      this._chatService
                        .deleteChats(chats)
                        .pipe(catchError(() => of(true))),

                      // tracks
                      this._trackService
                        .deleteTracks(tracks)
                        .pipe(catchError(() => of(true))),

                      // mixes
                      this._mixService
                        .deleteMixes(mixes)
                        .pipe(catchError(() => of(true))),

                      // delete song
                      this.songService
                        .deleteSong(action.song)
                        .pipe(catchError(() => of(true)))
                    ]).pipe(
                      map(() => {
                        console.log('done');
                      })
                    );
                  })
                );
              }
            )
          )
        )
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private sptFirestore: CustomFirestoreService,
    private songService: SongService,
    private store: Store,
    private playerCache: SptPlayerCacheService,
    private fileDeleteService: AudioFileDeleteService,
    private _storage: FirebaseStorageService,
    private _trackMixService: TrackMixesService,
    private _chatService: ChatService,
    private _trackService: FirestoreTracksService,
    private _mixService: MixesService
  ) {
    // console.log('LOGIN SONG EFFECTS');
  }
}
