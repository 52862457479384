import {MediaMatcher} from '@angular/cdk/layout';
import {Inject, Injectable} from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  OnInitEffects,
  ROOT_EFFECTS_INIT
} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {SptSystemInformation} from '@spout/global-any/models';
import {DeviceDetectionService} from './device-detection.service';

import {WINDOW} from '@spout/global-web/utils';
import {map, switchMap} from 'rxjs/operators';
import {UAParser} from 'ua-parser-js';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {
  detectDevices,
  deviceLoadDesktopInformation,
  deviceLoaded
} from './device.actions';

@Injectable({providedIn: 'root'})
export class DeviceDetectionEffects implements OnInitEffects {
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => {
        return this.deviceStorageService.getSystemInformation().pipe(
          map((desktopInformation: SptSystemInformation) => {
            const ua = new UAParser(navigator.userAgent);
            const regex = /Electron\/(\d+\.)?(\d+\.)?(\*|\d+)/gm;
            const electronUserAgent = regex.exec(navigator.userAgent);
            const electronVersion =
              electronUserAgent && electronUserAgent.length
                ? electronUserAgent[0]
                : '';
            return deviceLoadDesktopInformation({
              electron: electronVersion.replace('Electron/', ''),
              useragent: ua.getResult(),
              desktopInformation
            });
          })
        );
      })
    );
  });

  detectDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(detectDevices),
      map((action: Action) => {
        return deviceLoaded({
          payload: this.deviceDetectionService.getDeviceSpecs()
        });
      })
    );
  });

  constructor(
    private store: Store,
    public deviceStorageService: DeviceStorageService,
    private deviceDetectionService: DeviceDetectionService,
    private actions$: Actions,
    private mediaMatcher: MediaMatcher,
    @Inject(WINDOW) private window: Window
  ) {}

  ngrxOnInitEffects(): Action {
    return detectDevices();
  }
}
