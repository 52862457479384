import {TrackMix, TrackMixAudioSnippet} from '@spout/global-any/models';
import {LoadPlayerAudioWorklet} from '@spout/global-web/models';
import {getDefaultSnippet} from '../entities/get-snippets';

export function updateTrackMixAudio(
  trackMix: TrackMix,
  parsedRecordExport: LoadPlayerAudioWorklet
): TrackMix {
  const defaultSnippet: TrackMixAudioSnippet | null = getDefaultSnippet(
    trackMix.audioSnippets
  );

  if (defaultSnippet) {
    const _trackMix = {
      ...trackMix,
      audioSnippets: {
        ...trackMix.audioSnippets
      }
    };

    // Save Audio Snippets
    // TODO AUDIO SNIPPET
    _trackMix.audioSnippets[defaultSnippet.audioFileMetaDataEntityId] = <
      TrackMixAudioSnippet
    >{
      ...trackMix.audioSnippets[defaultSnippet.audioFileMetaDataEntityId],
      ...defaultSnippet,
      audioDuration: parsedRecordExport.waveformValues.audioDuration,
      // audioFileMetaDataEntity: action.trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity,
      start: 0,
      offsetMs: parsedRecordExport.recordOffsetMs,
      sampleRate:
        parsedRecordExport.trackEntityAndAudioFileMetaDataEntity
          .audioFileMetaDataEntity.sampleRate,
      stop: parsedRecordExport.waveformValues.audioDuration
    };

    return _trackMix;
  }

  return trackMix;
}
