import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {TooltipModule} from 'ng2-tooltip-directive';
import {HelpCompressorModule} from '../content/help-compressor/help-compressor.module';
import {HelpGainModule} from '../content/help-gain/help-gain.module';
import {HelpRecordLatencyModule} from '../content/help-record-latency/help-record-latency.module';
import {HelpSelectMicrophoneModule} from '../content/help-select-microphone/help-select-microphone.module';
import {HelpTooltipComponent} from './help-tooltip.component';

@NgModule({
  declarations: [HelpTooltipComponent],
  exports: [HelpTooltipComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    OverlayModule,

    HelpSelectMicrophoneModule,
    HelpGainModule,
    HelpCompressorModule,
    HelpRecordLatencyModule,
    TooltipModule
  ]
})
export class SptHelpTooltipModule {}
