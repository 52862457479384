import {Location} from '@angular/common';
import {Inject, Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {SptSystemInformation} from '@spout/global-any/models';
import {
  getDesktopSerialNumber,
  localCacheKey_sha256L40$
} from '@spout/global-web/fns';
import {
  AccountState,
  ENVIRONMENT,
  IEnvironmentState,
  UserAccount
} from '@spout/global-web/models';
import {User} from '@firebase/auth';
import {EMPTY, of} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {ConsoleStore} from '../+console/console.store';
import {getDesktopInformation} from '../+device-detection/device-detection.selectors';
import {
  ga_studioAppActive,
  ga_studioAppInActive,
  marketingAppActive,
  marketingAppInActive
} from '../+google-analytics/actions';
import {GAEventItem} from '../+google-analytics/analytics';
import {serviceDoDisconnectAction} from '../+websocket-registry/websocket-registry.actions';
import {
  selectAllDisconnectedFn,
  selectDoConnect
} from '../+websocket-registry/websocket-registry.selectors';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';
import {CustomFirestoreService} from '../firebase';
import {
  accountLoadedFromAuthStateChange,
  accountSaveFirebase
} from './account.actions';
import {
  addMissingUserAccountProperties,
  createAccountStateFromFirestore,
  createFirestoreUserAccountFromAuth,
  hasAllUserAccountProperties
} from './account.fns';
import {selectAccountState} from './account.selectors';
import {AccountService} from './account.service';
import {AuthLogoutWatchService} from './auth-logout-watch.service';
import {authError, authLoggedOut, logout} from './auth.actions';

@Injectable({providedIn: 'root'})
export class AccountEffects {
  saveAccountToFirebase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(accountSaveFirebase),
        map(action => {
          this._accountService.saveToFirebase(action.payload);
        })
      ),
    {dispatch: false}
  );

  createDefaultConfigsToFirestore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createDefaultConfigsToFirestore),
        switchMap(action => {
          if (action.project && action.song && action.mix && action.track) {
            const payload: Partial<AccountState> = {
              defaultProjectId: action.project.id,
              defaultSongId: action.song.id,
              defaultMixId: action.mix.id,
              defaultTrackId: action.track.id
            };

            this._accountService.saveToFirebase(payload);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  createAccountFromAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(accountLoadedFromAuthStateChange), // getDesktopInformation
        switchMap(action => {
          return this.store.pipe(
            select(getDesktopInformation),
            map((desktopInformation: SptSystemInformation) => {
              // console.log(desktopInformation);

              return {
                desktopInformation,
                serial: getDesktopSerialNumber(desktopInformation)
              };
            }),
            filter(({serial}) => serial !== null),
            take(1),
            switchMap(
              ({
                desktopInformation,
                serial
              }: {
                desktopInformation: SptSystemInformation;
                serial: string;
              }) => {
                return this._accountService.saveMusicianDeviceSystemInformation(
                  action.payload,
                  desktopInformation,
                  serial
                );
              }
            )
          );
        })
      ),
    {dispatch: false}
  );

  // Use Case: Only to indicate when logging out
  $logout = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),

      // Tell all WebSockets to disconnect
      tap(() => {
        this.zone.run(() => {
          this.store.dispatch(serviceDoDisconnectAction());
        });

        this.localStorage.removeItem('redirect').subscribe(() => {
          /* noop */
        });
      }),

      // Listen for when all WebSockets are disconnected
      mergeMap(() =>
        this.store.pipe(
          select(selectAllDisconnectedFn()),
          // tap(allDisconnected => {
          //   console.log(`selectAllDisconnected -> ${allDisconnected} `);
          // }),
          filter((allDisconnected: boolean) => allDisconnected),
          distinctUntilChanged(),
          take(1),
          // Sign out of app
          switchMap(() => {
            return this.authLogoutWatch.logoutFirebase$();
          }),

          tap(() => {
            if ((<any>window).FB && (<any>window).FB.logout) {
              try {
                // (<any>window).FB.logout(function (response: any) {
                // });
              } catch (e) {
                console.error(e);
              }
            }
          }),

          // Update Store when signed out
          map((r: any) => {
            this.consoleStore.authLoggedOut();
            return authLoggedOut();
          }),

          catchError((r: any) => {
            return of(authError({payload: {code: r.code, message: r.message}}));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private _accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private locationService: Location,
    private store: Store,
    private localStorage: LocalStorage,
    public consoleStore: ConsoleStore,
    private authLogoutWatch: AuthLogoutWatchService,
    // @Inject(ApiOptionsToken) private options: ApiOptions,
    // libs/mixer-browser-desktop/feature-app/src/lib/api-options.ts
    // @Inject(LOGOUT_REDIRECT) private logoutRedirect: string,
    private _firestore: CustomFirestoreService,
    private zone: NgZone,
    @Inject(ENVIRONMENT) private environment: IEnvironmentState
  ) {
    const that = this;

    this._firestore.user$
      .pipe(
        mergeMap((user: User) => {
          const userAccount: UserAccount =
            createFirestoreUserAccountFromAuth(user);

          return this._accountService.createAccountIfNotExist(userAccount).pipe(
            switchMap((f: UserAccount) => {
              const hasAllProperties = hasAllUserAccountProperties(f);

              if (!hasAllProperties) {
                return this._accountService.updateUserAccount$(
                  addMissingUserAccountProperties(f)
                );
              }

              return of(f);
            }),
            switchMap((f: UserAccount) => {
              return localCacheKey_sha256L40$(<string>f.uid).pipe(
                map((uidHash: string) => {
                  return <AccountState>{
                    ...createAccountStateFromFirestore(
                      f,
                      this.environment.production,
                      uidHash,
                      user
                    )
                  };
                })
              );
            })
          );
        })
      )
      .subscribe((account: AccountState) => {
        that.zone.run(() => {
          that.store.dispatch(
            accountLoadedFromAuthStateChange({
              payload: account
            })
          );
        });
      });

    // analytics
    this.store
      .pipe(
        select(selectAccountState),
        filter((account: AccountState) => account.isRetrievedFromFirestore),
        distinctUntilChanged((a: AccountState, b: AccountState) => {
          return (
            a.uid === b.uid &&
            a.email === b.email &&
            a.stageName === b.stageName
          );
        }),
        mergeMap((account: AccountState) =>
          this.store.pipe(
            select(selectDoConnect),
            distinctUntilChanged(),
            map((doConnect: boolean) => {
              return {
                doConnect,
                account
              };
            })
          )
        )
      )
      .subscribe(
        ({doConnect, account}: {doConnect: boolean; account: AccountState}) => {
          let action: Action & {event: GAEventItem};

          if (
            this.environment.appName === 'studio-app' ||
            this.environment.appName === 'dev-studio-app'
          ) {
            if (doConnect) {
              action = ga_studioAppActive();
            } else {
              action = ga_studioAppInActive();
            }
          } else {
            if (doConnect) {
              action = marketingAppActive();
            } else {
              action = marketingAppInActive();
            }
          }

          if (account.promoCode) {
            action.event.eventParams = {
              ...action.event.eventParams,
              promo_code: account.promoCode
            };
          }

          this.store.dispatch(action);
        }
      );
  }
}
