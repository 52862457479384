import {BreakpointObserver, MediaMatcher} from '@angular/cdk/layout';
import {Inject, Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppVersions} from '@spout/global-any/models';
import {isElectron} from '@spout/studio-web/utils';
import {
  BREAKPOINTS,
  DeviceDetectionState,
  SptBreakpointResult
} from '@spout/global-web/models';
import {WINDOW} from '@spout/global-web/utils';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import * as UAParser from 'ua-parser-js';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {DevicePartialState} from './device-detection.reducer';
import {
  deviceIsLandscape,
  deviceIsPortrait,
  deviceIsSmall,
  loadElectronVersion
} from './device.actions';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {
  private _onWindowResize: Subject<boolean>;
  resume: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(
    null
  );
  constructor(
    private breakpointObserver: BreakpointObserver,
    private mediaMatcher: MediaMatcher,
    private store: Store<DevicePartialState>,
    private deviceStorage: DeviceStorageService,
    private zone: NgZone,
    @Inject(WINDOW) private window: Window
  ) {
    this._onWindowResize = new Subject<boolean>();

    const that = this;
    this.window.addEventListener('resize', () => {
      that._onWindowResize.next(true);
    });

    this._onWindowResize.next(true);

    // if (isCordova(platformID())) {
    //   fromEvent(document, 'resume').subscribe((event) => {
    //     this.zone.run(() => {
    //       this.onResume();
    //     });
    //   });
    // }

    this.deviceStorage.getElectronBuild().subscribe((a: AppVersions) => {
      if (a && a.build && a.build.electronVersion) {
        that.zone.run(() => {
          that.store.dispatch(
            loadElectronVersion({electronVersion: a.build.electronVersion})
          );
        });
      }
    });

    this.breakpointObserver
      .observe(['(orientation: portrait)'])
      .subscribe(result => {
        that.zone.run(() => {
          if (result.breakpoints['(orientation: portrait)']) {
            that.store.dispatch(deviceIsPortrait());
          } else {
            that.store.dispatch(deviceIsLandscape());
          }
        });
      });
  }

  registerSmall() {
    const that = this;
    this._onWindowResize
      .pipe(
        debounceTime(100),
        map(() => {
          const isSmall = this.breakpointObserver.isMatched(BREAKPOINTS.SMALL);
          const isLarge = this.breakpointObserver.isMatched(BREAKPOINTS.LARGE);
          return {isSmall, isLarge};
        })
      )
      .subscribe((r: SptBreakpointResult) => {
        that.zone.run(() => {
          that.store.dispatch(deviceIsSmall({isSmall: r.isSmall}));
        });
      });

    this._onWindowResize.next(true);
  }

  openLinkInBrowser(url: string) {
    const that = this;
    // cordova plugin supporting SafariViewController has bugs
    // (<any>this.window).SafariViewController.isAvailable(function (available: boolean) {
    //   // if ( available ) {
    //   //   _window().SafariViewController.show({
    //   //     url: url,
    //   //     barColor: '#f7f7f9',
    //   //     tintColor: '#1ca8dd',
    //   //     controlTintColor: '#1ca8dd'
    //   //   });
    //   // } else {
    //   (<any>that.window).cordova.InAppBrowser.open(url, '_blank');
    //   // }
    // });
  }

  getDeviceSpecs(): Partial<DeviceDetectionState> {
    const parser: UAParser = new UAParser();

    return {
      isLoaded: true,
      isActive: true,
      // isIphoneNotch: isIphoneNotch(model()),
      // isCordova: isCordova(platformID()) && !isElectron(),
      // isCordova: _isMobile(),
      isElectron: isElectron(),
      isBrowser: !isElectron(),
      // isBrowser: _isBrowser(),
      isLandscape: this.mediaMatcher.matchMedia('(orientation: portrait)')
        .matches,
      isPortrait: this.mediaMatcher.matchMedia('(orientation: landscape)')
        .matches,
      isSmall: this.mediaMatcher.matchMedia(BREAKPOINTS.SMALL).matches,
      // cordova: this.window.cordova,
      // device: this.window.device,
      useragent: parser.getResult()
    };
  }
}
