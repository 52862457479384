import {Dictionary} from '@ngrx/entity';
import {
  initialSptMediaTrackConstraints,
  SptMediaTrackConstraintSet
} from './audio/media-track-constraints';
import {AudioFileMetaData} from './entities/audio-file-meta-data';
import {MixEntity} from './entities/mix-entity.model';
import {ProjectEntity} from './entities/project-entity.model';
import {SongEntity} from './entities/song-entity.model';
import {TrackEntity} from './entities/track-entity.model';
import {SavedLatency} from './latency.model';
import {appVersions} from './spout-version/spout-version';
import {
  BuildVersionElectron,
  EnvPlatform
} from './spout-version/spout-version.model';

export interface DeleteFilesFromSystem {
  account: string;
  audioFileMetaData: AudioFileMetaData[];
}

export interface DeleteProjectSystem {
  account: string;
  projectId: string;
}

export interface FileBackblazeB2LoadPayload {
  // backblazeB2: BackBlazeB2Options;
  account: string;
  audioFileMetaData: AudioFileMetaData;
}

export interface UploadFileProgress {
  audioFileMetaData: AudioFileMetaData;
  progress: number;
}

export interface CurrentIds {
  currentProjectId: string | null;
  currentSongId: string | null;
  currentTrackId: string | null;
  currentMixId: string | null;
  // currentFileId: string;
}

export interface BaseDeviceStorage {
  cacheDirectory: string | null;
  exportDirectory: string | null;
  controlsViewOpen: boolean;
  currentIds: CurrentIds | null;
  latency: SavedLatency;
  masterVolume: number | null;
  mediaDeviceInfo: any | null; // MediaDeviceInfo
  version: string | null | undefined;
  platform: EnvPlatform | null | undefined;
  build: BuildVersionElectron;
}

export type DeviceStorageState = BaseDeviceStorage & SptMediaTrackConstraintSet;

export type MergeEntityConfig =
  | Partial<AudioFileMetaData>
  | Partial<TrackEntity>
  | Partial<MixEntity>
  | Partial<SongEntity>
  | Partial<ProjectEntity>;

export interface IdEntity {
  [key: string]: MergeEntityConfig;
}

export type MergeEntity = Dictionary<MergeEntityConfig> | IdEntity;

export const intialDeviceStorageState: DeviceStorageState = {
  cacheDirectory: null,
  exportDirectory: null,
  controlsViewOpen: true,
  platform: {
    isPc: false,
    isIntelMac: false,
    isM1Mac: false
  },
  build: appVersions.build,
  currentIds: {
    currentProjectId: null,
    currentSongId: null,
    currentTrackId: null,
    currentMixId: null
    // currentFileId: null,
  },
  // Track Files saved to disk
  latency: {
    detected: null,
    adjusted: 0
  },
  masterVolume: 50,
  mediaDeviceInfo: null,
  microphoneGain: 1,
  recordInputCompressorThreshold: -24,
  recordInputCompressorKnee: 30,
  recordInputCompressorRatio: 12,
  recordInputCompressorAttack: 0.003,
  recordInputCompressorRelease: 0.25,
  exportMergeCompressorThreshold: -24,
  exportMergeCompressorKnee: 30,
  exportMergeCompressorRatio: 12,
  exportMergeCompressorAttack: 0.003,
  exportMergeCompressorRelease: 0.25,

  version: '',

  // Microphone Settings
  // SptMediaTrackConstraints
  ...initialSptMediaTrackConstraints,

  arrayGeometry: false,
  beamforming: false,
  typingNoiseDetection: false
};

export interface DeviceStoragePayload {
  account: string; // getUidCryptoHash$ - crypto-hash of account uid
  storage: Partial<DeviceStorageState>;
}

export interface GetDeviceStorage {
  account: string; // getUidCryptoHash$ - crypto-hash of account uid
}

export interface DeviceStorageProjectEntity {
  account: string;
  project: ProjectEntity;
}

export interface DeviceStorageSongEntity {
  account: string;
  song: SongEntity;
}

export interface DeviceStorageTrackEntity {
  account: string;
  track: TrackEntity;
}

export interface DeviceStorageAudioFileMetaData {
  account: string;
  audioFileMetaData: AudioFileMetaData;
}

export interface DeviceStorageMixEntity {
  account: string;
  mix: MixEntity;
}

export interface ElectronDialogText {
  account: string | null;
  title: string;
  properties: string[];
  buttonLabel: string;
}

export const exportDirectoryDialogText: ElectronDialogText = {
  account: null,
  title: `Select Directory To Save Song`,
  properties: ['openDirectory', 'createDirectory'],
  buttonLabel: 'Choose Directory'
};
