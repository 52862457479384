import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  AudioFileMetaData,
  ElectronNativeAudio,
  FileBackblazeB2LoadPayload
} from '@spout/global-any/models';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {selectUidHash$} from '../../+account/account.selectors';
import {DeviceStorageService} from './device-storage.service';

@Injectable()
export class AudioFileLoadService {
  constructor(private device: DeviceStorageService, private store: Store) {}

  loadFileFromDisk(
    audioFileMetaData: AudioFileMetaData
  ): Observable<ElectronNativeAudio> {
    return this.store.pipe(
      selectUidHash$,
      take(1),
      switchMap((account: string) => {
        return this.device.loadAudioFile(<FileBackblazeB2LoadPayload>{
          account,
          audioFileMetaData
        });
      })
    );
  }
}
