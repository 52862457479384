import {createFeatureSelector, createSelector} from '@ngrx/store';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {TreeConfig, TreeNode} from '@spout/global-any/models';
import {
  INSTRUMENT_FEATURE_KEY,
  InstrumentState
} from '@spout/global-web/models';

// Lookup the 'Instrument' feature state managed by NgRx
export const getInstrumentState = createFeatureSelector<InstrumentState>(
  INSTRUMENT_FEATURE_KEY
);
const selectAll = createSelector(
  getInstrumentState,
  (state: InstrumentState): TreeConfig[] => {
    if (state && state.entities) {
      return <TreeConfig[]>Object.values(state.entities);
    }
    return [];
  }
);

export const getInstrumentRootTreeNode = createSelector(
  getInstrumentState,
  selectAll,
  (state: InstrumentState, children: TreeConfig[]): TreeNode | null => {
    if (state.rootNode && state.rootNode.id && children && children.length) {
      const rootNode: TreeNode = new TreeNode(
        state.rootNode,
        null,
        null,
        null,
        true
      );
      rootNode.addChildren(children);

      return rootNode;
    }

    return null;
  }
);
