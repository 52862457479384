import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'spt-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
