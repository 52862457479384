<ng-content></ng-content>
<div
  class="ui-popover-ripple"
  matRipple
  [matRippleDisabled]="disableRipple || disabled"
  [matRippleTrigger]="_getHostElement()"></div>

<svg
  *ngIf="_triggersSubpopover"
  class="ui-popover-subpopover-icon"
  viewBox="0 0 5 10"
  focusable="false">
  <polygon points="0,0 5,5 0,10" />
</svg>
