import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardStudioSiteService} from './guards/auth-guard-studio-site.service';
const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@spout/marketing').then(
        m => m.MarketingModule
      )
  },
  {
    path: 'old-home',
    loadChildren: () =>
      import('@spout/marketing/feature-page-home').then(
        m => m.MixerFeaturePageHomeModule
      )
  },
  {
    path: 'old-subscribe',
    loadChildren: () =>
      import('@spout/global-web/feature-page-subscribe').then(
        m => m.PageSubscribeModule
      )
  },
  {
    path: 'company',
    loadChildren: () =>
      import('@spout/marketing/feature-page-company').then(
        m => m.PageCompanyModule
      )
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('@spout/marketing/feature-page-contact').then(
        m => m.PageContactModule
      )
  },
  {
    path: 'policies',
    loadChildren: () =>
      import('@spout/marketing/feature-page-policies').then(
        m => m.PagePoliciesModule
      )
  },
  {
    path: 'old-download',
    loadChildren: () =>
      import('@spout/marketing/feature-page-download').then(
        m => m.PageDownloadModule
      )
  },
  // {
  //   path: 'account',
  //   redirectTo: 'account/overview',
  //   pathMatch: 'full'
  // },
  {
    path: 'account',
    loadChildren: () =>
      import('@spout/marketing/feature-page-account').then(
        m => m.MarketingAccountModule
      ),
    canActivate: [AuthGuardStudioSiteService]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@spout/marketing/feature-page-login').then(
        m => m.MarketingFeaturePageLoginModule
      )
  },
  {
    path: 'signup-with-promo',
    loadChildren: () =>
      import('@spout/marketing/feature-page-login').then(
        m => m.MarketingFeaturePageLoginModule
      )
  },
   {
    path: 'signup',
    loadChildren: () =>
      import('@spout/marketing/feature-page-login').then(
        m => m.MarketingFeaturePageLoginModule
      )
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // enableTracing: true,
      // useHash: true,
      // enableTracing: true,
      // useHash: true,
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class StudioSiteRoutingModule {
  constructor() {
    // console.log('AppRoutingModule');
  }
}
