<div class="steps-row">
  <div>
    <div class="step-number">1</div>
  </div>

  <div class="step-task">
    <strong>Select the song to review.</strong>
  </div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">2</div>
  </div>
  <div class="step-task"><strong>Add annotations and chat</strong></div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">3</div>
  </div>
  <div class="step-task">
    <strong>Add a mix. ( REQUIRED )</strong>. Add your own mix to customize mix
    settings.
  </div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">4</div>
  </div>
  <div class="step-task">Make great music together.</div>
</div>
