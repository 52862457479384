import {
  AccountState,
  UserAccount,
  userAccountProperties
} from '@spout/global-web/models';
import {allValuesTruthy, hasValue} from '@uiux/fn';
import {User} from '@firebase/auth';
import * as _ from 'lodash';

export function getUserValue(user: User, key: string) {
  if (_.hasIn(user, key)) {
    return _.get(user, key);
  } else if (_.hasIn(user, `providerData[0].${key}`)) {
    return _.get(user, `providerData[0].${key}`);
  } else {
    return null;
  }
}

function getProviderData(user: User, key: string) {
  if (_.hasIn(user, `providerData[0].${key}`)) {
    return _.get(user, `providerData[0].${key}`);
  } else if (_.hasIn(user, key)) {
    return _.get(user, key);
  } else {
    return null;
  }
}

export function createFirestoreUserAccountFromAuth(user: User): UserAccount {
  return {
    active: true,
    uid: user.uid,
    // displayName: user.displayName,
    displayName: getUserValue(user, 'displayName'),
    // photoURL: getUserValue(user, 'photoURL'),
    // email: user.email,
    email: getUserValue(user, 'email'),
    emailVerified: user.emailVerified,
    // phoneNumber: user.phoneNumber,
    phoneNumber: getUserValue(user, 'phoneNumber'),
    providerId: getProviderData(user, 'providerId'),

    defaultMixId: null,
    defaultProjectId: null,
    defaultSongId: null,
    defaultTrackId: null,
    hasGenres: null,
    hasInstruments: null,
    isOnline: true,
    promoCode: null,
    stageName: null,
    totalCloudStorageUsed: 0,
    createdAt: {
      nanoseconds: null,
      seconds: null
    },
    updatedAt: {
      nanoseconds: null,
      seconds: null
    },
    subscribedAt: null

    // not exposed by firebase User interface
    // stsTokenManager: result.user.stsTokenManager,
  };

  // return <AccountState>{
  //   isLoggedIn: true,
  //   uid: user.uid,
  //   displayName: getUserValue(user, 'displayName'),
  //   photoURL: getUserValue(user, 'photoURL'),
  //   email: getUserValue(user, 'email'),
  //   // emailVerified: user.emailVerified,
  //   // phoneNumber: getUserValue(user, 'phoneNumber'),
  //   providerId: getProviderData(user, 'providerId'),
  //   isOnline: true,
  //   isRetrievedFromFirestore: true
  // };
}

// export function createFirestoreAccount(a: User): UserAccount {
//   return {
//     username: null,
//     displayName: a.displayName,
//     createdAt: null,
//     updatedAt: null,
//     uid: a.uid,
//     email: a.email
//   };
// }

export function addMissingUserAccountProperties(
  userAccount: UserAccount
): UserAccount {
  return userAccountProperties.reduce((acc: UserAccount, prop: string) => {
    if ((<any>userAccount)[prop] === undefined) {
      (<any>acc)[prop] = null;
    } else {
      (<any>acc)[prop] = (<any>userAccount)[prop];
    }

    return acc;
  }, <UserAccount>{});
}

export function hasAllUserAccountProperties(userAccount: UserAccount): boolean {
  return <boolean>userAccountProperties.reduce(
    (hasAllProperties: boolean, prop: string) => {
      if (hasAllProperties) {
        if ((<any>userAccount)[prop] === undefined) {
          console.log(`${prop} is missing from account`);
        }

        return (<any>userAccount)[prop] !== undefined;
      }

      return hasAllProperties;
    },
    true
  );
}

/**
 * AcountState has a few more properties that what is store in firestore
 *
 * AccountState  is saved in client store
 * UserStore is saved in firestore
 *
 * @param userAccount
 */
export function createAccountStateFromFirestore(
  userAccount: UserAccount,
  isProductionEnvironment: boolean,
  uidHash: string,
  user: User
): AccountState {
  if (!isProductionEnvironment) {
    uidHash = `dev-${uidHash}`;
  }

  const _accountState: AccountState = {
    ...userAccount,
    authError: null,
    isRetrievedFromFirestore: true,
    isLoggedIn: true,
    photoURL: getUserValue(user, 'photoURL'),
    uidHash
  };

  return {
    ..._accountState
  };
}

export function validateOnboardingValues(a: UserAccount) {
  return {
    // hasEmail: hasValue(a.email),
    hasStageName: hasValue(a.stageName)
    // hasGenres: a.hasGenres,
    // hasInstruments: a.hasInstruments
    // hasDefaultProject: hasValue(a.defaultProjectId),
    // hasDefaultSong: hasValue(a.defaultSongId),
    // hasDefaultTrack: hasValue(a.defaultTrackId),
    // hasDefaultMix: hasValue(a.defaultMixId)
  };
}

export function accountIsOnboarded(user: AccountState) {
  return allValuesTruthy(validateOnboardingValues(user));
}

export function accountIsLoaded(account: AccountState): boolean {
  return account && account.uid !== null && account.uid.length > 0;
}
