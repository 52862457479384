// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet
} from '@spout/global-any/models';
import {ApplySyncMix} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {getAllEntities} from './get-all-entities';

export function getDefaultSnippet(filesEntities: {
  [audioFileMetaDataEntityId: string]: TrackMixAudioSnippet;
}): TrackMixAudioSnippet | null {
  return (<TrackMixAudioSnippet[]>getAllEntities(filesEntities)).reduce(
    (acc: TrackMixAudioSnippet | null, i: TrackMixAudioSnippet) => {
      if (i.isDefault) {
        return i;
      }

      return acc;
    },
    null
  );
}

export function getTrackMixSnippets(
  trackMix: TrackMix
): TrackMixAudioSnippet[] {
  if (trackMix && hasValue(trackMix.audioSnippets)) {
    return Object.values(trackMix.audioSnippets);
  }

  return [];
}

/**
 * Pull off TrackMixAudioSync of audio snippets
 * this will help track-audio changes only applied to sync
 * @param trackMix
 * @param trackEntity
 */
export function getTrackMixAudioSync(
  trackMix: TrackMix,
  trackEntity: TrackEntity
): ApplySyncMix {
  return getTrackMixSnippets(trackMix).reduce(
    (a: ApplySyncMix, c: TrackMixAudioSnippet) => {
      a.snippetsSync.push({
        audioFileMetaDataEntityId: c.audioFileMetaDataEntityId,
        start: c.start,
        offsetMs: c.offsetMs,
        stop: c.stop,
        audioDuration: c.audioDuration,
        sampleRate: c.sampleRate
      });

      return a;
    },
    <ApplySyncMix>{
      trackEntity: trackEntity,
      snippetsSync: []
    }
  );
}
