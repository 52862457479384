import {
  algoliaConfigProd,
  firebaseProdMarketingConfig,
  stripePublishableKeyProd
} from '@spout/global-any/secrets';
import {endpointsMain} from '@spout/marketing/models';
import {defaultStripePaymentsOptions} from '@spout/global-web/data-access';
import {IEnvironmentState} from '@spout/global-web/models';

export const environment: IEnvironmentState = {
  production: true,
  mobileTest: false,
  appName: 'marketing',
  facebookAppId: '2219988261549213',
  firebaseProjectID: 'spout-prod',
  firebase: firebaseProdMarketingConfig,
  stripePublishableKey: stripePublishableKeyProd,
  stripePaymentOptions: defaultStripePaymentsOptions,
  // firebaseMonitor: firebaseMonitorConfig,
  serviceWorker: true,

  // proxy server
  endpoints: endpointsMain,
  algolia: algoliaConfigProd,
  emulator: false,
  authSite: 'https://auth.spoutstudio.io'
};

// faceBookInit(environment.facebookAppId);
