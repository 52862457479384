import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AccountState} from '@spout/global-web/models';
import {
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  where
} from '@firebase/firestore';
import {CustomFirestoreService} from '../../firebase';
import {AbstractConnectionService} from '../../firebase/abstract-connection.service';
import {FirestoreCollectionQuery} from '../../firebase/firestore-query';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {aggregateUpdates} from '../../fns/aggregate-updates';
import {
  firestoreTrialPath,
  firestoreUserSubscriptionsCollection
} from '../database-paths';
import {
  deleteSubscriptions,
  subscriptionRetrievedFromFirestore,
  updateSubscriptions,
  updateTrial,
  upsertSubscriptions
} from './subscription.actions';
import {removeFirestoreFeatures} from './subscription.fns';
import {Subscription, Trial} from './subscription.model';
import {subscriptionFeatureKey} from './subscription.reducer';

// import {firestoreSubscriptionCollection} from '../../firebase/database-paths';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends AbstractConnectionService {
  private _queryService: FirestoreCollectionQuery<Subscription>;
  private _trailSub: () => void = () => {};

  constructor(
    private _firestore: CustomFirestoreService,
    override _connector: FirestoreWebSocketConnectorService,
    override _store: Store,
    private _zone: NgZone
  ) {
    super(subscriptionFeatureKey, _connector, _store);

    const that = this;

    this._queryService = new FirestoreCollectionQuery<Subscription>(
      {
        queryConstrains: [
          // where('status', '==', 'active'),
          // where('status', '==', 'trialing')
        ],
        queryMember: false,
        upsertManyAction: (subscriptions: Subscription[]) =>
          upsertSubscriptions({
            subscriptions: removeFirestoreFeatures(subscriptions)
          }),
        updateManyAction: (subscriptions: Subscription[]) =>
          updateSubscriptions({
            subscriptions: aggregateUpdates(
              removeFirestoreFeatures(subscriptions)
            )
          }),
        deleteManyAction: (ids: string[]) => deleteSubscriptions({ids}),
        isRetrievedFromFirestoreAction: () =>
          subscriptionRetrievedFromFirestore(),
        mapFirestoreID: true,
        logUpsert: false
      },
      _zone,
      _store,
      _firestore
    );

    this._trailSub = onSnapshot(
      this._firestore.docRef(firestoreTrialPath()),
      (_doc: DocumentSnapshot<DocumentData>) => {
        that._zone.run(() => {
          that._store.dispatch(
            /**
             * Triggers 'doConnect' for all other services
             */
            updateTrial({
              trial: <Trial>_doc.data()
            })
          );
        });
      }
    );
  }

  onConnect(user: AccountState) {
    const that = this;

    this._connector.keyIsConnected(subscriptionFeatureKey);
    // implement query
    // console.log(user);
    // console.log(firestoreUserSubscriptionsCollection(<string>user.uid));
    this._queryService.onConnect(
      firestoreUserSubscriptionsCollection(<string>user.uid),
      null,
      <string>user.uid
    );
  }

  onDisconnect(user: AccountState) {
    // Unsubscribe to query
    this._queryService.onDisconnect();

    // Unsubscribe to query before calling this
    this._connector.keyIsDisconnected(subscriptionFeatureKey);

    this._trailSub();
  }
}
