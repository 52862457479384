import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {distinctUntilChanged} from 'rxjs/operators';
import {ComponentDictItem} from './content/content.model';
import {HelpModel} from '@spout/global-web/models';
import {ga_showHelpItem, getHelpView} from '@spout/global-web/data-access';
import {SptComponentRef} from '../../component-loader';
import {ReplaySubject} from 'rxjs';
import componentRefDict from './content/content-dictionary';

@Injectable({
  providedIn: 'root'
})
export class SptComponentRefService {
  componentRef$: ReplaySubject<SptComponentRef> =
    new ReplaySubject<SptComponentRef>(1);

  constructor(private store: Store) {
    this.store
      .pipe(select(getHelpView), distinctUntilChanged())
      .subscribe((ref: HelpModel) => {
        this.store.dispatch(ga_showHelpItem(ref));
        this._loadComponent(ref);
      });
  }

  private _loadComponent(ref: HelpModel) {
    if (componentRefDict.has(ref)) {
      this.componentRef$.next(
        (<ComponentDictItem>componentRefDict.get(ref)).component
      );
    }
  }
}
