import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SptUser} from './spt-user.model';
import * as fromSptUser from './spt-user.reducer';

export const selectSptUserState =
  createFeatureSelector<fromSptUser.SptUserState>(
    fromSptUser.sptUsersFeatureKey
  );

// const {selectIds, selectEntities, selectAll, selectTotal} =
//   fromSptUser.adapter.getSelectors();

export const selectSptUserEntities = createSelector(
  selectSptUserState,
  (state: fromSptUser.SptUserState) => state.entities
);

export const getSptUserByUID = (uid: string) =>
  createSelector(
    selectSptUserEntities,
    (entities: Dictionary<SptUser>): SptUser | null | undefined => {
      return entities[uid];
    }
  );
