import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpGainContentComponent} from './help-gain-content/help-gain-content.component';
import {HelpGainComponent} from './help-gain.component';

@NgModule({
  declarations: [HelpGainComponent, HelpGainContentComponent],
  exports: [HelpGainComponent, HelpGainContentComponent],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpGainModule {}
