import {WaveformChannel, WaveformValues} from '@spout/global-web/models';
import {max, min} from 'd3-array';
import {Observable, Observer} from 'rxjs';
import WaveformData, {
  WaveformDataAudioBufferOptions,
  WaveformDataAudioContextOptions
} from 'waveform-data';

function getWaveformChannelData(waveformData: WaveformData): WaveformChannel[] {
  const channels: WaveformChannel[] = [];
  for (let i = 0; i < waveformData.channels; i++) {
    channels[i] = <WaveformChannel>{
      max_array: waveformData.channel(i).max_array(),
      min_array: waveformData.channel(i).min_array()
    };
  }

  return channels;
}

function getMaxYFromWaveformData(waveformData: WaveformChannel[]): number {
  let maxArray: number[] = [];
  for (let i = 0; i < waveformData.length; i++) {
    maxArray = [...maxArray, ...waveformData[i].max_array];
  }
  return <number>max(maxArray);
}

function getMinYFromWaveformData(waveformData: WaveformChannel[]): number {
  let minArray: number[] = [];
  for (let i = 0; i < waveformData.length; i++) {
    minArray = [...minArray, ...waveformData[i].min_array];
  }
  return <number>min(minArray);
}

function getWaveformValues(w: WaveformData): WaveformValues {
  const channel = getWaveformChannelData(w);

  return {
    bits: w.bits,
    channels: w.channels,
    audioDuration: w.duration,
    hasData: w.duration > 0,
    length: w.length,
    pixels_per_second: w.pixels_per_second,
    sample_rate: w.sample_rate,
    scale: w.scale,
    seconds_per_pixel: w.seconds_per_pixel,
    channel: getWaveformChannelData(w),
    maxY: getMaxYFromWaveformData(channel),
    minY: getMinYFromWaveformData(channel)
  };
}

/**
 *
 * @param array_buffer: ArrayBuffer
 * @param audio_context: AudioContext
 */
export function getWaveformValuesFromArrayBuffer(
  array_buffer: ArrayBuffer,
  audio_context: AudioContext
): Promise<WaveformValues> {
  return new Promise<WaveformValues>((resolve, reject) => {
    // To create a WaveformData object from audio content in an ArrayBuffer:
    // https://github.com/bbc/waveform-data.js/blob/master/doc/API.md#examples-1
    // https://github.com/bbc/waveform-data.js/blob/master/doc/API.md#waveformdatacreatefromaudiooptions-callback
    const options: WaveformDataAudioContextOptions = {
      audio_context,
      array_buffer
      // scale: 512
    };

    WaveformData.createFromAudio(options, (err, w: WaveformData) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(getWaveformValues(w));
    });
  });
}

/**
 *
 * @param audio_buffer: ArrayBuffer
 */
export function getWaveformValuesFromAudioBuffer(
  audio_buffer: AudioBuffer
): Promise<WaveformValues> {
  return new Promise<WaveformValues>((resolve, reject) => {
    // To create a WaveformData object from audio content in an ArrayBuffer:
    // https://github.com/bbc/waveform-data.js/blob/master/doc/API.md#examples-1
    // https://github.com/bbc/waveform-data.js/blob/master/doc/API.md#waveformdatacreatefromaudiooptions-callback
    const options: WaveformDataAudioBufferOptions = {
      audio_buffer
      // scale: 512
    };

    WaveformData.createFromAudio(options, (err, w: WaveformData) => {
      if (err) {
        reject(err);
        return;
      }

      // console.log(w);

      resolve(getWaveformValues(w));
    });
  });
}
