import {formatCurrency} from '@angular/common';
import {Price} from '../../+product';
import {
  ProductWizardStore,
  Subscription,
  SubscriptionItem
} from '../../+subscriptions';
import {
  ItemizedCost,
  StorageAndInterval
} from '../payment-wizard.service.model';

export function createCurrencyDisplay(p: Price) {
  if (p && p.currency) {
    return formatCurrency(
      p.unit_amount / 100,
      'en-US',
      '$',
      p.currency.toUpperCase(),
      '1.2-2'
    );
  }

  return '';
}

export function totalCost(items: ItemizedCost[]): number {
  return items.reduce((t: number, i: ItemizedCost) => {
    t = t + i.itemPrice;

    return t;
  }, 0);
}

export function isUpdatedPlan(
  currentSubscriptionPlan: SubscriptionItem | null,
  selectedPlanPrice: Price | null
): boolean {
  if (selectedPlanPrice) {
    if (currentSubscriptionPlan) {
      return currentSubscriptionPlan.price.id !== selectedPlanPrice.id;
    }

    return true;
  }
  return false;
}

export function getCurrentSubscriptionStorageQuantity(
  state: ProductWizardStore
): number | null {
  return state.currentSubscriptionStorageProduct &&
    state.currentSubscriptionStorageProduct.quantity !== undefined
    ? state.currentSubscriptionStorageProduct.quantity
    : null;
}

export function selectCurrentSubscription(
  state: ProductWizardStore
): Subscription | null {
  return state.currentSubscription;
}

export function selectStorageQtyAndInterval(
  state: ProductWizardStore
): StorageAndInterval {
  return <StorageAndInterval>{
    additionalStoragePerGB: state.additionalStoragePerGB,
    interval: state.interval,
    selectedProductPrice: state.selectedProductPrice
  };
}

export function signEarlyAdopterAgreement(
  state: ProductWizardStore,
  checked: boolean
) {
  return {
    ...state,
    earlyAdopterAgreementSigned: checked
  };
}

export const selectEarlyAdopterAgreementSigned = (
  state: ProductWizardStore
): boolean => {
  return state.earlyAdopterAgreementSigned;
};

export const selectedStoragePrice = (state: ProductWizardStore) =>
  state.baseMonthlyStoragePrice ? state.baseMonthlyStoragePrice?.id : null;

export const isPerMonth = (state: ProductWizardStore) =>
  state.selectedProductPrice?.interval === 'month';
