import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'spt-help-content',
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpContentComponent {
  @Input() showDivider = true;
}
