import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {UpdateSubscriptionSuccessComponent} from './update-subscription-success.component';

@NgModule({
  declarations: [UpdateSubscriptionSuccessComponent],
  exports: [UpdateSubscriptionSuccessComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule]
})
export class UpdateSubscriptionSuccessModule {}
