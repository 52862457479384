import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {SongEntity} from '@spout/global-any/models';

export const loadSongs = createAction(
  '[Song] Load Songs',
  props<{songs: SongEntity[]}>()
);

export const addSong = createAction(
  '[Song] Add Song',
  props<{song: SongEntity}>()
);

export const upsertSong = createAction(
  '[Song] Upsert Song',
  props<{song: SongEntity}>()
);

export const addSongs = createAction(
  '[Song] Add Songs',
  props<{songs: SongEntity[]}>()
);

export const upsertSongs = createAction(
  '[Song] Upsert Songs',
  props<{songs: SongEntity[]}>()
);

export const updateSong = createAction(
  '[Song] Update Song',
  props<{song: Update<SongEntity>}>()
);

export const updateSongs = createAction(
  '[Song] Update Songs',
  props<{songs: Update<SongEntity>[]}>()
);

export const deleteSong = createAction(
  '[Song] Delete Song',
  props<{id: string}>()
);
export const deleteSongEffect = createAction(
  '[Song] Delete Song Effect',
  props<{song: SongEntity}>()
);

export const deleteSongs = createAction(
  '[Song] Delete Songs',
  props<{ids: string[]}>()
);

export const clearSongs = createAction('[Song] Clear Songs');

// Select ID so song can be updated
export const selectSongEffect = createAction(
  '[Song] Select Song Effect',
  props<{song: SongEntity}>()
);

export const currentSongHasMaster = createAction(
  '[Song] Current Song Has Master',
  props<{currentSongHasMaster: boolean}>()
);

export const shareSong = createAction(
  '[Song] Share',
  props<{
    share: boolean;
    project: SongEntity;
  }>()
);

export const syncSong = createAction(
  '[Song] Sync',
  props<{
    sync: boolean;
    project: SongEntity;
  }>()
);

export const addSongFromSidenav = createAction('[Song] Add From Sidenav');
