import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {SavedLatency} from '@spout/global-any/models';
import {
  AUDIO_WORKLET_MAP,
  RecordType,
  SHARED_BUFFER_TRANSPORT_FACTORY
} from '@spout/global-web/models';
import {hasValuePipe, isDefinedPipe} from '@uiux/rxjs';
import {
  BehaviorSubject,
  combineLatest,
  from,
  interval,
  Observable,
  of,
  ReplaySubject,
  Subscription
} from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  take,
  tap
} from 'rxjs/operators';
import {
  millisecondsToSeconds,
  secondsToMilliseconds
} from './helpers/audio.helpers';
import {SptAudioContextSubject} from './services/spt-audio-context.subject';
import {SptMergerAudioworklet} from './spt-merger.audioworklet';

// See processor
// apps/mixer-browser-desktop/src/app/workers/shared-array-buffer-transport.worker.ts
// libs/web-external/audio-worklet/src/lib/shared-buffer-player-worklet-processor.ts:3
// libs/web-external/audio-worklet/src/lib/shared-buffer-record-worklet-processor.ts AS SPT_TRANSPORT_STATE_BUFFER
// libs/mixer-browser-desktop/web-worker-track/src/lib/web-worker-buffer-encoder.ts
// apps/mixer-browser-desktop/src/app/workers/shared-record-buffer.worker.ts

const SPT_PLAYER_STATE_BUFFER = {
  PLAYING: 0,
  RECORDING: 1,
  RECORDING_PLAYING: 2,
  RECORD_LATENCY: 3,
  CURRENT_TIME_MS: 4,
  CHANNEL_COUNT: 5,
  SAMPLE_RATE: 6,

  //
  OUTPUT_GAIN: 7,

  // Time in playhead when recording started
  RECORD_OFFSET_MS: 8
};

const SPT_TRANSPORT_CONFIG = {
  BYTES_PER_STATE: Int32Array.BYTES_PER_ELEMENT,

  // Number of keys in SPT_PLAYER_STATE_BUFFER x 2.
  STATE_BUFFER_LENGTH: 16,

  /**
   * When playing or recording, output time interval
   * in milliseconds. Used for scrubber and current
   * time output in UI.
   */
  CLOCK_INTERVAL_MS: 10
};

export type BasicPlaybackState = 'started' | 'stopped';
export enum TRANSPORT_STATE {
  STARTED = 'started',
  STOPPED = 'stopped'
}

export interface OutputNodes {
  audioContext: AudioContext;
  recordType: RecordType;
}

export interface InputMergeMap {
  [playerId: string]: number;
}

@Injectable({
  providedIn: 'root'
})
export class SptTransportService {
  private workletAdded = false;

  /**
   * Track the time lapse when the transport was last stopped.
   * This is to determine where the current time is after
   * the transport is restarted.
   * @private
   */
  private _atLastStopTimeSeconds = 0;

  /**
   * Track the AudioContext time the transport is started to play or record
   * as a reference of how long the players and recorders are running
   * in seconds.
   * @private
   */
  private _AudioContextStartTime = 0;

  /**
   * Tracks play or record time.
   * @private
   */
  private _playClock: Subscription;

  transportSharedArrayBuffer$: ReplaySubject<SharedArrayBuffer>;
  transportSharedArrayBuffer: SharedArrayBuffer | undefined;
  onStartTimeSeconds$: BehaviorSubject<number>;
  onStopTimeSeconds$: BehaviorSubject<number>;

  // state
  onTransportReady$: BehaviorSubject<boolean>;
  onPlayRecordStart$: BehaviorSubject<boolean>;
  onPlayRecordStop$: BehaviorSubject<boolean>;
  onRecordStart$: BehaviorSubject<boolean>;
  onRecordStop$: BehaviorSubject<boolean>;

  private _audioContext$: SptAudioContextSubject;
  audioContext$: Observable<AudioContext>;
  audioContextDistinct$: Observable<AudioContext>;
  songSampleRate$: Observable<number>;
  deviceSampleRate$: Observable<number>;
  recordType$: BehaviorSubject<RecordType>;
  recordTypeIsRender$: Observable<boolean>;
  recordTypeIsTrackTime$: Observable<boolean>;
  outputNodes$: Observable<OutputNodes>;

  state$: BehaviorSubject<BasicPlaybackState>;
  get state(): BasicPlaybackState {
    return this.state$.value;
  }

  seconds$: BehaviorSubject<number>;
  secondsIfTrackTime$: Observable<number>;

  set seconds(seconds: number) {
    if (!this._states) {
      return;
    }

    // this._atLastStopTimeSeconds = 0;

    // Store as milliseconds
    Atomics.store(
      this._states,
      SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS,
      secondsToMilliseconds(seconds)
    );

    if (this.seconds$.value !== seconds) {
      this.seconds$.next(this.seconds);
      this.onStopTimeSeconds$.next(this.seconds);
    }
  }

  /**
   * Time in seconds
   */
  get seconds(): number {
    if (!this._states) {
      return 0;
    }

    if (
      this._states[SPT_PLAYER_STATE_BUFFER.PLAYING] ||
      this._states[SPT_PLAYER_STATE_BUFFER.RECORDING] ||
      this._states[SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING]
    ) {
      return (
        this._audioContext$.currentTime -
        this._AudioContextStartTime +
        millisecondsToSeconds(
          this._states[SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS]
        )
      );
    }
    // convert to seconds
    return millisecondsToSeconds(
      this._states[SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS]
    );
  }

  latency$: BehaviorSubject<number>;

  set latency(l: number) {
    if (this._states) {
      Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORD_LATENCY, l);
      this.latency$.next(l);
    }
  }

  get latency() {
    return this._states
      ? this._states[SPT_PLAYER_STATE_BUFFER.RECORD_LATENCY]
      : 0;
  }

  set latencyFromStore(l: SavedLatency) {
    if (l.detected) {
      this.latency = l.detected + l.adjusted;
    }
  }

  get recordOffsetMs() {
    return this._states
      ? this._states[SPT_PLAYER_STATE_BUFFER.RECORD_OFFSET_MS]
      : 0;
  }

  bpm$: BehaviorSubject<number>;

  get bpm() {
    return this.bpm$.value;
  }

  set bpm(b: number) {
    this.bpm$.next(b);
  }

  /**
   * Is transport started
   */
  get isStarted(): boolean {
    return (
      this._states !== undefined &&
      (this._states[SPT_PLAYER_STATE_BUFFER.PLAYING] === 1 ||
        this._states[SPT_PLAYER_STATE_BUFFER.RECORDING] === 1 ||
        this._states[SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING] === 1)
    );
  }

  get isRecording() {
    return (
      this._states &&
      (this._states[SPT_PLAYER_STATE_BUFFER.RECORDING] === 1 ||
        this._states[SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING] === 1)
    );
  }

  /**
   * Is transport stopped
   */
  get isStopped(): boolean {
    return !(
      this._states &&
      (this._states[SPT_PLAYER_STATE_BUFFER.PLAYING] === 1 ||
        this._states[SPT_PLAYER_STATE_BUFFER.RECORDING] === 1 ||
        this._states[SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING] === 1)
    );
  }

  get audioContext(): AudioContext {
    return this._audioContext$.getValue();
  }

  private _states: Int32Array | undefined;
  mergerWorklet$: ReplaySubject<SptMergerAudioworklet>;
  private _inputMergeMap$: BehaviorSubject<InputMergeMap> =
    new BehaviorSubject<InputMergeMap>({});

  connectMerger$: Observable<[SptMergerAudioworklet, InputMergeMap]>;

  constructor(
    private store: Store,
    @Inject(SHARED_BUFFER_TRANSPORT_FACTORY)
    private sharedArrayBufferTransportWorkerFactory: () => Worker
  ) {
    const that = this;
    this._audioContext$ = new SptAudioContextSubject();
    this.onTransportReady$ = new BehaviorSubject<boolean>(false);
    this.transportSharedArrayBuffer$ = new ReplaySubject<SharedArrayBuffer>(1);

    const worker: Worker = this.sharedArrayBufferTransportWorkerFactory();

    worker.onmessage = (e: MessageEvent) => {
      if (e.data && e.data.message && e.data.message === 'WORKER_READY') {
        that._states = new Int32Array(e.data.transportSharedArrayBuffer);
        this.transportSharedArrayBuffer$.next(
          e.data.transportSharedArrayBuffer
        );

        // initialize
        Atomics.store(that._states, SPT_PLAYER_STATE_BUFFER.PLAYING, 0);
        Atomics.store(that._states, SPT_PLAYER_STATE_BUFFER.RECORDING, 0);
        Atomics.store(
          that._states,
          SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING,
          0
        );
        Atomics.store(that._states, SPT_PLAYER_STATE_BUFFER.RECORD_LATENCY, 0);
        Atomics.store(that._states, SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS, 0);
        Atomics.store(that._states, SPT_PLAYER_STATE_BUFFER.CHANNEL_COUNT, 2);
        Atomics.store(that._states, SPT_PLAYER_STATE_BUFFER.OUTPUT_GAIN, 1);
        Atomics.store(
          that._states,
          SPT_PLAYER_STATE_BUFFER.RECORD_OFFSET_MS,
          0
        );

        this.onTransportReady$.next(true);
      }
    };

    worker.postMessage({
      message: 'INITIALIZE_WORKER'
    });

    this.deviceSampleRate$ = new BehaviorSubject(
      this._audioContext$.sampleRate
    );

    this.recordType$ = new BehaviorSubject<RecordType>(RecordType.TRACK);

    this.recordTypeIsRender$ = this.recordType$.pipe(
      map((recordType: string) => recordType === RecordType.MIX_MASTER),
      distinctUntilChanged()
    );

    this.recordTypeIsTrackTime$ = this.recordType$.pipe(
      map((recordType: string) => recordType === RecordType.TRACK),
      distinctUntilChanged<boolean>()
    );

    this.mergerWorklet$ = new ReplaySubject<SptMergerAudioworklet>(1);

    this.connectMerger$ = combineLatest([
      this.mergerWorklet$,
      this._inputMergeMap$
    ]);

    this.audioContext$ = this._audioContext$.pipe(
      mergeMap((audioContext: AudioContext) => {
        if (!this.workletAdded) {
          return combineLatest([
            from(
              audioContext.audioWorklet.addModule(
                AUDIO_WORKLET_MAP.LATENCY_WORKLET_PROCESSOR
              )
            ),
            from(
              audioContext.audioWorklet.addModule(
                AUDIO_WORKLET_MAP.SHARED_BUFFER_RECORD_WORKLET_PROCESSOR
              )
            ),
            from(
              audioContext.audioWorklet.addModule(
                AUDIO_WORKLET_MAP.CONNECTOR_WORKLET_PROCESSOR
              )
            ),
            from(
              audioContext.audioWorklet.addModule(
                AUDIO_WORKLET_MAP.MERGER_WORKLET_PROCESSOR
              )
            )
          ]).pipe(map(() => audioContext));
        }

        return of(audioContext);
      })
    );

    // this.store
    //   .pipe(
    //     select(selectCurrentSongEntity),
    //     isDefinedPipe<SongEntity | null, SongEntity>(),
    //     map((song: SongEntity) => {
    //       if (!song.sampleRate) {
    //         const ctx = new AudioContext();
    //         return {
    //           ...song,
    //           sampleRate: ctx.sampleRate
    //         };
    //       }
    //
    //       return song;
    //     }),
    //     distinctUntilKeyChanged<SongEntity>('sampleRate')
    //   )
    //   .subscribe((song: SongEntity) => {
    //     // console.log(song);
    //     if (this._audioContext$ && song.sampleRate) {
    //       this._audioContext$.sampleRate = song.sampleRate;
    //     }
    //   });

    this._playClock = Subscription.EMPTY;
    this.onStartTimeSeconds$ = new BehaviorSubject<number>(0);
    this.onStopTimeSeconds$ = new BehaviorSubject<number>(0);
    this.onPlayRecordStart$ = new BehaviorSubject<boolean>(false);
    this.onPlayRecordStop$ = new BehaviorSubject<boolean>(true);
    this.onRecordStart$ = new BehaviorSubject<boolean>(false);
    this.onRecordStop$ = new BehaviorSubject<boolean>(false);

    this.songSampleRate$ = this._audioContext$.sampleRate$;
    this.seconds$ = new BehaviorSubject<number>(0);
    this.latency$ = new BehaviorSubject<number>(0);
    this.bpm$ = new BehaviorSubject<number>(120);
    this.state$ = new BehaviorSubject<BasicPlaybackState>(
      TRANSPORT_STATE.STOPPED
    );

    this.secondsIfTrackTime$ = combineLatest([
      this.recordTypeIsTrackTime$,
      this.seconds$.pipe(
        hasValuePipe<number, number>(),
        distinctUntilChanged<number>()
      )
    ]).pipe(
      filter(([isTrackTime]: [boolean, number]) => isTrackTime),
      map(([isTrackTime, seconds]: [boolean, number]) => {
        return seconds;
      })
    );

    this.onTransportReady$
      .pipe(
        distinctUntilChanged(),
        filter((ready: boolean) => ready),
        mergeMap(() => {
          return this.audioContext$.pipe(
            isDefinedPipe<AudioContext, AudioContext>(),
            tap((audioContext: AudioContext) => {
              this._AudioContextStartTime = audioContext.currentTime;

              if (audioContext.state === 'suspended') {
                // this.onStartTimeSeconds$.next(this.seconds);
                this.onStopTimeSeconds$.next(this.seconds);
              }

              if (audioContext.state === 'running') {
                this.onStartTimeSeconds$.next(this.seconds);
                // this.onStopTimeSeconds$.next(this.seconds);
              }
            })
          );
        })
      )
      .subscribe((audioContext: AudioContext) => {
        if (this._states) {
          this.mergerWorklet$.next(new SptMergerAudioworklet(audioContext));
          Atomics.store(
            this._states,
            SPT_PLAYER_STATE_BUFFER.SAMPLE_RATE,
            audioContext.sampleRate
          );
        }
      });

    this.audioContextDistinct$ = this.audioContext$.pipe(
      distinctUntilKeyChanged<any>('id')
    );

    this.outputNodes$ = combineLatest([
      this.audioContextDistinct$,
      this.recordType$
    ]).pipe(
      map(([audioContext, recordType]: [AudioContext, RecordType]) => {
        return {
          audioContext,
          recordType
        };
      })
    );
  }

  onClick(event: MouseEvent) {
    if (
      this.audioContext.state === 'suspended' ||
      this.audioContext.state === 'closed'
    ) {
      this.audioContext.resume();
    }
  }

  play(): void {
    if (!this._states) {
      return;
    }

    this._AudioContextStartTime = this._audioContext$.currentTime;

    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.PLAYING, 1);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING, 0);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING, 0);
    // Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.OUTPUT_GAIN, 0);

    this.onStartTimeSeconds$.next(this.seconds);

    this.onPlayRecordStart$.next(true);
    this.onPlayRecordStop$.next(false);
    this.onRecordStart$.next(false);
    this.onRecordStop$.next(false);

    this.startClock();
  }

  stop(): void {
    if (this.isStopped || !this._states) {
      return;
    }

    // console.log('stop');
    this.onPlayRecordStart$.next(false);
    this.onPlayRecordStop$.next(true);
    this.onRecordStart$.next(false);
    this.onRecordStop$.next(true);
    // this.onStart$.next(false);

    const stopTime =
      this._audioContext$.currentTime -
      this._AudioContextStartTime +
      millisecondsToSeconds(
        this._states[SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS]
      );
    this._playClock.unsubscribe();

    Atomics.store(
      this._states,
      SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS,
      secondsToMilliseconds(stopTime)
    );

    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.PLAYING, 0);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING, 0);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING, 0);
    // Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.OUTPUT_GAIN, 1);

    // if (seconds !== null && seconds !== undefined) {
    //   Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS, seconds);
    // }

    this.onStopTimeSeconds$.next(this.seconds);
    this.seconds$.next(this.seconds);
  }

  record(): void {
    if (!this._states) {
      return;
    }

    this.onPlayRecordStart$.next(true);
    this.onPlayRecordStop$.next(false);
    this.onRecordStart$.next(true);
    this.onRecordStop$.next(false);

    this._AudioContextStartTime = this._audioContext$.currentTime;

    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.PLAYING, 0);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING, 1);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING, 0);
    // Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.OUTPUT_GAIN, 0);
    Atomics.store(
      this._states,
      SPT_PLAYER_STATE_BUFFER.RECORD_OFFSET_MS,
      this._states[SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS]
    );

    this.onStartTimeSeconds$.next(this.seconds);

    this.startClock();
  }

  playAndRecord(): void {
    if (!this._states) {
      return;
    }

    this.onPlayRecordStart$.next(true);
    this.onPlayRecordStop$.next(false);
    this.onRecordStart$.next(true);
    this.onRecordStop$.next(false);

    this._AudioContextStartTime = this._audioContext$.currentTime;

    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.PLAYING, 0);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING, 0);
    Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.RECORDING_PLAYING, 1);
    // Atomics.store(this._states, SPT_PLAYER_STATE_BUFFER.OUTPUT_GAIN, 0);
    Atomics.store(
      this._states,
      SPT_PLAYER_STATE_BUFFER.RECORD_OFFSET_MS,
      this._states[SPT_PLAYER_STATE_BUFFER.CURRENT_TIME_MS]
    );

    this.onStartTimeSeconds$.next(this.seconds);

    this.startClock();
  }

  startClock() {
    if (!this._playClock.closed) {
      this._playClock.unsubscribe();
    }

    this._playClock = interval(
      SPT_TRANSPORT_CONFIG.CLOCK_INTERVAL_MS
    ).subscribe(() => {
      this.seconds$.next(this.seconds);
    });
  }

  setTypeTrack() {
    this.recordType$.next(RecordType.TRACK);
  }

  setTypeMixMaster() {
    this.recordType$.next(RecordType.MIX_MASTER);
  }

  setTypeLatencyTest() {
    this.recordType$.next(RecordType.LATENCY_TEST);
  }

  setRenderMap(playerIds: string[]) {
    const that = this;
    combineLatest([this.mergerWorklet$, this.audioContext$])
      .pipe(take(1))
      .subscribe(
        ([merger, audioContext]: [SptMergerAudioworklet, AudioContext]) => {
          merger.disconnect();

          that._inputMergeMap$.next(
            playerIds.reduce(
              (a: InputMergeMap, plyaerId: string, index: number) => {
                a[plyaerId] = index;
                return a;
              },
              {}
            )
          );

          this.mergerWorklet$.next(
            new SptMergerAudioworklet(audioContext, {
              numberOfInputs: playerIds.length,
              numberOfOutputs: 1,
              outputChannelCount: [2]
            })
          );
        }
      );
  }
}
