
import { IEnvironmentState } from '@spout/global-web/models';
import {ProductWizardStore} from '../../+subscriptions';
import {TrialDaysUIDCheckout} from '../../stripe.selectors';
import {SubscribePayload} from '../payment-wizard.service.model';

export function createCheckoutSession([c, a]: [
  ProductWizardStore,
  TrialDaysUIDCheckout
],hasPromo:boolean,ENVIRONMENT:IEnvironmentState): {session: any; gaEventParams: {[key: string]: any}} | null {
  // console.log(c.selectedProductPrice);

  if (c.selectedProductPrice) {
    const subscriptionPrices: SubscribePayload[] = [];

    let gaEventParams: {[key: string]: any} = {};

    const plan: SubscribePayload = {
      dynamic_tax_rates: [],
      price: c.selectedProductPrice.id,
      quantity: 1
    };

    gaEventParams = {
      plan_price: c.selectedProductPrice.id,
      plan_quantity: 1
    };

    subscriptionPrices.push(plan);

    let storage: SubscribePayload = {
      dynamic_tax_rates: [],
      price: c.baseMonthlyStoragePrice?.id,
      quantity: c.additionalStoragePerGB
      // trial_end: trial_end
    };

    if (c.additionalStoragePerGB > 0) {
      if (c.interval === 'month') {
        gaEventParams = {
          ...gaEventParams,
          storage_price: c.monthlyStoragePrice?.id,
          storage_quantity: c.additionalStoragePerGB
        };

        storage = {
          dynamic_tax_rates: [],
          price: c.monthlyStoragePrice?.id,
          quantity: c.additionalStoragePerGB
        };
      } else {
        // Yearly
        gaEventParams = {
          ...gaEventParams,
          storage_price: c.yearlyStoragePrice?.id,
          storage_quantity: c.additionalStoragePerGB
        };

        storage = {
          dynamic_tax_rates: [],
          price: c.yearlyStoragePrice?.id,
          quantity: c.additionalStoragePerGB
        };
      }
    } else {
      if (c.interval === 'month') {
        gaEventParams = {
          ...gaEventParams,
          storage_price: c.baseMonthlyStoragePrice?.id,
          storage_quantity: 1
        };

        storage = {
          dynamic_tax_rates: [],
          price: c.baseMonthlyStoragePrice?.id,
          quantity: 1
        };
      } else {
        gaEventParams = {
          ...gaEventParams,
          storage_price: c.baseYearlyStoragePrice?.id,
          storage_quantity: 1
        };

        storage = {
          dynamic_tax_rates: [],
          price: c.baseYearlyStoragePrice?.id,
          quantity: 1
        };
      }
    }

    subscriptionPrices.push(storage);
    // console.log('createCheckoutSession', subscriptionPrices);
    const prod_promo = "promo_1ObnoIFr7h7OltJtuDxnKmxU";
    const dev_promo = "promo_1OZJhaFr7h7OltJth1nABFsA";
    // https://stripe.com/docs/api/checkout/sessions/create#create_checkout_session-success_url
    console.log("PROMO? "+hasPromo);
    const session: any = {
      mode: 'subscription',
      allow_promotion_codes: true,
      line_items: subscriptionPrices,
      success_url: window.location.href.replace('checkout', 'downloads'),
      cancel_url: window.location.href,
      promotion_code: hasPromo ? (ENVIRONMENT?.production == false?dev_promo: prod_promo ):"",
      // NOTE: TRIAL PLAN
      trial_from_plan: false

      // DOES NOT WORK CLIENT SIDE https://github.com/stripe/stripe-firebase-extensions/issues/114#issuecomment-743779607
      // subscription_data: {
      //   trial_end: Math.floor(Date.now() / 1000) + trialDays * 24 * 60 * 60,
      //   trial_period_days: trialDays
      // }
    };

    session.customer = a.uid;

    // return null;

    return {
      session,
      gaEventParams
    };
  }

  return null;
}
