<!--<div class="getting-started-title">HOW TO CREATE A SONG</div>-->

<div class="steps-row">
  <div>
    <div class="step-number">1</div>
  </div>

  <div class="step-task">
    <strong>Set latency.</strong> Setting latency corrects synchronization of
    recording audio while playing tracks at the same time.
  </div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">2</div>
  </div>
  <div class="step-task">
    <strong>Select sample rate that matches your microphone.</strong> You will
    see a warning next to the sample rate selector of sample rate does not match
    microphone. Selecting sample rate aligns playback sample rate of all tracks
    but does NOT resample recorded audio.
  </div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">3</div>
  </div>
  <div class="step-task">
    <strong>Record a reference track.</strong> The reference track should never
    change once other tracks have been recorded.
  </div>
</div>

<div class="steps-row">
  <div>
    <div class="step-number">4</div>
  </div>
  <div class="step-task">Record more tracks, add collaborators, enjoy.</div>
</div>
