import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {TrackMixAudioSync} from '@spout/global-any/models';
import {
  addDurationOffset,
  calculateAudioLength,
  getDefaultSongWaveformValues
} from '@spout/global-web/fns';
import {
  MixMetrics,
  TrackReportingDict,
  WaveformSongMetrics,
  WaveformTrackMetricsDict,
  WaveformValues
} from '@spout/global-web/models';
import {allValuesTruthy} from '@uiux/fn';
import {BehaviorSubject, combineLatest, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  parseAudioTrackMetrics,
  parseSongWaveformMetrics
} from '../helpers/mix-metrics.helpers';
import {SptTransportService} from '../spt-transport.service';

interface ReportingTrackIds {
  [trackId: string]: boolean;
}

/**
 * Calculate track-audio metrics as they relate to each other
 * in the current song play list.
 */
@Injectable({
  providedIn: 'root'
})
export class SongMetricsService {
  songWaveformMetrics$: ReplaySubject<WaveformSongMetrics> =
    new ReplaySubject<WaveformSongMetrics>(1);

  currentMixBPM$: ReplaySubject<number> = new ReplaySubject<number>(1);
  currentDuration$ = this.songWaveformMetrics$.pipe(
    map((metrics: WaveformSongMetrics) => (metrics ? metrics.songDuration : 0))
  );

  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // currentSampleRate$ = this.metrics$.pipe(
  //   map((metrics: MixMetrics) => (metrics ? metrics.maxSampleRate : 0))
  // );
  timeLeft$ = combineLatest([
    this.transport.seconds$,
    this.currentDuration$
  ]).pipe(
    map(([currentTimeSeconds, duration]: [number, number]) => {
      return duration - currentTimeSeconds;
    })
  );

  mixHasAudioToPlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  mixNotHasAudioToPlay$ = this.mixHasAudioToPlay$.pipe(
    map((hasAudioToPlay: boolean) => hasAudioToPlay === false)
  );

  // playerChannelMergerIds: { [key: string]: { 0: number; 1: number; }} = {};

  private numberTracksActiveInSong$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  private reporting: ReportingTrackIds = {};
  private tracksReporting: TrackReportingDict = {};
  private trackWaveforms: WaveformTrackMetricsDict = {};

  constructor(private store: Store, private transport: SptTransportService) {}

  setTrackIdsFromSong(trackIds: string[]): void {
    this.processing$.next(true);
    // console.log('setTrackIdsFromSong', trackIds);
    const that = this;

    if (trackIds && trackIds.length) {
      trackIds.forEach((trackId: string) => {
        that.reporting[trackId] = false;
      });

      this.numberTracksActiveInSong$.next(trackIds.length);
    }
  }

  resetTrackMixMetrics(trackId: string) {
    this.reporting[trackId] = true;
    this.tracksReporting[trackId] = {
      trackId,
      playerIds: [],
      trackDuration: 0
      // offsetMs: 0,
      // maxSampleRate: 0,
      // minSampleRate: 0
    };
  }

  private _reportTrack(
    trackId: string,
    trackDuration: number,
    playerIds: string[]
  ): void {
    this.tracksReporting[trackId] = {
      trackId,
      trackDuration,
      playerIds
    };
  }

  // addTrackMixMetrics(t: TrackReporting) {
  //   console.log('addTrackMixMetrics', t);
  //
  //   this.reporting[t.trackId] = true;
  //   this.tracksReporting[t.trackId] = {
  //     ...t
  //   };
  //
  //   if (allValuesTruthy(this.reporting)) {
  //     this.calculateAudioMetrics(this.tracksReporting);
  //   }
  // }

  // TODO: Calculate offset in caller of this method
  addTrackWaveformValues(
    w: WaveformValues,
    trackMixAudioSync: TrackMixAudioSync,
    trackId: string,
    playerIds: string[]
  ): void {
    this.processing$.next(true);
    /**
     * Add duration + offset
     */
    const totalTrackDuration = addDurationOffset(
      trackMixAudioSync.audioDuration,
      trackMixAudioSync.offsetMs
    );

    this.reporting[trackId] = true;
    this._reportTrack(trackId, totalTrackDuration, playerIds);

    this.trackWaveforms[trackId] = {
      // length: max([w.length, defaultWaveformValues.length]),
      // duration: max([w.duration, defaultWaveformValues.duration]),
      length: calculateAudioLength(totalTrackDuration, w.pixels_per_second),
      // trackDuration: trackMixAudioSync.audioDuration + (trackMixAudioSync.offsetMs * 0.001),
      trackDuration: totalTrackDuration,
      // trackDuration: trackMixAudioSync.audioDuration,
      channels: w.channels,
      hasData: w.hasData,
      sample_rate: w.sample_rate,
      // scale: w.scale ? w.scale : defaultWaveformValues.scale,
      scale: w.scale,
      seconds_per_pixel: w.seconds_per_pixel,
      pixels_per_second: w.pixels_per_second,
      bits: w.bits
    };

    if (allValuesTruthy(this.reporting)) {
      this.calculateAudioMetrics(this.tracksReporting);
    }
  }

  deleteTrack(trackId: string) {
    // console.log('deleteTrack', trackId);
    delete this.reporting[trackId];
    delete this.tracksReporting[trackId];
    delete this.trackWaveforms[trackId];

    if (allValuesTruthy(this.reporting)) {
      this.calculateAudioMetrics(this.tracksReporting);
    }
  }

  addZeroDuration(trackId: string) {
    this.processing$.next(true);
    // console.log('addZeroDuration', trackId);
    this.reporting[trackId] = true;
    if (allValuesTruthy(this.reporting)) {
      this.calculateAudioMetrics(this.tracksReporting);
    }
  }

  clear() {
    // console.log('clear');
    this.reporting = {};
    this.tracksReporting = {};
    this.trackWaveforms = {};
    this.calculateAudioMetrics(this.tracksReporting);
  }

  private calculateAudioMetrics(tracksReporting: TrackReportingDict) {
    const that = this;

    // console.log('calculateAudioMetrics', tracksReporting);
    const _tracksReporting = Object.values(tracksReporting);
    const _mixMetrics: MixMetrics = parseAudioTrackMetrics(_tracksReporting);

    this.transport.audioContext$.subscribe((audioContext: AudioContext) => {
      // console.log('MixMetrics', metrics);

      this.mixHasAudioToPlay$.next(_mixMetrics.duration > 0);
      // this.metrics$.next(metrics);
      this.transport.setRenderMap(_mixMetrics.playerIds);

      if (!_mixMetrics.hasAudio) {
        this.songWaveformMetrics$.next(
          getDefaultSongWaveformValues(
            audioContext.sampleRate,
            _mixMetrics.defaultDuration
          )
        );
      } else {
        this.songWaveformMetrics$.next(
          parseSongWaveformMetrics(Object.values(this.trackWaveforms))
        );
      }

      this.processing$.next(false);
    });

    // this.store
    //   .pipe(
    //     select(selectCurrentMixEntity),
    //     hasValuePipe<MixEntity | null, MixEntity>()
    //   )
    //   .subscribe((mixEntity: MixEntity) => {
    //     if (mixEntity) {
    //       that.currentMixBPM$.next(mixEntity.bpm);
    //     }
    //   });
  }
}
