import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpGettingStartedCreatingContentComponent} from './help-getting-started-creating-content/help-getting-started-creating-content.component';
import {HelpGettingStartedCreatingComponent} from './help-getting-started-creating.component';

@NgModule({
  declarations: [
    HelpGettingStartedCreatingComponent,
    HelpGettingStartedCreatingContentComponent
  ],
  exports: [
    HelpGettingStartedCreatingComponent,
    HelpGettingStartedCreatingContentComponent
  ],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpGettingStartedCreatingModule {}
