import {FileUint8ArrayType} from '@spout/global-any/models';
import {from} from 'rxjs';

export function convertBlobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return blob.arrayBuffer();
}

export async function convertBlobToUint8Array(
  blob: Blob
): Promise<FileUint8ArrayType> {
  const arrayBuffer: ArrayBuffer = await blob.arrayBuffer();

  return {
    type: blob.type,
    uint8Array: new Uint8Array(arrayBuffer)
  };
}

export const convertBlobToUint8Array$ = (blob: Blob) =>
  from(convertBlobToUint8Array(blob));

export async function convertArrayBufferTypeToFileUint8ArrayType(
  arrayBuffer: ArrayBuffer,
  _type: string
): Promise<FileUint8ArrayType> {
  return {
    type: _type,
    uint8Array: new Uint8Array(arrayBuffer)
  };
}
