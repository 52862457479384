import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HelpCreateProjectComponent} from './help-create-project.component';

@NgModule({
  declarations: [HelpCreateProjectComponent],
  exports: [HelpCreateProjectComponent],
  imports: [CommonModule]
})
export class HelpCreateProjectModule {}
