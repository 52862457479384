import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LetModule, PushModule} from '@ngrx/component';
import {StripeSubscribeViewComponent} from './stripe-subscribe-view.component';

@NgModule({
  declarations: [StripeSubscribeViewComponent],
  exports: [StripeSubscribeViewComponent],
  imports: [CommonModule, LetModule, PushModule]
})
export class StripeSubscribeViewModule {}
