import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LetModule, PushModule} from '@ngrx/component';
import {SocialModule} from '../social/social.module';
import {AppFooterComponent} from './app-footer.component';

@NgModule({
  declarations: [AppFooterComponent],
  imports: [CommonModule, LetModule, PushModule, SocialModule],
  exports: [AppFooterComponent]
})
export class AppFooterModule {}
