import {Component, ChangeDetectionStrategy, Inject} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'spt-stripe-error',
  templateUrl: './stripe-error-modal.component.html',
  styleUrls: ['./stripe-error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StripeErrorModalComponent {
  constructor(
    public dialogRef: MatDialogRef<StripeErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}
}
