import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  ServiceWorkerStatus,
  studioFeatureKey,
  StudioVersionState
} from '@spout/global-web/models';

export const selectStudioState =
  createFeatureSelector<StudioVersionState>(studioFeatureKey);

export const selectInstalledStudioVersion = createSelector(
  selectStudioState,
  (state: StudioVersionState) => {
    return state.installed;
  }
);

export const selectServiceWorkerStatus = createSelector(
  selectStudioState,
  (state: StudioVersionState): ServiceWorkerStatus => state.serviceWorker
);
