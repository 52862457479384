import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Update} from '@ngrx/entity';
import {
  AudioFileMetaData,
  AudioFileMetaDataEntity
} from '@spout/global-any/models';
import {
  firestoreFileByIdPath,
  firestoreFileByProjectIdAndIdPath,
  resetAudioFileMetaDataEntity
} from '@spout/global-web/fns';
import {CompositeAudioBufferDataAndAudioMetaData} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import 'firebase/functions';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {AudioFileSaveService} from '../+device-storage/services/audio-file-save.service';
import {clearTrackEffect, deleteTrackEffect} from '../+tracks/track.actions';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';
import {
  createProjectAction,
  createSongAction,
  createTrackAction
} from '../actions/create-entites.actions';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {CustomFirestoreService} from '../firebase';
import {AudioFileMetaDataFirestoreService} from './audio-file-meta-data-firestore.service';
import {
  updateAudioFileMetaDataEntities,
  updateAudioFileMetaDataEntity,
  updateAudioFileMetaDataEntityEffect
} from './audio-meta-data.actions';

@Injectable({
  providedIn: 'root'
})
export class AudioFileMetaDataEffects {
  createDefaultFileEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createDefaultConfigsToFirestore),
        mergeMap(action => {
          return this.firestoreAudioMedatData.createAudioFileConfig(
            action.file
          );
        })
      ),
    {dispatch: false}
  );

  saveFileEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        // Note: do not add createTracksAction, that is handled in the import-files.service.ts
        ofType(createTrackAction, createProjectAction, createSongAction),
        switchMap(action => {
          // console.log(action);

          // .sync
          return this.sptFirestore.upsertDoc<AudioFileMetaData>(
            firestoreFileByIdPath(action.file),
            action.file
          );
          // if (action.file.sync) {
          //   this.firestoreAudioMedatData.updateAudioFileMetaData(action.file);
          // }
        })
      ),
    {dispatch: false}
  );

  updateFileEntity$ = createEffect(() =>
    this.actions$.pipe(
      // Note: do not add createTracksAction, that is handled in the import-files.service.ts
      ofType(updateAudioFileMetaDataEntityEffect),
      switchMap(action => {
        return this.sptFirestore
          .update<AudioFileMetaData>(
            firestoreFileByProjectIdAndIdPath(
              action.projectId,
              <string>action.file.id
            ),
            action.file.changes
          )
          .pipe(
            map(() =>
              updateAudioFileMetaDataEntity({
                file: action.file
              })
            )
          );
      })
    )
  );

  saveManyDeviceStoreAudioMetaDataEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAudioFileMetaDataEntities),
        map(action => {
          return action.files.map((file: Update<AudioFileMetaDataEntity>) => {
            return file.changes;
          });
        }),
        switchMap((files: Partial<AudioFileMetaDataEntity>[]) => {
          // const toUpdate = action.files.filter((file) => file.sync);

          // .sync
          return this.firestoreAudioMedatData.batchUpdateAudioFileMetaData(
            files
          );
        })
      ),
    {dispatch: false}
  );

  deleteTrackEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTrackEffect),
        map(action => {
          const that = this;
          if (
            action.trackModel?.fileData &&
            action.trackModel.fileData.length
          ) {
            const toDelete: AudioFileMetaDataEntity[] =
              action.trackModel.fileData

                // .sync
                // .filter((file) => file.audioFileMetaData.sync)
                .map(
                  (c: CompositeAudioBufferDataAndAudioMetaData) =>
                    c.audioFileMetaData
                );

            if (hasValue(toDelete)) {
              this.firestoreAudioMedatData
                .batchDeleteAudioFileMetaData(toDelete)
                .subscribe(() => {
                  /* noop */
                });
            }
          }
        })
      ),
    {dispatch: false}
  );

  clearTrackEffectSync$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearTrackEffect),
        map(action => {
          const that = this;
          if (
            action.trackModel?.fileData &&
            action.trackModel.fileData.length
          ) {
            const toUpdate: AudioFileMetaDataEntity[] =
              action.trackModel.fileData
                // .filter((file) => file?.audioFileMetaData?.sync)
                .map(
                  (c: CompositeAudioBufferDataAndAudioMetaData) =>
                    c.audioFileMetaData
                )
                .map((c: AudioFileMetaDataEntity) => {
                  return resetAudioFileMetaDataEntity(c);
                });

            if (hasValue(toUpdate)) {
              this.firestoreAudioMedatData
                .batchUpdateAudioFileMetaData(toUpdate)
                .subscribe(() => {
                  /* noop */
                });
            }
          }
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private firestoreAudioMedatData: AudioFileMetaDataFirestoreService,
    private audioMetaDataFirestoreService: AudioFileMetaDataFirestoreService,
    private fileSaveService: AudioFileSaveService,
    private dss: DynamicStoreService,
    private sptFirestore: CustomFirestoreService
  ) {}
}
