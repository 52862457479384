import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubscriptionEndComponent} from './subscription-end.component';

@NgModule({
  declarations: [SubscriptionEndComponent],
  exports: [SubscriptionEndComponent],
  imports: [CommonModule]
})
export class SubscriptionEndModule {}
