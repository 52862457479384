import {TrackEntity} from '@spout/global-any/models';

export interface TrackItemDialogData<T> {
  type: string;
  data?: T | null;
}

export interface TrackItemDialog<T> {
  width: string;
  minHeight: string;
  backdropClass: string;
  data: TrackItemDialogData<T>;
}

export enum TRACK_DIALOG_MESSAGE {
  WARN_DELETE_SELECTED_TRACK = 'WARN_DELETE_SELECTED_TRACK',
  WARN_CLEAR_SELECTED_TRACK = 'WARN_CLEAR_SELECTED_TRACK',
  WARN_TRACK_RECORDED = 'WARN_TRACK_RECORDED',
  WARN_IS_MASTER_TRACK = 'WARN_IS_MASTER_TRACK'
}

export interface WarnDeleteSelectedTrack {
  track: TrackEntity | null;
}

const WARN_DELETE_SELECTED_TRACK: TrackItemDialog<WarnDeleteSelectedTrack> = {
  width: '600px',
  minHeight: '300px',
  backdropClass: 'studio-dialog-backdrop',
  data: {
    type: TRACK_DIALOG_MESSAGE.WARN_DELETE_SELECTED_TRACK,
    data: {
      track: null
    }
  }
};

const WARN_CLEAR_SELECTED_TRACK: TrackItemDialog<WarnDeleteSelectedTrack> = {
  width: '600px',
  minHeight: '150px',
  backdropClass: 'studio-dialog-backdrop',
  data: {
    type: TRACK_DIALOG_MESSAGE.WARN_CLEAR_SELECTED_TRACK,
    data: {
      track: null
    }
  }
};

export interface WarnTrackRecorded {
  track: TrackEntity | null;
}

const WARN_TRACK_RECORDED: TrackItemDialog<WarnTrackRecorded> = {
  width: '600px',
  minHeight: '200px',
  backdropClass: 'studio-dialog-backdrop',
  data: {
    type: TRACK_DIALOG_MESSAGE.WARN_TRACK_RECORDED,
    data: {
      track: null
    }
  }
};

const WARN_IS_MASTER_TRACK: TrackItemDialog<WarnTrackRecorded> = {
  width: '600px',
  minHeight: '200px',
  backdropClass: 'studio-dialog-backdrop',
  data: {
    type: TRACK_DIALOG_MESSAGE.WARN_IS_MASTER_TRACK,
    data: {
      track: null
    }
  }
};

export const TRACK_DIALOG_DATA = {
  WARN_DELETE_SELECTED_TRACK: WARN_DELETE_SELECTED_TRACK,
  WARN_CLEAR_SELECTED_TRACK: WARN_CLEAR_SELECTED_TRACK,
  WARN_TRACK_RECORDED: WARN_TRACK_RECORDED,
  WARN_IS_MASTER_TRACK: WARN_IS_MASTER_TRACK
};

export function createTrackDialog<T>(
  i: TrackItemDialog<T>,
  data: T | null = null
): TrackItemDialog<T> {
  return {
    ...i,
    backdropClass: 'studio-dialog-backdrop',
    data: {
      ...i.data,
      data
    }
  };
}

export function getTrackDialogData<T>(
  i: TrackItemDialogData<T>
): T | null | undefined {
  return i.data;
}

export function getTrackDialogType(i: TrackItemDialogData<any>): string {
  return i.type;
}

export function trackDialogTypeMatches(
  i: TrackItemDialogData<any>,
  match: string
): boolean {
  return i.type === match;
}
