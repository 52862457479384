import {
  DeviceStorageState,
  GoogleMediaTrackConstraints,
  SptMediaTrackConstraintSet
} from '@spout/global-any/models';
import {objectTransformMap} from '@uiux/fn';

/**
 * https://source.chromium.org/chromium/chromium/src/+/master:third_party/blink/renderer/modules/mediastream/media_stream_track_content_hint.h;l=17;bpv=1;bpt=0?q=contentHint&sq=&ss=chromium%2Fchromium%2Fsrc
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack
 * https://github.com/w3c/mediacapture-main/issues/457
 * @param s
 */
export function transformStorageToGoogle(
  s: DeviceStorageState
): GoogleMediaTrackConstraints {
  const map = {
    autoGainControl: 'googAutoGainControl',
    autoGainControl2: 'googAutoGainControl2',
    echoCancellation: 'googEchoCancellation',
    echoCancellation2: 'googEchoCancellation2',
    noiseSuppression: 'googNoiseSuppression',
    noiseSuppression2: 'googNoiseSuppression2',
    noiseReduction: 'googNoiseReduction',
    DAEchoCancellation: 'googDAEchoCancellation',
    audioMirroring: 'googAudioMirroring'

    // NOTE: CAUSES MONO RECORDING
    // highpassFilter: 'googHighpassFilter',

    // NOTE: NOT SUPPORTED
    // typingNoiseDetection: 'googTypingNoiseDetection',
    // beamforming: 'googBeamforming',
    // arrayGeometry: 'googArrayGeometry',
  };

  const mapped: GoogleMediaTrackConstraints = objectTransformMap(s, map);

  return Object.keys(mapped).reduce(
    (o: GoogleMediaTrackConstraints | any, key: string) => {
      if (o[key] === null || o[key] === undefined) {
        o[key] = false;
      }

      return o;
    },
    mapped
  );
}

export function transformGoogleToTrackConstraints(
  s: GoogleMediaTrackConstraints
): SptMediaTrackConstraintSet {
  const map: any = {
    googAutoGainControl: 'autoGainControl',
    googAutoGainControl2: 'autoGainControl2',
    googEchoCancellation: 'echoCancellation',
    googEchoCancellation2: 'echoCancellation2',
    googNoiseSuppression: 'noiseSuppression',
    googNoiseSuppression2: 'noiseSuppression2',
    googNoiseReduction: 'noiseReduction',
    googDAEchoCancellation: 'DAEchoCancellation',
    googAudioMirroring: 'audioMirroring'

    // NOTE: CAUSES MONO RECORDING
    // googHighpassFilter: 'highpassFilter',

    // NOTE: NOT SUPPORTED
    // googTypingNoiseDetection: 'typingNoiseDetection',
    // googBeamforming: 'beamforming',
    // googArrayGeometry: 'arrayGeometry',
  };

  const mapped: SptMediaTrackConstraintSet = objectTransformMap(s, map);

  return Object.keys(mapped).reduce(
    (o: SptMediaTrackConstraintSet, key: string) => {
      if ((<any>o)[key] === null || (<any>o)[key] === undefined) {
        (<any>o)[key] = false;
      }

      return o;
    },
    mapped
  );
}

/**
 * https://source.chromium.org/chromium/chromium/src/+/master:third_party/blink/renderer/modules/mediastream/media_stream_track_content_hint.h;l=17;bpv=1;bpt=0?q=contentHint&sq=&ss=chromium%2Fchromium%2Fsrc
 * @param s
 */
export function transformGoogleToStorage(
  s: GoogleMediaTrackConstraints
): SptMediaTrackConstraintSet {
  const map: any = {
    googAutoGainControl: 'autoGainControl',
    googAutoGainControl2: 'autoGainControl2',
    googEchoCancellation: 'echoCancellation',
    googNoiseSuppression: 'noiseSuppression',

    googEchoCancellation2: 'echoCancellation2',
    googNoiseSuppression2: 'noiseSuppression2',
    googDAEchoCancellation: 'DAEchoCancellation',
    googNoiseReduction: 'noiseReduction',
    googAudioMirroring: 'audioMirroring'

    // NOTE: CAUSES MONO RECORDING
    // googHighpassFilter: 'highpassFilter',

    // NOTE: NOT SUPPORTED
    // googTypingNoiseDetection: 'typingNoiseDetection',
    // googBeamforming: 'beamforming',
    // googArrayGeometry: 'arrayGeometry',
  };

  const mapped: SptMediaTrackConstraintSet = objectTransformMap(s, map);

  return Object.keys(mapped).reduce(
    (o: SptMediaTrackConstraintSet, key: string) => {
      if ((<any>o)[key] === null || (<any>o)[key] === undefined) {
        (<any>o)[key] = false;
      }

      return o;
    },
    mapped
  );
}

export function transformStorageToMediaTrackConstraints(
  s: DeviceStorageState
): SptMediaTrackConstraintSet {
  const map: any = {
    autoGainControl: 'autoGainControl',
    // autoGainControl2: 'autoGainControl2',
    echoCancellation: 'echoCancellation',
    noiseSuppression: 'noiseSuppression',
    highpassFilter: 'highpassFilter',
    // echoCancellation2: 'echoCancellation2',
    // noiseSuppression2: 'noiseSuppression2',
    DAEchoCancellation: 'DAEchoCancellation',
    noiseReduction: 'noiseReduction',
    audioMirroring: 'audioMirroring'
    // typingNoiseDetection: 'typingNoiseDetection',
    // beamforming: 'beamforming',
    // arrayGeometry: 'arrayGeometry',
  };

  const mapped: SptMediaTrackConstraintSet = objectTransformMap(s, map);

  return Object.keys(mapped).reduce(
    (o: SptMediaTrackConstraintSet, key: string) => {
      if ((<any>o)[key] === null) {
        (<any>o)[key] = false;
      }

      return o;
    },
    mapped
  );
}
