import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpCreateTrackComponent} from './help-create-track.component';

@NgModule({
  declarations: [HelpCreateTrackComponent],
  exports: [HelpCreateTrackComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule]
})
export class HelpCreateTrackModule {}
