import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {
  AccountStateConnect,
  MusicianNotification,
  notificationsesFeatureKey,
  FirebaseConnectionService,
  AccountState
} from '@spout/global-web/models';
import {upsertWebsocketRegistry} from '../+websocket-registry/websocket-registry.actions';
import {selectAuthAccountConnect$} from '../+websocket-registry/websocket-registry.selectors';
import {CustomFirestoreService} from '../firebase';

@Injectable({
  providedIn: 'root'
})
export class SptNotificationsService
  implements FirebaseConnectionService<AccountState>
{
  constructor(
    private store: Store<AccountStateConnect>,
    private sptFirestore: CustomFirestoreService,
    private zone: NgZone
  ) {
    // console.log('Account Service');

    const that = this;
    that.zone.run(() => {
      this.store.dispatch(
        upsertWebsocketRegistry({
          id: notificationsesFeatureKey
        })
      );
    });

    this.store.pipe(selectAuthAccountConnect$).subscribe(s => {
      if (s.doConnect) {
        this.onConnect.call(this, s.user);
      } else {
        this.onDisconnect.call(this);
      }
    });
  }

  /**
   * Save to OTHER user's account
   * @param notification
   */
  sendNotification(notification: MusicianNotification) {
    // const docRef = `users/${firestoreUserKey_sha256(
    //   notification.targetMusician.uid
    // )}/notifications/${notification.id}`;

    const docRef = `users/${notification.targetMusician.uid}/notifications/${notification.id}`;

    this.sptFirestore.setDoc(docRef, notification.toString());
  }

  onConnect(user: AccountState): void {
    this.sptFirestore.setWebSocketConnected(notificationsesFeatureKey);
  }

  onDisconnect() {
    this.sptFirestore.setWebSocketDisconnected(notificationsesFeatureKey);
  }
}
