import {Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';
import {TreeConfig} from '@spout/global-any/models';
import {
  AggregateFirebaseSnapshotChangesEntities,
  GenreActionTypes,
  GenreState
} from '@spout/global-web/models';

export const genreLoaded = createAction(
  GenreActionTypes.GenreLoaded,
  props<{payload: GenreState}>()
);

export const saveGenresToFirebase = createAction(
  GenreActionTypes.SaveGenresToFirebase,
  props<{payload: any}>()
);

// export const loggedOut = createAction(GenreActionTypes.LoggedOut, props<{ payload: any }>());

// export const genreDoNothingAction = createAction(GenreActionTypes.GenreDoNothingAction);

export const loadGenres = createAction(
  '[Genre/API] Load Genres',
  props<{genres: TreeConfig[]}>()
);

export const addGenre = createAction(
  '[Genre/API] Add Genre',
  props<{genre: TreeConfig}>()
);

export const upsertGenre = createAction(
  '[Genre/API] Upsert Genre',
  props<{genre: TreeConfig}>()
);

export const addGenres = createAction(
  '[Genre/API] Add Genres',
  props<{genres: TreeConfig[]}>()
);

export const upsertGenres = createAction(
  '[Genre/API] Upsert Genres',
  props<{genres: TreeConfig[]}>()
);

export const updateGenre = createAction(
  '[Genre/API] Update Genre',
  props<{genre: Update<TreeConfig>}>()
);

export const updateGenres = createAction(
  '[Genre/API] Update Genres',
  props<{genres: Update<TreeConfig>[]}>()
);

export const deleteGenre = createAction(
  '[Genre/API] Delete TreeConfig',
  props<{id: string}>()
);

export const deleteGenres = createAction(
  '[Genre/API] Delete TreeConfigs',
  props<{ids: string[]}>()
);

export const clearGenres = createAction('[Genre/API] Clear TreeConfigs');

export const genreRoot = createAction(
  '[Genre/API] Genre Root',
  props<{root: TreeConfig}>()
);

export const genreAggregate = createAction(
  '[Genre/API] Update Aggregate',
  props<{aggregate: AggregateFirebaseSnapshotChangesEntities<TreeConfig>}>()
);
