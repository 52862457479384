import {AccountState} from '@spout/global-web/models';
import {ProductWizardStore} from '../../+subscriptions';
import {
  APIStripeSubscriptionPayload,
  APIStripeUpdateSubscrptionItem
} from '../payment-wizard.service.model';

export function createUpdatePayload([c, account]: [
  ProductWizardStore,
  AccountState
]): APIStripeSubscriptionPayload {
  const subscritpionPayload: APIStripeSubscriptionPayload = {
    subscriptionId: <string>c.currentSubscription?.id,
    items: []
  };

  if (c.currentSubscription && c.currentSubscriptionStorageProduct) {
    // console.log(c);

    /**
     * Plan
     */
    if (
      c.selectedProductPrice &&
      c.selectedProduct &&
      c.currentSubscriptionProduct
    ) {
      subscritpionPayload.items.push({
        id: c.currentSubscriptionProduct.id,
        price: c.selectedProductPrice.id,
        itemNameForDev: 'Plan'
      });

      // if (
      //   isUpdatedPlan(
      //     c.currentSubscriptionProduct,
      //     c.selectedProductPrice
      //   ) &&
      //   c.currentSubscriptionProduct
      // ) {
      //   const updatedPlan: APIStripeUpdateSubscrptionItem = {
      //     id: c.currentSubscriptionProduct.id,
      //     price: c.selectedProductPrice.id
      //   };
      //
      //   subscritpionPayload.items.push(updatedPlan);
      // } else {
      //   subscritpionPayload.items.push({
      //     id: c.currentSubscriptionProduct.id,
      //     price: c.selectedProductPrice?.id
      //   });
      // }
    }

    /**
     * Storage
     */

    const updatedStorage: APIStripeUpdateSubscrptionItem = {
      id: c.currentSubscriptionStorageProduct?.id,
      price: c.baseMonthlyStoragePrice?.id,
      itemNameForDev: 'Storage'
    };

    if (
      c.currentSubscriptionStorageProduct &&
      c.additionalStoragePerGB > 0 &&
      c.monthlyStoragePrice &&
      c.yearlyStoragePrice
    ) {
      updatedStorage.quantity = c.additionalStoragePerGB;

      if (c.interval === 'month') {
        updatedStorage.price = c.monthlyStoragePrice.id;
      } else {
        // year
        updatedStorage.price = c.yearlyStoragePrice.id;
      }
    } else if (c.baseMonthlyStoragePrice && c.baseYearlyStoragePrice) {
      if (c.interval === 'month') {
        updatedStorage.price = c.baseMonthlyStoragePrice.id;
      } else {
        // year
        updatedStorage.price = c.baseYearlyStoragePrice.id;
      }
    }

    subscritpionPayload.items.push(updatedStorage);
  }

  return subscritpionPayload;
}
