import {Update} from '@ngrx/entity';

export function aggregateUpdates<T>(
  updates: T[],
  selectID?: (a: T) => string | number
): Update<T>[] {
  return updates.map((i: T) => {
    let id: string | number;
    if (selectID) {
      id = selectID(i);
    } else {
      id = (<any>i)['id'];
    }

    return <Update<T>>{
      id,
      changes: {...i}
    };
  });
}
