import {Component, EventEmitter, Inject, Input} from '@angular/core';
import {HelpModel} from '@spout/global-web/models';
import {Observable, Subject} from 'rxjs';
import {CONTAINER_DATA, ContentModel} from '../content.model';

@Component({
  selector: 'spt-help-latency-vs-offset',
  templateUrl: './help-latency-vs-offset.component.html',
  styleUrls: ['./help-latency-vs-offset.component.scss']
})
export class HelpLatencyVsOffsetComponent implements ContentModel {
  static ref = HelpModel.LATENCY_VS_OFFSET;
  static title = 'Latency Vs Offset';

  showBackHeader = true;

  helpTitle = HelpLatencyVsOffsetComponent.title;

  private modalClose: Subject<any> = new Subject();

  onModalClose(): Observable<any> {
    return this.modalClose.asObservable();
  }

  constructor(@Inject(CONTAINER_DATA) private data: ContentModel) {
    if (this.data) {
      this.showBackHeader = this.data.showBackHeader;
    }
  }

  closeModal() {
    this.modalClose.next(null);
    this.modalClose.complete();
  }
}
