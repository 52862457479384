import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {HelpContentModule} from '../../common/help-content/help-content.module';
import {HelpNavBackModule} from '../../common/help-nav-back/help-nav-back.module';
import {HelpSampleRateContentComponent} from './help-sample-rate-content/help-sample-rate-content.component';
import {HelpSampleRateComponent} from './help-sample-rate.component';

@NgModule({
  declarations: [HelpSampleRateComponent, HelpSampleRateContentComponent],
  exports: [HelpSampleRateComponent, HelpSampleRateContentComponent],
  imports: [
    CommonModule,
    HelpContentModule,
    HelpNavBackModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HelpSampleRateModule {}
