import {createFeatureSelector, createSelector} from '@ngrx/store';
import {promoCodes} from '../../../../../../../apps/firebase/design-data/promo-codes/promo-codes';
import * as PromoCodeReducer from './promo-code.reducer';
import {Dictionary} from '@ngrx/entity/src/models';
import {PromoCode} from './promo-code.model';

export const selectPromoCodeState =
  createFeatureSelector<PromoCodeReducer.PromoCodeState>(
    PromoCodeReducer.promoCodeFeatureKey
  );

const {selectIds, selectEntities, selectAll, selectTotal} =
  PromoCodeReducer.promoCodeAdapter.getSelectors();

export const selectAllPromoCodes = createSelector(
  selectPromoCodeState,
  (state: PromoCodeReducer.PromoCodeState) => selectAll(state)
);
export const selectPromoCodeEntities = createSelector(
  selectPromoCodeState,
  (state: PromoCodeReducer.PromoCodeState) => selectEntities(state)
);
export const selectPromoCodeIds = createSelector(
  selectPromoCodeState,
  (state: PromoCodeReducer.PromoCodeState) => selectIds(state)
);
export const selectPromoCodeTotal = createSelector(
  selectPromoCodeState,
  (state: PromoCodeReducer.PromoCodeState) => selectTotal(state)
);

export const selectedPromoCodeID = createSelector(
  selectPromoCodeState,
  (state: PromoCodeReducer.PromoCodeState) => state.selectedPromoCodeID
);

export const selectSelectedPromo = createSelector(
  selectPromoCodeEntities,
  selectedPromoCodeID,
  (
    entities: Dictionary<PromoCode>,
    selectedPromoCodeID: string | null
  ): PromoCode | null => {
    if (selectedPromoCodeID && entities[selectedPromoCodeID] !== undefined) {
      return <PromoCode>entities[selectedPromoCodeID];
    }
    return null;
  }
);

export const getPromoCodeByID = (id: string) =>
  createSelector(selectPromoCodeEntities, (entities: Dictionary<PromoCode>) => {
    return entities[id];
  });

export const selectStorageOfSelectedPromo = createSelector(
  selectSelectedPromo,
  (promoCode: PromoCode | null): number => {
    if (promoCode && promoCode.baseStorageGB) {
      return promoCode.baseStorageGB;
    }

    return 0;
  }
);
