import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LetModule, PushModule} from '@ngrx/component';
import {SptSliderModule} from '../spt-slider/spt-slider.module';
import {SptDynamicsCompressorComponent} from './spt-dynamics-compressor.component';

@NgModule({
  declarations: [SptDynamicsCompressorComponent],
  exports: [SptDynamicsCompressorComponent],
  imports: [CommonModule, SptSliderModule, LetModule, PushModule]
})
export class SptDynamicsCompressorModule {}
