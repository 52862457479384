import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {ConsoleState, initialConsoleState} from '@spout/global-web/models';

@Injectable({
  providedIn: 'root'
})
export class ConsoleStore extends ComponentStore<ConsoleState> {
  constructor() {
    super(JSON.parse(JSON.stringify(initialConsoleState)));
  }

  readonly selectExpandAllTracks$ = this.select(state => state.expandAllTracks);
  readonly selectCloseAllTracks$ = this.select(state => !state.expandAllTracks);
  readonly selectHelpMicrophone$ = this.select(state => state.selectMicrophone);
  readonly selectOpenPlayer$ = this.select(state => state.player);
  readonly selectDisableWhenRecordingWhenCurrentTrackIsNotMaster$ = this.select(
    (state: ConsoleState) =>
      state.disableWhenRecordingWhenCurrentTrackIsNotMaster
  );

  readonly consoleToggleAllTracks = this.updater((state: ConsoleState) => {
    return {
      ...state,
      expandAllTracks: !state.expandAllTracks
    };
  });

  authLoggedOut() {
    this.setState((state: ConsoleState) => {
      return JSON.parse(JSON.stringify(initialConsoleState));
    });
  }
}
