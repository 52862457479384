<p>Create your own mix to have more control of each track.</p>

<p>
  Tracks are shared across all mixes tp allow each mix creator to experiment
  with track controls.
</p>

<p>
  To keep collaborators from changing another musician's work without
  notification, some track controls can only be changed by the creator of a Mix.
</p>
