import {TimeStamp} from '@spout/global-any/models';
import {ACCOUNT_ROLE, FEATURE_ID} from '../payment.model';
import {ACCOUNT_FEATURE_KEY} from '../studio-app-keys';

export interface AccountAlgolia {
  email: string | null;
  stageName: string | null;
  displayName: string | null;
  hasSubscription?: boolean;
  uid: string | null;
  active: boolean;
}

export interface AccountDefaultIds {
  defaultMixId: string | null;
  defaultProjectId: string | null;
  defaultSongId: string | null;
  defaultTrackId: string | null;
}

export interface UserAccount extends AccountDefaultIds {
  active: boolean;
  createdAt: TimeStamp | null;
  defaultMixId: string | null;
  defaultProjectId: string | null;
  defaultSongId: string | null;
  defaultTrackId: string | null;
  displayName: string | null;
  email: string | null;
  emailVerified: boolean | null;

  hasGenres: boolean | null;
  hasInstruments: boolean | null;

  /**
   * User is actively interacting with app for set period of time.
   */
  isOnline: boolean;

  phoneNumber: string | null;

  promoCode?: string | null;
  /**
   * Authentication provider received from Google Auth.
   */
  providerId: string | null;

  stageName: string | null;

  totalCloudStorageUsed: number;

  uid: string | null;

  updatedAt: TimeStamp | null;

  subscribedAt: number | null;
}

export interface AccountState extends UserAccount {
  authError: AuthError | null;
  /**
   * When account is received from Firestore, not when authenticated
   */

  isRetrievedFromFirestore: boolean;

  /**
   * When account is received from authStateChanges of firebase auth.
   * This does not mean the account has been received from firestore.
   */
  isLoggedIn: boolean;
  photoURL: string | null;

  uidHash: string | null;
}

export const userAccountProperties: string[] = [
  'active',
  'createdAt',
  'defaultMixId',
  'defaultProjectId',
  'defaultSongId',
  'defaultTrackId',
  'displayName',
  'email',
  'emailVerified',
  'hasGenres',
  'hasInstruments',
  'isOnline',
  'phoneNumber',
  'promoCode',
  'providerId',
  'stageName',
  'totalCloudStorageUsed',
  'subscribedAt',
  'uid',
  'updatedAt'
];

export interface MentorAccount {
  username: string | null;
  displayName: string | null;
  uid: string | null;
}

export const accountfirestoreMentorProperties: string[] = [
  'username',
  'displayName',
  'uid'
];

export interface PublicProfile {
  production?: boolean;
  stageName?: string;
  displayName?: string;
  photoURL?: string;
  uid?: string;
}

export interface PartialAccountStates {
  [ACCOUNT_FEATURE_KEY]: AccountState;
}

export interface AccountStateConnect {
  user: AccountState;
  doConnect: boolean;
}

export const initialAccountState: AccountState = {
  // UserAccount
  active: false,
  createdAt: {
    nanoseconds: 0,
    seconds: 0
  },
  defaultProjectId: null,
  defaultSongId: null,
  defaultMixId: null,
  defaultTrackId: null,
  displayName: null,
  email: null,
  emailVerified: null,
  hasGenres: false,
  hasInstruments: false,
  isOnline: false,
  phoneNumber: null,
  promoCode: null,
  providerId: null,
  stageName: null,
  totalCloudStorageUsed: 0,
  uid: '',
  updatedAt: {
    nanoseconds: 0,
    seconds: 0
  },
  subscribedAt: null,

  // AccountState
  authError: null,
  isRetrievedFromFirestore: false,
  isLoggedIn: false,
  photoURL: null,
  uidHash: null
};

export interface ProviderIDAndEmail {
  providerId: string;
  email: string;
}

export interface UserImage {
  isPhotoURL: boolean;
  photoURL: string;
  character: string;
  name: string;
}

export interface AuthError {
  code: number | null;
  message: string | null;
}

export interface AuthEmail {
  email: string;
  password: string;
}

/**
 * stsTokenManager
 */
// export interface StsTokenManager {
//   apiKey: string;
//   refreshToken: string;
//   accessToken: string;
//   expirationTime: number;
// }

/**
 * Provider Data
 */
export interface Provider {
  uid: string;
  displayName: string;
  photoURL: string;
  email: string;
  providerId: string;
}

// export const permissionInitialState: SptPermissionState = {
//   accountRole: ACCOUNT_ROLE.PERSONAL,
//   projectRole: ACCOUNT_ROLE.PERSONAL,
//   permissionRole: ACCOUNT_ROLE.PERSONAL,
//   permissions: {
//     [FEATURE_ID.ADD_ANNOTATIONS]: false,
//     [FEATURE_ID.ADD_COLLABORATORS]: false,
//     [FEATURE_ID.CHAT]: false,
//     [FEATURE_ID.CLEAR_TRACKS]: false,
//     [FEATURE_ID.CREATE_MIXES]: false,
//     [FEATURE_ID.CREATE_PROJECTS]: false,
//     [FEATURE_ID.CREATE_SONGS]: false,
//     [FEATURE_ID.CREATE_TRACKS]: false,
//     [FEATURE_ID.COPY_TRACKS]: false,
//     [FEATURE_ID.DELETE_TRACKS]: false,
//     [FEATURE_ID.EXPORT_SONG]: false,
//     [FEATURE_ID.IMPORT_AUDIO_FILES]: false,
//     [FEATURE_ID.RECORD]: false,
//     // [FEATURE_ID.RENDER_MIX]: false,
//     [FEATURE_ID.SHARE_TRACKS]: false
//   }
// };
