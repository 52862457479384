import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  BatchCreateNewMix,
  Mix,
  MixEntity,
  TrackMix
} from '@spout/global-any/models';
import {
  firestoreMixByIdPath,
  firestoreTrackMixByIdPath
} from '@spout/global-web/fns';

import {merge} from '@uiux/fn';
import {DocumentReference, WriteBatch} from '@firebase/firestore';
import {EMPTY, from, Observable, Observer} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {Exists, CustomFirestoreService} from '../firebase';
import {getMixEntityById} from './mix-storage.selectors';

@Injectable({
  providedIn: 'root'
})
export class MixesService {
  constructor(
    private sptFirestore: CustomFirestoreService,
    private store: Store
  ) {}

  createMixInFirestore(mix: Partial<MixEntity>): Observable<MixEntity> {
    return new Observable((observer: Observer<any>) => {
      // CREATE PROJECT IN FIRESTORE
      this.sptFirestore
        .setDocIfNotExist<MixEntity>(firestoreMixByIdPath(mix), mix)
        .subscribe(
          (r: Exists<Mix>) => {
            // DOES NOT DISPATCH ACTION
            // THIS DATA GOES NO WHERE
            observer.next(r.data);
          },
          error => {
            observer.error(error);
          }
        );
    });
  }

  updateMixToFirestore(
    mixConfig: Partial<MixEntity>
  ): Observable<Exists<MixEntity>> {
    if (mixConfig && mixConfig.id) {
      return this.store.pipe(
        select(getMixEntityById, {id: mixConfig.id}),
        take(1),
        switchMap((mixEntity: MixEntity | undefined) => {
          let mix;

          if (mixEntity) {
            mix = merge(mixEntity, mixConfig);
          } else {
            mix = mixConfig;
          }

          // console.log('mixConfig', mixConfig, firestoreMixByIdPath(mixConfig));
          // console.log('mix', mix, firestoreMixByIdPath(mix));

          return this.sptFirestore.upsertDoc<MixEntity>(
            firestoreMixByIdPath(mix),
            mix
          );
        })
      );
    }

    return EMPTY;
  }

  batchUpdateFirestoreMixes(mixes: MixEntity[]): Observable<MixEntity[]> {
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      mixes.forEach((mix: MixEntity) => {
        const doc: DocumentReference = this.sptFirestore
          // .collection(fileCollection(file))
          .docRef(firestoreMixByIdPath(mix));

        batch.update(doc, <any>mix);
      });

      batch.commit().then(
        () => {
          observer.next(mixes);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  batchCreateNewMix(d: BatchCreateNewMix): Observable<BatchCreateNewMix> {
    const that = this;
    return new Observable((observer: Observer<any>) => {
      const batch: WriteBatch = this.sptFirestore.writeBatch();

      d.trackMixes.forEach((trackMix: TrackMix) => {
        const doc: DocumentReference = this.sptFirestore
          // .collection(fileCollection(file))
          .docRef(firestoreTrackMixByIdPath(trackMix));

        batch.set(doc, that.sptFirestore.payloadForSet(trackMix));
      });

      const mixDoc: DocumentReference = this.sptFirestore
        // .collection(fileCollection(file))
        .docRef(firestoreMixByIdPath(d.mix));

      batch.set(mixDoc, that.sptFirestore.payloadForSet(d.mix));

      batch.commit().then(
        () => {
          observer.next(d);
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  deleteMixes(mixes: MixEntity[]) {
    const batch: WriteBatch = this.sptFirestore.writeBatch();

    mixes.forEach((mix: MixEntity) => {
      const doc: DocumentReference = this.sptFirestore
        // .collection(fileCollection(file))
        .docRef(firestoreMixByIdPath(mix));

      batch.delete(doc);
    });

    return from(batch.commit());
  }
}
