import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {ACCOUNT_ROLE, FeaturePermission} from '@spout/global-web/models';

export const loadPermissions = createAction(
  '[Permission/API] Load Permissions',
  props<{permissions: FeaturePermission[]}>()
);

export const addPermission = createAction(
  '[Permission/API] Add Permission',
  props<{permission: FeaturePermission}>()
);

export const upsertPermission = createAction(
  '[Permission/API] Upsert Permission',
  props<{permission: FeaturePermission}>()
);

export const addPermissions = createAction(
  '[Permission/API] Add Permissions',
  props<{permissions: FeaturePermission[]}>()
);

export const upsertPermissions = createAction(
  '[Permission/API] Upsert Permissions',
  props<{permissions: FeaturePermission[]}>()
);

export const updatePermission = createAction(
  '[Permission/API] Update Permission',
  props<{permission: Update<FeaturePermission>}>()
);

export const updatePermissions = createAction(
  '[Permission/API] Update Permissions',
  props<{permissions: Update<FeaturePermission>[]}>()
);

export const deletePermission = createAction(
  '[Permission/API] Delete Permission',
  props<{id: string}>()
);

export const deletePermissions = createAction(
  '[Permission/API] Delete Permissions',
  props<{ids: string[]}>()
);

export const clearPermissions = createAction(
  '[Permission/API] Clear Permissions'
);

export const addPermissionAccountRole = createAction(
  '[Permission/API] Add Permission Account Role',
  props<{role: ACCOUNT_ROLE}>()
);
