import {DocumentData, DocumentReference} from '@firebase/firestore';
import {Subscription} from '../+subscriptions';
import {PriceInterval} from '../+prices';

// https://stripe.com/docs/api/prices/object
export interface Price {
  // local only
  id: string;
  name: string;
  product: string;
  role: string | null;

  // the storageProduct this price belongs to has a trial options
  productHasTrial: boolean;
  isTrial: boolean;

  // in database
  trial_period_days: number;
  interval_count: number; // 1
  recurring: {
    interval: string; // 'month'
    usage_type: string; // 'licensed'
    trial_period_days: number;
    interval_count: number; // 1
    aggregate_usage: number;
  };
  transform_quantity: number;
  unit_amount: number; // number
  currency: string; // 'usd'
  tiers_mode: number;
  tiers: number;
  description: number;
  type: string; // 'recurring'
  interval: PriceInterval; // 'month'
  billing_scheme: string; // 'per_unit'
  active: boolean; // boolean
  parentID: string;

  // Properties for UI
  productID: string;
}

export interface PriceEntities {
  [id: string]: Price;
}

export interface PriceByIntervalDict {
  month: Price | null;
  year: Price | null;
}

export interface StorageAndPriceByIntervalDict {
  baseMonthlyStoragePrice: Price | null;
  monthlyStoragePrice: Price | null;
  yearlyStoragePrice: Price | null;
  baseYearlyStoragePrice: Price | null;
  storageProduct: Product | null;
}

export interface CurrentSubscriptionEntities {
  [id: string]: Subscription;
}

export enum PRODUCT_FEATURE_LIST {
  COLLABORATOR = 'collaborator',
  STORAGE = 'storage',
  EARLY_ADOPTER = 'earlyAdopter',
  BAND = 'band',
  PERSONAL = 'personal'
}

/**
 * Stripe api returns string for all values,
 * values need to be converted to proper type
 */
export interface ProductMetaData {
  trial: string | number | null | 'null';
  sortOrder: string | number | null | 'null';
  baseStorageGB: string | number;
  addon: 'storage' | 'null' | null;
  accountRole: string | number;
  description: string | 'null' | null;
  productType: 'plan' | 'addon';
  featureList:
    | 'collaborator'
    | 'storage'
    | 'earlyAdopter'
    | 'band'
    | 'personal';
}

export interface Product {
  // local only
  id: string;

  // database
  active: boolean;
  description: string;
  images: string[];
  name: string;
  role: string | null;
  metadata: ProductMetaData;

  // metadata
}

export interface ProductEntities {
  [id: string]: Product | null | undefined;
}

export interface PriceOptions {
  product: Product;
  prices: Price[];
}

export interface PriceOptionsFlat {
  product: Product;
  price: Price;
}

export interface SubscriptionItemDict {
  subscriptionItemEntities: {[id: string]: Subscription};
  subscriptionId: string | null;
}

// firestore object created by subscriptions-stripe payments
export interface ProductFirestoreSubscription {
  product: DocumentReference<DocumentData>;
  prices: DocumentReference<DocumentData>[];
  items: Subscription[];
  price: DocumentReference<DocumentData>;
}

export interface ProductWithPrices {
  product: Product;
  prices: Price[];
}

/**
 * To Purchase a subscription
 */
// export interface SubscribePayload {
//   dynamic_tax_rates: string[];
//   price: string | undefined; // price id
//   quantity: number;
//   customer?: string;
//   trial_end?: number;
// }

// export interface Customer {
//   stripeId: string;
//   stripeLink: string;
// }
