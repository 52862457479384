import {PriceInterval} from '../../+prices';
import {PriceByIntervalDict, Product} from '../../+product';
import {ProductWizardStore} from '../../+subscriptions';

export function updateProductPrice(
  state: ProductWizardStore,
  productWithPrice: {
    price: PriceByIntervalDict;
    product: Product;
    interval: PriceInterval;
  }
) {
  return {
    ...state,
    selectedProduct: productWithPrice.product,
    selectedProductPrice: productWithPrice.price[productWithPrice.interval],
    interval: productWithPrice.interval
    // storageProduct: productWithPrice.storage.storageProduct
    // baseMonthlyStoragePrice:
    //   state.additionalStoragePerGB > 0
    //     ? productWithPrice.storage[productWithPrice.interval]
    //     : productWithPrice.interval === 'month'
    //     ? productWithPrice.storage.baseMonthlyStoragePrice
    //     : productWithPrice.storage.baseYearlyStoragePrice
  };
}
