<h1 mat-dialog-title class="warn">Track Has Audio Recorded</h1>
<mat-dialog-content>
  <div class="push-top-10 push-bottom-10">
    Recording will overwrite this track's saved audio.
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="okWithoutClearAudio()">
    <mat-icon color="accent">fiber_manual_record</mat-icon> Record
  </button>

  <button mat-flat-button (click)="cancel.emit(true)" class="push-left-20">
    Cancel
  </button>
</mat-dialog-actions>
