import {Injectable, NgZone} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {TimeStamp} from '@spout/global-any/models';
import {DIALOG_COMPONENT} from '@spout/global-web/models';
import {combineLatest, mergeMap} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {accountSaveFirebase} from '../../+account/account.actions';
import {
  CheckHasSubscribed,
  selectAccountLoadedAndCreateAt,
  selectSubscribedAtFromAccount$
} from '../../+account/account.selectors';
import {
  closeDialog,
  openDialog
} from '../../+dialog-queue/dialog-queue.actions';
import {CustomFirestoreService} from '../../firebase';
import {accountYoungerThanNumberTrialDays} from '../stripe.selectors';
import {
  CheckHasActiveSubscription,
  selectIsRetrievedFromFirestoreHasSubscription$,
  selectTrialDays
} from './subscription.selectors';
import {SubscriptionService} from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionEffects {
  constructor(
    private _actions$: Actions,
    private _firestore: CustomFirestoreService,
    private _store: Store,
    private _subscriptionService: SubscriptionService,
    private _zone: NgZone
  ) {
    const that = this;

    combineLatest([
      this._store.pipe(selectAccountLoadedAndCreateAt),
      this._store.pipe(
        select(selectTrialDays),
        filter((trialDays: number) => trialDays > 0)
      )
    ])
      .pipe(
        mergeMap(([createdAt, trialDays]: [TimeStamp | null, number]) => {
          return combineLatest([
            this._store.pipe(selectSubscribedAtFromAccount$),
            this._store.pipe(selectIsRetrievedFromFirestoreHasSubscription$)
          ]).pipe(
            map(
              ([a, s]: [CheckHasSubscribed, CheckHasActiveSubscription]): [
                boolean,
                CheckHasSubscribed,
                CheckHasActiveSubscription
              ] => {
                return [
                  accountYoungerThanNumberTrialDays(createdAt, trialDays),
                  a,
                  s
                ];
              }
            )
          );
        })
      )
      .subscribe(
        ([accountYoungerThanNumberTrialDays, a, s]: [
          boolean,
          CheckHasSubscribed,
          CheckHasActiveSubscription
        ]) => {
          if (!accountYoungerThanNumberTrialDays) {
            if (s.hasActiveSubscription) {
              if (a.subscribedAt === null) {
                that._zone.run(() => {
                  this._store.dispatch(
                    accountSaveFirebase({
                      payload: {
                        subscribedAt: new Date().valueOf()
                      }
                    })
                  );
                });
              } else {
                // do nothing
              }

              that._zone.run(() => {
                this._store.dispatch(
                  closeDialog({id: DIALOG_COMPONENT.STRIPE_SUBSCRIBE})
                );
              });
            } else {
              that._zone.run(() => {
                this._store.dispatch(
                  openDialog({id: DIALOG_COMPONENT.STRIPE_SUBSCRIBE})
                );
              });
            }
          } else {
            that._zone.run(() => {
              this._store.dispatch(
                closeDialog({id: DIALOG_COMPONENT.STRIPE_SUBSCRIBE})
              );
            });
          }
        }
      );
  }
}
