import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CheckoutWaitDialogComponent} from './checkout-wait-dialog.component';

@NgModule({
  declarations: [CheckoutWaitDialogComponent],
  imports: [CommonModule, MatDialogModule, MatProgressSpinnerModule],
  exports: [CheckoutWaitDialogComponent]
})
export class CheckoutWaitDialogModule {}
