import {FileType, FileUint8ArrayType} from '@spout/global-any/models';
import {
  LoadPlayerAudioWorklet,
  TrackEntityAndAudioFileMetaDataEntity,
  WaveformValues
} from '@spout/global-web/models';
import {from, Observable} from 'rxjs';
import {
  convertArrayBufferToAudioBuffer,
  convertBlobToAudioBuffer,
  convertUint8ArrayToAudioBuffer
} from './convert-to-audiobuffer';
import {convertAudioBufferToFloat32Array} from './convert-to-float32array';
import {
  convertArrayBufferTypeToFileUint8ArrayType,
  convertBlobToUint8Array
} from './convert-to-uint8Array';
import {copyArrayBuffer, copyUint8Array} from './helpers';
import {getWaveformValuesFromAudioBuffer} from './parse-waveform-data';

export function parseRecordExport(
  trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity,
  uint8ArrayType: FileUint8ArrayType,
  waveformValues: WaveformValues,
  recordOffsetMs: number,
  inputs: Float32Array[][] = [[]]
): LoadPlayerAudioWorklet {
  return {
    trackEntityAndAudioFileMetaDataEntity,
    uint8ArrayType,
    waveformValues,
    recordOffsetMs,
    inputs
  };
}

/**
 *
 * @param trackEntityAndAudioFileMetaDataEntity
 * @param uint8ArrayType
 * @param audioContext
 */
export async function convertUint8ArrayToParsedRecordExport(
  trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity,
  uint8ArrayType: FileUint8ArrayType,
  audioContext: AudioContext
): Promise<LoadPlayerAudioWorklet> {
  // console.log(
  //   'convertUint8ArrayToParsedRecordExport sampleRate',
  //   trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity.sampleRate
  // );
  // console.log('AudioContext SampleRate', audioContext.sampleRate);

  const _audioContext = new AudioContext({
    latencyHint: 0
  });

  const audioBuffer1: AudioBuffer = await convertUint8ArrayToAudioBuffer(
    uint8ArrayType.uint8Array,
    audioContext
  );
  const audioBuffer2: AudioBuffer = await convertUint8ArrayToAudioBuffer(
    uint8ArrayType.uint8Array,
    audioContext
  );

  const _trackEntityAndAudioFileMetaDataEntity = {
    ...trackEntityAndAudioFileMetaDataEntity,
    audioFileMetaDataEntity: {
      ...trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity,
      duration: audioBuffer1.duration,
      sampleRate: audioBuffer1.sampleRate,
      numberOfChannels: audioBuffer1.numberOfChannels
    }
  };

  const inputs: Float32Array[][] =
    convertAudioBufferToFloat32Array(audioBuffer1);

  const waveformValues: WaveformValues = await getWaveformValuesFromAudioBuffer(
    audioBuffer2
  );

  return {
    trackEntityAndAudioFileMetaDataEntity:
      _trackEntityAndAudioFileMetaDataEntity,
    waveformValues,
    recordOffsetMs: 0,
    uint8ArrayType,
    inputs
  };
}

/**
 *
 * @param trackEntityAndAudioFileMetaDataEntity
 * @param blob: Blob
 * @param audioContext
 */
export async function convertBlobToParsedRecordExport(
  trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity,
  blob: Blob,
  audioContext: AudioContext
): Promise<LoadPlayerAudioWorklet> {
  const audioBuffer1: AudioBuffer = await convertBlobToAudioBuffer(
    blob,
    audioContext
  );

  const audioBuffer2: AudioBuffer = await convertBlobToAudioBuffer(
    blob,
    audioContext
  );

  const _trackEntityAndAudioFileMetaDataEntity = {
    ...trackEntityAndAudioFileMetaDataEntity,
    audioFileMetaDataEntity: {
      ...trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity,
      duration: audioBuffer1.duration,
      sampleRate: audioBuffer1.sampleRate,
      numberOfChannels: audioBuffer1.numberOfChannels
    }
  };

  const waveformValues: WaveformValues = await getWaveformValuesFromAudioBuffer(
    audioBuffer1
  );

  const inputs: Float32Array[][] =
    convertAudioBufferToFloat32Array(audioBuffer2);

  const uint8ArrayType: FileUint8ArrayType = await convertBlobToUint8Array(
    blob
  );

  return {
    trackEntityAndAudioFileMetaDataEntity:
      _trackEntityAndAudioFileMetaDataEntity,
    waveformValues,
    recordOffsetMs: 0,
    uint8ArrayType,
    inputs
  };
}

/**
 *
 * @param trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity
 * @param blob: Blob
 * @param type: FileType
 * @param audioContext: AudioContext
 */
export async function convertBlobTypeToParsedRecordExport(
  trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity,
  blob: Blob,
  type: FileType,
  audioContext: AudioContext
): Promise<LoadPlayerAudioWorklet> {
  const arrayBuffer: ArrayBuffer = await blob.arrayBuffer();

  const audioBuffer: AudioBuffer = await convertArrayBufferToAudioBuffer(
    copyArrayBuffer(arrayBuffer),
    audioContext
  );

  const _trackEntityAndAudioFileMetaDataEntity = {
    ...trackEntityAndAudioFileMetaDataEntity,
    audioFileMetaDataEntity: {
      ...trackEntityAndAudioFileMetaDataEntity.audioFileMetaDataEntity,
      duration: audioBuffer.duration,
      sampleRate: audioBuffer.sampleRate,
      numberOfChannels: audioBuffer.numberOfChannels
    }
  };

  const uint8ArrayType: FileUint8ArrayType =
    await convertArrayBufferTypeToFileUint8ArrayType(
      copyArrayBuffer(arrayBuffer),
      type
    );

  const waveformValues: WaveformValues = await getWaveformValuesFromAudioBuffer(
    audioBuffer
  );

  const inputs: Float32Array[][] =
    convertAudioBufferToFloat32Array(audioBuffer);

  return {
    trackEntityAndAudioFileMetaDataEntity:
      _trackEntityAndAudioFileMetaDataEntity,
    waveformValues,
    recordOffsetMs: 0,
    uint8ArrayType,
    inputs
  };
}

export const convertBlobTypeToParsedRecordExport$ = (
  trackEntityAndAudioFileMetaDataEntity: TrackEntityAndAudioFileMetaDataEntity,
  blob: Blob | File,
  type: FileType,
  audioContext: AudioContext
): Observable<LoadPlayerAudioWorklet> =>
  from(
    convertBlobTypeToParsedRecordExport(
      trackEntityAndAudioFileMetaDataEntity,
      blob,
      type,
      audioContext
    )
  );
