import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {MixEntity} from '@spout/global-any/models';
import {MixState} from '@spout/global-web/models';
import {authLoggedOut} from '../+account/auth.actions';
import {
  createMixAction,
  createProjectAction,
  createSongAction,
  createTrackAction
} from '../actions/create-entites.actions';
import {
  addMix,
  addMixs,
  clearMixs,
  deleteMix,
  deleteMixs,
  loadMixs,
  updateMix,
  updateMixs,
  upsertMix,
  upsertMixsFromFirestore,
  upsertMixsFromStorage,
  upsertModifiedMixsFromFirestore
} from './mix.actions';

export const mixAdapter: EntityAdapter<MixEntity> =
  createEntityAdapter<MixEntity>();

export const initialMixState: MixState = mixAdapter.getInitialState({
  // additional entity state properties
  loaded: false
});

const reducer = createReducer(
  initialMixState,
  on(addMix, (state, action) => mixAdapter.addOne(action.mix, state)),
  on(createProjectAction, (state, action) =>
    mixAdapter.addOne(action.mix, state)
  ),
  on(createSongAction, (state, action) => mixAdapter.addOne(action.mix, state)),
  on(createMixAction, (state, action) => mixAdapter.addOne(action.mix, state)),
  on(createTrackAction, (state, action) =>
    mixAdapter.updateMany(action.mixConfigs, state)
  ),
  on(upsertMix, (state, action) => mixAdapter.upsertOne(action.mix, state)),
  on(addMixs, (state, action) => mixAdapter.addMany(action.mixConfigs, state)),
  on(upsertMixsFromFirestore, (state, action) =>
    mixAdapter.upsertMany(action.mixConfigs, state)
  ),
  on(upsertModifiedMixsFromFirestore, (state, action) =>
    mixAdapter.upsertMany(action.mixConfigs, state)
  ),
  on(upsertMixsFromStorage, (state, action) =>
    mixAdapter.upsertMany(action.mixConfigs, state)
  ),
  on(updateMix, (state, action) => {
    return mixAdapter.updateOne(action.mix, state);
  }),
  on(updateMixs, (state, action) =>
    mixAdapter.updateMany(action.mixConfigs, state)
  ),
  on(deleteMix, (state, action) => mixAdapter.removeOne(action.id, state)),
  on(deleteMixs, (state, action) => mixAdapter.removeMany(action.ids, state)),
  on(loadMixs, (state, action) => mixAdapter.setAll(action.mixConfigs, state)),
  on(clearMixs, state => mixAdapter.removeAll(state)),
  on(authLoggedOut, (state, action) => {
    return mixAdapter.removeAll(state);
  })
);

export function mixesReducer(state: MixState | undefined, action: Action) {
  return reducer(state, action);
}
