<h1 mat-dialog-title>Loading Shopping Cart</h1>
<div mat-dialog-content>
  <h3 class="accent">Do not reload the page or hit the back button.</h3>

  <div class="progress-spinner-wrapper">
    <mat-progress-spinner
      class="example-margin"
      color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</div>
<!--<div mat-dialog-actions>-->
<!--  <button-->
<!--    mat-flat-button-->
<!--    [mat-dialog-close]="true"-->
<!--    color="primary"-->
<!--    cdkFocusInitial>-->
<!--    Ok-->
<!--  </button>-->
<!--</div>-->
