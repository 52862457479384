import * as fromAppStore from './+appstore/appstore-in-app-purchase.reducer';
import {AppstoreInAppPurchaseService} from './+appstore/appstore-in-app-purchase.service';

export const appStoreReducers = {
  [fromAppStore.appstoreInAppPurchaseFeatureKey]: fromAppStore.reducer
};

export const appStoreInitialStates = {
  [fromAppStore.appstoreInAppPurchaseFeatureKey]:
    fromAppStore.initialAppstoreInAppPurchaseState
};

export const appStoresEffects = [AppstoreInAppPurchaseService];
