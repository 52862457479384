import {
  AudioFileMetaDataEntity,
  FILE_TYPE,
  MixEntity,
  SongEntity,
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet
} from '@spout/global-any/models';
import {AccountState} from '@spout/global-web/models';
import {assignMethod, objectMethodAssign} from '@uiux/fn';
import {
  createInitialAudioFileMetaDataEntity,
  setAudioFileMetaDataEntityProps
} from '../entities/create-initial-audio-file-meta-data-entity';

import {
  createInitialTrackEntity,
  setTrackEntityProps
} from '../entities/create-initial-track-entity';
import {
  assignTrackMixAudioFileMetaMethod,
  createInitialTrackMixConfig
} from '../entities/create-initial-track-mix';

/**
 * Create new track-audio and add to mix and song
 * @param account
 * @param song
 * @param mixes
 * @param currentSongTrackCount number
 * @param fileId
 * @param name
 */
export function createAndAddAudioTrackEntityToSongAndMix(
  account: AccountState,
  song: SongEntity,
  mixes: MixEntity[],
  currentSongTrackCount: number = 0,
  fileId?: string,
  name?: string
): {
  trackEntity: TrackEntity;
  audioFileMetaDataEntity: AudioFileMetaDataEntity;
  mixes: MixEntity[]; // can have multiple per song
  trackMixes: TrackMix[]; // can have multiple per song
} {
  const trackEntity = objectMethodAssign(
    createInitialTrackEntity(account, song, null, name)
  ).pipe(
    assignMethod(setTrackEntityProps, {
      name: name ? name : 'Audio',
      description: `for ${song.name}`
    })
  );

  const audioFileMetaDataEntity = objectMethodAssign(
    createInitialAudioFileMetaDataEntity(
      account,
      trackEntity,
      FILE_TYPE.AUDIO_WAV,
      fileId,
      name
    )
  ).pipe(
    assignMethod(setAudioFileMetaDataEntityProps, {
      name: name ? name : 'Audio'
    })
  );

  // console.log('before trackmixes =>');
  // console.log(account, trackEntity, audioFileMetaDataEntity);

  // Create TrackMix for each Mix
  const trackMixes: TrackMix[] = mixes.map((mix: MixEntity) => {
    return objectMethodAssign<TrackMix>(
      createInitialTrackMixConfig(
        account,
        trackEntity,
        mix.id,
        audioFileMetaDataEntity
      )
    ).pipe(
      assignMethod<TrackMix, TrackMixAudioSnippet>(
        assignTrackMixAudioFileMetaMethod(audioFileMetaDataEntity),
        {
          isDefault: true
        }
      )
    );
  });

  // console.log(trackMixes);

  return {
    trackEntity,
    audioFileMetaDataEntity,
    mixes,
    trackMixes
  };
}
