import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {ProductService} from './product.service';
import {PartialProductState} from './product.reducer';

@Injectable({
  providedIn: 'root'
})
export class ProductEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<PartialProductState>,
    private _productService: ProductService
  ) {}
}
