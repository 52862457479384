import {
  AudioFileMetaDataEntity,
  Mix,
  MixEntity,
  ProjectEntity,
  SongEntity,
  TrackEntity,
  TrackMix,
  TrackMixAudioSnippet
} from '@spout/global-any/models';
import {collectionItemsHaveValue} from '@uiux/fn';

export function songIsOfProject(
  project: ProjectEntity,
  song: SongEntity
): boolean {
  return song.projectId === project.id;
}

export function trackIsOfProject(
  project: ProjectEntity,
  track: TrackEntity
): boolean {
  return track.projectId === project.id;
}

export function mixIsOfProject(
  project: ProjectEntity,
  mix: MixEntity
): boolean {
  return mix.projectId === project.id;
}

export function mixIsOfSong(song: SongEntity, mix: MixEntity): boolean {
  return collectionItemsHaveValue([song, mix]) && mix.songId === song.id;
}

export function getTrackMixOfTrackAndMix(
  mix: MixEntity,
  track: TrackEntity,
  trackMixes: TrackMix[]
): TrackMix | null {
  const found = trackMixes.find((trackMix: TrackMix) => {
    return trackMix.trackId === track.id && trackMix.mixId === mix.id;
  });
  return found || null;
}

export function getAudioMetaDataEntitiesOfTrackMix(
  trackMix: TrackMix,
  audioFiles: AudioFileMetaDataEntity[]
): AudioFileMetaDataEntity[] {
  if (audioFiles && audioFiles.length) {
    return <AudioFileMetaDataEntity[]>Object.values(trackMix.audioSnippets).map(
      (snippet: TrackMixAudioSnippet) => {
        return audioFiles.find(
          (audioFileMetaDataEntity: AudioFileMetaDataEntity) => {
            return (
              audioFileMetaDataEntity.id === snippet.audioFileMetaDataEntityId
            );
          }
        );
      }
    );
  }

  return [];
}

export function ifAllAreInSameProject(
  project: ProjectEntity,
  song: SongEntity,
  mix: MixEntity,
  track: TrackEntity
): boolean {
  return (
    trackIsOfProject(project, track) &&
    mixIsOfProject(project, mix) &&
    songIsOfProject(project, song)
  );
}

export function trackIsInSong(song: SongEntity, track: TrackEntity): boolean {
  return track.songId === song.id;
}

export function projectChildrenAreRelated(
  project: ProjectEntity | undefined,
  song: SongEntity | undefined,
  mix: MixEntity | undefined,
  track: TrackEntity | undefined
): boolean {
  if (project && song && mix && track) {
    return (
      collectionItemsHaveValue([project, song, mix, track]) &&
      ifAllAreInSameProject(project, song, mix, track) &&
      trackIsInSong(song, track)
    );
  }

  return false;
}
