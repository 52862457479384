
export class FirebaseConfig {
	projectId:string;
	apiKey:string;
	authDomain:string;
	databaseURL:string;
	storageBucket:string;
	appId:string;
	messagingSenderId:string;
	measurementId:string

	constructor(
		projectId:string,
		apiKey:string,
		authDomain:string,
		databaseURL:string,
		storageBucket:string,
		appId:string,
		messagingSenderId:string,
		measurementId:string,
	) {
	this.projectId = projectId;
	this.apiKey = apiKey;
	this.authDomain = authDomain;
	this.databaseURL = databaseURL;
	this.storageBucket = storageBucket;
	this.appId = appId;
	this.messagingSenderId = messagingSenderId;
	this.measurementId = measurementId;
	}
}

let firebaseProdStudioAppConfig: FirebaseConfig = {
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

  apiKey: "AIzaSyC7VBAUaFgaQKmEwnaMMxhdBOTamFei5nw",

  authDomain: "spout-prod.firebaseapp.com",

  databaseURL: "https://spout-prod.firebaseio.com",

  projectId: "spout-prod",

  storageBucket: "spout-prod.appspot.com",

  messagingSenderId: "601310708173",

  appId: "1:601310708173:web:b4978e1c03d06edb560484",

  measurementId: "G-5KQW12EJTS"

};

let firebaseProdMarketingConfig: FirebaseConfig = {
  apiKey: "AIzaSyC7VBAUaFgaQKmEwnaMMxhdBOTamFei5nw",

  authDomain: "spout-prod.firebaseapp.com",

  databaseURL: "https://spout-prod.firebaseio.com",

  projectId: "spout-prod",

  storageBucket: "spout-prod.appspot.com",

  messagingSenderId: "601310708173",

  appId: "1:601310708173:web:de1a9bca23bf1372560484",

  measurementId: "G-FEW2RFJHEY"


};

export  {firebaseProdStudioAppConfig, firebaseProdMarketingConfig};

