export function copyUint8Array(src: Uint8Array): Uint8Array {
  const dst: Uint8Array = new Uint8Array(src.byteLength);
  dst.set(src, 0);
  return dst;
}

// https://stackoverflow.com/questions/10100798/whats-the-most-straightforward-way-to-copy-an-arraybuffer-object
export function copyArrayBuffer(src: ArrayBuffer): ArrayBuffer {
  const dst = new ArrayBuffer(src.byteLength);
  new Uint8Array(dst).set(new Uint8Array(src));
  return dst;
}
