import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HelpWorkspaceComponent} from './help-workspace.component';

@NgModule({
  declarations: [HelpWorkspaceComponent],
  exports: [HelpWorkspaceComponent],
  imports: [CommonModule]
})
export class HelpWorkspaceModule {}
