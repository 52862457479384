import {Injectable, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {TrackMix} from '@spout/global-any/models';
import {
  resetTrackMixAudioSnippet,
  updateTrackMixAudio
} from '@spout/global-web/fns';
import {LoadPlayerAudioWorklet} from '@spout/global-web/models';
import {hasValue} from '@uiux/fn';
import {EMPTY} from 'rxjs';
import {map, mergeMap, switchMap, take} from 'rxjs/operators';
import {setTrackMixAsMaster} from '../+mixes/mix.actions';
import {clearTrackEffect, deleteTrackEffect} from '../+tracks/track.actions';
import {createDefaultConfigsToFirestore} from '../actions/common.actions';
import {
  createProjectAction,
  createSongAction,
  createTrackAction
} from '../actions/create-entites.actions';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {
  deleteTrackMixs,
  updateTrackMix,
  updateTrackMixs
} from './track-mix.actions';
import {
  getTrackMixesByTrackId,
  selectCurrentTrackMixes
} from './track-mix.selectors';
import {TrackMixesService} from './track-mixes.service';

@Injectable({providedIn: 'root'})
export class TrackMixesEffects {
  createTrackEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        // NOTE do not add createTracksAction, that is handled in the import-files.service
        ofType(
          createDefaultConfigsToFirestore,
          createProjectAction,
          createSongAction
        ),
        mergeMap(action => {
          return this.trackMixService.createTrackMixInFirestore(
            action.trackMix
          );
        })
      ),
    {dispatch: false}
  );

  createTrackMixesEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        // NOTE do not add createTracksAction, that is handled in the import-files.service
        ofType(createTrackAction),
        switchMap(action => {
          return this.trackMixService.batchCreateTrackMixesInFirestore(
            action.trackMixs
          );
        })
      ),
    {dispatch: false}
  );

  updateTrackMixes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTrackMixs),
        map(action => {
          return action.trackMixs.map((file: Update<TrackMix>) => {
            return <TrackMix>file.changes;
          });
        }),
        switchMap((files: TrackMix[]) => {
          // const toUpdate = action.files.filter((file) => file.sync);

          // .sync
          return this.trackMixService.batchUpdateFirestoreTrackMixes(files);
        })
      ),
    {dispatch: false}
  );

  updateTrackMix$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTrackMix),
        map(action => {
          return <TrackMix>action.trackMix.changes;
        }),
        switchMap((trackMix: TrackMix) => {
          // const toUpdate = action.files.filter((file) => file.sync);

          // .sync
          return this.trackMixService.updateTrackMixToFirestore(trackMix);
        })
      ),
    {dispatch: false}
  );

  deleteTrackEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTrackEffect),
      switchMap(action => {
        return this.store.pipe(
          select(getTrackMixesByTrackId, {
            trackId: action.trackModel.trackEntity.id
          }),
          take<TrackMix[]>(1),
          switchMap((trackMixes: TrackMix[]) => {
            return this.trackMixService
              .batchDeleteFirestoreTrackMixes(trackMixes)
              .pipe(
                map(() => {
                  return deleteTrackMixs({
                    ids: trackMixes.map((trackMix: TrackMix) => trackMix.id)
                  });
                })
              );
          })
        );
      })
    )
  );

  constructor(
    private dss: DynamicStoreService,
    private actions$: Actions,
    private trackMixService: TrackMixesService,
    private store: Store,
    private zone: NgZone
  ) {
    this.saveRecordedAudio();
  }

  setTrackMixAsMaster$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setTrackMixAsMaster),
      switchMap(action => {
        return this.store.pipe(
          select(selectCurrentTrackMixes),
          take(1),
          map((_trackMixes: TrackMix[]) => {
            const trackMixes: Update<TrackMix>[] = [..._trackMixes].map(
              (trackMix: TrackMix) => {
                return {
                  id: trackMix.id,
                  changes: {
                    ...trackMix,
                    isReference: trackMix.trackId === action.trackMix.trackId
                  }
                };
              }
            );

            return updateTrackMixs({
              trackMixs: trackMixes
            });
          })
        );
      })
    );
  });

  clearTrackEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearTrackEffect),
        switchMap(action => {
          return this.store.pipe(
            select(getTrackMixesByTrackId, {
              trackId: action.trackModel.trackEntity.id
            }),
            take(1),
            switchMap((trackMixes: TrackMix[]) => {
              if (hasValue(trackMixes)) {
                const _trackMixes = trackMixes.map((trackMix: TrackMix) => {
                  const _trackMix: TrackMix = {
                    ...trackMix,
                    audioSnippets: {
                      ...trackMix.audioSnippets
                    }
                  };

                  const audioFileEntityIds = Object.keys(
                    _trackMix.audioSnippets
                  );

                  audioFileEntityIds.forEach((audioFileEntityId: string) => {
                    _trackMix.audioSnippets[audioFileEntityId] = {
                      ..._trackMix.audioSnippets[audioFileEntityId],
                      ...resetTrackMixAudioSnippet(
                        _trackMix.audioSnippets[audioFileEntityId]
                      )
                    };
                  });

                  return _trackMix;
                });

                // Will update local
                return this.trackMixService.batchUpdateFirestoreTrackMixes(
                  _trackMixes
                );
              } else {
                return EMPTY;
              }
            })
          );
        })
      ),
    {dispatch: false}
  );

  saveRecordedAudio() {
    const that = this;
    this.dss
      .event<LoadPlayerAudioWorklet>(this.dss.DYN_STORE.SAVE_RECORDED_AUDIO)
      .pipe(
        switchMap(action => {
          return this.store.pipe(
            select(getTrackMixesByTrackId, {
              trackId:
                action.trackEntityAndAudioFileMetaDataEntity.trackEntity.id
            }),
            take(1),
            switchMap((trackMixes: TrackMix[]) => {
              const updates: TrackMix[] = trackMixes.map((mix: TrackMix) => {
                return updateTrackMixAudio(mix, action);
              });

              return this.trackMixService.batchUpdateFirestoreTrackMixes(
                updates
              );
            })
          );
        })
      )
      .subscribe((mixes: TrackMix[]) => {
        that.zone.run(() => {
          that.store.dispatch(
            updateTrackMixs({
              trackMixs: mixes.map((mix: TrackMix) => {
                return {
                  id: mix.id,
                  changes: {
                    ...mix
                  }
                };
              })
            })
          );
        });
      });
  }
}
