import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {
  GlobalTableConfig,
  GlobalTableState,
  GlobalTableStateService
} from './global-table-state.service';

export interface TableRowDict {
  [rowId: string]: boolean;
}

export interface TableState {
  rowsExpanded: TableRowDict;
  config: GlobalTableConfig;
}

@Injectable()
export class TableStateStore extends ComponentStore<TableState> {
  readonly selectIsAllExpanded$ = this.select((state: TableState) => {
    if (state['config'].id) {
      return state['config'].allExpanded;
    }

    return state['config'].allExpanded;
  });

  readonly selectNotAllExpanded$ = this.select((state: TableState) => {
    // console.log(state);
    if (state['config'].id) {
      return !state['config'].allExpanded;
    }

    return !state['config'].allExpanded;
  });

  constructor() {
    super({
      rowsExpanded: {},
      config: {
        id: '',
        allExpanded: false
      }
    });
  }

  upsertTableId = this.updater(
    (state: TableState, config: GlobalTableConfig) => {
      // this.globalTableStateService.upsertTableId(config);

      return {
        ...state,
        config
      };
    }
  );

  init(ids: string[], allExpanded: boolean = true) {
    this.setState(state => {
      const rowsExpanded: TableRowDict = ids.reduce(
        (a: TableRowDict, i: string) => {
          a[i] = allExpanded;
          return a;
        },
        {}
      );

      return {
        ...state,
        allExpanded,
        rowsExpanded: {
          ...state.rowsExpanded,
          ...rowsExpanded
        },
        config: {
          ...state.config,
          allExpanded
        }
      };
    });
  }

  /**
   * TODO change to toggleRow
   * @param id
   */
  toggleRow(id: string) {
    this.setState((state: TableState) => {
      state.rowsExpanded[id] = !state.rowsExpanded[id];
      return state;
    });
  }

  expandAll = this.updater((state: TableState) => {
    Object.keys(state.rowsExpanded).forEach((key: string) => {
      state.rowsExpanded[key] = true;
    });

    state.config.allExpanded = true;

    return {
      ...state,
      config: {
        ...state.config
      }
    };
  });

  collapseAll = this.updater((state: TableState) => {
    Object.keys(state.rowsExpanded).forEach((key: string) => {
      state.rowsExpanded[key] = false;
    });

    state.config.allExpanded = false;

    return {
      ...state,
      config: {
        ...state.config
      }
    };
  });

  /**
   * TODO change to getRowExpanded
   * @param id
   */
  getExpanded(id: string): Observable<boolean> {
    // console.log(id);

    return this.select((state: TableState) => {
      return state.rowsExpanded[id];
    }).pipe(
      map((row: boolean) => {
        if (row !== undefined && row !== null) {
          return row;
        }

        return false;
      })
      // tap(( isExpanded: boolean ) => {
      //   console.log(id, isExpanded);
      // }),
    );
  }

  /**
   * TODO change to getRowCollapsed
   * @param id
   */
  getCollapsed(id: string): Observable<boolean> {
    return this.select((state: TableState) => {
      return state.rowsExpanded[id];
    }).pipe(
      map((row: boolean) => {
        if (row !== undefined && row !== null) {
          return !row;
        }

        return true;
      })
    );
  }

  toggleAll() {
    const that = this;

    this.selectIsAllExpanded$
      .pipe(take(1))
      .subscribe((allExpanded: boolean) => {
        // console.log(allExpanded);
        if (allExpanded) {
          that.collapseAll();
        } else {
          that.expandAll();
        }

        this.setState((_state: TableState) => {
          const state = {
            ..._state
          };

          if (allExpanded) {
            Object.keys(state.rowsExpanded).forEach((key: string) => {
              state.rowsExpanded[key] = false;
            });
          } else {
            Object.keys(state.rowsExpanded).forEach((key: string) => {
              state.rowsExpanded[key] = true;
            });
          }

          return state;
        });
      });
  }
}
