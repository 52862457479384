import {Component, Inject, Input} from '@angular/core';
import {marketingPaths, stopEvent} from '@spout/global-web/fns';
import {ENVIRONMENT, IEnvironmentState} from '@spout/global-web/models';
import {WINDOW} from '@spout/global-web/utils';

@Component({
  selector: 'spt-a-marketing',
  templateUrl: './spt-a-external.component.html',
  styleUrls: ['./spt-a-external.component.scss']
})
export class SptAExternalComponent {
  @Input() href = '';

  constructor(
    @Inject(ENVIRONMENT) private environment: IEnvironmentState,
    @Inject(WINDOW) private window: Window
  ) {}

  onClick(event: MouseEvent) {
    stopEvent(event);
    if (this.href && this.href.length) {
      this.window.open(
        marketingPaths(this.href, this.environment.production),
        '_blank'
      );
    }
  }
}
