import {select, Store} from '@ngrx/store';
import {
  AudioFileMetaDataEntity,
  ElectronErrorCodes,
  ElectronNativeAudio,
  FileUint8ArrayType,
  TrackMix,
  TrackMixAudioSnippet,
  TrackMixAudioSync
} from '@spout/global-any/models';
import {getDefaultWaveformValues} from '@spout/global-web/fns';
import {
  ApplyVolumeMix,
  LoadPlayerAudioWorklet,
  SONG_METRICS,
  TrackEntityAndAudioFileMetaDataEntity,
  WaveformValues,
  WaveformValuesTrackMixAudioSync
} from '@spout/global-web/models';
import {hasValuePipe, isDefinedPipe} from '@uiux/rxjs';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';
import {
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import {getAudioMetaDataById} from '../+audio-file-meta-data/audio-metadata-storage.selectors';
import {AudioFileLoadService} from '../+device-storage/services/audio-file-load.service';
import {AudioFileSaveService} from '../+device-storage/services/audio-file-save.service';
import {DeviceStorageService} from '../+device-storage/services/device-storage.service';
import {getTrackMixByTrackIdFn} from '../+track-mix/track-mix.selectors';
import {convertBlobToUint8Array$} from '../../../../fns/src/lib/audio/convert-to-uint8Array';
import {
  convertBlobToParsedRecordExport,
  convertUint8ArrayToParsedRecordExport
} from '../../../../fns/src/lib/audio/parse-record-export';
import {DynamicStoreService} from '../services/dynamic-store.service';
import {FirebaseStorageService} from '../services/firebase-storage.service';
import {volumeParamsEqual} from './helpers/audio-player.helpers';
import {getPlayerInstanceKeyByEntities} from './helpers/audio.helpers';
import {SongMetricsService} from './services/song-metrics.service';
import {SptVolumeTranslateService} from './services/spt-volume-translate.service';
import {SptMergerAudioworklet} from './spt-merger.audioworklet';
import {SharedBufferWorkletOptions} from './spt-recorder.audioworklet';
import {InputMergeMap, SptTransportService} from './spt-transport.service';

export class SptAudioPlayerWorklet extends AudioWorkletNode {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _isLoaded$: BehaviorSubject<boolean>;
  private _loadSub: Subscription;

  waveformValues$: ReplaySubject<WaveformValues | null>;
  trackMixAudioSync$: ReplaySubject<TrackMixAudioSync>;
  waveformValuesTrackMixAudioSync$: Observable<WaveformValuesTrackMixAudioSync>;

  onClear$: Subject<boolean>;
  onLoadAudio$: Subject<boolean>;

  trackId: string;
  audioFileMetaDataEntityId: string;

  // Sync
  start: number;
  offsetMs: number;
  stop: number;
  volume: number;
  masterVolume: number;

  private calculatedGain: number;

  // Volume
  private readonly _audioContextId: number;
  private readonly _gain: GainNode;

  set mute(m) {
    this.parameters.forEach(
      (value: AudioParam, key: string, parent: AudioParamMap) => {
        if (key === 'mute') {
          value.setValueAtTime(m ? 1 : 0, this._audioContext.currentTime);
        }
      }
    );
  }

  get mute() {
    let mute = false;
    this.parameters.forEach(
      (value: AudioParam, key: string, parent: AudioParamMap) => {
        if (key === 'mute') {
          mute = value.value === 1;
        }
      }
    );

    return mute;
  }

  unmute$() {
    this.mute = false;

    return of(true);
  }

  constructor(
    // TODO Is this needed if injected SptTransportService ?
    private _audioContext: AudioContext,
    public id: string,
    public a: TrackEntityAndAudioFileMetaDataEntity,
    private dss: DynamicStoreService,
    private store: Store,
    private transport: SptTransportService,
    private audioFileLoadService: AudioFileLoadService,
    private audioFileSaveService: AudioFileSaveService,
    private volumeService: SptVolumeTranslateService,
    private metrics: SongMetricsService,
    private _storage: FirebaseStorageService,
    public isActiveInPlaylist: boolean,
    private device: DeviceStorageService,
    options: SharedBufferWorkletOptions
  ) {
    super(_audioContext, 'shared-buffer-player-worklet-processor', options);

    this.transport.transportSharedArrayBuffer$
      .pipe(take(1))
      .subscribe((transportSharedArrayBuffer: SharedArrayBuffer) => {
        this.port.postMessage({
          message: 'INITIALIZE',
          sampleRate: this._audioContext.sampleRate,
          transportSharedArrayBuffer: transportSharedArrayBuffer
        });
      });

    const that = this;

    this._gain = this._audioContext.createGain();
    this.connect(this._gain);

    this._audioContextId = (<any>this._audioContext)['id'];

    // console.log('AudioContext', audioContext);

    this.trackId = this.a.trackEntity.id;
    this.audioFileMetaDataEntityId = this.a.audioFileMetaDataEntity.id;

    this._isLoaded$ = new BehaviorSubject<boolean>(false);
    this._loadSub = Subscription.EMPTY;
    // this.waveformValues$ = new BehaviorSubject<WaveformValues>(getDefaultWaveformValues());
    this.waveformValues$ = new ReplaySubject<WaveformValues | null>(1);
    this.trackMixAudioSync$ = new ReplaySubject<TrackMixAudioSync>(1);

    this.onLoadAudio$ = new Subject<boolean>();
    this.onClear$ = new Subject<boolean>();
    this.start = 0;
    this.offsetMs = 0;
    this.stop = 0;

    this.volume = 0;
    this.calculatedGain = 1;
    this.masterVolume = 0;

    // this.metrics.metrics$
    //   .pipe(
    //     map((m: MixMetrics) => m.defaultDuration),
    //     distinctUntilChanged()
    //   )
    //   .subscribe(defaultDuration => {
    //     this.waveformValues$.next(getDefaultWaveformValues(this._audioContext.sampleRate, defaultDuration, false));
    //   });

    this.waveformValuesTrackMixAudioSync$ = combineLatest([
      this.waveformValues$,
      this.trackMixAudioSync$
    ]).pipe(
      map(
        ([waveformValues, trackMixAudioSync]: [
          WaveformValues | null,
          TrackMixAudioSync
        ]) => {
          return {
            waveformValues,
            trackMixAudioSync
          };
        }
      ),
      takeUntil(this._onDestroy$)
    );

    this.port.onmessage = function (event) {
      const data = event.data;

      if (data.message === 'PLAYER_READY') {
      }
    };

    // Load recorded audio
    this.dss
      .event<LoadPlayerAudioWorklet>(this.dss.DYN_STORE.SAVE_RECORDED_AUDIO)
      .pipe(
        filter((data: LoadPlayerAudioWorklet) => {
          return (
            getPlayerInstanceKeyByEntities(
              data.trackEntityAndAudioFileMetaDataEntity
            ) === this.id
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((data: LoadPlayerAudioWorklet) => {
        // console.log('load DYN_STORE.saveRecordedAudio', data);
        this.loadBuffer(data);
        this.waveformValues$.next(data.waveformValues);
      });

    // Get Latest
    this.store
      .pipe(
        select(getAudioMetaDataById, {
          audioFileMetaDataId: this.audioFileMetaDataEntityId
        }),
        isDefinedPipe<
          AudioFileMetaDataEntity | undefined,
          AudioFileMetaDataEntity
        >(),
        filter((_a: AudioFileMetaDataEntity) => _a.fileUploaded),
        distinctUntilKeyChanged<AudioFileMetaDataEntity>('fileUploaded'),
        takeUntil(this._onDestroy$)
      )
      .subscribe((_a: AudioFileMetaDataEntity) => {
        that.loadFromSystem.call(this, _a);
      });

    this.store
      .pipe(
        select(getAudioMetaDataById, {
          audioFileMetaDataId: this.audioFileMetaDataEntityId
        }),
        isDefinedPipe<
          AudioFileMetaDataEntity | undefined,
          AudioFileMetaDataEntity
        >(),
        filter((_a: AudioFileMetaDataEntity) => !(_a && _a.fileUploaded)),
        distinctUntilKeyChanged<AudioFileMetaDataEntity>('fileUploaded'),
        takeUntil(this._onDestroy$)
      )
      .subscribe((_a: AudioFileMetaDataEntity) => {
        // that.disconnect();
        that.clear();
      });

    this.transport.onStopTimeSeconds$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((seconds: number) => {
        this.port.postMessage({
          message: 'SET_CURRENT_TIME_MS'
        });
      });

    /**
     * Apply TrackMixAudioSnippet options
     */
    combineLatest([
      this.onLoadAudio$.pipe(filter(audioLoaded => audioLoaded)),
      this.store.pipe(
        select(getTrackMixByTrackIdFn({trackId: this.a.trackEntity.id})),
        hasValuePipe<TrackMix | null, TrackMix>(),
        map((trackMix: TrackMix) => Object.values(trackMix.audioSnippets)[0])
      )
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        ([_, trackMixAudioSnippet]: [boolean, TrackMixAudioSnippet]) => {
          // console.log(
          //   'Apply TrackMixAudioSnippet options',
          //   _,
          //   trackMixAudioSnippet
          // );

          this.start = trackMixAudioSnippet.start;
          this.stop = trackMixAudioSnippet.stop;
          this.offsetMs = trackMixAudioSnippet.offsetMs;

          this.port.postMessage({
            message: 'SET_MIX_OPTIONS',
            mixOptions: {
              ...trackMixAudioSnippet
              // sampleRate: this._audioContext.sampleRate
            }
          });

          this.trackMixAudioSync$.next(trackMixAudioSnippet);
        }
      );
  }

  private loadFromSystem(a: AudioFileMetaDataEntity) {
    const that = this;
    // console.log(a);
    this._loadSub.unsubscribe();
    this._loadSub = this.audioFileLoadService
      .loadFileFromDisk(a)
      .pipe(
        switchMap((response: ElectronNativeAudio) => {
          if (
            response.uint8ArrayType &&
            response.uint8ArrayType.uint8Array &&
            !(
              response &&
              response.error &&
              response.error.error &&
              response.error.error.code ===
                ElectronErrorCodes.FILE_DOES_NOT_EXIST
            )
          ) {
            return from(
              convertUint8ArrayToParsedRecordExport(
                that.a,
                response.uint8ArrayType,
                that._audioContext
              )
            );
          } else if (a.fileUploaded) {
            // console.log('DOWNLOADING', a);
            return this._storage.downloadStorage(a).pipe(
              // tap((d: {progress: number; result: Blob | null}) => {
              //   console.log(d.result);
              // }),
              filter(
                (d: {progress: number; result: Blob | null}) =>
                  d && d.result !== null
              ),
              map((d: {progress: number; result: Blob | null}) => d.result),
              switchMap((result: Blob | null) => {
                // console.log(result);
                if (result) {
                  convertBlobToUint8Array$(result)
                    .pipe(
                      switchMap((uint8ArrayType: FileUint8ArrayType) => {
                        return this.audioFileSaveService.saveNativeAudioFile({
                          uint8ArrayType,
                          audioFileMetaDataEntity:
                            that.a.audioFileMetaDataEntity
                        });
                      })
                    )
                    .subscribe(() => {
                      /* noop */
                    });

                  return from(
                    convertBlobToParsedRecordExport(
                      that.a,
                      result,
                      that._audioContext
                    )
                  );
                }

                return of(null);
              })
            );
          } else {
            // console.log('FILESYSTEM');
            return of(null);
          }
        }),

        // TODO BLOB

        takeUntil(this._onDestroy$)
      )
      .subscribe((data: LoadPlayerAudioWorklet | null) => {
        // console.log('PARSED', data.uint8ArrayType.uint8Array);
        if (data) {
          that.loadBuffer.call(that, data);
        }
      });
  }

  /**
   * Push audio data to AudioWorklet
   * Publish Audio metrics
   * @param data
   * @private
   */
  private loadBuffer(data: LoadPlayerAudioWorklet) {
    const that = this;
    // console.log('LOADBUFFER', data.uint8ArrayType.uint8Array);

    this.transport.transportSharedArrayBuffer$
      .pipe(take(1))
      .subscribe(async (transportSharedArrayBuffer: SharedArrayBuffer) => {
        // Only load if has data
        if (!(data && that.transport && transportSharedArrayBuffer)) {
          return;
        }

        // console.log(data);

        that.waveformValues$.next(data.waveformValues);

        that._isLoaded$.next(true);

        // console.log(data);

        if (
          data.inputs.length &&
          data.inputs[0].length &&
          data.inputs[0][0].length > 0
        ) {
          const bufferPayload = {
            message: 'LOAD_BUFFER',
            transportSharedArrayBuffer: transportSharedArrayBuffer,
            inputs: data.inputs,
            sampleRate: this._audioContext.sampleRate,
            trackName:
              data.trackEntityAndAudioFileMetaDataEntity.trackEntity.name
          };

          try {
            // console.log(JSON.stringify(bufferPayload));
            that.port.postMessage(bufferPayload);
          } catch (e) {
            console.log(e);
          }
        }

        this.onLoadAudio$.next(true);
      });
  }

  connectOutput(dest: AudioDestinationNode) {
    this._gain.connect(dest);
  }

  connectMerger(merger: SptMergerAudioworklet, inputMap: InputMergeMap) {
    this._gain.connect(merger, 0, inputMap[this.id]);
  }

  disconnectOutput$(): Observable<boolean> {
    // console.log('disconnectOutput destroy');
    this._gain.disconnect();
    return of(true);
  }

  mute$(m: boolean): Observable<boolean> {
    this.mute = true;

    return of(true);
  }

  /**
   * Apply Volume
   * @param v
   */
  setVolume(v: ApplyVolumeMix): void {
    const volume = this.volumeService.percentToDecibelWithMaster(
      v.volume,
      v.masterVolume
    );

    // console.log('percentToDecibelWithMaster -->', volume);

    if (!volumeParamsEqual(this, v)) {
      if (volume !== this.volume || this.masterVolume !== v.masterVolume) {
        // console.log('apply volume');
        const calculatedGainWithMaster =
          this.volumeService.calculatedGainWithMaster(v.volume, v.masterVolume);
        // console.log('calculatedGainWithMaster', calculatedGainWithMaster);

        this.calculatedGain = calculatedGainWithMaster;
        this.volume = v.volume;
        this.masterVolume = v.masterVolume;
        this._gain.gain.value = calculatedGainWithMaster;
      }

      /**
       * NOTE: Mute must come after setting volume to apply
       */
      if (this.mute !== v.mute) {
        this.mute = v.mute;
      }

      if (this.mute) {
        this._gain.gain.value = 0;
      }
    }
  }

  /**
   * Delete audio from file system and cloud storage
   */
  clear() {
    // console.log('CLEAR');
    this.port.postMessage({
      message: 'CLEAR'
    });
    this._isLoaded$.next(false);
    this.onClear$.next(true);
    this.waveformValues$.next(null);

    this.waveformValues$.next(
      getDefaultWaveformValues(
        this._audioContext.sampleRate,
        SONG_METRICS.BASE_TRACK_DURATION, // is 0
        false
      )
    );
  }

  destroy() {
    // console.log('disconnect destroy');
    this.disconnect();
    this._onDestroy$.next(true);
    this.port.postMessage({
      message: 'CLEAR'
    });
  }

  audioContextIdMatches(id: number): boolean {
    return this._audioContextId === id;
  }
}
