import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {LetModule, PushModule} from '@ngrx/component';
import {SptSliderComponent} from './spt-slider.component';

@NgModule({
  declarations: [SptSliderComponent],
  exports: [SptSliderComponent],
  imports: [CommonModule, MatSliderModule, LetModule, PushModule]
})
export class SptSliderModule {}
