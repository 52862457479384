import {Action, createReducer, on} from '@ngrx/store';
import {
  initialLatencyState,
  latencyAdapter,
  LatencyState
} from '@spout/global-web/models';
import {
  addLatencies,
  addLatency,
  clearLatencies,
  deleteLatencies,
  deleteLatency,
  loadLatencies,
  updateLatencies,
  updateLatency,
  upsertLatencies,
  upsertLatency
} from './latency.actions';

const reducer = createReducer(
  initialLatencyState,
  on(addLatency, (state, action) =>
    latencyAdapter.addOne(action.latency, state)
  ),
  on(upsertLatency, (state, action) =>
    latencyAdapter.upsertOne(action.latency, state)
  ),
  on(addLatencies, (state, action) =>
    latencyAdapter.addMany(action.latencies, state)
  ),
  on(upsertLatencies, (state, action) =>
    latencyAdapter.upsertMany(action.latencies, state)
  ),
  on(updateLatency, (state, action) =>
    latencyAdapter.updateOne(action.latency, state)
  ),
  on(updateLatencies, (state, action) =>
    latencyAdapter.updateMany(action.latencies, state)
  ),
  on(deleteLatency, (state, action) =>
    latencyAdapter.removeOne(action.id, state)
  ),
  on(deleteLatencies, (state, action) =>
    latencyAdapter.removeMany(action.ids, state)
  ),
  on(loadLatencies, (state, action) =>
    latencyAdapter.setAll(action.latencies, state)
  ),
  on(clearLatencies, state => latencyAdapter.removeAll(state))
);

export function latencyReducer(
  state: LatencyState | undefined,
  action: Action
) {
  return reducer(state, action);
}
