import {Dictionary} from '@ngrx/entity/src/models';
import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {
  CurrentIds,
  CurrentMixSongMixes,
  MixEntity,
  SongEntity
} from '@spout/global-any/models';
import {
  createPassThroughFeatureSelector,
  createPassThroughSelector,
  getDefaultEntityConfig,
  isCreatedByLoggedInMusician
} from '@spout/global-web/fns';
import {
  AccountState,
  mixesFeatureKey,
  MixState
} from '@spout/global-web/models';
import {getIn} from '@uiux/fn';
import {isDefinedPipe} from '@uiux/rxjs';
import {pipe} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {selectAccountState, selectUid} from '../+account/account.selectors';
import {selectCurrentIDsFromStore} from '../+device-storage/device-storage.selectors';
import {selectCurrentSongEntity} from '../+songs/song-storage.selectors';
import {StudioAppState} from '../models/studio-app-state.model';

// MIX MIX MIX MIX
// MIX MIX MIX MIX
// MIX MIX MIX MIX
// MIX MIX MIX MIX
export const mixSate = createFeatureSelector<MixState>(mixesFeatureKey);
export const mixSate_passThrough =
  createPassThroughFeatureSelector<MixState>(mixesFeatureKey);

export const selectMixEntities = createSelector(
  mixSate,
  (state: MixState): Dictionary<MixEntity> => {
    return state.entities;
  }
);

export const selectMixEntities_passThrough = createPassThroughSelector(
  mixSate_passThrough,
  (state: MixState): Dictionary<MixEntity> => {
    return state.entities;
  }
);

export const selectAllMixes = createSelector(
  selectMixEntities,
  (entities: Dictionary<MixEntity>): MixEntity[] => {
    if (Object.values(entities).length) {
      return <MixEntity[]>Object.values(entities);
    }

    return [];
  }
);

export const getMixEntitiesBySongIdPassThrough = (props: {songId: string}) => {
  return createPassThroughSelector(
    selectAllMixes,
    (mixes: MixEntity[]): MixEntity[] => {
      return mixes.filter((mix: MixEntity) => mix.songId === props.songId);
    }
  );
};

export const getMixEntitiesByProjectId = createSelector(
  selectAllMixes,
  (mixes: MixEntity[], props: {projectId: string}) => {
    return mixes.filter((mix: MixEntity) => mix.projectId === props.projectId);
  }
);

export const getMixEntitiesByProjectIdFn = (projectId: string) =>
  createSelector(selectAllMixes, (mixes: MixEntity[]) => {
    return mixes.filter((mix: MixEntity) => mix.projectId === projectId);
  });

export const selectDefaultMix = createSelector(
  selectAllMixes,
  selectAccountState,
  (entities: MixEntity[], account: AccountState): MixEntity | null => {
    return <MixEntity>(
      getDefaultEntityConfig(
        <{isDefault: boolean; createdByUID: string}[]>entities,
        account
      )
    );
  }
);

export const selectCurrentMixEntity = createSelector(
  selectAllMixes,
  selectCurrentSongEntity,
  selectCurrentIDsFromStore,
  (
    mixes: MixEntity[],
    currentSong: SongEntity | null,
    ids: CurrentIds | null
  ): MixEntity | null => {
    // console.log('currentIds', ids);
    if (mixes && mixes.length && currentSong) {
      let mixSongs: MixEntity[] = mixes.filter(
        (m: MixEntity) => m.songId === currentSong.id
      );

      if (mixSongs && mixSongs.length && ids) {
        let currentMix = mixSongs.find(
          (m: MixEntity) => m.id === ids.currentMixId
        );

        if (currentMix) {
          return currentMix;
        }

        return mixSongs[0];
      }
    }

    return null;
  }
);

export const selectCurrentMixWithSongMixes = (
  state: StudioAppState
): {currentMix: MixEntity | null | undefined; songMixes: MixEntity[]} => {
  const currentMixId: string | null = getIn(
    state,
    'deviceStorage.currentIds.currentMixId',
    null
  );
  const currentSongId: string | null = getIn(
    state,
    'deviceStorage.currentIds.currentSongId',
    null
  );

  if (
    state.mix &&
    state.mix.entities &&
    currentMixId &&
    state.mix.entities[currentMixId]
  ) {
    return {
      songMixes: <MixEntity[]>(
        Object.values(state.mix.entities).filter(
          (mix: MixEntity | undefined) => mix && mix.songId === currentSongId
        )
      ),
      currentMix: state.mix.entities[currentMixId]
    };
  }

  return {
    currentMix: null,
    songMixes: []
  };
};

export const selectCurrentMixWithSongMixes$ = pipe(
  select(selectCurrentMixWithSongMixes),
  isDefinedPipe<CurrentMixSongMixes, CurrentMixSongMixes>(),
  map((d: CurrentMixSongMixes) => JSON.stringify(d)),
  distinctUntilChanged(),
  map((d: string): CurrentMixSongMixes => <CurrentMixSongMixes>JSON.parse(d))
);

export const selectCurrentMixEntityId = createSelector(
  selectCurrentMixEntity,
  mixEntity => {
    if (mixEntity) {
      return mixEntity.id;
    }
    return null;
  }
);

export const selectCurrentMixEntityNamePassThrough = createSelector(
  selectCurrentMixEntity,
  mixEntity => {
    if (mixEntity) {
      return mixEntity.name;
    }
    return null;
  }
);

export const getCurrentMixIsCreatedByLoggedInUser = createSelector(
  selectUid,
  selectCurrentMixEntity,
  (uid: string, currentMix: MixEntity | null) =>
    isCreatedByLoggedInMusician(uid, currentMix)
);

export const getMixEntityById = createSelector(
  selectMixEntities,
  (
    entities: Dictionary<MixEntity>,
    props: {id: string}
  ): MixEntity | undefined => entities[props.id]
);
